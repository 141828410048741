import React from "react";
import { useBtnOptionMenu } from "../../../../Hooks/useBtnOptionMenu";
import { ViewCourseModalInviteMenbers } from "./ViewCourseModalInviteMembers";
import "./../../css/view-course-options.css";

export const ViewCourseOptionsItemMobile = ({
  exist,
  click,
  clickPending,
  clickExit,
  isActive,
}) => {
  const { openModal, notiClass, wrapperRef } = useBtnOptionMenu();

  return (
    <div ref={wrapperRef} className="ml-2">
      <a
        id="dropdownMenu4"
        className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss pointer"
        data-toggle="dropdownMenu4"
        aria-haspopup="true"
        onClick={openModal}
        aria-expanded="false"
      ></a>
      <div
        className={`dropdown-menu p-3 rounded-xxl border-0 ms-auto mt-4 content-modal-view-course shadow-lg  ${notiClass}`}
        aria-labelledby="dropdownMenu3"
      >
        <div style={{ borderBottom: "1px solid #00589b60" }}>
          <h6 className="fw-700 font-xss mb-3">Option menu</h6>
        </div>
        <ul>
          <>
            {!isActive && (
              <>
                {exist == "exit" && (
                  <li
                    onClick={click}
                    className="cursor-pointer d-flex"
                    style={{ borderBottom: "1px solid #00589b60" }}
                  >
                    <div className="icon-li ">
                      <i className="font-sm feather-user-plus text-grey"></i>
                    </div>
                    <div className="text-li">
                      <span className="pb-1"> Join the course </span>
                    </div>
                  </li>
                )}
                {exist == "pending" && (
                  <li
                    onClick={clickPending}
                    className="cursor-pointer d-flex"
                    style={{ borderBottom: "1px solid #00589b60" }}
                  >
                    <div className="icon-li ">
                      <i className="font-sm feather-user-x text-grey"></i>
                    </div>
                    <div className="text-li">
                      <span className="pb-1"> Pending approval </span>
                    </div>
                  </li>
                )}
                {exist == "success" && (
                  <>
                    <ViewCourseModalInviteMenbers />
                    <li
                      onClick={clickExit}
                      className="cursor-pointer d-flex"
                      style={{ borderBottom: "1px solid #00589b60" }}
                    >
                      <div className="icon-li ">
                        <i className="font-sm feather-user-minus text-grey"></i>
                      </div>
                      <div className="text-li">
                        <span className="pb-1"> Exit the course </span>
                      </div>
                    </li>
                  </>
                )}
              </>
            )}
          </>

          {isActive && <ViewCourseModalInviteMenbers />}
        </ul>
      </div>
    </div>
  );
};
