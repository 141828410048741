import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setNewNotificationReset } from "actions/notification";
import { chatCountMessage } from "actions/ui";
import { client } from "helpers/chat";

export const useHeader = () => {
  const dispatch = useDispatch();

  let { photoUrl, name, uid } = useSelector((state) => state.auth);
  const { newNotification } = useSelector((state) => state.notifications);
  const {countMessage} = useSelector( state => state.chat );
  name = name.replaceAll(' ', '_');

  const { updateCoverPage, updateProfilePage } = useSelector(
    (state) => state.user
  );
  const [currentTimeStamp, setCurrentTimeStamp] = useState(
    new Date().getTime()
  );

  //notification message

  useEffect(() => {
    return ()=> {
      client.on(event => { 
        if (event?.total_unread_count !== null && event?.total_unread_count > 0 && event?.total_unread_count ) { 
          console.log(`unread messages count is now: ${event.total_unread_count}`); 
          dispatch(chatCountMessage(event?.total_unread_count));
        }else if(event?.total_unread_count == 0){
          dispatch(chatCountMessage(0));
        }
      }); 
    }
  }, []);
  

  

  useEffect(() => {
    setCurrentTimeStamp(new Date().getTime());
  }, [updateCoverPage, updateProfilePage]);

  const ProfilePhoto = photoUrl?.includes("https://storage.googleapis")
    ? photoUrl + `&version=` + currentTimeStamp
    : photoUrl;
  const [state, setState] = useState({
    isOpen: false,
    isActive: false,
    isNoti: false,
  });

  const toggleOpen = () => setState({ isOpen: !state.isOpen });
  const toggleActive = () => setState({ isActive: !state.isActive });
  const toggleisNoti = () => setState({ isNoti: !state.isNoti });

  const handleOpenNotification = () => {
    dispatch(setNewNotificationReset()); 
  }

  return {state,
    toggleisNoti,
    toggleOpen,
    toggleActive,
    ProfilePhoto,
    currentTimeStamp,
    setCurrentTimeStamp,
    updateProfilePage,
    updateCoverPage,
    name, 
    uid,
    newNotification,
    handleOpenNotification,
    countMessage
  }
};
