import React from 'react'

export const GoBackNavigate = ({ onClick }) => {
    return (
        <div style={{ position: 'absolute' , margin: '10px', cursor: 'pointer'}}>
            <div onClick={ onClick }>
            <i className="feather-arrow-left font-sm bg-greylight btn-round-sm theme-dark-bg text-grey-500 "></i>
            </div>
        </div>
    )
}
