import { types } from "../types/types";

const inicialState ={
    loading : true,
    data : null,
    isadmin: false,

}

export const adminReducer = (state = inicialState, action )=>{
   
    switch (action.type) {
        case types.adminGetInformacionGeneral:
            return{
               ...state,
               loading: false,
               data: action.payload
            }
            case types.isAdminActive:
                return{
                   ...state,
                   isadmin: action.payload
                }
    
        default:
            return state
    }


}