import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllOrganizations } from "actions/organization";
import { addExperienceOrganizationForProfile } from "actions/profile";
import { setError, removeError } from "actions/ui";

export const useModalUserExperience = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { uid } = useSelector((state) => state.auth);
  const { msgError } = useSelector((state) => state.ui);

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [select, seSelect] = useState("");
  const [inputJob, setInputJob] = useState("");
  const { allOrganization } = useSelector((state) => state.organization);

  useEffect(() => {
    if (show) {
      dispatch(getAllOrganizations());
    }
  }, [show]);

  function onChangeInput(e) {
    const data = e.target.value;
    setInputJob(data);
  }

  //  Validations
  const isFormValidate = () => {
    const pattern = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g;

    if (inputJob.trim().length >= 1 && !pattern.test(inputJob)) {
      dispatch(setError("Must not have special or numeric characters"));
      return false;
    } else if (inputJob.trim().length < 2) {
      dispatch(setError("Add job"));
      return false;
    } else if (!select) {
      dispatch(setError("you must select organizations"));
      return false;
    }

    dispatch(removeError());

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const end =
      endDate.getFullYear() +
      "-" +
      (endDate.getMonth() + 1) +
      "-" +
      endDate.getDate();
    const start =
      startDate.getFullYear() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getDate();
    const data = {
      uid: `${start}-${end}-${select?.value}`,
      userId: uid,
      startDate: start,
      endDate: end,
      job: inputJob,
      organizationId: select?.value,
      type: 'create'
    };

    if (isFormValidate()) {
      dispatch(addExperienceOrganizationForProfile(data, "create"));
      handleClose();
    }
  };

  return {
    handleShow,
    show,
    handleClose,
    handleSubmit,
    msgError,
    onChangeInput,
    seSelect,
    allOrganization,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };
};
