import React from "react";
import Header from "components/headers/Header";
import Appfooter from 'components/Appfooter';
import Popupchat from 'components/Popupchat';
import PropTypes from 'prop-types';

export const ContainerRouteProfileOptions = ({ component: Component }) => {
    return (
            <> 
                <Header />
                {/* <Leftnav />
                <Rightchat /> */}
                
                  <Component key={ Component } />
                
                <Popupchat />
                <Appfooter /> 
            </>
        
    )
}

ContainerRouteProfileOptions.propTypes = {
    component: PropTypes.func.isRequired,
}