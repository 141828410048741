import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCourseSuggestions } from "actions/course";

 let theRequestWasMade = false;

export const useCourseSuggestions = () => {
  const dispatch = useDispatch();
  const { courseSuggestions, loadingSuggestion: loading } = useSelector((state) => state.courses);
  
  const [courseSuggestionslist, setCourseSuggestionslist] = useState(courseSuggestions);

  useEffect(() => {
    if (!theRequestWasMade && courseSuggestions?.length <= 0) {
      dispatch(getCourseSuggestions());
      theRequestWasMade = true;
    }
  }, [courseSuggestions]);

  useEffect(() => {
    if (courseSuggestions?.length > 0 && courseSuggestionslist?.length <= 0) {
      setCourseSuggestionslist(courseSuggestions);
    }
  }, [courseSuggestions, courseSuggestionslist]);

  return {
    courseSuggestionslist,
    loading,
  };
};
