import React from "react";
import { SpinnerCustom } from "../../../shared/SpinnerCustom";
import { ViewProjectModalInviteMenbers } from "./ViewProjectModalInviteMembers";

export const ViewProjectOptionsItemBrowser = ({
  exist,
  click,
  loading,
  clickExit,
  clickPending,
  isActive,
}) => {
  return (
    <>
      { !isActive && (
          <>
            {exist == "exit" && (
              <div className=" cursor-pointer d-flex align-items-center justify-content-center pl-2 pr-3 right-15 top-0">
                <a
                  onClick={click}
                  className=" ms-auto d-none d-lg-block bg-success p-2 z-index-1 rounded-3 text-white font-xsss  fw-700 ls-3"
                >
                  {loading && <SpinnerCustom />}
                  {!loading && "Join the project"}
                </a>
              </div>
            )}

            {exist == "pending" && (
              <div className=" cursor-pointer d-flex align-items-center justify-content-center pl-2 pr-3 right-15 top-0">
                <a
                  onClick={clickPending}
                  className=" ms-auto d-none d-lg-block bg-info p-2 z-index-1 rounded-3 text-white font-xsss  fw-700 ls-3"
                >
                  {loading && <SpinnerCustom />}
                  {!loading && "Pending approval"}
                </a>
              </div>
            )}

            {exist == "success" && (
              <div className="d-flex ms-auto position-relative">
                <ViewProjectModalInviteMenbers />
                <div className=" cursor-pointer d-flex align-items-center justify-content-center pl-2 pr-3 right-15 top-0 ">
                  <a
                    onClick={clickExit}
                    className=" ms-auto d-none d-lg-block bg-danger p-2 z-index-1 rounded-3 text-white font-xsss  fw-700 ls-3"
                  >
                    {loading && <SpinnerCustom />}
                    {!loading && "Exit the project"}
                  </a>
                </div>
              </div>
            )}
          </>
        )}
      {isActive && <ViewProjectModalInviteMenbers />}
    </>
  );
};
