import { format } from 'date-fns';
import React, { useState } from 'react'
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { inviteMembers } from '../../../../actions/notification';
import { AddMembers } from '../../../shared/AddMembers';
import { useMediaQuery } from "react-responsive";

export const ViewCourseModalInviteMenbers = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {uid} = useSelector( state => state.auth);
  const {tempCourse} = useSelector( state => state.course );
  const [members, setMembers] = useState([]);
  const dispatch = useDispatch();
  const onChangeMembers = (data) => {
    setMembers(data);
  }

  const inviteMembersSubmit=(e)=>{
    e.preventDefault();
   const Ids = members.target.value.map(value =>{ 
     return value.value;
    })
    const date = format(new Date(), "dd/MM/yyyy HH:mm:ss")
    const data ={
        uid: '',
        type: 'invitationCourse',
        userFromId: uid,
        params: {
          userToId: Ids,
          courseToId: tempCourse?.uid,
        },
        notification: "",
        state: false,
        createDate: date, 
    }
    dispatch(inviteMembers(data));
  }
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });

    return (
        <>
          {
            isDesktopOrLaptop ?
            <div onClick={handleShow} className="cursor-pointer  align-items-center justify-content-center pl-3 mt-1 top-0 ">
            <a className=" d-none d-lg-block bg-success p-2 z-index-1 rounded-3 text-white font-xsss fw-700 ls-3">
              + Invite
            </a>
          </div>
            :  <li onClick={handleShow} className="cursor-pointer d-flex" style={{ borderBottom: '1px solid #00589b60' }}>
                <div className="icon-li ">
                  <i className="font-sm feather-user-plus text-grey"></i>
                </div>
                <div className="text-li">
                  <span className="pb-1">  Invite </span>
                </div>
              </li>
          }
          
        <Modal show={show} onHide={handleClose}>
        <form onSubmit={inviteMembersSubmit}>
          <Modal.Header>
            <Modal.Title>Invite friends to this group</Modal.Title>
            <button
              className="btn ti-close"
              type="button"
              onClick={handleClose}
              closeButton
            ></button>
          </Modal.Header>
          <Modal.Body>
            <AddMembers handleInputChange={onChangeMembers}></AddMembers>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="outline-info" onClick={handleClose}>
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
        </>
    )
}
