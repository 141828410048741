import React from "react";
import PropTypes from "prop-types";
import { TypeShare } from "./TypeShare";

export const AddTypeShare = ({
  data,
  titleAddTypeShare,
  name,
  handleInputChange,
  ref,
}) => {
  return (
    <div style={{ marginBottom: 5, padding: 10 }}>
      <label className="mont-font fw-600 font-xsss" htmlFor="Members">
        {titleAddTypeShare}{" "}
      </label>
      <TypeShare
        ref={ref}
        data={data}
        name={name}
        onChange={handleInputChange}
      ></TypeShare>
    </div>
  );
};

AddTypeShare.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
};
