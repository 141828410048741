import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { imageExistsProfile } from 'helpers/verifyImg';

export const CardListUsersStyle = ({ photoUrl, userName, uid }) => {
    return (
        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
             <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                 <figure className="overflow-hidden avatar mb-0 position-relative d-flex z-index-1"><img src={`${photoUrl}`} onError={ imageExistsProfile } alt="avater" className="float-right p-0 bg-white border rounded-circle  shadow-xss" style={{ width: "100px", height: "100px", borderRadius: "50px", objectFit: 'cover', margin: 'auto' }}/></figure>
                 <div className="clearfix w-100"></div>
                 <h4 className="fw-700 font-xsss mt-3 mb-0">{userName} </h4>
                 <Link to={ `/PROFILE/${(userName)?.replaceAll(' ', '_')}/${uid}/` } className="mt-4 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">VIEW FRIEND</Link>
             </div>
        </div>
        )
}
