import React from "react";

import Header from "../components/headers/Header";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Friends from "../components/suggestions/FriendsSuggestions";
import CreatePost from "../components/post/CreatePost";
import GroupSuggestions from "../components/suggestions/GroupSuggestions";
import ProjectSuggestions from "../components/suggestions/ProjectSuggestions";
import CourseSuggestions from "../components/suggestions/CourseSuggestions";
import PostViewList from "../components/post/PostViewList";

import { useMediaQuery } from "react-responsive";
import Leftnav from "../components/Leftnav";
import { CardLoadingFiles } from "components/Loadings/CardLoadingFiles";

export const Home = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });
  return (
    <>
      <Header />
      {/* <Leftnav /> */}
      <div className="main-content right-chat-active ">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body">
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                <CreatePost />
                <CardLoadingFiles/>
                <PostViewList />
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                {isDesktopOrLaptop && (
                  <>
                    <Friends />
                    <GroupSuggestions />
                    <ProjectSuggestions />
                    <CourseSuggestions />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default Home;
