import { useEffect, useRef } from "react";

export const useScroll = () => {
    const divRref = useRef(null);

  useEffect(() => {
    divRref.current.scrollIntoView({ behavior: 'smooth' });
  });
    return {
      divRref
    }
}
