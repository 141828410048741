import React from "react";
import Load from "components/Load";

import { usePostView } from "Hooks/post/usePostView";
import { usePostComents } from "Hooks/post/usePostComents";
import { LoadingCommentsPost } from "components/Loadings/LoadingCommentsPost";
import { UsePostViewLike } from "Hooks/post/UsePostViewLike";
import { CommentPostViewItem } from "../CommentPostViewItem";
import { Link } from "react-router-dom";
import { imageExistsProfile } from "helpers/verifyImg";
import "../../../../css/PostViewCommentStyle.css";

export const CommentPostViewBrowser = ({
  post,
  type,
  typeLike,
  // comment,
  groupId,
}) => {
  const { userId, toggleActive } = usePostView();
  const { onDistLike, onLike } = UsePostViewLike(
    post?.uid,
    type,
    typeLike,
    groupId
  );
  // const commemtCount = comment?.quentityComment;
  const {
    HandleSubmitComents,
    contentComment,
    lastCommentElementRef,
    loadingPagination,
    changeInput,
    loading,
    ListComments,
    loadMore,
    loadMoreComment,
    isLoadMoreComment,
  } = usePostComents(type, groupId, post);

  return (
    <div className="card shadow-xss border-0 comment-card">
      <div className="card-body p-0">
        <div className="row w-100 p-0 m-0">
          <div className="col-12">
            <div className=" d-flex">
              <figure className="avatar me-3">
                <img
                  src={post?.publicUser?.photoUrl}
                  onError={imageExistsProfile}
                  alt="avater"
                  className="shadow-sm rounded-circle"
                  style={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "50px",
                  }}
                />
              </figure>
              <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                {post?.publicUser?.userName}
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {post?.createDate}
                </span>
              </h4>

              {post?.type && post?.linkTo && (
                <span className="position-absolute right-15 top-15 d-flex align-items-center">
                  <Link to={post?.linkTo}>
                    <div
                      className="text-center p-2 me-3 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                      style={{ cursor: "pointer" }}
                    >
                      OPEN
                    </div>
                  </Link>
                </span>
              )}
            </div>
            <span>
              {loadMoreComment}
              {post?.content.length > 200 && (
                <div
                  className="load-more pointer text-info fs-6"
                  onClick={loadMore}
                >
                  {isLoadMoreComment ? "See Less" : " See more"}
                </div>
              )}
            </span>
            <hr />
            <div className="card-body d-flex p-0">
              <div
                className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
                onClick={toggleActive}
              >
                {!post?.like?.itsYouLike && (
                  <button
                    onClick={onLike}
                    className="feather-thumbs-up border text-black bg-light-gradiant me-1 btn-round-xs font-xss"
                  ></button>
                )}
                {post?.like?.itsYouLike && (
                  <button
                    onClick={onDistLike}
                    className="feather-thumbs-up border text-white bg-primary-gradiant me-1 btn-round-xs font-xss"
                  ></button>
                )}
                {post?.like?.quantityLike} Like
              </div>
            </div>
            <hr />
          </div>

          <div className="col-12">
            {loading && <LoadingCommentsPost />}
            {ListComments?.map((value, index) => {
              if (ListComments.length === index + 1) {
                return (
                  <div
                    key={index}
                    ref={lastCommentElementRef}
                    className="cajaLeft"
                  >
                    <CommentPostViewItem
                      index={index}
                      loading={loading}
                      value={value}
                      type={type}
                      userId={userId}
                      groupId={groupId}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={index} className="cajaLeft">
                    <CommentPostViewItem
                      index={index}
                      loading={loading}
                      value={value}
                      type={type}
                      userId={userId}
                      groupId={groupId}
                    />
                  </div>
                );
              }
            })}
            {loadingPagination && <Load />}
          </div>
        </div>
      </div>
      <div className="comment-card-button-browser fixed-bottom ">
        <form onSubmit={HandleSubmitComents}>
          <div className="form-group mb-0 icon-input text-center">
            <input
              onChange={changeInput}
              value={contentComment}
              type="text"
              placeholder="Add a comment.."
              className="bg-grey border-0 w-100 lh-32 pb-2 pt-2 font-xssss fw-500 rounded-xl theme-dark-bg"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
