// React Required
import React from 'react';
import ReactDOM from 'react-dom';

// Create Import File
// import './main.scss';
import './main.scss';
import '../public/assets/scss/main.scss';
import '../public/assets/css/style.css';





// import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from '../src/store/store'
import { AppRoutes } from './routes/AppRoutes';


export const Root = ()=> {
    
        return(
            <Provider store={store}>
            <AppRoutes></AppRoutes>
            </Provider>

        )
    
}

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function(registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function(err) {
        console.log("Service worker registration failed, error:", err);
      });
  }

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();