import { useEffect, useState } from "react";
import removeFileStorage from "helpers/removeFile";
import uploadFile from "helpers/uploadFile";

import { useDispatch, useSelector } from "react-redux";
import { updatePost, updatePostGroup } from "actions/post";
import { format } from "date-fns";

export const useEditPost = (data, groupId) => {
  const { uid: userId, photoUrl } = useSelector((state) => state.auth);
  // const { posts } = useSelector((state) => state.posts);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const [forms, setForms] = useState({
    content: data?.des,
  });

  const [fileType, setFileType] = useState(null);
  const [ImageSelectedPrevious, setImageSelectedPrevious] = useState(null);
  const [fileSelectedFile, setFileSelectedFile] = useState(null);
  const reader = new FileReader();

  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    if (data?.postImage != "" && data?.postImage != null) {
      setFileType(data?.postImage == "" ? null : "jpg");
      setImageSelectedPrevious(data?.postImage == "" ? null : data?.postImage);
    }
  }, [data?.postImage]);

  const changeImage = async (e) => {
    e.preventDefault();
    if (e.target.files[0] !== undefined) {
      reader.readAsDataURL(e.target.files[0]);
      setFileSelectedFile(e.target.files[0]);
      setFileType(e.target.files[0].type);
      reader.onload = (e) => {

        setImageSelectedPrevious(e.target.result);
      };
    }
  };

  const changeTextArea = (e) => {
    e.preventDefault();
    setForms({ content: e.target.value });
  };

  const removeFile = () => {
    setImageSelectedPrevious(null);
    setFileSelectedFile(null);
  };

  const hanledCreatePost = async (e) => {
    e.preventDefault();
    const { content } = forms;
    const date = format(new Date(), "dd/MM/yyyy HH:mm:ss");

    const post = {
      content,
      attachments: data?.postImage,
      lastUpdateDate: date,
      uid: data?.id,
    };

    if (fileSelectedFile) {
      const nameFile = new Date().valueOf();
      const type = fileType.includes("mp4") ? "mp4" : "jpg";
      const route = `posts/${userId}/${nameFile}.${type}`;
      const url = await uploadFile(
        fileSelectedFile,
        route,
        setProgress,
        setError
      );
      if (url) {
        post?.attachments != "" && (await removeFileStorage(post?.attachments));
        post.attachments = url;
      }
      if (groupId) {
        const group = {
          ...post,
          groupId,
        };
        dispatch(updatePostGroup(group));
      } else {
        dispatch(updatePost(post));
      }
    } else {
      if (post?.attachments != "" && ImageSelectedPrevious == null) {
        await removeFileStorage(post?.attachments);
        post.attachments = "";
      }

      if (groupId) {
        const group = {
          ...post,
          groupId,
        };
        dispatch(updatePostGroup(group));
      } else {
        dispatch(updatePost(post));
      }
    }
  };

  return {
    show,
    handleShow,
    handleClose,
    removeFile,
    ImageSelectedPrevious,
    fileType,
    changeTextArea,
    hanledCreatePost,
    changeImage,
    photoUrl,
    forms,
    progress, 
    error
  };
};
