import React from "react";
import ReactMarkdown from "react-markdown";
import { LoadExpanded } from "components/shared/LoadExpanded";
import { useEditProject } from "Hooks/project/useEditProject";

export const EditProject = () => {

  const { loading, changeImage, handlerform,handleEdit, deleteFile,
    ImageSelectedPrevious, progress, listFiles, formsValue,
    acceptedFiles, isDragActive, getInputProps, getRootProps } = useEditProject();

    const validateIcon = (value) => {
      if (value.includes("png")) {
        return "png";
      } else if (value.includes("pdf")) {
        return "pdf";
      } else if (value.includes("docx")) {
        return "docx";
      } else if (value.includes("jpeg")) {
        return "jpeg";
      } else if (value.includes("jpg")) {
        return "jpg";
      } else if (value.includes("pptx")) {
        return "pptx";
      }  else if (value.includes("mp4")) {
        return "mp4";}
      else {
        return "otro";
      }
    };
  

  if (loading) {
    return <LoadExpanded />;
  }

  return (
    <>
      <span className="p-2">Edit Project</span>
      <form onSubmit={ handleEdit }>
        <div className="col-xl-12 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Title</label>
            <input
              type="text"
              name="title"
              value={ formsValue?.title || "" }
              className="form-control"
              onChange={ handlerform }
              placeholder="title"
              required>
            </input>
          </div>

          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Description</label>
            <textarea
              type="text"
              name="description"
              value={formsValue?.description || ""}
              className="form-control"
              onChange={handlerform}
              placeholder="description"
              required
              style={{lineHeight: "normal", height: "150px" }}
            ></textarea>
            <label className="mont-font  font-xssss">minimum 200 characters</label>
          </div>

          <label className="mont-font fw-600 font-xsss">Add cover image</label>
          <div
            className="Container pt-4 pb-4"
            style={{
              width: "200px",
              height: "240px",
              textAlign: "center",
              alignContent: "center",
              display: "flex",
              margin: "auto",
            }}
          >
            <img
              src={ImageSelectedPrevious ?? formsValue?.filePortada}
              className=" border float-right p-1 bg-white rounded-circle w-100"
              width="200px"
              height="200px"
              style={{ width: "200px", objectFit: "cover" }}
            ></img>
            <div className="">
              <input
                type="file"
                name="logo"
                id="file"
                style={{
                  width: "0.1px",
                  height: "0.1px",
                  opacity: " 0",
                  overflow: "hidden",
                  position: "absolute",
                }}
                onChange={changeImage}
              />
              <label htmlFor="file">
                <li className="cursor-pointer nav-content-bttn open-font">
                  <i
                    className="feather-edit btn-round-md color-light bg-primary-gradiant me-3"
                    style={{ color: "#fff" }}
                  ></i>
                </li>
              </label>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-xl-6 col-xxl-6 col-lg-6 ">
              <div className="form-group">
                <label>Text</label>
                <textarea
                  value={formsValue?.content}
                  type="text"
                  className="form-control"
                  name="content"
                  onChange={handlerform}
                  placeholder="# Hello world!"
                  style={{lineHeight: "normal", height: 400 }}
                ></textarea>
                <label className="mont-font  font-xssss">minimum 200 characters</label>
              </div>
            </div>

            <div className="col-xl-6 col-xxl-6 col-lg-6">
              <label>Preview</label>
              <div className="container border" style={{ height: 400, overflow: "auto", }}>
                <ReactMarkdown>{formsValue?.content}</ReactMarkdown>
              </div>
            </div>

            <section className="container p-3">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div
                    className="container p-3 text-center"
                    style={{ width: 500, height: 70, border: "dotted" }}
                    >
                    <p> Drop files here ... </p>
                  </div>
                ) : (
                  <div
                  className="container  text-center"
                  style={{ width: 500, height: 70, border: "dotted" }}
                  >
                    <p>
                      {" "}
                      Drag 'n' drop some files here, or click to select files{" "}
                    </p>
                  </div>
                )}
              </div>
              <aside>
                {acceptedFiles.length !== 0 && (
                  <div className="text-center">
                    <ul className="pt-2">{"New Files  " + acceptedFiles.length}</ul>
                    <progress value={progress} max={100}></progress>
                    <ul>{progress + "%"}</ul>

                  </div>
                )}
              </aside>
            </section>


            <label className="mont-font fw-600 font-xsss p-3">Project Files</label>
            <div className="p-2">
              {listFiles?.map((value, index) => (
                <i key={index}>
                   <a
                        
                          className=" fw-700 text-900 font-xsss "
                          href={value}
                          target="_blank"
                        >
                          {validateIcon(value) == "pdf" && (
                            <i
                              className={`feather-file-text  `}
                              style={{ fontSize: "50px", color: "red" }}
                            ></i>
                          )}
                          {(validateIcon(value) ==
                            "png" || validateIcon(value) == "jpg" || validateIcon(value) == "jpeg") && (
                            <i
                              className={`cursor-pointer feather-image   `}
                              style={{ fontSize: "50px", color: "#afa6d5" }}
                            ></i>
                          )}
                          {validateIcon(value) == "docx" && (
                            <i
                              className={` cursor-pointer feather-file-text  `}
                              style={{ fontSize: "50px", color: "#00579b" }}
                            ></i>
                          )}
                          {validateIcon(value) == "pptx" && (
                            <i
                              className={`feather-file-text  `}
                              style={{ fontSize: "50px", color: "#ffc204" }}
                            ></i>
                          )}
                          {validateIcon(value) == "mp4" && (
                            <i
                              className={`feather-film  `}
                              style={{ fontSize: "50px", color: "#3D4FFF" }}
                            ></i>
                          )}
                          {validateIcon(value) == "otro" && (
                            <i
                              className={`feather-file  text-grey-500  `}
                              style={{ fontSize: "50px" }}
                            ></i>
                          )}
                        </a>

                  <i
                    onClick={(e) => deleteFile(e, index)}
                    className="cursor-pointer font-sm ti-trash me-3  text-red-200"
                  ></i>
                </i>
              ))}
            </div>
          </div>
        </div>
        {/* <AddMembers isEdit={true} values={formsValue?.members} handleInputChange={handlerform}/> */}
        <div  className='mt-5 pb-5'>  
         <button type="submit" className=" bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-blockbg-current text-center text-white font-xsss fw-600 p-3 w200 rounded-3 d-inline-block "> Update project </button>
       </div>
      </form>
    </>
  );
};
