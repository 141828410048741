import React from "react";
import PropTypes from "prop-types";

import { useScroll } from "Hooks/scroll/useScroll";
import { PageTitleProfile } from "../PageTitleProfile";
import { RouterCourses } from "./routes/RouterCourses";
import { usePropietor } from "Hooks/usePropietor";

export const Courses = ({ routeProfile }) => {
  const { isPropietor } = usePropietor();
  const { divRref } = useScroll();

  return (
    <div ref={divRref}
      className="card w-100 border-0 p-3 bg-white shadow-xss rounded-xxl">
      <PageTitleProfile
        name1="My courses"
        route1={`${routeProfile}/courses/my-courses`}
        name2="Courses where I participate"
        route2={`${routeProfile}/courses/my-courses-participate`}
        isActiveButtom={isPropietor}
        title="Courses"
        titleButtom="Create Course"
        route="/CreateCourse"
      />
      <RouterCourses routeProfile={routeProfile} />
    </div>
  );
};

Courses.propTypes = {
  routeProfile: PropTypes.string.isRequired,
};
