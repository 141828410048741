import React from "react";
import PropTypes from "prop-types";

import { Route } from "react-router-dom";
import { ViewAboutGroup } from "components/group/all/ViewAboutGroup";
import { ViewInformationGroup } from "components/group/all/ViewInformationGroup";
import { ViewMembersGroup } from "components/group/all/ViewMembersGroup";

export const RouterViewGroup = ({
  id,
  userMember,
  userId,
  tempGroup = {},
  loadingMembers,
}) => {
  return (
    <>
      <Route exact path="/ViewGroup/:id">
        <ViewInformationGroup
          id={id}
          userMember={userMember}
          tempGroup={tempGroup}
        />
      </Route>
      <Route path={`/ViewGroup/:id/members`}>
        <ViewMembersGroup
          idUser={userId}
          idUserGroup={tempGroup?.userId}
          idGroup={id}
          loading={loadingMembers}
        />
      </Route>
      <Route path={`/ViewGroup/:id/about`}>
        <ViewAboutGroup tempGroup={tempGroup} />
      </Route>
    </>
  );
};

RouterViewGroup.propTypes = {
  id: PropTypes.string.isRequired,
  userMember: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  tempGroup: PropTypes.object.isRequired,
  loadingMembers: PropTypes.bool.isRequired,
};
