
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {useForm} from "./userForms";
import { starloginEmailPassword, StartGoogleLogin } from "actions/auth";
import { removeError, setError } from "actions/ui";
import validator from "validator";


export const useLogin = () => {
  const dispatch = useDispatch();
  const { msgError } = useSelector((state) => state.ui);

  const [formsValue, handlerform] = useForm({
    correo: "",
    pass: "",
  });

  const { correo, pass } = formsValue;

  const handleLogin = (e) => {
    e.preventDefault();
    if (isFormValidate()) {
      dispatch(starloginEmailPassword(correo, pass));
    }
  };

  const isFormValidate = () => {
    if (!validator.isEmail(correo)) {
      dispatch(setError("Email is not validate"));
      return false;
    } else if (pass.trim().length < 5) {
      dispatch(setError("passwor no validate"));
      return false;
    }
    dispatch(removeError());
    return true;
  };

  const handleGoogleLogin = (e) => {
    e.preventDefault();
    dispatch(StartGoogleLogin());
  };

  return {
    handleLogin,
    handleGoogleLogin,
    msgError,
    handlerform,
  };
};
