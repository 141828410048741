import React from "react";
import Header from "components/headers/Header";

import { useHistory } from "react-router-dom";
import { imageExistsProduct } from "helpers/verifyImg";
import { useViewProject } from "Hooks/project/useViewProject";
import { useBtnOptionMenu } from "Hooks/useBtnOptionMenu";
import { GoBackNavigate } from "../shared/GoBack";
import { ShareProject } from "./ShareProject";
import { ViewProjecMenuItem } from "./all/components/ViewProjecMenuItemt";
import { RouterViewProject } from "./routes/RouterViewProject";
import { ViewProjectOptions } from "./all/components/ViewProjectOptions";
import { ViewProjectLoadingMobile } from "./loading/LoadingMobile";
import { ViewProjectLoadingBrowser } from "./loading/LoadingBrowser";
import { useMediaQuery } from "react-responsive";

export const ViewProject = () => {
  const {
    Id,
    uid,
    projectValue,
    exitsMember,
    loading,
    loadingMember,
    idUserProject,
    page403,
  } = useViewProject();
  const navigate = useHistory();
  const { openModal, notiClass, wrapperRef, closeModal } = useBtnOptionMenu();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });

  if(page403){
    navigate.push('/notfound')
  }

  return (
    <>
      {loading ? (
        <>
          {isDesktopOrLaptop ? (
            <ViewProjectLoadingBrowser />
          ) : (
            <ViewProjectLoadingMobile />
          )}
        </>
      ) : (
        <>
          <Header />
          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              {exitsMember && (
                <div className="middle-sidebar-left pe-0">
                  <div className="row">
                    <div className="col-xl-12 mb-3">
                      <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                        <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                          <GoBackNavigate onClick={() => navigate.goBack()} />
                          <img
                            src={projectValue?.pageCover}
                            style={{ objectFit: "cover" }}
                            width="1200"
                            height="250"
                            alt="avater"
                            onError={imageExistsProduct}
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-7">
                            <h4 className="fw-700 font-sm mt-2 mb-lg-1 mb-1 pt-2 pl-4">
                              {projectValue?.title}
                            </h4>
                            <span className="pl-4 text-grey-900 font-xssss mt-0">
                              {"Published " + projectValue?.createDate}
                            </span>
                          </div>

                          <div className="col-md-5">
                            <ViewProjectOptions
                              iduser={projectValue?.userId}
                              idproject={Id}
                              statusMember={exitsMember}
                              nameproject={projectValue?.title}
                              openModal={openModal}
                              projectValue={projectValue}
                            />

                            <ShareProject
                              notiClass={notiClass}
                              closeOpen={closeModal}
                            />
                          </div>
                        </div>

                        <ViewProjecMenuItem
                          uid={projectValue?.uid}
                          idUserProject={idUserProject}
                          userId={uid}
                        />
                      </div>
                    </div>
                  </div>
                  <RouterViewProject
                    idUser={uid}
                    exitsMember={exitsMember}
                    projectValue={ projectValue }
                    projectId={Id}
                    loadingMember={loadingMember}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
