import { firebase } from "../firebase/config";

const uploadFile = async (file, ruta, setProgress, setError) => {
  let porcentage = 0;
  return new Promise((resolve, reject) => {
      const type = file.type;
      const path = `${ruta}/${file.name}/`;

      const newMetadata = {
        contentType: type,
      };

      const storageRef = firebase.storage().ref(path);
      const state = storageRef.put(file, newMetadata);
      state.on(
        "state_changed",
        (snapshot) => {
          porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          let percent = Math.round(porcentage);
          setProgress(percent);
        },
        (error) => 
        {setError(error)
        },
        async () => {
          const url = await storageRef.getDownloadURL();
         
            return resolve(url);
          
        }
      );
    });
  
};
// const uploadFile = async (file, ruta, setProgress, setError) => { 
//   const storageRef = firebase
//     .storage()
//     .ref(ruta);
//   try {
//     const state = await storageRef.put(file);

//     let porcentage = (state.bytesTransferred / state.totalBytes) * 100;
//     let percent = Math.round(porcentage)
//     setProgress(percent);

//     if (state.state === "success") {
//       const url = await storageRef.getDownloadURL();
//       return url;
//     }

//     return null;
//   } catch (error) {
//     setError(error);
//   }
// };

export default uploadFile;
