import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPost, createPostGroup } from "actions/post";
import uploadFile from "helpers/uploadFile";
import { format } from "date-fns";
import { PercertFiles } from "actions/ui";

export const useCreatePost = (userId, groupId, friendsprofile) => {
  const dispatch = useDispatch();
  const {uid: friendUid} = useSelector( state => state.navigateAuth );
  const {uid} = useSelector( state => state.auth );

  const [forms, setForms] = useState({
    content: "",
  });

  const [fileType, setFileType] = useState(null);
  const [ImageSelectedPrevious, setImageSelectedPrevious] = useState(null);
  const [fileSelectedFile, setFileSelectedFile] = useState(null);
  const reader = new FileReader();

  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(PercertFiles(progress))
  }, [progress])
  

  const changeImage = async (e) => {
    e.preventDefault();
    if (e.target.files[0] !== undefined) {
      reader.readAsDataURL(e.target.files[0]);
      setFileSelectedFile(e.target.files[0]);
      setFileType(e.target.files[0].type);
      reader.onload = (e) => {
        setImageSelectedPrevious(e.target.result);
      };
    }
  };

  const changeTextArea = (e) => {
    e.preventDefault();
    setForms({ content: e.target.value });
  };

  const removeFile = () => {
    setImageSelectedPrevious(null);
    setFileSelectedFile(null);
  };

  const hanledCreatePost = async (e) => {
    e.preventDefault();
    const { content } = forms;
    const date = format(new Date(), "dd/MM/yyyy HH:mm:ss");
    let post = {
      content,
      userId,
      attachments: "",
      comment: "",
      originalPostId: "",
      isShared: false,
      createDate: date,
      lastUpdateDate: date,
      active: true,
      createTime: new Date().valueOf(),
    };

    if (fileSelectedFile) {
      const nameFile = new Date().valueOf();
      const type = fileType.includes("mp4") ? "mp4" : "jpg";
      const route = `posts/${userId}/${nameFile}.${type}`;
      const url = await uploadFile(
        fileSelectedFile,
        route,
        setProgress,
        setError
      );
      post.attachments = url;
      if (groupId) {
        const group = {
          ...post,
          groupId,
        };
        dispatch(createPostGroup(group));
      } else {
        if(uid != friendUid && friendsprofile ){
          post.userId = friendUid
          post.friend = uid
          dispatch(createPost(post));
         
        }else{
          dispatch(createPost(post));

        }
      }
    } else {
      if (groupId) {
        const group = {
          ...post,
          groupId,
        };

        dispatch(createPostGroup(group));
      } else {
        if(uid != friendUid  && friendsprofile){
          post.userId = friendUid
          post.friend = uid
          dispatch(createPost(post));
        }else{
          dispatch(createPost(post));
           
        }
      }
    }
    reset();
  };

  const reset = () => {
    setFileType(null);
    setImageSelectedPrevious(null);
    setFileSelectedFile(null);
    setForms(null);
  };

  return {
    removeFile,
    ImageSelectedPrevious,
    fileType,
    changeTextArea,
    hanledCreatePost,
    changeImage,
  };
};
