import React from "react";
import Load from "components/Load";

import { EmptyData } from "components/shared/EmptyData";
import { CardListGroupStyle } from "./components/CardListGroupStyle";
import { useMyGroupsParticipate } from "Hooks/group/useMyGroupsParticipate";
import { MyGroupLoadingIndicator } from "./loading/MyGroupLoadingIndicator";
import "css/groupList.css";

export const MyGroupsParticipate = () => {
  const {
    loading,
    loadingPagination,
    results,
    lastMyGroupsElementRef,
    isProprietor,
  } = useMyGroupsParticipate();

  return (
    <>
      {loading ? (
        <MyGroupLoadingIndicator/>
        ) : (
        <div className="row ps-2 pe-1">
          {
            // cuando se ponga el loadin validar aqui que sea diferente de true
            results?.length === 0 && !loading && (
              <EmptyData
                pathImg="/assets/images/NoData.svg"
                title="There are no groups where you are participating"
              />
            )
          }
          {results?.map((value, index) => {
            if (results.length === index + 1) {
              return (
                <div
                  key={index}
                  ref={lastMyGroupsElementRef}
                  className="col-md-6 col-sm-6 pe-2 ps-2"
                >
                  <CardListGroupStyle
                    uid={value.uid}
                    title={value.title}
                    pageCover={value.pageCover}
                    email={value.email}
                    isProprietor={!isProprietor}
                  />
                </div>
              );
            } else {
              return (
                <div key={index} className="col-md-6 col-sm-6 pe-2 ps-2">
                  <CardListGroupStyle
                    uid={value.uid}
                    title={value.title}
                    pageCover={value.pageCover}
                    email={value.email}
                    isProprietor={!isProprietor}
                  />
                </div>
              );
            }
          })}
          {loadingPagination && <Load />}
        </div>
      )}
    </>
  );
};
