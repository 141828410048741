import { types } from "../types/types";

const inicialState = {
  loading: false,
  commentsLoad: {
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  tempComment: null,
  page: 1,
  isUpdate: {
    state: false,
    uidUpdate: null,
  },
  isRemove: {
    state: false,
    uidRemove: null,
  },
  isReset: false,
};

export const commentsReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.loadingComents:
      return {
        ...state,
        loading: true,
      };

    case types.getComments:
      let hashPosts = {};
      let getCommentsIsCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;

      return {
        ...state,
        loading: false,
        commentsLoad:
          action.payload && action.payload?.isCollectionEmpty > 0
            ? state.commentsLoad
              ? {
                  docs: [
                    ...state.commentsLoad.docs,
                    ...action.payload?.docs,
                  ].filter((o) =>
                    hashPosts[o.uid] ? false : (hashPosts[o.uid] = true)
                  ),
                  isCollectionEmpty: getCommentsIsCollectionEmpty,
                  lastInResponse: action.payload?.lastInResponse,
                }
              : action.payload
            : {
                docs: state.commentsLoad.docs,
                isCollectionEmpty: getCommentsIsCollectionEmpty,
                lastInResponse: action.commentsLoad?.lastInResponse
                  ? action.commentsLoad?.lastInResponse
                  : null,
              },
      };

    case types.createComents:
      return {
        ...state,
        commentsLoad: state.commentsLoad && {
          lastInResponse: state.commentsLoad.lastInResponse,
          isCollectionEmpty: state.commentsLoad.isCollectionEmpty,
          docs: [...state.commentsLoad.docs, action.payload],
        },
      };

    case types.updateComment:
      state.commentsLoad &&
        state.commentsLoad.docs.splice(
          state.commentsLoad.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1,
          action.payload
        );
      return {
        ...state,
        isUpdate: {
          state: true,
          uidUpdate: action.payload?.uid,
        },
      };

    case types.isUpdateReset:
      return {
        ...state,
        isUpdate: {
          state: false,
          uidUpdate: null,
        },
      };

    case types.tempComment:
      return {
        ...state,
        tempComment: action.payload,
        page: 2,
      };

    case types.resetComment:
      return {
        ...state,
        page: 1,
      };

    case types.getRemoveComment:
      state.commentsLoad &&
        state.commentsLoad.docs.splice(
          state.commentsLoad.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1
        );
      return {
        ...state,
        isRemove: {
          state: true,
          uidRemove: action.payload?.uid,
        },
      };

    case types.isRemoveReset:
      return {
        ...state,
        isRemove: {
          state: true,
          uidRemove: null,
        },
      };

    case types.resetCommentsPostById:
      return {
        ...state,
        isReset: true,
        commentsLoad: {
          docs: [],
          isCollectionEmpty: -1,
          lastInResponse: null,
        },
      };

    case types.viewNewPost:
      return {
        ...state,
        isReset: false,
      };

    default:
      return state;
  }
};
