import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsChange,
  getPostsPaginationsById,
  isCreateReset,
  isRemoveReset,
  isUpdateReset,
} from "actions/post";
import { LIMIT } from "helpers/env";

export const usePostViewListById = () => {
  const dispatch = useDispatch();
  const observer = useRef();

  const { postsByIdLoad, isCreate, isUpdate, isRemove, loadingMyPost: loading } = useSelector(
    (state) => state.posts
  );

  const { uid : userIdVisited, name, photoUrl } = useSelector((state) => state.navigateAuth);
  const {uid: userIdActive} = useSelector( state => state.auth );
  const [docs, setDocs] = useState(postsByIdLoad?.docs);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isPostListEmpty, setIsPostListEmpty] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      userToId: userIdVisited,
      userFromId: userIdActive,
      limit: LIMIT,
      action: action,
      lastInResp: lastInResp,
    };
    dispatch(getPostsPaginationsById(userIdVisited, data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastPostsElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isPostListEmpty) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isPostListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> CHANGE USER PROFILE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (postsByIdLoad?.userData && userIdVisited != postsByIdLoad?.userData) {
      dispatch(getIsChange());
    }
  }, [userIdVisited]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (
      (postsByIdLoad?.docs.length <= 0 &&
        postsByIdLoad?.isCollectionEmpty == -1) ||
      (postsByIdLoad?.docs.length <= 0 && postsByIdLoad?.isCollectionEmpty == 0)
    ) {
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
      // setLoading(true);
    }
  }, [postsByIdLoad?.docs]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (postsByIdLoad?.docs.length > 0 && !isCreate?.state) {
      postsByIdLoad.docs.map((element) => {
        if (!docs?.includes(element)) {
          setDocs((ele) => ele.concat(element));
        }
      });
      setlastInResponse(postsByIdLoad?.lastInResponse);
      setIsPostListEmpty(postsByIdLoad?.isCollectionEmpty === 0 ? true : false);
      // setLoading(false);
      setLoadingPagination(false);
    } else if (
      postsByIdLoad?.docs.length <= 0 &&
      postsByIdLoad?.isCollectionEmpty == 0
    ) {
      setIsPostListEmpty(true);
      setDocs([]);
      // setLoading(false);
      setLoadingPagination(false);
    } else if (
      postsByIdLoad?.docs.length <= 0 &&
      postsByIdLoad?.isCollectionEmpty == -1
    ) {
      setDocs([]);
      // setLoading(false);
      setLoadingPagination(false);
    }
  }, [postsByIdLoad, userIdVisited, isPostListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS CREATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isCreate?.state) {
      const element = postsByIdLoad?.docs?.find(
        (dc) => dc.uid == isCreate?.uidCreate
      );
      if (element && !docs?.includes(element)) setDocs((ele) => [element, ...ele]);
    }
    dispatch(isCreateReset());
  }, [isCreate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS UPDATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isUpdate?.state) {
      setDocs((items) => {
        const position = docs?.findIndex((dc) => dc.uid === isUpdate?.uidUpdate);
        const newPost = postsByIdLoad?.docs?.find(
          (dc) => dc.uid === isUpdate?.uidUpdate
        );
        docs.splice(position, 1, newPost);
        return items;
      });
    }
    dispatch(isUpdateReset());
  }, [isUpdate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS REMOVE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isRemove?.state) {
      setDocs((items) => {
        const position = docs?.findIndex((dc) => dc.uid === isRemove?.uidRemove);
        docs.splice(position, 1);
        return items;
      });
    }
    dispatch(isRemoveReset());
  }, [isRemove?.state]);


  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    loading,
    loadingPagination,
    docs,
    name,
    photoUrl,
    lastPostsElementRef,
  };
};
