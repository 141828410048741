import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { notificationGroupResponse } from "actions/group";
import { SpinnerCustom } from "components/shared/SpinnerCustom";
import { ViewGroupModalInviteMenbers } from "./ViewGroupModalInviteMenbers";
import { useMediaQuery } from "react-responsive";
import { ViewGroupDeleteMember } from "./ViewGroupDeleteMember";
import { ViewGroupBtnOptionMenu } from "./ViewGroupBtnOptionMenu";

export const ViewGroupOptions = ({ userId, group, existMember, openModal }) => {

  const [state, setstate] = useState({
    loading: false,
  });

  const dispatch = useDispatch();
  const click = () => {
    const data = {
      groupId: group?.uid,
      userId: userId,
      createDate: new Date().toLocaleString(),
      state: true,
    };
    dispatch(notificationGroupResponse(data));
    setstate({ loading: true });

    setTimeout(() => {
      setstate({ loading: false });
    }, 5000);
  };
  const { loading } = state;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });

  return (
    <>
        <div className="d-flex">
          <div
            className={`pointer d-flex ml-auto align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss`}
            id={`dropdownMenu`}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={openModal}>
            <i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i>
            <span className="d-none-xs"></span>
          </div>
          {isDesktopOrLaptop && (
            <>
              {userId !== group?.userId && existMember === false && (
                <div className=" cursor-pointer d-flex align-items-center justify-content-center pl-2 pr-3 right-15 top-0">
                  <div onClick={click}
                    className=" ms-auto d-none d-lg-block bg-success p-2 z-index-1 rounded-3 text-white font-xsss  fw-700 ls-3" >
                    {loading && <SpinnerCustom  />}
                    {!loading && "Join the group"}
                  </div>
                </div>
              )}

              {existMember && userId !== group?.userId && (
                <div className="d-flex">
                  <ViewGroupModalInviteMenbers />
                  <ViewGroupDeleteMember />
                </div>
              )}

              {userId === group?.userId && (
                <div className="d-flex mr-3 mb-3">
                  <ViewGroupModalInviteMenbers />
                </div>
              )}
            </>
          )}
          {
            isTabletOrMobile && (
              <ViewGroupBtnOptionMenu
                id= { userId }
                group={ group }
                existMember={ existMember }
                click={ click }
              />
            )
          }
        </div>
    </>
  );
};
