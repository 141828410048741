import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPostsForumCourse } from "actions/course";
import {
  DeleteForumReset,
  getPostsForumProject,
  isChangeCommentsForums,
  RessetCommentsCreateForum,
  UpdateForumReset,
} from "actions/project";
import { LIMIT } from "helpers/env";
export const useGetForum = (type) => {
  const dispatch = useDispatch();
  const observer = useRef();
  const { id: uid } = useParams();

  const {
    postsForum,
    isCreate,
    loading: load,
    isUpdate,
    isRemove,
  } = useSelector((state) => state.forumCommens);
  const { name, photoUrl, uid: userId } = useSelector((state) => state.auth);

  const [docs, setDocs] = useState(postsForum?.docs);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isPostListEmpty, setIsPostListEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    if (type == "project") {
      const data = {
        limit: LIMIT,
        action: action,
        projectId: uid,
        userId,
        lastInResp: lastInResp,
      };
      dispatch(getPostsForumProject(data, uid));
    } else {
      const data = {
        limit: LIMIT,
        action: action,
        courseId: uid,
        userId,
        lastInResp: lastInResp,
      };
      dispatch(getPostsForumCourse(data, uid));
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastPostsElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isPostListEmpty) {
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isPostListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (
      (postsForum?.docs.length <= 0 && postsForum?.isCollectionEmpty == -1) ||
      (postsForum?.docs.length <= 0 && postsForum?.isCollectionEmpty == 0)
    ) {
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
      setLoading(true);
    }
  }, [postsForum?.docs]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (postsForum?.docs.length > 0 && !isCreate?.state) {
      postsForum.docs.map((element) => {
        if (!docs.includes(element)) {
          setDocs((ele) => ele.concat(element));
        }
      });
      setlastInResponse(postsForum?.lastInResponse);
      setIsPostListEmpty(postsForum?.isCollectionEmpty === 0 ? true : false);
      setLoading(false);
      setLoadingPagination(false);
    } else if (
      postsForum?.docs.length <= 0 &&
      postsForum?.isCollectionEmpty == 0
    ) {
      setIsPostListEmpty(true);
      setDocs([]);
      setLoading(false);
      setLoadingPagination(false);
    } else if (
      postsForum?.docs.length <= 0 &&
      postsForum?.isCollectionEmpty == -1
    ) {
      setDocs([]);
      setLoading(false);
      setLoadingPagination(false);
    }
  }, [postsForum, uid, isPostListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS CREATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isCreate?.state) {
      // const element = postsForum?.docs.find(
      //   (dc) => dc.uid == isCreate?.uidCreate
      // );
      // if (!docs.includes(element)) setDocs((ele) => [element, ...ele]);
      dispatch(RessetCommentsCreateForum());
    }
  }, [isCreate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> CHANGE GROUP ID
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    
    if (postsForum?.userData && uid != postsForum?.userData) {
        dispatch(isChangeCommentsForums());
      }
       
  }, [uid]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS UPDATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isUpdate?.state) {
      // setDocs((items) => {
      //   const position = docs.findIndex((dc) => dc.uid === isUpdate?.uidUpdate);
      //   const newElement = postsForum?.docs.find(
      //     (dc) => dc.uid === isUpdate?.uidUpdate
      //   );
      //   docs.splice(position, 1, newElement);
      //   return items;
      // });

      dispatch(UpdateForumReset());
    }
  }, [isUpdate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS REMOVE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isRemove?.state) {
    //   setDocs((items) => {
    //     const position = docs.findIndex((dc) => dc.uid === isRemove?.uidRemove);
    //     docs.splice(position, 1);
    //     return items;
    //   });

      dispatch(DeleteForumReset());
    }
  }, [isRemove?.state]);

  useEffect(() => {
    setDocs(postsForum.docs);
  }, [postsForum.docs])
  

  return {
    load,
    docs,
    name,
    photoUrl,
    lastPostsElementRef,
    loadingPagination,
    userId,
  };
};
