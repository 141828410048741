import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { UpdateUser } from "actions/profile";
import { useVerify } from 'Hooks/useVerify';

export const ViewSkills = () => {
  const { skills } = useSelector((state) => state.user.user);
  const {uid} = useSelector( state => state.auth );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [FilesDelete, setFilesDelete] = useState([]);
  const[ listFiles, setListFile] = useState( skills );
  const dispatch = useDispatch();
  const verify = useVerify();

  useEffect(() => {
    setListFile(skills);
   }, [skills])

const deleteFile = (e, value)=>{
    const newFile = [...listFiles];
    const newFilesDelete = [...FilesDelete];
      newFilesDelete.push(newFile.splice(value, 1)); 
     
   setFilesDelete(newFile);
   setListFile(newFile);
   
  
 }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      uid,
      skills: listFiles,
    };
    dispatch(UpdateUser(data));
  };

  return (
    <div>
      {skills && (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
          <div className="card-body d-flex align-items-center p-4">
            <h4 className="fw-700 mb-0 font-xssss text-grey-900">Skills</h4>
           {
             verify && <i onClick={handleShow}
              className="btn-round-sm font-xs ms-auto text-primary feather-edit-3 me-2 bg-greylight"></i>
            }

          </div>
          {skills?.map((value, index) => (
            <div
              key={index}
              className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden"
            >
              <i className={`feather-award text-grey-500 me-3 font-lg`}></i>

              <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                {value.label}{" "}
                <span className="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">
                  {value.location}
                </span>{" "}
              </h4>
            </div>
          ))}

          <Modal show={show} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>Change Skills</Modal.Title>
                <button
                  className="btn ti-close"
                  type="button"
                  onClick={handleClose}
                  closeButton
                ></button>
              </Modal.Header>
              <Modal.Body>
                {listFiles?.map((value, index) => (
                  <div key={index} className="card-body border-top-xs d-flex">
                    <i
                      className={`feather-award text-grey-500 me-3 font-lg`}
                    ></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                      {value.label}
                    </h4>

                    <i  onClick={(e) => deleteFile(e, index)} className="cursor-pointer btn-round-sm font-xs ms-auto text-primary  feather-trash-2 me-2 bg-greylight"></i>
                  </div>
                ))}
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" variant="outline-info" onClick={handleClose}>
                  Save
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      )}
    </div>
  );
};
