import { types } from "../types/types";

const inicialState = {
  allCourses: {
    docs: [],
    isCollectionEmpty: 0,
    lastInResponse: null,
  },
  courseSuggestions: [],
  loading: false,
  loadingSuggestion: true,

};

export const coursesReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.courseSuggestions:
      return {
        ...state,
        courseSuggestions: action.payload,
        loadingSuggestion: false
      };

    case types.allCourses:
      let hash = {};

      return {
        ...state,
        allCourses:
          action.payload && action.payload?.isCollectionEmpty > 0
            ? state.allCourses
              ? {
                  docs: [
                    ...state.allCourses.docs,
                    ...action.payload.docs,
                  ].filter((o) => (hash[o.uid] ? false : (hash[o.uid] = true))),
                  isCollectionEmpty: action.payload?.isCollectionEmpty,
                  lastInResponse: action.payload?.lastInResponse,
                }
              : action.payload
            : {
                docs: state.allCourses?.docs,
                isCollectionEmpty: action.payload?.isCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              },
      };

      case types.loagingAllCourse:
        return {
          ...state,
          loading: action.payload,
        };
    default:
      return state;
  }
};
