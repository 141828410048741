import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getOrganizationById } from "actions/organization";
import { useHistory } from "react-router-dom";

export const useViewOrganization = () => {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const { id: organizationIdVisited } = useParams();
  const { tempOrganization, loading } = useSelector(
    (state) => state.organization
  );
  const { page403 } = useSelector((state) => state.page403);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> GET PETITION DB
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (tempOrganization?.uid != organizationIdVisited) {
      dispatch(getOrganizationById({ uid: organizationIdVisited }));
    }
  }, [organizationIdVisited]);


  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    tempOrganization,
    loading,
    navigate,
    page403
  };
};
