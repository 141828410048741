import { types } from "../types/types";

const inicialState = {
  loading: true,
  newNotification: false,
  token: null,
  allNotifications: {
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  isRemove: {
    state: false,
    uidRemove: null,
  },
};

export const notificationReducer = (state = inicialState, action) => {
  switch (action.type) {
    // case types.loadingAllNotification:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    case types.getToken:
      return {
        ...state,
        token: action.payload,
      };

    case types.allNotificationsResponse:
      let hash = {};
      let allNotificationsResponseIsCollectionEmpty = action.payload
        ?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;
      return {
        ...state,
        loading: false,
        allNotifications:
          action.payload && action.payload?.isCollectionEmpty > 0
            ? state.allNotifications
              ? {
                  docs: [
                    ...state.allNotifications.docs,
                    ...action.payload.docs,
                  ].filter((o) => (hash[o.uid] ? false : (hash[o.uid] = true))),
                  isCollectionEmpty: allNotificationsResponseIsCollectionEmpty,
                  lastInResponse: action.payload?.lastInResponse,
                }
              : action.payload
            : {
                docs: state.allNotifications.docs,
                isCollectionEmpty: allNotificationsResponseIsCollectionEmpty,
                lastInResponse: action.allNotifications?.lastInResponse
                  ? action.allNotifications?.lastInResponse
                  : null,
              },
      };

    case types.newNotificationActive:
      return {
        ...state,
        newNotification: true,
      };

    case types.newNotification:
      let hashNewNotification = {};

      return {
        ...state,
        allNotifications: state.allNotifications && {
          lastInResponse: state.allNotifications.lastInResponse,
          isCollectionEmpty: state.allNotifications.isCollectionEmpty,
          docs: [action.payload, ...state.allNotifications.docs].filter((o) =>
            hashNewNotification[o.uid]
              ? false
              : (hashNewNotification[o.uid] = true)
          ),
        },
      };

    case types.newNotificationReset:
      return {
        ...state,
        newNotification: false,
      };

    case types.changeNotificationStateById:
      state.allNotifications &&
        state.allNotifications.docs.splice(
          state.allNotifications.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1,
          action.payload
        );
      return {
        ...state,
      };

    case types.removeNotificationById:
      state.allNotifications &&
        state.allNotifications.docs.splice(
          state.allNotifications.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1
        );

      return {
        ...state,
        isRemove: {
          state: true,
          uidRemove: action.payload?.uid,
        },
      };

    case types.removeNotificationByIdReset:
      return {
        ...state,
        isRemove: {
          state: false,
          uidRemove: null,
        },
      };
    case types.removeAllNotifications:
      return {
        ...state,
        allNotifications: {
          docs: [],
          isCollectionEmpty: -1,
          lastInResponse: null,
        },
      };

    case types.logout:
      return {
        ...state,
        token: null,
      };

    default:
      return state;
  }
};
