import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import algoliasearch from "algoliasearch/lite";
import {
  ALGOLIA_INDEX_USERS_GLOBAL,
  APPLICATION_ID,
  LIMIT,
  SEARCH_API_KEY,
} from "helpers/env";
import { useSearchAlgolia } from "../useSearchAlgolia";
import { useState } from "react";
import { getCoursesParticipateById, getIsChangeMyCourse } from "actions/course";

const client = algoliasearch(APPLICATION_ID, SEARCH_API_KEY);
const index = client.initIndex(ALGOLIA_INDEX_USERS_GLOBAL);

export const useMyCoursesParticipate = () => {
  const dispatch = useDispatch();
  const observer = useRef();

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> SELECTORS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { uid: userIdVisited } = useSelector((state) => state.navigateAuth);
  const { uid: userIdActive } = useSelector((state) => state.auth);
  const { courseParticipateLoad, loading } = useSelector(
    (state) => state.course
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> STATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [myCourseList, setMyCourseList] = useState([]);
  const [docs, setDocs] = useState([]);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isMyCourseListEmpty, setIsMyCourseListEmpty] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [isProprietor, setIsProprietor] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { performSearch, results: hit } = useSearchAlgolia(
    index,
    ALGOLIA_INDEX_USERS_GLOBAL
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC IS PROPRIETOR
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (userIdVisited == userIdActive) {
      setIsProprietor(true);
    } else {
      setIsProprietor(false);
    }
  }, [userIdVisited, userIdActive]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      limit: LIMIT,
      action: action,
      lastInResp: lastInResp,
      userId: userIdVisited,
    };
    dispatch(getCoursesParticipateById(userIdVisited, data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastMyCourseElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isMyCourseListEmpty) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isMyCourseListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> CHANGE USER PROFILE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (
      courseParticipateLoad?.userData &&
      userIdVisited != courseParticipateLoad?.userData
    ) {
      dispatch(getIsChangeMyCourse());
    }
  }, [userIdVisited]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (
      (courseParticipateLoad?.docs.length <= 0 &&
        courseParticipateLoad?.isCollectionEmpty == -1) ||
      (courseParticipateLoad?.docs.length <= 0 &&
        courseParticipateLoad?.isCollectionEmpty == 0)
    ) {
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
      // setLoading(true);
    }
  }, [courseParticipateLoad?.docs]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER MY PROJECT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (courseParticipateLoad?.docs.length > 0) {
      courseParticipateLoad.docs.map((element) => {
        if (!myCourseList.includes(element)) {
          setMyCourseList((ele) => ele.concat(element));
        }
      });
      setlastInResponse(courseParticipateLoad?.lastInResponse);
      setIsMyCourseListEmpty(
        courseParticipateLoad?.isCollectionEmpty === 0 ? true : false
      );
      // setLoading(false);
      setLoadingPagination(false);
    } else if (
      courseParticipateLoad?.docs.length <= 0 &&
      courseParticipateLoad?.isCollectionEmpty == 0
    ) {
      setIsMyCourseListEmpty(true);
      setMyCourseList([]);
      // setLoading(false);
      setLoadingPagination(false);
    } else if (
      courseParticipateLoad?.docs.length <= 0 &&
      courseParticipateLoad?.isCollectionEmpty == -1
    ) {
      setMyCourseList([]);
      // setLoading(false);
      setLoadingPagination(false);
    }
  }, [courseParticipateLoad, userIdVisited, isMyCourseListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER COURSES Y SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (hit && hit.length > 0) {
      setDocs(hit);
    } else {
      setDocs(myCourseList);
    }
  }, [hit, docs, myCourseList]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC ONCHANGE SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleChange = (e) => {
    var { value } = e.target;
    if (value == "") {
      setDocs([]);
      setDocs(myCourseList);
    } else {
      value.length >= 3 && performSearch(value);
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    docs,
    handleChange,
    lastMyCourseElementRef,
    loading,
    loadingPagination,
    isProprietor,
  };
};
