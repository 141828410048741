import React from "react";
import Load from "components/Load";

import { useMyProjectsParticipate } from "Hooks/project/useMyProjectsParticipate";
import { EmptyData } from "components/shared/EmptyData";
import { CardListMyProjectStyle } from "./components/CardListMyProjectStyle";
import { ProjectsLoadingIndicator } from "./loading/ProjectsLoading";

export const MyProjectsParticipate = () => {
  const {
    loading,
    loadingPagination,
    docs,
    lastMyProjectElementRef,
    isProprietor,
  } = useMyProjectsParticipate();

  return (
    <div className="row m-auto mt-4">
      {loading && docs?.length <= 0 && <ProjectsLoadingIndicator />}
      {!loading && docs?.length <= 0 && (
        <EmptyData
          pathImg="/assets/images/NoData.svg"
          title="There are no projects where you are participating"
        />
      )}
      {docs?.map((value, index) => {
        if (docs.length === index + 1) {
          return (
            <div
              key={value.uid}
              ref={lastMyProjectElementRef}
              className="col-md-4 col-sm-6 pe-2 ps-2"
            >
              <CardListMyProjectStyle
                uid={value.uid}
                title={value.title}
                pageCover={value.pageCover}
                isProprietor={!isProprietor}
              />
            </div>
          );
        } else {
          return (
            <div key={value.uid} className="col-md-4 col-sm-6 pe-2 ps-2">
              <CardListMyProjectStyle
                uid={value.uid}
                title={value.title}
                pageCover={value.pageCover}
                isProprietor={!isProprietor}
              />
            </div>
          );
        }
      })}

      {loadingPagination && <Load />}
    </div>
  );
};
