import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { imageExistsProfile } from 'helpers/verifyImg';
import { useUserSuggestions } from 'Hooks/user/useUserSuggestions';
import { SuggestionUserLoading } from 'components/Loadings/SuggestionUserLoading';


const FriendsSuggestions = () => {

    const { userSuggestionslist, loading } = useUserSuggestions();

        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex align-items-center p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">Friend suggestions</h4>
                    <Link to="/users" className="fw-600 ms-auto font-xssss text-primary">See all</Link>
                </div>
                {
                    loading  &&
                    <SuggestionUserLoading/>
                }
                {userSuggestionslist?.map((value , index) => (
                <div className="wrap" key={index}>
                    <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0 animate__animated animate__fadeIn">
                        <figure className="avatar me-3"><img src={value.photoUrl} onError={ imageExistsProfile }  alt="avater" className="shadow-sm rounded-circle w45" /></figure>
                        <h4 className="fw-700 text-grey-900 font-xssss mt-1">{value.userName} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{value.email}</span></h4>
                    </div>
                    <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                        <Link to={`/PROFILE/${value?.userName?.replaceAll(' ', '_')}/${value?.uid}`} className="p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Add friend</Link>
                        {/* <a href="/USERS" className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">Delete</a> */}
                    </div>
                </div>

                ))}

                
            </div>
        );
}

export default FriendsSuggestions;