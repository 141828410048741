import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateToken } from "actions/notification";
import { messaging } from "firebase/config";

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const useGetToken = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.notifications);

  const getTken = async (userIdActive) => {
    let currentToken = await messaging.getToken({
      vapidKey: publicKey,
    });
    
    if (currentToken != token && userIdActive) {
      dispatch(updateToken({ token: currentToken, userId: userIdActive }));
    }
  };

  return {
    getTken,
  };
};
