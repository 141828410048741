import React, { useEffect } from "react";
import Load from "../../Load";
import SearchAll from "../../../components/shared/SearchAll";
import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getGroupMembersList, notificationGroupResponse } from "../../../actions/group";
import { imageExistsProfile } from "../../../helpers/verifyImg";

export const ViewMembersGroup = ({ idUser, idUserGroup, idGroup, loading }) => {
  const { membersGroup } = useSelector((state) => state.group);

  useEffect(() => {
    dispatch(getGroupMembersList({ groupId: idGroup }));
  }, [idGroup]);

  const dispatch = useDispatch();
  const handleShowAlert = (e, value) => {
    Swal.fire({
      title: "Are you sure you want to remove this participant from the group?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      const data = {
        groupId: idGroup,
        userId: value,
        state: false,
      };

      if (result.isConfirmed) {
        dispatch(notificationGroupResponse(data));
      }
    });
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <SearchAll title="Member" />
        {loading && <Load/>}
        {!loading && (
          <div className="row ps-2 pe-2">
            {membersGroup?.map((value, index) => (
              <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2">
                <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                  <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                    {idUserGroup === value?.userId && (
                      <div className="badge badge-info ">Admin</div>
                    )}

                    <figure className="overflow-hidden pt-2 avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                      <img
                        src={value?.photoUrl}
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                        alt="avater"
                        onError={ imageExistsProfile }
                        className="border  p-0 bg-white rounded-circle shadow-xss"
                      />
                    </figure>
                    <div className="clearfix w-100"></div>
                    <h4 className="fw-700 font-xsss mt-3 mb-3">
                      {value?.userName}
                    </h4>

                    {idUser !== value?.userId && (
                      <Link
                        to={`/PROFILE/${value?.userName.replaceAll(' ', '_')}/${value?.userId}`}
                        className="mt-0 btn pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                      >
                        VIEW FRIEND
                      </Link>
                    )}

                    {idUser === value?.userId && <span>Your</span>}
                    {idUser === idUserGroup && (
                      <div className="ms-auto d-block pt-3">
                        {idUser !== value.userId && (
                          <i
                            onClick={(e) => {
                              handleShowAlert(e, value?.userId);
                            }}
                            className=" cursor-pointer btn-round-sm font-xs text-primary feather-trash-2  bg-greylight"
                          ></i>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
