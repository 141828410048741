import React from "react";
import ContentLoader from "react-content-loader";

const OrganizationLoading = () => (
  <ContentLoader
    width="100%"
    height={330}
    // viewBox="0 0 300 400"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede">
    
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="200" />

    <rect x="33%" y="220" rx="5" ry="5" width="100" height="10" />
    <rect x="46%" y="240" rx="5" ry="5" width="30" height="30" />
    <rect x="23%" y="300" rx="20" ry="20"  width="150" height="30" />

  </ContentLoader>
);

export { OrganizationLoading };
