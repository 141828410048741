import { types } from "../types/types";

const inicialState = {
  loading: false,
  groupLoad: {
    userData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  groupParticipateLoad: {
    userData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  tempGroup: null,
  createdFinished: false,
  userMember: false,
  lengthGroup: 0,
  membersGroup: [],
  loadingMembers: false,
  groupsIBelongTo: null,
  isChange: false,
};

export const groupReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.loadingGroup:
      return {
        ...state,
        loading: true,
      };

    case types.loadingGroupNavigationRefresh:
      return {
        ...state,
        loading: false,
        tempGroup: action.payload,
      };

    case types.createGroup:
      return {
        ...state,
        loading: false,
        groupLoad: state.groupLoad && {
          lastInResponse: state.groupLoad.lastInResponse,
          isCollectionEmpty: state.groupLoad.isCollectionEmpty,
          docs: [action.payload, ...state.groupLoad.docs],
        },
        createdFinished: true,
      };

    case types.updateGroup:
      state.groupLoad &&
        state.groupLoad.docs.splice(
          state.groupLoad.docs.findIndex(
            (org) => org.uid === action.payload?.uid
          ),
          1,
          action.payload
        );
      return {
        ...state,
        loading: false,
        tempGorup: action.payload,
        createdFinished: true,
      };

    case types.tempGroup:
      return {
        ...state,
        loading: false,
        tempGroup: action.payload,
      };

    case types.tempGroupLoad:
      const group = state.groupLoad?.docs.find(
        (org) => org.uid == action.payload?.uid
      );
      return {
        ...state,
        loading: false,
        tempGroup: group,
      };

    case types.removeTempGroup:
      return {
        ...state,
        loading: false,
        tempGroup: null,
        createdFinished: false,
      };

    case types.getGroups:
      let hash = {};
      let isLoadedData =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getGroupsIsCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;
      return {
        ...state,
        loading: false,
        groupLoad: isLoadedData
          ? state.groupLoad
            ? {
                userData: action.payload.userData,
                docs: [
                  ...state.groupLoad?.docs,
                  ...action.payload?.docs,
                ].filter((o) => (hash[o.uid] ? false : (hash[o.uid] = true))),
                isCollectionEmpty: getGroupsIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.groupLoad?.userData,
              docs: state.groupLoad?.docs,
              isCollectionEmpty: getGroupsIsCollectionEmpty,
              lastInResponse: state.groupLoad?.lastInResponse,
            },
      };

    case types.getGroupsParticipate:
      let hashParticipate = {};
      let isLoadedDataParticipate =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getGroupsParticipateIsCollectionEmpty = action.payload
        ?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;
      return {
        ...state,
        loading: false,
        groupParticipateLoad: isLoadedDataParticipate
          ? state.groupParticipateLoad
            ? {
                userData: action.payload.userData,
                docs: [
                  ...state.groupParticipateLoad?.docs,
                  ...action.payload?.docs,
                ].filter((o) =>
                  hashParticipate[o.uid]
                    ? false
                    : (hashParticipate[o.uid] = true)
                ),
                isCollectionEmpty: getGroupsParticipateIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.groupParticipateLoad?.userData,
              docs: state.groupParticipateLoad?.docs,
              isCollectionEmpty: getGroupsParticipateIsCollectionEmpty,
              lastInResponse: state.groupParticipateLoad?.lastInResponse,
            },
      };

    case types.isChangeMyGroup:
      return {
        ...state,
        groupLoad: {
          userData: null,
          docs: [],
          isCollectionEmpty: -1,
          lastInResponse: null,
        },
      };

    case types.userMemberGroup:
      return {
        ...state,
        userMember: action.payload,
      };

    case types.allMembersGroup:
      return {
        ...state,
        lengthGroup: action.payload,
      };

    case types.allMembersDataGroup:
      function SortArrayMembers(x, y) {
        if (x.userName < y.userName) {
          return -1;
        }
        if (x.userName > y.userName) {
          return 1;
        }
        return 0;
      }
      return {
        ...state,
        membersGroup: [...action.payload].sort(SortArrayMembers),
      };

    case types.loadingMembersgroup:
      return {
        ...state,
        loadingMembers: action.payload,
      };

    case types.getGroupsIBelongTo:
      return {
        ...state,
        groupsIBelongTo: action.payload,
      };

    default:
      return state;
  }
};
