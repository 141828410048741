
import React from 'react'

import ReactMarkdown from 'react-markdown'
import { FilesProject } from './FilesProject';
import { useCreateProject } from 'Hooks/project/useCreateProject';


export const CreateFormProject = () => {
    
    const { handlerform, handleImg, onChangeMembers, formsValue, dataMembers, page, msgError } = useCreateProject();

    return (
        <> 
              { 
                  (page === 1)&& 
                <form onSubmit={handleImg}>
                  { 
                        msgError && 
                         <div className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0 ">
                          {msgError}
                         </div>}
                  <div className="col-xl-12 mb-3">
                        <div className="form-group ">
                          <label className="mont-font fw-600 font-xsss">Title</label>
                          <input type="text" name="title" className="form-control" onChange={handlerform} placeholder="title" ></input >
                        </div> 
                        <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">Description</label>
                            <textarea  style={{lineHeight: "normal", height: "150px" }}  type="text" name="description" className="form-control" onChange={handlerform} placeholder="description" ></textarea >
                            <label className="mont-font  font-xssss">minimum 200 characters</label>
                        </div> 

                      <div className="row text-center">
                        <div className="col-md-6 ">
                        <label className="mont-font fw-600 font-xsss" >Content</label>
                          <div className="form-group">
                              <textarea type="text" className="form-control"  name="content" onChange={handlerform} placeholder="# Hello world!" style={{height: 400}}></textarea>
                              <label className="mont-font  font-xssss">minimum 200 characters</label>
                          </div>
                        </div>

                          <div className="col-md-6">
                            <label className="mont-font fw-600 font-xsss" >Preview</label>
                            <div className="container border" style={{height: 400, overflow: "scroll"}}>
                            <ReactMarkdown> 
                              {formsValue?.content}</ReactMarkdown>
                            </div>
                          </div>
                      </div>  

                      <div className="form-group">  
                      <label className="mont-font fw-600 font-xsss">Status project</label>              
                      <select className="form-control" value={formsValue?.stateProject} name="stateProject" onChange={handlerform}>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                      </select>
                      </div>
                      </div>
                      
                      
                        {/* <div className="p-3 form-group">
                        <label className="mont-font fw-600 font-xsss" htmlFor="Members">Members</label>
                        <Members
                            isEdit={ false }
                            value= { dataMembers } 
                            onChange={ onChangeMembers }>
                        </Members>
                        
                    </div> */}

                  <div  className='mt-5 pb-5'>  
                    <button type="submit" className=" bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-blockbg-current text-center text-white font-xsss fw-600 p-3 w200 rounded-3 d-inline-block ">Create project</button>
                  </div>
            </form>
            }
            {
              (page === 2)&& 
              <FilesProject/>
            }
              
        </>
    )
}

