import { types } from "../types/types"


export const setError = (err) =>({
    type: types.uiSetError,
    payload: err 
});

export const removeError = () =>({
    type: types.RemoveError,
  
});

export const chatCountMessage = (data) =>({
    type: types.chatCount,
    payload: data 
});

export const PercertFiles = (data) =>({
    type: types.UploadingFiles,
    payload: data 
});