import React from 'react'
import { CardRouteProfileOptions } from 'components/shared/CardRouteProfileOptions'
import { CreateFormProject } from './CreateFormProjects'

export const CreateProyect = () => {
    return (
    <>
        <CardRouteProfileOptions title="Create Project"  component={  CreateFormProject } ></CardRouteProfileOptions>
    </>
    )
}
