import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createCourse, setTempCourseReset } from "../../actions/course";
import { removeError, setError } from "../../actions/ui";
import uploadFile from "helpers/uploadFile";
import { firebase } from "firebase/config";
import { closeLoading, successLoading } from "../../helpers/loadingCreate";

export const useCreateCourse = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, createdFinished } = useSelector((state) => state.course);
  let { uid: userId, name } = useSelector((state) => state.auth);
  const { msgError } = useSelector((state) => state.ui);
  const [progress, setProgress] = useState(0);
  const [error, setErrorURL] = useState("");
  const [inputFields, setInputFields] = useState([
    { titleVideo: "", urlVideo: "" },
  ]);
  const [coverPageSelectedPrevious, setCoverPageSelectedPrevious] =
    useState(null);
  const [isUpdate, setisUpdate] = useState(false);
  const [coverPageSelectedFile, setCoverPageSelectedFile] = useState(null);
  const [formDetail, setFormDetail] = useState({
    title: "",
    description: "",
    pageCover: "",
  });
  const handleAddFields = () => {
    setInputFields([...inputFields, { firstName: "", urlVideo: "" }]);
  };

  //  Validations
  const isFormValidate = () => {
    if (formDetail?.title.trim().length < 5) {
      dispatch(setError("Title too short"));
      return false;
    } else if (formDetail?.description.trim().length < 5) {
      dispatch(setError("Description too short"));

      return false;
    }

    dispatch(removeError());

    return true;
  };

  const changeImage = (e, type) => {
    if (e.target.files[0] !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      setCoverPageSelectedFile(e.target.files[0]);
      reader.onload = (e) => {
        e.preventDefault();
        setCoverPageSelectedPrevious(e.target.result);
      };
    }
  };

  const handlerform = ({ target }) => {
    const value = target.value;
    setFormDetail({
      ...formDetail,
      [target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisUpdate(true);
    successLoading("Creating course please wait...");

    const uid = firebase.firestore().collection("courses").doc().id;
    const createDate = new Date().toLocaleString();

    let pageCover = "";
    if (coverPageSelectedFile) {
      pageCover = await uploadFile(
        coverPageSelectedFile,
        `/course/${uid}/imageMain/coverPage.jpg`,
        setProgress,
        setErrorURL
      );
    }
    const data = {
      uid,
      ...formDetail,
      url: inputFields,
      createDate,
      userId,
      pageCover,
    };

    if (isFormValidate()) {
      // dispatch(imageCourse(coverPageSelectedFile));
      dispatch(createCourse(data));
    }
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.urlVideo) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.urlVideo === id),
      1
    );
    setInputFields(values);
  };

  useEffect(() => {
    if (createdFinished) {
      setisUpdate(false);
      closeLoading();
      setCoverPageSelectedPrevious(null);
      setCoverPageSelectedFile(null);
      dispatch(setTempCourseReset());
      name = name?.replaceAll(" ", "_");
      history.push(`/PROFILE/${name}/${userId}/courses/my-courses`);
    }
  }, [createdFinished]);

  return {
    handleSubmit,
    handleChangeInput,
    changeImage,
    handlerform,
    coverPageSelectedPrevious,
    inputFields,
    handleAddFields,
    handleRemoveFields,
    loading,
    msgError,
    isUpdate,
  };
};
