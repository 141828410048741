import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { imageExistsProduct } from "helpers/verifyImg";

export const ProfileModalExperience = ({
  show,
  handleSubmit,
  handleClose,
  listFiles,
  deleteFile,
}) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>Change Experience</Modal.Title>
          <button
            className="btn ti-close"
            type="button"
            onClick={handleClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          {listFiles.length <= 0 && (
            <div className=" d-flex">
              <h6 className="m-auto"> You have no experiences</h6>
            </div>
          )}
          {listFiles?.map((value) => (
            <div
              key={value?.uid}
              className="card-body border-top-xs d-flex"
            >
              <img
                src={value?.organizationInfo?.logoUrl}
                alt="avater"
                className="border me-3 bg-white rounded-circle"
                width="40"
                height="40"
                onError={imageExistsProduct}
              />

              <h4 className="cursor-pointer fw-700 text-grey-900 font-xssss mt-2">
                {value?.organizationInfo?.name}
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {value?.job}
                </span>
                <span
                  className=" d-block fw-600 mt-1 lh-3 text-grey-500"
                  style={{ fontSize: 9 }}
                >
                  {value?.startDate} - {value?.endDate}
                </span>
              </h4>

              {/* <div
                // onClick={() => deleteFile(value)}
                className="cursor-pointer bg-danger btn-round-sm font-xs ms-auto text-primary me-2 bg-greylight"
              >

              </div> */}
              <Form.Check className=" ms-auto" inline name="group1" id={`inline-1`} onChange={() =>  deleteFile(value) }  />
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="outline-info" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
