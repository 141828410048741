import { useState } from "react";
import { useSelector } from "react-redux";
import {
  ALGOLIA_INDEX_GROUPS_GLOBAL,
  ALGOLIA_INDEX_USERS_GLOBAL,
} from "helpers/env";

export const useSearchAlgolia = (index, type) => {

  const [results, setResults] = useState(null);
  let { uid } = useSelector((state) => state.auth);

  const performSearch = async (value) => {
    const option =
      type == ALGOLIA_INDEX_USERS_GLOBAL
        ? {
            hitsPerPage: 5,
            filters: `NOT uid:${uid}`,
          }
        : {
            hitsPerPage: 5,
          };

    
    const { hits } = await index.search(value, option);
    const rs = hits.map((hit) => {
      if (type == ALGOLIA_INDEX_USERS_GLOBAL) {
        const {
          objectID: uid,
          userName,
          photoUrl,
          email,
          _highlightResult,
        } = hit;
        return { uid, userName, photoUrl, email };
      } else if (type == ALGOLIA_INDEX_GROUPS_GLOBAL) {
        const {
          objectID: uid,
          title,
          pageCover,
          userId,
          _highlightResult,
        } = hit;
        return { uid, title, pageCover, userId };
      } else {
        const {
          objectID: uid,
          userName,
          photoUrl,
          email,
          _highlightResult,
        } = hit;
        return { uid, userName, photoUrl, email };
      }
    });
    setResults(rs);
  };

  return {
    results,
    performSearch,
    setResults
  };
};
