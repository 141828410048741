import React from 'react';
import { useRemoveComment } from 'Hooks/post/useRemoveComment';

export const RemoveComment = ( { data, groupId, closeModal }) => {
   const { uid, postId }= data;
   const { handleRemoveComment } = useRemoveComment(uid, postId, groupId, closeModal)
  return (
        <li onClick={ handleRemoveComment } className="cursor-pointer d-flex">
            <div className='icon-li '>
                <i className="font-sm ti-trash  text-grey"></i> 
            </div> 
            <div className='text-li'>
                <span className='pb-1'> Remove comment </span>
            </div>
        </li>
  );
};
