import React, { Component , Fragment } from "react";
import Header from "../components/headers/Header";
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import Profilephoto from '../components/Profilephoto';
import { ProfileCardOne } from '../components/ProfileCardOne';
import CreatePost from '../components/post/CreatePost';
import Events from '../components/Events';
import PostView from '../components/post/PostView';
import Load from '../components/Load';
import { ProfileDetailPerson } from "../components/profile/components/ProfileDetailPerson";


class GroupPage extends Component {
    render() {
        return (
            <Fragment> 
                <Header />
                <Leftnav />
                <Rightchat />

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                    <ProfileCardOne />
                                    <ProfileDetailPerson />
                                    <Profilephoto />
                                    <Events />
                                </div>
                                <div className="col-xl-8 col-xxl-9 col-lg-8 mt-3">
                                    <CreatePost />
                                    <PostView id="32" postvideo="" postimage="post.png" avater="user.png" user="Surfiya Zakir" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <PostView id="31" postvideo="" postimage="post.png" avater="user.png" user="David Goria" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <PostView id="33" postvideo="" postimage="post.png" avater="user.png" user="Anthony Daugloi" time="2 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <Load />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popupchat />
                <Appfooter /> 

            </Fragment>
        );
    }
}

export default GroupPage;