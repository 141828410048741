import React from "react";
import PropTypes from "prop-types";

import { Route } from "react-router-dom";
import { ViewCourseInformation } from "../all/ViewCourseInformation";
import { ViewCourseMembers } from "../all/ViewCourseMembers";
import { ViewCourseRequesMembers } from "../all/ViewCourseRequesMembers";
import { ViewCourseAbout } from "../all/ViewCourseAbout";
import { ForumCourse } from "../all/ForumCourse";

export const RouterViewCourse = ({
  exitsMember= '',
  userIdActive,
  tempCourse = {},
  loadingMembers,
  active,
  courseIdVisited
}) => {
  return (
    <>
      <Route exact path="/ViewCourse/:id">
        <ViewCourseInformation
          exitsMember={exitsMember}
          userIdActive={userIdActive}
          tempCourse={tempCourse}
        />
      </Route>
      <Route path={`/ViewCourse/:id/members`}>
        <ViewCourseMembers
          tempId={tempCourse?.uid}
          courseId={courseIdVisited}
          idUserOwner={tempCourse?.userId}
          idUser={userIdActive}
          loading={loadingMembers}
        />
      </Route>
      <Route path={`/ViewCourse/:id/requests`}>
        <ViewCourseRequesMembers />
      </Route>
      <Route path={`/ViewCourse/:id/forum`}>
        <ForumCourse existMember={active} />
      </Route>
      <Route path={`/ViewCourse/:id/about`}>
        <ViewCourseAbout tempCourse={tempCourse} />
      </Route>
    </>
  );
};

RouterViewCourse.propTypes = {
  exitsMember: PropTypes.string.isRequired,
  userIdActive: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  tempCourse: PropTypes.object.isRequired,
  loadingMembers: PropTypes.bool.isRequired,
};
