import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPublicInfoGlobal, getUser, navigateAuth } from "actions/auth";
import { getFriendInvitation, getTempFriendInvitation } from "actions/profile";
import { stateFriend } from "helpers/stateFriend";
import { getIsChange } from "actions/post";

export const useUserPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const { name, uid, photoUrl } = useSelector((state) => state.navigateAuth);
  const { loading: userLoading } = useSelector((state) => state.user);
  const { uid: userId } = useSelector((state) => state.auth);
  const { tempFriendInvitation } = useSelector((state) => state.friend);
  const [accountFriend, setAccountFriend] = useState(null);

  useEffect(() => {
    if (id != userId) {
      dispatch(getFriendInvitation({ userFromId: userId, userToId: id }));
    }

    if (id != uid && accountFriend?.state != stateFriend.locked.description) {
      dispatch(getPublicInfoGlobal());
      dispatch(getUser({ uid: id }));
      dispatch(getTempFriendInvitation(null))
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (uid != "" && name != "" && photoUrl != "") {
      dispatch(navigateAuth(uid, name, photoUrl));
    }
  }, [uid, name, photoUrl]);

  useEffect(() => {
    if (tempFriendInvitation) {
      setAccountFriend(tempFriendInvitation);
    }
  }, [tempFriendInvitation]);


  return {
    uid,
    name,
    photoUrl,
    accountFriend,
    userLoading,
  };
};
