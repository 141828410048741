import React from "react";
import Header from "../components/headers/Header";
import { LoadingIndicator } from "stream-chat-react";
import { useChat } from "../Hooks/chat/useChat";
import { useMediaQuery } from "react-responsive";
import { ChatViewBrowser } from "../components/chat/ChatViewBrowser";
import { ChatViewMobile } from "../components/chat/ChatViewMobile";

export const Chats = () => {
  const {
    Channels,
    animatedComponents,
    client,
    filters,
    friends,
    onChangeMembers,
    sort,
    createChannel,
    membersusers,
  } = useChat();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });


  if (!client) {
    return <LoadingIndicator />;
  }


  return (
    <>
      <Header />
      { (isDesktopOrLaptop) && (
        <ChatViewBrowser
          onChangeMembers={onChangeMembers}
          createChannel={createChannel}
          membersusers={membersusers}
          animatedComponents={animatedComponents}
          Channels={Channels}
          client={client}
          filters={filters}
          sort={sort}
        />
      )}
      {isTabletOrMobile && (
        <ChatViewMobile
          onChangeMembers={onChangeMembers}
          createChannel={createChannel}
          membersusers={membersusers}
          animatedComponents={animatedComponents}
          Channels={Channels}
          client={client}
          filters={filters}
          sort={sort}
        />
      )}
    </>
  );
};
