import React from 'react'
import { Modal, Button } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { useCreatePost } from '../../Hooks/post/useCreatePost';
import { ModaPostlFilePrevious } from './ModaPostlFilePrevious';
import { ModalCreatePostOption } from './ModalCreatePostOption';
import { imageExistsProfile } from '../../helpers/verifyImg'

export const ModalCreatePostView = ({groupId, handleShow, show, handleClose, friendProfile}) => {

    const { uid, photoUrl } = useSelector((state) => state.auth);
    const { hanledCreatePost, removeFile, ImageSelectedPrevious, fileType, changeImage, changeTextArea } = useCreatePost(uid, groupId, friendProfile);
    const style = ImageSelectedPrevious != null ? { overflow: 'scroll', height: '500px' } : { overflowY: 'scroll' }

    return (
        <>
          <div className="card-body p-0 mt-3 position-relative" onClick={ handleShow }>
              <figure className="avatar position-absolute ms-2 mt-1 top-5"><img src={photoUrl} alt="icon" onError={ imageExistsProfile } className="shadow-sm rounded-circle w30" /></figure>
              <textarea name="message" className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder="What's on your mind?"></textarea>
          </div>

          <Modal 
            show={show} 
            onHide={handleClose}
            size="lg"
            >
            <form onSubmit={hanledCreatePost}>
              <Modal.Header>
                <Modal.Title>Create new post </Modal.Title>
                <button className="btn ti-close" type="button" onClick={handleClose}></button>
              </Modal.Header>
              <Modal.Body>
                <div style={style}>
                    <div className="card-body p-0 mt-1 position-relative">
                       <figure className="avatar position-absolute ms-2 mt-1 top-5"><img src={ photoUrl != null ? photoUrl : "assets/images/user.png"} alt="icon" className="shadow-sm rounded-circle w30" /></figure>
                       <textarea name="message" className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder="What's on your mind?"  onChange={ changeTextArea }></textarea>
                    </div> 
                    <ModaPostlFilePrevious  
                      ImageSelectedPrevious = { ImageSelectedPrevious }
                      fileType= { fileType } 
                      removeFile= { removeFile } />
                </div>
              <ModalCreatePostOption 
                 titleBtn=' Create Post'
                 changeImage = { changeImage } 
                 handleClose = { handleClose } />
              </Modal.Body>
            </form>
          </Modal>
        </>
      );
}


