import Swal from "sweetalert2";
import connectNetwork from "../helpers/connectNetwork";

import { types } from "../types/types";
import { firebase } from "../firebase/config";
import { configApi, urlApi, urlApiDev } from "../helpers/env";
import { client } from "../helpers/chat";
import { titleCase } from "helpers/titleCase";



export const starloginEmailPassword = (email, password) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        dispatch(login(user.uid, user.displayName, user.photoURL));
        Swal.close();
      })
      .catch((e) => {
        Swal.close();
        Swal.fire("Error", `${e.message}`, "error");
      });
  };
};

export const updateProfilePhoto = (data, type) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updatePhotoUserById`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch(
          login(
            response.user.uid,
            response.user.userName,
            response.user.photoUrl
          )
        );
        const data = {
          type,
          user: response.user,
        };
        dispatch(setUpdateProfileImage(data));
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateProfileCoverPage = (data, type) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();

    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updatePhotoCoverUserById`, config);
      let response = await resp.json();
      if (response.ok) {
        const data = {
          type,
          user: response.user,
        };
        dispatch(setUpdateProfileImage(data));
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const UpdateUserPrivate = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();

    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/privacySettingsProfile`, config);
      let response = await resp.json();
      if (response.ok) {
        Swal.close();
        Swal.fire("Ok", "Datos Actualizados correctamente", "success");
        dispatch(setUpdateProfile(response.user));
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getPublicInfoGlobal = () => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi();
      let resp = await fetch(`${urlApi}/privacySettingsGlobal`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch(getGlobalInfo(response.privacySettings));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getUser = (data) => {
  return async (dispatch) => {
    dispatch(LoadingUser());
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getUserById`, config);
      let response = await resp.json();

      if (response.ok) {
        const { uid, userName, photoUrl } = response.user;
        dispatch(setUpdateProfile(response.user));
        dispatch(navigateAuth(uid, userName, photoUrl));
        dispatch(LoadingUserOff());
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
      dispatch(LoadingUserOff());
    }
  };
};

export const CreateUser = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });
    Swal.showLoading();

    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/signUp`, config);
      let response = await resp.json();
      if (response.ok) {
        Swal.close();
        dispatch(starloginEmailPassword(response.user.email, data.password));
      }
      return response.ok;
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
      return false;
    }
  };
};

export const StartGoogleLogin = () => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(async ({ user }) => {
        const {
          uid,
          displayName: userName,
          photoURL: photoUrl,
          phoneNumber,
          email,
        } = user;

        const data = {
          uid,
          userName: titleCase(userName),
          photoUrl,
          phoneNumber: phoneNumber ?? "",
          email,
        };
        try {
          let config = configApi(data);
          let resp = await fetch(`${urlApi}/signUpGoogle`, config);
          let response = await resp.json();
          if (response.ok) {
            dispatch(setUpdateProfile(response.user));
            dispatch(login(user.uid, user.displayName, user.photoURL));
          }
        } catch (error) {
          Swal.fire("Error", `${error.message}`, "error");
        }
      })
      .catch((e) => {
        Swal.fire("Error", `${e.message}`, "error");
      });
  };
};

export const changePassword = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/changePassword`, config);
      let response = await resp.json();
      if (response.ok) {
        Swal.close();
        response.isEmail && dispatch(sedEmailChangePass(data.email));
        !response.isEmail &&
          Swal.fire("Ups!", "El correo no se encuentra registrado", "warning");
      }
      return response.ok;
    } catch (error) {
      Swal.close();

      Swal.fire("Error", `${error.message}`, "error");
      return false;
    }
  };
};

const sedEmailChangePass = (email) => {
  return async () => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((res) => {
        Swal.fire(
          "Success",
          `Check your email to change your password`,
          "success"
        );
      })
      .catch((e) => {
        Swal.close();
        Swal.fire("Error", `${e.message}`, "error");
      });
  };
};

export const navigateAuth = (uid, displayName, photoUrl) => {
  return {
    type: types.navigate,
    payload: {
      uid,
      name: displayName,
      photoUrl,
    },
  };
};

export const login = (uid, displayName, photoUrl) => {
  return {
    type: types.login,
    payload: {
      uid,
      displayName,
      photoUrl,
    },
  };
};
export const setUpdateProfileImage = (datos) => {
  return {
    type: types.getUpdateProfileImage,
    payload: datos,
  };
};

export const setUpdateProfileImageReset = (datos) => {
  return {
    type: types.getUpdateProfileImageReset,
  };
};

export const setUpdateProfile = (datos) => {
  return {
    type: types.getUpdateProfile,
    payload: datos,
  };
};

export const setUpdateProfileReset = () => {
  return {
    type: types.getUpdateProfileReset,
  };
};

export const getGlobalInfo = (datos) => {
  return {
    type: types.getBasicInfo,
    payload: datos,
  };
};

export const startLogout = (data) => {
  return async (dispatch) => {
    try {
      await firebase.auth().signOut();
      dispatch(logout());
      client.disconnectUser();
      let config = configApi(data);
      let resp = await fetch(`${urlApiDev}/signOut`, config);
      let response = await resp.json();
      if (response.ok) {
      }
    } catch (error) {}
  };
};

export const logout = () => ({
  type: types.logout,
});

export const LoadingUser = () => ({
  type: types.userLoading,
});

export const LoadingUserOff = () => ({
  type: types.userLoadingoff,
});
