import { types } from "../types/types";

const inicialState = {
  userSuggestions: null,
  allUsers: {
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  querySearch: null,
  loading: true,
};

export const usersReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.getAllUserLoading:
      return {
        ...state,
        loading: true,
      };

    case types.userSuggestions:
      return {
        ...state,
        userSuggestions: action.payload,
        loading: false
      };

    case types.allUserResponse:
      let hash = {};
      let isLoadedData =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let allUsersIsCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;

      function SortArray(x, y) {
        if (x.userName < y.userName) {
          return -1;
        }
        if (x.userName > y.userName) {
          return 1;
        }
        return 0;
      }
      return {
        ...state,
        allUsers: isLoadedData
          ? state.allUsers
            ? {
                docs: [...state.allUsers.docs, ...action.payload?.docs]
                  .filter((o) => (hash[o.uid] ? false : (hash[o.uid] = true)))
                  .sort(SortArray),
                isCollectionEmpty: allUsersIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              docs: state.allUsers?.docs,
              isCollectionEmpty: allUsersIsCollectionEmpty,
              lastInResponse: state.allUsers?.lastInResponse,
            },
        loading: false,
      };

    case types.getQuerySearch:
      return {
        ...state,
        querySearch: action.payload,
      };

    default:
      return state;
  }
};
