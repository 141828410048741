import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { imageExistsProfile } from "helpers/verifyImg";
import { useProfileContact } from "Hooks/profile/useProfileContact";

export const ProfileInfo = () => {
  const { uid, photoUrl, name, contactsMyFriends } = useProfileContact();

  const styleImgContainer = {
    width: "100%",
    height: "100px",
    textAlign: "center",
    alignContent: "center",
    display: "-ms-inline-flexbox",
    margin: "auto",
  };
  const styleImgProfile = {
    width: "100px",
    objectFit: "cover",
    height: "100px",
  };

  return (
    <div className="nav-wrap border-0  bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
      <ul>
        <div className="Container" style={styleImgContainer}>
          <img
            src={photoUrl}
            onError={imageExistsProfile}
            alt="avater"
            className=" border  p-1 bg-white rounded-circle "
            style={styleImgProfile}
          />
        </div>
        <div className="text-center p-2 ">
          <h4 className="fw-700 mb-0 font-xssssss text-grey-900">{name}</h4>
        </div>
        <li className="border-top-xs">
          <Link
            to={`/PROFILE/${name}/${uid}/friends`}
            className="nav-content-bttn open-font"
          >
            <i className="feather-user-plus btn-round-md bg-blue-gradiant me-3"></i>
            <span>Friends</span>
            <span className="circle-count bg-warning mt-1">
              {contactsMyFriends}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
