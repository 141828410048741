import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addExperienceOrganizationForProfile } from "actions/profile";
import { getAllOrganizations } from "actions/organization";
import { useForm } from "Hooks/userForms";

export const useModalEditExperience = (uidExperience) => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { uid } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { allOrganization } = useSelector((state) => state.organization);
  const { experience } = useSelector(({ user: state }) => state.user);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [select, seSelect] = useState("");
  const [inputJob, setInputJob] = useState("");

  const [values, handleInputChange, reset] = useForm({
    startDate: new Date(),
    endDate: new Date(),
    job: "",
    organizationId: "",
  });

  useEffect(() => {
    show && dispatch(getAllOrganizations());
  }, [show]);

  useEffect(() => {
    const selectedOrganization = experience?.find(
      (exp) => exp?.uid == uidExperience
    );
    setSelectedOrganization(selectedOrganization);

    seSelect({
      label: selectedOrganization?.organizationInfo?.name,
      image: selectedOrganization?.organizationInfo?.logoUrl,
      value: selectedOrganization?.organizationInfo?.uid,
    });

    setStartDate(new Date(selectedOrganization?.startDate));
    setEndDate(new Date(selectedOrganization?.endDate));
    setInputJob(selectedOrganization?.job);

    // setListEdit(result2);
    // setresultEdit(result);
  }, [allOrganization, seSelect]);

  function onChangeInput(e) {
    const data = e.target.value;
    setInputJob(data);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const end =
      endDate.getFullYear() +
      "-" +
      (endDate.getMonth() + 1) +
      "-" +
      endDate.getDate();
    const start =
      startDate.getFullYear() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getDate();

    const data = {
      uid: selectedOrganization?.uid,
      userId: uid,
      startDate: start,
      endDate: end,
      job: inputJob,
      organizationId: select?.value
        ? select?.value
        : selectedOrganization?.organizationInfo.organizationId,
      type: "update",
    };

    dispatch(addExperienceOrganizationForProfile(data, 'update'));
  };

  return {
    handleShow,
    show,
    handleSubmit,
    handleClose,
    select,
    onChangeInput,
    allOrganization,
    endDate,
    setEndDate,
    seSelect,
    startDate,
    setStartDate,
    inputJob,
  };
};
