import React, {Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../actions/auth";
import { useForm } from "../Hooks/userForms";
import validator from 'validator'
import { removeError, setError } from "../actions/ui";
import SliderInfo from "components/sliderInformation/SliderInfo";


export const Forgot = ({ docInfo, docInfoLoading }) =>{

    const dispatch = useDispatch();
  const {msgError} = useSelector( state => state.ui );
    const [formsValue, handlerform] = useForm({
        email: "",
    })

    const { email } = formsValue;

    const handleLogin = (e) => {
        e.preventDefault();
        if(isFormValidate()){
            dispatch(changePassword({email}));
        }
    }

  const  isFormValidate = () =>{
       if(!validator.isEmail(email)){
         dispatch(setError("Email is not validate"));
         return false
       }
     
       dispatch(removeError());

        return true;
    }

        return (
            <Fragment> 
                <div className="main-wrap">
                    <div className="nav-header bg-transparent shadow-none border-0">
                        <div className="nav-top w-100">
                            {/* <a href="/"><i className="feather-zap text-success display1-size me-2 ms-0"></i><span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">Sociala. </span> </a> */}
                            {/* <button className="nav-menu me-0 ms-auto"></button> */}
            
                            <a href="/auth/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                            <a href="/auth/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-6">
                            <SliderInfo docInfo={docInfo} docInfoLoading={docInfoLoading}/>
                        </div>

                        <div className="col-xl-6 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left">
                                    <h2 className="fw-700 display1-size display2-md-size mb-4">Change <br />your password</h2>                        
                                    <form onSubmit={handleLogin}>

                                    { 
                                            msgError && 
                                           <div className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0 ">
                                                {msgError}
                                            </div>}
                                        
                                        
                                        <div className="form-group icon-input mb-3">
                                            <input type="email" name="email" onChange={handlerform}  className="style2-input ps-5 form-control text-grey-900 font-xss ls-3" placeholder="Enter your email" />
                                            <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                        </div>
                                        {/* <div className="form-group icon-input mb-1">
                                            <input type="Password" className="style2-input ps-5 form-control text-grey-900 font-xss ls-3" placeholder="New Password" />
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                        </div>
                                        <div className="form-check text-left mb-3">
                                            <input type="checkbox" className="form-check-input mt-2" id="exampleCheck4" />
                                            <label className="form-check-label font-xsss text-grey-500">Accept Term and Conditions</label>
                                        </div> */}
                                    
                                    <div className="col-sm-12 p-0 text-left">
                                        <div className="form-group mb-1"><button type="submit"  className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">Request</button></div>
                                        
                                    </div>
                                    </form>
                                    
                                </div>
                            </div> 
                        </div>
                        
                    </div>

                </div>
            </Fragment>
        );
}

export default Forgot;