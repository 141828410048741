import React from 'react'
import { CardRouteProfileOptions } from '../shared/CardRouteProfileOptions'
import { EditCourseView } from './EditCourseView'

export const EditCourse = () => {
    return (
        <>
        <CardRouteProfileOptions title="Edit Course" component={  EditCourseView } ></CardRouteProfileOptions>
    </>
    )
}
