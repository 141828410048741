import React from "react";
import { NavLink } from "react-router-dom";
import "../../../../css/scroll-horizontal.css";

export const ViewProjecMenuItem = ({ uid, idUserProject, userId }) => {
  return (
    <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
      <ul
        className="nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4 horizontal-slide"
        id="pills-tab"
        role="tablist">
        <li className="list-inline-item me-5 span2">
          <NavLink
            exact
            to={`/ViewProject/${uid}`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab">
            Information
          </NavLink>
        </li>
        <li className="list-inline-item me-5 span2">
          <NavLink
            to={`/ViewProject/${uid}/members`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab">
            Members
          </NavLink>
        </li>
        <li className="list-inline-item me-5 span2">
          <NavLink
            to={`/ViewProject/${uid}/achievements`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab">
            Achievements
          </NavLink>
        </li>
        {idUserProject == userId && (
          <li className="list-inline-item me-5 span2">
            <NavLink
              to={`/ViewProject/${uid}/requests`}
              activeClassName="active"
              className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
              data-toggle="tab">
              Requests
            </NavLink>
          </li>
        )}
        <li className="list-inline-item me-5 span2">
          <NavLink
            to={`/ViewProject/${uid}/forum`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab">
            Forum
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
