import React from "react";
import { imageExistsProfile } from "helpers/verifyImg";

export const ViewOrganizationJob = ({ members }) => {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">    
            Characters who work or belonged at some point       
        </h4>
        {members?.map((value) => (
          <div key={value?.user?.uid} className="card-body border-top-xs d-flex">
            <img
              src={value?.user?.photoUrl}
              alt="avater"
              className=" me-3 bg-white rounded-circle"
              width="30"
              onError={ imageExistsProfile }
            />
            <h4 className="fw-700 text-grey-900 font-xssss mt-2">
              {value?.user?.userName}
            </h4>
          </div>
        ))}
      </div>
    </div>
  );
};
