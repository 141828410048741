import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useScroll } from "../../../Hooks/scroll/useScroll";
import { EmptyData } from "../../shared/EmptyData";
import { ViewProjectModalAddAchievements } from "./ViewProjectModalAddAchievemnts";


export const ViewProjectAchievements = ({uidProject, uidUser}) => {
  const {tempProject} = useSelector( state => state.project );
  const {uid} = useSelector( state => state.auth );
  const { divRref } = useScroll();
  const [badgetList, setBadgetList] = useState(tempProject?.achievements);

  useEffect(() => {
    setBadgetList(tempProject?.achievements);
  }, [tempProject?.achievements])
  
  return (
    <div>
      <div className="row" ref={divRref} >
        <div className="col-xl-12">
          <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
            <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
              Achievements
              {
                uid === uidUser &&
                <ViewProjectModalAddAchievements uidProject={uidProject} listado={tempProject?.achievements}></ViewProjectModalAddAchievements>

              }
            </h2>
          </div>

          { 

           badgetList?.length == 0 &&
          <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
          <EmptyData pathImg="/assets/images/achievement.png" title="There are no achievements in this project yet." />
          </div>
          }




          <div className="row ps-2 pe-1">
            {badgetList?.map((value, index) => (
              <div key={index} className="col-md-4 col-sm-6 pe-2 ps-2">
                <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                  <div className="card-body d-block w-100 p-4 text-center">
                    {/* <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1"><img src={`/assets/images/${value.imageUrl}`} alt="avater" className="float-right p-1 bg-white rounded-circle w-100" /></figure> */}
                    <div className="clearfix"></div>
                    <h4
                      style={{ textAlign: "center" }}
                      className="fw-700 font-xss mt-3 mb-0 pb-2"
                    >
                      {value.title}{" "}
                    </h4>
                    <p
                      style={{ textAlign: "justify" }}
                      className="fw-500 font-xssss text-grey-500 mt-0 mb-3"
                    >
                      {value.description}
                    </p>

                    <ul className="d-flex align-items-center justify-content-center mt-1">
                    
                        <li className="m-1">
                          <img
                            src={`/assets/images/challenge-medal.svg`}
                            alt="icon"
                          />
                        </li>
                      
                      
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
