import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  getGroupById,
  getGroupMembersById,
  getSizeMembers,
} from "actions/group";

import { getPostsGroup } from "actions/post";

export const useViewGroup = () => {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const { tempGroup, loading, lengthGroup, userMember, loadingMembers } =
    useSelector((state) => state.group);

  const { page403 } = useSelector((state) => state.page403);

  const { uid: userId } = useSelector((state) => state.auth);
  const { id } = useParams();

  useEffect(() => {
    if (tempGroup?.uid !== id) {
      dispatch(getPostsGroup(null));
      dispatch(getGroupById({ uid: id }));
      dispatch(getGroupMembersById({ userId, groupId: id }));
      dispatch(getSizeMembers({ groupId: id }));
    }
  }, [id]);

  return {
    loading,
    tempGroup,
    userId,
    userMember,
    lengthGroup,
    navigate,
    loadingMembers,
    id,
    page403,
  };
};
