import React from "react";
import { Route } from "react-router-dom";
import { MyCourses } from "../MyCourses";
import { MyCoursesParticipate } from "../MyCoursesParticipate";
import PropTypes from 'prop-types'


export const RouterCourses = ({ routeProfile }) => {
  return (
    <div>
      <Route   path={`${routeProfile}/courses/my-courses`} component={MyCourses} />
      <Route   path={`${routeProfile}/courses/my-courses-participate`} component={MyCoursesParticipate} />
    </div>
  );
};

RouterCourses.propTypes = {
    routeProfile: PropTypes.string.isRequired,
}