import { useDispatch } from "react-redux";
import { removeCommentPost, removeCommentPostGroup } from "actions/comments";

export const useRemoveComment = (uid, postId, groupId, closeModal) => {
  const dispatch = useDispatch();

  const handleRemoveComment = () => {
    if (groupId) {
      dispatch(removeCommentPostGroup({ uid, postId, groupId }));
    } else {
      dispatch(removeCommentPost({ uid, postId }));
    }
    closeModal();
  };

  return {
    handleRemoveComment,
  };
};
