import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Forgot from "../pages/Forgot";
import { useInfoSetting } from "Hooks/settingInformation/useInfoSetting";

export const AuthRoutes = () => {
  const { docInfo, docInfoLoading } = useInfoSetting();
  return (
    <div>
      <Switch>
        <Route path="/auth/login">
          <Login docInfo={docInfo} docInfoLoading={docInfoLoading} />
        </Route>
        <Route path="/auth/register" component={Register} >
          <Register docInfo={docInfo} docInfoLoading={docInfoLoading}  />
        </Route>

        <Route path={`/auth/forgot`} component={Forgot} >
           <Forgot docInfo={docInfo} docInfoLoading={docInfoLoading}  />
        </Route>
        <Redirect to="/auth/login" />
      </Switch>
    </div>
  );
};
