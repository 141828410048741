import React from "react";
import ContentLoader from "react-content-loader";

const CourseLoading = ({ isPropietor }) => {
  return (
    <ContentLoader
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      height="145"
      width="100%"
    >
      <rect x="0" y="0" rx="4" ry="4" width="470" height="100" />

      <rect x="0" y="120" rx="4" ry="4" width="130" height="20" />
      {
        isPropietor && <rect x="65%" y="115" rx="50" ry="50" width="30" height="30" />
      }
      <rect x="76%" y="115" rx="10" ry="10" width="24%" height="30" />
    </ContentLoader>
  );
};

export { CourseLoading };
