import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNotificationStateById,
  getMyNotification,
  getMyNotifications,
  removeAllNotifications,
  removeNotificationById,
  setNotificationActive,
  setRemoveNotificationByIdReset,
} from "actions/notification";
import { LIMIT } from "helpers/env";
import { onMessageReceived } from "firebase/config";
import { closeLoading, successLoading } from "helpers/loadingCreate";

export const useMyNotifications = () => {
  const dispatch = useDispatch();
  const observer = useRef();

  const { uid: userIdActive } = useSelector((state) => state.auth);
  const { allNotifications, isRemove, loading } = useSelector(
    (state) => state.notifications
  );

  const [results, setResults] = useState(null);
  const [notificationList, setNotificationList] = useState(allNotifications?.docs);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isNotificationListEmpty, setIsNotificationListEmpty] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      userId: userIdActive,
      limit: LIMIT,
      action: action,
      lastInResp: lastInResp,
    };
    dispatch(getMyNotifications(data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> NOTIFICATIONS LISTENER
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    onMessageReceived().then((payload) => {
      const { data } = payload;
      dispatch(getMyNotification(data));
      dispatch(setNotificationActive());
    });
  }, []);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastNotificationsElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isNotificationListEmpty) {
          if (allNotifications && allNotifications?.isCollectionEmpty == 0) {
            setLoadingPagination(false);
          } else {
            setLoadingPagination(true);
          }
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isNotificationListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (
      allNotifications?.docs.length <= 0 &&
      allNotifications?.isCollectionEmpty != 0
    ) {
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
    }
  }, []);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER GROUPS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (allNotifications?.docs.length > 0) {
      allNotifications.docs.map((element) => {
        if (!notificationList.includes(element)) {
          setNotificationList((ele) => ele.concat(element));
        }
      });
      setlastInResponse(allNotifications?.lastInResponse);
      setIsNotificationListEmpty(
        allNotifications?.isCollectionEmpty === 0 ? true : false
      );
      setLoadingPagination(false);
    } else if (
      allNotifications?.docs.length <= 0 &&
      allNotifications?.isCollectionEmpty == 0
    ) {
      setIsNotificationListEmpty(true);
      setNotificationList(null);
      closeLoading();
    }
  }, [allNotifications, notificationList, loading]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS REMOVE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isRemove?.state) {
      setNotificationList((items) => {
        const position = notificationList.findIndex(
          (dc) => dc.uid === isRemove?.uidRemove
        );
        notificationList.splice(position, 1);
        return items;
      });

      dispatch(setRemoveNotificationByIdReset());
    }
  }, [isRemove?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC ONCLICK STATE CHANGE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleViewNotificationState = ({ uid, state }) => {
    if (!state) {
      dispatch(
        changeNotificationStateById({
          userToId: userIdActive,
          notificationId: uid,
          state: true,
        })
      );
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC ONCLICK REMOVE NOTIFICATION
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleRemoveNotification = ({ uid }) => {
    successLoading("Clearing notifications");
    dispatch(
      removeNotificationById({
        userToId: userIdActive,
        notificationId: uid,
      })
    );
  };

  const handleRemoveAllNotifications = () => {
    successLoading("Clearing notifications");
    dispatch(
      removeAllNotifications({
        userToId: userIdActive,
      })
    );
  };

  let navigate = (value) => {
    if (
      (value?.params?.postToId && value?.type == "like") ||
      value?.type == "comments"
    ) {
      return `/POSTS/${value?.params?.postToId}`;
    } else if (
      (value.userName && value?.userFromId && value?.type == "friendRequest") ||
      value?.type == "friendAcceptRequest"
    ) {
      const name = value?.userName.replaceAll(" ", "_");
      return `/PROFILE/${name}/${value?.userFromId}`;
    } else if (
      (value?.params?.postToId &&
        value?.params?.groupToId &&
        value?.type == "likeGroup") ||
      value?.type == "commentsGroup"
    ) {
      return `/POSTS/GROUP/${value?.params?.groupToId}/${value?.params?.postToId}`;
    } else if (value?.params?.groupToId && value?.type == "joinGroup") {
      return `/ViewGroup/${value?.params?.groupToId}`;
    } else if (value?.params?.projectToId && value?.type == "joinProject") {
      return `/ViewProject/${value?.params?.projectToId}/requests`;
    } else if (value?.params?.courseToId && value?.type == "joinCourse") {
      return `/ViewCourse/${value?.params?.courseToId}/requests`;
    } else if (value?.params?.groupToId && value?.type == "invitationGroup") {
      return `/ViewGroup/${value?.params?.groupToId}`;
    } else if (
      value?.params?.projectToId &&
      value?.type == "invitationProject"
    ) {
      return `/ViewProject/${value?.params?.projectToId}`;
    } else if (value?.params?.courseToId && value?.type == "invitationCourse") {
      return `/ViewCourse/${value?.params?.courseToId}`;
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    results: notificationList,
    loading,
    loadingPagination,
    lastNotificationsElementRef,
    handleViewNotificationState,
    handleRemoveNotification,
    handleRemoveAllNotifications,
    navigate,
  };
};
