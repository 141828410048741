import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/messaging";
import'firebase/firestore';
;


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const fbStorage = firebase.storage();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const messaging = firebase.messaging();

export const onMessageReceived = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export { firebase, messaging, fbStorage, googleAuthProvider };
