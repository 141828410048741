import React, { useState } from "react";
import uploadFilesProject from "helpers/uploadFileProject";

import { useDispatch, useSelector } from "react-redux";
import { Loading, updateProjectEnd } from "actions/project";
import { useDropzone } from "react-dropzone";
import uploadFile from "helpers/uploadFile";

export const useCreateFileProject = () => {
  const dispatch = useDispatch();
  const { tempProjectCreate, loading } = useSelector((state) => state.project);
  const [progress, setProgress] = useState(0);
  const [progressCoverPage, setProgressCoverPage] = useState(0);
  const [error, setError] = useState("");

  const [ImageSelectedPrevious, setImageSelectedPrevious] = useState(null);
  const [ImageSelectedFile, setImageSelectedFile] = useState(null);
  const [filesMultipl, setfilesMultipl] = useState([]);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone();
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.name} - {file.type}
    </li>
  ));

  const changeImage = (e) => {
    if (e.target.files[0] !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      setImageSelectedFile(e.target.files[0]);
      reader.onload = (e) => {
        e.preventDefault();
        setImageSelectedPrevious(e.target.result);
      };
    }
  };

  const handleImg = async (e) => {
    e.preventDefault();
    dispatch(Loading());
    let urlFiles = [];
    const urlPageCover = await uploadFile(
      ImageSelectedFile,
      `/project/${tempProjectCreate?.uid}/CoverPage.jpg`,
      setProgressCoverPage,
      setError
    );

    if(acceptedFiles.length > 0){
      urlFiles = await uploadFilesProject(
       acceptedFiles,
       `/project/${tempProjectCreate?.uid}`,
       setProgress,
       setError
     );

    }

    const data = {
      uid: tempProjectCreate?.uid,
      pageCover: urlPageCover,
      files: urlFiles,
    };

    dispatch(updateProjectEnd(data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    handleImg,
    changeImage,
    files,
    ImageSelectedPrevious,
    loading,

    progress,
    getRootProps,
    getInputProps,
    isDragActive,
    progressCoverPage,
    acceptedFiles,
  };
};
