import React from "react";
import { Carousel } from "react-bootstrap";
import { imageExistsSliderInformation } from "helpers/verifyImg";
import { SpinnerCustom } from "components/shared/SpinnerCustom";

const SliderInfo = ({ docInfo, docInfoLoading }) => {
  return (
    <div className="vh-100">
      {docInfoLoading ? (
        <div className="vh-100" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SpinnerCustom/>
        </div>
      ) : (
        <div >
          {docInfo?.sliderImages ? (
            <Carousel fade>
              {docInfo?.sliderImages?.map((doc, index) => (
                <Carousel.Item className="vh-100" key={index}>
                  <div className="vh-100 animate__fadeOut">
                    <img
                      className="d-block w-100 h-100"
                      src={doc?.image}
                      alt="First slide"
                      style={{objectFit: "cover"}}
                      onError={imageExistsSliderInformation}
                    />
                    <Carousel.Caption>
                      <h3 className="text-light">{doc?.caption}</h3>
                      <p>{doc?.seption}</p>
                    </Carousel.Caption>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <img
              className="d-block w-100 h-100"
              src="/assets/images/principal.png"
              alt="First slide"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SliderInfo;
