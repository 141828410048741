import React from "react";

export const ModaPostlFilePrevious = ({
  ImageSelectedPrevious,
  fileType,
  removeFile,
}) => {
  return (
    <div
      className="content-img align-items-center text-center"
      style={{ marginTop: "10px", marginBottom: "20px" }}
    >
      {ImageSelectedPrevious != null && !fileType.includes("mp4") && (
        <div className="mb-4">
          <div
            className="position-relative cursor-pointer"
            style={{ textAlign: "right", marginRight: "10px" }}
            onClick={removeFile}
          >
            <i className="cursor-pointer font-sm ti-close  text-grey-500"></i>
          </div>
          <img
            src={ImageSelectedPrevious}
            style={{ width: "100%", height: "60%" }}
          />
        </div>
      )}

      {ImageSelectedPrevious != null && fileType.includes("mp4") && (
        <div className="mb-4">
          <div
            className="position-relative cursor-pointer"
            style={{ textAlign: "right", marginRight: "10px" }}
            onClick={removeFile}
          >
            <i className="cursor-pointer font-sm ti-close  text-grey-500"></i>
          </div>
          <video autoPlay loop className="float-right w-100">
            <source
              src={ImageSelectedPrevious}
              style={{ width: "100%", height: "60%" }}
            />
          </video>
        </div>
      )}
    </div>
  );
};
