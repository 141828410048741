import React from "react";
import Header from "../../headers/Header";
import {
  ViewOrganizationLoadingHeader,
  ViewOrganizationLoadingBodyLeft,
  ViewOrganizationLoadingBodyRight,
  ViewOrganizationLoadingCircleHeader,
  ViewOrganizationLoadingCircleBackHeader
} from "./ViewOrganizationLoadingComponents";

export const ViewOrganizationLoadingBrowser = () => {
  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <ViewOrganizationLoadingHeader />
                    <div style={{ position: 'absolute', zIndex: '102', top: 10 }}>
                      <ViewOrganizationLoadingCircleHeader/>
                    </div>
                    <div style={{ position: 'absolute', zIndex: '100', top: 10 }}>
                      <ViewOrganizationLoadingCircleBackHeader/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <ViewOrganizationLoadingBodyLeft />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                  <div className="card-body d-block p-4">   
                   <ViewOrganizationLoadingBodyRight/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
