import React from "react";
import Header from "components/headers/Header";

import { ViewGroupOptions } from "./all/components/ViewGroupOptions";
import { GoBackNavigate } from "components/shared/GoBack";
import { useViewGroup } from "Hooks/group/useViewGroup";
import { ShareGroup } from "./ShareGroup";
import { useBtnOptionMenu } from "Hooks/useBtnOptionMenu";
import { imageExistsProduct } from "helpers/verifyImg";
import { ViewGroupMenuItem } from "./all/components/ViewGroupMenuItem";
import { RouterViewGroup } from "./routes/RouterViewGroup";
import { useMediaQuery } from "react-responsive";
import { ViewGroupLoadingBrowser } from "./loading/ViewGroupLoadingBrowser";
import { ViewGroupLoadingMobile } from "./loading/ViewGroupLoadingMobile";

export const ViewGroup = () => {
  const {
    loading,
    tempGroup,
    userId,
    newDataPost,
    userMember,
    lengthGroup,
    navigate,
    loadingMembers,
    id,
    page403,
  } = useViewGroup();
  const { openModal, notiClass, wrapperRef, closeModal } = useBtnOptionMenu();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });

  if (page403) {
    navigate.push("/notfound");
  }

  return (
    <>
      {loading ? (
        <>
          {isDesktopOrLaptop ? (
            <ViewGroupLoadingBrowser />
          ) : (
            <ViewGroupLoadingMobile />
          )}
        </>
      ) : (
        <>
          <Header />
          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12 mb-3">
                    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                      <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                        <GoBackNavigate onClick={() => navigate.goBack()} />
                        <img
                          src={tempGroup?.pageCover}
                          style={{ objectFit: "cover" }}
                          width="1200"
                          height="250"
                          alt="avater"
                          onError={imageExistsProduct}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-7">
                          <h4
                            className="fw-500  mt-2 mb-lg-1 mb-1 pt-2 pl-4 "
                            style={{
                              fontSize:
                                "calc([15] + ([20] - [15]) * ((100vw - [300]) / ([1250] - [300])))",
                              textTransform: "capitalize",
                            }}
                          >
                            {tempGroup?.title}
                          </h4>
                          <span className="pl-4">
                            {lengthGroup + "  Members"}
                          </span>
                        </div>
                        <div className="col-md-5">
                          <ViewGroupOptions
                            userId={userId}
                            group={tempGroup}
                            existMember={userMember}
                            openModal={openModal}
                          />
                        </div>
                        <ShareGroup
                          notiClass={notiClass}
                          openModal={openModal}
                          closeOpen={closeModal}
                        />
                      </div>
                      <ViewGroupMenuItem uid={tempGroup?.uid} />
                    </div>
                  </div>
                </div>
                <RouterViewGroup
                  id={id}
                  userMember={userMember}
                  newDataPost={newDataPost}
                  userId={userId}
                  tempGroup={tempGroup ?? {}}
                  loadingMembers={loadingMembers}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
