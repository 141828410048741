import React, { Component } from "react";

const LoadCrcle = () => {
  return (
    <div
      className="card text-center shadow-xss border-0 p-4 mb-3 mt-3"
      style={{ width: "100px", height: "100px", borderRadius: "50px" }}
    >
      <div className="snippet m-auto" data-title=".dot-typing">
        <div className="stage">
          <div className="ml-2  mt-2 dot-typing"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadCrcle;
