import React from "react";
import CreatePost from "components/post/CreatePost";
import PostViewListBy from "components/post/profile/PostViewListBy ";
import { ViewUserExperience } from "./ViewUserExperience";
import { ViewSkills } from "./ViewSkills";
import { ProfileDetailPerson } from "./ProfileDetailPerson";
import { CardLoadingFiles } from "components/Loadings/CardLoadingFiles";
import { useBtnAddFriends } from "Hooks/friends/useBtnAddFriends";
import { useVerify } from "Hooks/useVerify";

export const UserPageInformationItem = () => {
  const {isFriend} = useBtnAddFriends();
 const profilePerson = useVerify();
  return (
    <div className="row">
      <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
        <ProfileDetailPerson />
        <ViewSkills  />
        <ViewUserExperience  />
      </div>
      <div className="col-xl-8 col-xxl-9 col-lg-8">
          {
            (isFriend === "accepted" || profilePerson ) &&
        <CreatePost friendprofile={true} />
          }
        <CardLoadingFiles/>
        <PostViewListBy />
      </div>
    </div>
  );
};
