import React from "react";
import PropTypes from "prop-types";

export const SearchAll2 = ({ placeHolder, onChange, value, onKeyDown }) => {
  return (
    <div className="pt-0 pb-0 mb-5">
      <div className="search-form-2 pl-2 pr-2">
        <i className="ti-search font-xss"></i>
        <input
          type="text"
          className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
          placeholder={placeHolder}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
};

SearchAll2.propTypes = {
  value: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};
