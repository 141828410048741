
import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForum } from 'Hooks/forum/useForum';

export const ModalCreatePostForum = ({type}) => {
    
    const {changeTextArea, handleClose, handleShow, hanledCreateComment, photoUrl, show} = useForum(type)
    

    return (
        <>
        <div className="card-body p-0 mt-3 position-relative" onClick={handleShow} >
        <figure className="avatar position-absolute ms-2 mt-1 top-5"><img src={photoUrl} alt="icon" className="shadow-sm rounded-circle w30" /></figure>
        <textarea name="message" className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder="What's on your mind?"></textarea>
        </div>

        
      <Modal show={show} onHide={handleClose}>
        <form  onSubmit={hanledCreateComment}>

          <Modal.Header>
            <Modal.Title>Create new comment</Modal.Title>
            <button
              className="btn ti-close"
              type="button"
              onClick={handleClose}
              closeButton
            ></button>
          </Modal.Header>
          <Modal.Body>
          <div className="card-body p-0 mt-1 position-relative">
             <figure className="avatar position-absolute ms-2 mt-1 top-5"><img src={ photoUrl != null ? photoUrl : "assets/images/user.png"} alt="icon" className="shadow-sm rounded-circle w30" /></figure>
              <textarea name="message" className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder="What's on your mind?"  onChange={changeTextArea}></textarea>
          </div> 
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="outline-info">
              Create Comment
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
        </>
    )
}
