import React from "react";
import CommentsHeaders from "./CommentsHeaders";
import Load from "components/Load";

import { useCommentsPost } from "Hooks/post/useCommentsPost";
import { useHistory } from "react-router-dom";
import { PostImageAvailableBrowser } from "./components/PostImageAvailableBrowser";
import { PostImageNotAvailable } from "./components/PostImageNotAvailable";
import { useMediaQuery } from "react-responsive";
import { PostImageAvailableMobile } from "./components/PostImageAvailableMobile";
import { typeEventLike, typeEventPost } from "helpers/typeEvent";
import "../../../css/PostViewImgStyle.css";

export const CommentsPost = () => {
  const navigate = useHistory();
  const { ImageSelectedPrevious, post, loading, page403 } = useCommentsPost(
    typeEventPost.global
  );

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });


  const onBack = () => {
    return navigate.goBack();
  };

  if (page403) {
    navigate.push("/notfound");
  }

  if (loading) {
    return (
      <>
        <CommentsHeaders onBack={onBack} />
        <div
          className="col-sm-12 col-lg-6 overflow-auto m-auto"
          style={{ paddingTop: 100, height: "100vh" }}
        >
          <Load />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row main-container ">
        <div className="col-12">
          <CommentsHeaders onBack={onBack} />
        </div>
        <div className="col-12">
          {ImageSelectedPrevious != "" && post?.attachments != "" ? (
            <>
              {isDesktopOrLaptop ? (
                <PostImageAvailableBrowser
                  post={post}
                  imageSelectedPrevious={ImageSelectedPrevious}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.temp}
                />
              ) : (
                <PostImageAvailableMobile
                  post={post}
                  imageSelectedPrevious={ImageSelectedPrevious}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.temp}
                />
              )}
            </>
          ) : (
            <PostImageNotAvailable
              post={post}
              type={typeEventPost.global}
              typeLike={typeEventLike.temp}
            />
          )}
        </div>
      </div>
    </>
  );
};
