import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export const CardListCourseStyle = ({ pageCover, title, uid, isProprietor  = true}) => {
    return (
        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
            <div className="card-body position-relative h100 w-100 bg-image-cover bg-image-center pl-0 ml-0" style={{backgroundImage: `url(${pageCover ? pageCover : '/assets/images/1200x450.png'})` }} ></div>
            <div className="card-body d-block w-100 pe-4 pb-4 pt-0 text-left position-relative">
                <div className="clearfix"></div>
                <h4 className="fw-700 font-xsss mt-3 mb-3 pt-2" >{title}</h4>
                <span className="position-absolute right-15 top-0 d-flex align-items-center">
                {
                    isProprietor && (
                        <Link to={`/EditCourse/${uid}`}>
                            <div className="text-center p-2 lh-24 w80 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white" style={{ cursor: 'pointer' }}>EDIT</div>
                        </Link>
                    )
                }
                <Link to={`/ViewCourse/${uid}`}>
                    <div className="text-center p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white" style={{ cursor: 'pointer' }}>OPEN</div>
                </Link>
                </span>
            </div>
        </div>
        )
}
