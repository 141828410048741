import React, { Component  } from "react";
import Chart from "react-apexcharts";

import Header from "../components/headers/Header";
import Appfooter from '../components/Appfooter';
import { useAdmin } from "Hooks/admin/useAdmin";
import Load from "components/Load";


 const Analytics = () => {
 
     const {data, loading} = useAdmin();
    
    
    //    const  state = {
    //         labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May' ,'Jun', 'Jul', 'Aug', 'Sep', 'Oct' , 'Nov', 'Dec'],
    //         series: [{
    //         name: 'Cursos',
    //         data: [35, 66, 34, 56, 18 ,35, 66, 34, 56, 18 , 56, 18]
    //         },{
    //         name: 'Proyectos',
    //         data: [12, 34, 12, 11, 7 ,12, 34, 12, 11, 7 , 11, 7]
    //         }],
    //         options: {
    //             chart: {
    //             type: 'bar',
    //         //   width:'100%',
    //             height: 250,
    //             stacked: true,
    //             toolbar: {
    //             show: false
    //             },    
    //         },
    //         responsive: [{
    //             breakpoint: 480,
    //             options: {
    //             legend: {
    //                 position: 'bottom',
    //                 offsetX: -10,
    //                 offsetY: 0
    //             }
    //             }
    //         }],
    //         plotOptions: {
    //             bar: {
    //                 horizontal: false,
    //             },
    //         },
    //         legend: {
    //             show: false
    //         },
    //         fill: {
    //             opacity: 1
    //         },
    //         },
            
    //     };
    

        return (
            <> 
                <Header />

                <div className="main-content bg-white right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <img src="/assets/images/LOGO-DEAL.png" alt="banner" className="w-100 b-1" />
                                            </div>
                                            <div className="col-lg-6 ps-lg-5 m-auto">
                                                <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">Deal social network dashboard</h2>
                                                <p className="font-xssss fw-500 text-grey-500 lh-26">Graphic representation of the general data of the social network deal for informative use.</p>
                                                {/* <a href="/defaultanalytics" className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Analysis</a> */}
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                                  
                                {
                                  loading ? ( 
                                    <div className="col-lg-12">
                                  <Load></Load>
                                  </div>
                                ): 
                                (
                                    <>
                                    <div className="col-lg-3 pe-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e5f6ff`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-primary-gradiant feather-user font-md text-white"></i>
                                            <h4 className="text-primary font-xl fw-700">{data?.users} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Total Users</span></h4>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-3 pe-2 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#f6f3ff`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-book-open font-md text-white"></i>
                                            <h4 className="text-secondary font-xl fw-700">{data?.courses} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Total Courses</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 pe-2 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e2f6e9`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-success feather-briefcase font-md text-white"></i>
                                            <h4 className="text-success font-xl fw-700">{data?.projects} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Total Projects</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#fff0e9`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-warning feather-users font-md text-white"></i>
                                            <h4 className="text-warning font-xl fw-700">{data?.groups} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Total Groups</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#FDEDEC`}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-danger feather-activity font-md text-white"></i>
                                            <h4 className="text-danger font-xl fw-700">{data?.post} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Total Post</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#f6f3ff `}}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-dark feather-clipboard font-md text-white"></i>
                                            <h4 className="text-dark font-xl fw-700">{data?.organizations} <span className="fw-500 mt-0 d-block text-grey-500 font-xsssss">Total Organization</span></h4>
                                        </div>
                                    </div>
                                </div>
                                    </>
                                    )}

                                {/* <div className="col-lg-12 mb-3">
                                <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                    <Chart
                                    options={state.options}
                                    series={state.series}
                                    type="bar"
                                    width="100%"
                                    />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Popupchat /> */}
                <Appfooter /> 
            </>
        );
    }


export default Analytics;