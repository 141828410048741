import React from "react";

import { CardRouteProfileOptions } from "components/shared/CardRouteProfileOptions";
import { EditOrganizationForm } from "./EditOrganizationForm";

export const EditOrganization = () => {
  return (
    <CardRouteProfileOptions
      title="Edit Organization"
      component={EditOrganizationForm}
    />
  );
};
