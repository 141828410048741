import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ProfileInfo } from "./ProfileContact";
import { CardLoadingFiles } from 'components/Loadings/CardLoadingFiles';

export const NavBarRigthHeader = ({ uid, name, navClass }) => {
  return (
    <nav className={`navigation scroll-bar ${navClass} z-80`}>
    <div className="container ">
      <div className="nav-content">
        <ProfileInfo />
        {/* <CardLoadingFiles/> */}
        <div className=" nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
          <div className="nav-caption fw-600 font-xssss text-grey-500">
            <span>More </span>Pages
          </div>
          <ul className="mb-3">
            {/* <li><Link to="/defaultemailbox" className="nav-content-bttn open-font"><i className="font-xl text-current feather-inbox me-3"></i><span>Email Box</span><span className="circle-count bg-warning mt-1">584</span></Link></li> */}
            <li>
              <Link
                to={`/PROFILE/${name}/${uid}/groups/my-groups`}
                className="nav-content-bttn open-font" >
                <i className="feather-users btn-round-md bg-red-gradiant me-3"></i>
                <span>My Groups </span>
              </Link>
            </li>
            <li>
              <Link
                to={`/PROFILE/${name}/${uid}/projects/my-projects`}
                className="nav-content-bttn open-font">
                <i className="feather-briefcase btn-round-md bg-gold-gradiant me-3"></i>
                <span>My Projects </span>
              </Link>
            </li>
            <li>
              <Link
                to={`/PROFILE/${name}/${uid}/courses/my-courses`}
                className="nav-content-bttn open-font">
                <i className="feather-book-open btn-round-md bg-mini-gradiant me-3"></i>
                <span>My Courses </span>
              </Link>
            </li>
          </ul>
        </div>

        <div className=" nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
          <div className="nav-caption fw-600 font-xssss text-grey-500">
            <span>More </span>Pages
          </div>

        <ul className="  pt-3 pb-1 mb-5 top-content">
             <li><Link to="/users" className="nav-content-bttn open-font"><i className="feather-user btn-round-md bg-blue-gradiant me-3"></i><span>Users</span></Link></li>
             <li><Link to="/groups" className="nav-content-bttn open-font"><i className="feather-users btn-round-md bg-red-gradiant me-3"></i><span>Groups</span></Link></li>
             <li><Link to="/projects" className="nav-content-bttn open-font"><i className="feather-briefcase btn-round-md bg-gold-gradiant me-3"></i><span>Projects</span></Link></li>
             <li><Link to="/courses" className="nav-content-bttn open-font"><i className="feather-book-open btn-round-md bg-mini-gradiant me-3"></i><span>Courses</span></Link></li>
         </ul>
         </div>

    
      </div>
    </div>
  </nav>
  );
};
