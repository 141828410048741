import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removePost, removePostGroup } from "actions/post";
import removeFileStorage from "helpers/removeFile";

export const useRemovePost = (data, groupId) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { uid: userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const remove = "Eliminar";
  const [formsValue, setforms] = useState({ textRemove: "" });


  const handleimag = async (e) => {
    e.preventDefault();

    if (formsValue.textRemove === "Eliminar" && data?.userId === userId) {
      if(data?.postImage != "" && data?.typePost == undefined){
        (await removeFileStorage(data?.postImage));
      } 
      if (groupId) {
        const group = {
          uid: data?.id,
          groupId,
        };
        dispatch(removePostGroup(group));
      } else {
        dispatch(removePost({ uid: data?.id }));
      }

      handleClose();
    }
  };

  const handlerform = (event) => {
    setforms({
      textRemove: event.target.value,
    });
  };

  return {
    handleShow,
    show,
    handleClose,
    handleimag,
    handlerform,
    formsValue,
    remove,
  };
};
