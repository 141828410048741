import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from 'prop-types'

export const ProfileNavItem = ({ name1='', name2='', route1='', route2='' }) => {
  return (
    <div className="card-body d-block w-50 shadow-none mb-0 p-0  mb-3">
      <ul
        className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 "
        id="pills-tab"
        role="tablist">
        <li className="list-inline-item me-5">
          <NavLink
            exact
            to={route1}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab">
            {name1}
          </NavLink>
        </li>
        <li className="list-inline-item me-5">
          <NavLink
            to={route2}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab"> 
            {name2}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

ProfileNavItem.propTypes = {
  name1: PropTypes.string.isRequired,
  route2: PropTypes.string.isRequired,
  name2: PropTypes.string.isRequired,
  route2: PropTypes.string.isRequired
}