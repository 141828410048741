import React from "react";
import { useLogin } from "../Hooks/useLogin";
import SliderInfo from "components/sliderInformation/SliderInfo";
import {PresentationVideoInfomation} from "components/sliderInformation/PresentationVideoInfomation";
import { SpinnerCustom } from "components/shared/SpinnerCustom";
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom.min";



export const Login = ({docInfo, docInfoLoading}) =>  {
        const {  handleLogin, handleGoogleLogin, msgError, handlerform } = useLogin();
        return (
            <> 
               <PresentationVideoInfomation urlVideo={docInfo?.presentationVideo} loading={docInfoLoading}/>
                <div className="main-wrap">
                    <div className="nav-header bg-transparent shadow-none border-0">
                        <div className="nav-top w-100">
                            {/* <a href="/"><i className="feather-zap text-success display1-size me-2 ms-0"></i><span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">Sociala. </span> </a> */}
                            <button className="nav-menu me-0 ms-auto"></button>

                        

                            <Link to="/auth/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</Link>
                            <Link to="/auth/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <SliderInfo docInfo={docInfo} docInfoLoading={docInfoLoading}/>
                        </div>
                            <div className="col-xl-6 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                                <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                    <div className="card-body rounded-0 text-left">
                                          {
                                            <>
                                              {
                                                   docInfoLoading ?
                                                    <div className="d-flex">
                                                        <div className="ml-auto mr-auto mb-2">
                                                            <SpinnerCustom width={20} height={20}/>
                                                        </div>
                                                    </div>
                                                   :
                                                   <h2 className="fw-400 justify-content-center mb-3"> 
                                                       {  
                                                           docInfo ? docInfo?.titleInformation 
                                                           : "Login into <br />your account"
                                                       }
                                                   </h2>
                                              }
                                            </>
                                          }
                                        <form onSubmit={handleLogin}>

                                           { 
                                            msgError && 
                                           <div className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0 ">
                                                {msgError}
                                            </div>}
                                            
                                            <div className="form-group icon-input mb-3">
                                                <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                                <input type="text" name="correo" onChange={handlerform} className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Email Address" />                        
                                            </div>
                                            <div className="form-group icon-input mb-1">
                                                <input type="Password" name="pass" onChange={handlerform} className="style2-input ps-5 form-control text-grey-900 font-xss ls-3" placeholder="Password" />
                                                <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                            </div>
                                            <div className="form-check text-left mb-3">
                                                <input type="checkbox" className="form-check-input mt-2" id="exampleCheck5" />
                                                <label className="form-check-label font-xsss text-grey-500">Remember me</label>
                                                <a href="/auth/forgot" className="fw-600 font-xsss text-grey-700 mt-1 float-right">Forgot your Password?</a>
                                            </div>

                                        <div className="col-sm-12 p-0 text-left">
                                            <button type="submit" className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">Login</button>
                                            <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Dont have account <Link to="/auth/register" className="fw-700 ms-1">Register</Link></h6>
                                        </div>
                                        </form>
                                        
                                        <div className="col-sm-12 p-0 text-center mt-2">
                                            
                                            <h6 className="mb-0 d-inline-block bg-white fw-500 font-xsss text-grey-500 mb-3">Or, Sign in with your social account </h6>
                                            <div onClick={handleGoogleLogin} className="form-group mb-1 cursor-pointer"><div  className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2"><img src="../assets/images/icon-1.png" alt="icon" className="ms-2 w40 mb-1 me-5"></img> Sign in with Google</div></div>
                                           
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        
                    </div>
                </div>
            </>
        );
    }


export default Login;