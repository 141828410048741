const connectNetwork = async () => {
  return new Promise((resolve, reject) => {
    var condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      fetch("https://www.google.com/", {
        mode: "no-cors",
      })
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    } else {
      resolve(false);
    }
  });
};

export default connectNetwork;
