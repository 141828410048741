import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getCourseById, getCourseMembersById } from "actions/course";
import { isChangeCommentsForums } from "actions/project";

export const useViewCourse = () => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const { Id: courseIdVisited } = useParams();
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> SELECTORS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { loading, tempCourse, exitsMember, loadingMembers } = useSelector(
    (state) => state.course
  );
  const { uid: userIdActive } = useSelector((state) => state.auth);
  const { page403 } = useSelector((state) => state.page403);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> GET PETITION DB
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    const data = {
      uid: courseIdVisited,
    };
    if (tempCourse?.uid !== courseIdVisited) {
      dispatch(getCourseById(data));
      dispatch(isChangeCommentsForums());
      dispatch(
        getCourseMembersById({
          courseId: courseIdVisited,
          userId: userIdActive,
        })
      );
    }
  }, [courseIdVisited]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    loading,
    tempCourse,
    exitsMember,
    loadingMembers,
    navigate,
    userIdActive,
    courseIdVisited,
    page403,
  };
};
