import { CreateReplyCommentsForumCourse } from "actions/course";
import { CreateReplyCommentsForum } from "actions/project";
import { format } from "date-fns";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";


export const useReplyForum = (type, idcoments) => {
    
    const { id } = useParams();
    const {uid} = useSelector( state => state.auth );
    const dispatch = useDispatch();
    const [forms, setForms] = useState({
        content: "",
      });

    
    const changeTextArea = (e) => {
        e.preventDefault();
        setForms({ content: e.target.value });
      };



      const hanledCreateComment = async (e) => {
        e.preventDefault();
        const date = format(new Date(), "dd/MM/yyyy HH:mm:ss" )
        const { content } = forms;
        if(type == "project"){
          const data = {
            content,
            userId: uid,
            projectId: id,
            createDate: date,
            commentId: idcoments,
            createTime: new Date().valueOf(),
          };
         dispatch(CreateReplyCommentsForum(data));
         
        }else{
          const data = {
            content,
            userId: uid,
            courseId: id,
            createDate: date,
            commentId: idcoments,
            createTime: new Date().valueOf(),
          };
         dispatch(CreateReplyCommentsForumCourse(data));

        }
    }



  return {
    hanledCreateComment,
    changeTextArea,
  }
}
