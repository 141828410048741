import React from "react";
import Load from "components/Load";
import PostViewShare from "../components/PostViewShare";
import PostView from "../PostView";

import { typeEventLike, typeEventPost } from "helpers/typeEvent";
import { usePostViewListById } from "Hooks/post/usePostViewListById";
import { EmptyData } from "components/shared/EmptyData";

const PostViewListById = () => {
  const {
    loading,
    docs,
    lastPostsElementRef,
    loadingPagination,
  } = usePostViewListById();

  if (loading && docs.length <= 0) {
    return (
      <div style={{ marginBottom: "100px" }}>
        <Load />;
      </div>
    );
  }

  if (docs.length <= 0 && !loading) {
    return <EmptyData title="no publications" />;
  }

  return (
    <div style={{ marginBottom: "100px" }}>
      {docs?.map((element, index) => {
        if (docs.length === index + 1) {
          if (element?.type == undefined) {
            return (
              <div key={element?.uid} ref={lastPostsElementRef}>
                <PostView
                  id={element?.uid}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.profile}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                  friendId={element?.friend}
                  nameFriend={element?.friendProfile?.userName}
                />
              </div>
            );
          } else {
            return (
              <div key={element?.uid} ref={lastPostsElementRef}>
                <PostViewShare
                  id={element?.uid}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.profile}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                />
              </div>
            );
          }
        } else {
          if (element?.type == undefined) {
            return (
              <div key={element?.uid}>
                <PostView
                  id={element?.uid}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.profile}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                  friendId={element?.friend}
                  nameFriend={element?.friendProfile?.userName}

                />
              </div>
            );
          } else {
            return (
              <div key={element?.uid}>
                <PostViewShare
                  id={element?.uid}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.profile}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                />
              </div>
            );
          }
        }
      })}
      {/* {loadingPagination && <Load />} */}
    </div>
  );
};

export default PostViewListById;
