import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import algoliasearch from "algoliasearch/lite";
import {
  getIsChangeMyProject,
  getMyProjectsParticipate,
  isRemoveProjectReset,
} from "actions/project";
import {
  ALGOLIA_INDEX_PROJECTS_GLOBAL,
  APPLICATION_ID,
  LIMIT,
  SEARCH_API_KEY,
} from "helpers/env";
import { useSearchAlgolia } from "../useSearchAlgolia";
import removeFileStorage from "helpers/removeFile";

const client = algoliasearch(APPLICATION_ID, SEARCH_API_KEY);
const index = client.initIndex(ALGOLIA_INDEX_PROJECTS_GLOBAL);

export const useMyProjectsParticipate = () => {
  const dispatch = useDispatch();
  const observer = useRef();

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> DATA
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { uid: userIdVisited } = useSelector((state) => state.navigateAuth);
  const { uid: userIdActive } = useSelector((state) => state.auth);
  const {
    projectParticipateLoad,
    isRemove,
    loading,
  } = useSelector((state) => state.project);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> STATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [myProjectList, setMyProjectList] = useState([]);
  const [docs, setDocs] = useState([]);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isMyProjectListEmpty, setIsMyProjectListEmpty] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [isProprietor, setIsProprietor] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { performSearch, results: hit } = useSearchAlgolia(
    index,
    ALGOLIA_INDEX_PROJECTS_GLOBAL
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC IS PROPRIETOR
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (userIdVisited == userIdActive) {
      setIsProprietor(true);
    } else {
      setIsProprietor(false);
    }
  }, [userIdVisited, userIdActive]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      userId: userIdVisited,
      limit: LIMIT,
      action: action,
      lastInResp: lastInResp,
    };
    dispatch(getMyProjectsParticipate(userIdVisited, data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastMyProjectElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isMyProjectListEmpty) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isMyProjectListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> CHANGE USER PROFILE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (projectParticipateLoad?.userData && userIdVisited != projectParticipateLoad?.userData) {
      dispatch(getIsChangeMyProject());
      setIsProprietor(userIdVisited == userIdActive ? true : false);
    }
  }, [userIdVisited]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (
      (projectParticipateLoad?.docs.length <= 0 && projectParticipateLoad?.isCollectionEmpty == -1) ||
      (projectParticipateLoad?.docs.length <= 0 && projectParticipateLoad?.isCollectionEmpty == 0)
    ) {
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
      // setLoading(true);
    }
  }, [projectParticipateLoad?.docs]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER MY PROJECT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (projectParticipateLoad?.docs.length > 0) {
      projectParticipateLoad.docs.map((element) => {
        if (!myProjectList.includes(element)) {
          setMyProjectList((ele) => ele.concat(element));
        }
      });
      setlastInResponse(projectParticipateLoad?.lastInResponse);
      setIsMyProjectListEmpty(
        projectParticipateLoad?.isCollectionEmpty === 0 ? true : false
      );
      // setLoading(false);
      setLoadingPagination(false);
    } else if (
      projectParticipateLoad?.docs.length <= 0 &&
      projectParticipateLoad?.isCollectionEmpty == 0
    ) {
      setIsMyProjectListEmpty(true);
      setMyProjectList([]);
      // setLoading(false);
      setLoadingPagination(false);
    } else if (
      projectParticipateLoad?.docs.length <= 0 &&
      projectParticipateLoad?.isCollectionEmpty == -1
    ) {
      setMyProjectList([]);
      // setLoading(false);
      setLoadingPagination(false);
    }
  }, [projectParticipateLoad, userIdVisited, isMyProjectListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS REMOVE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isRemove?.state) {
      setMyProjectList((items) => {
        const position = myProjectList.findIndex(
          (dc) => dc.uid === isRemove?.uidRemove
        );
        myProjectList.splice(position, 1);
        return items;
      });
      removeFiles();
    }

    dispatch(isRemoveProjectReset());
  }, [isRemove?.state]);

  const removeFiles = async () => {
    if (isRemove?.urlPageCover) {
      await removeFileStorage(isRemove?.urlPageCover);
    }
    for await (let url of isRemove?.urlFiles) {
      await removeFileStorage(url);
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER GROUPS Y SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (hit && hit.length > 0) {
      setDocs(hit);
    } else {
      setDocs(myProjectList);
    }
  }, [hit, docs, myProjectList]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC ONCHANGE SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleChange = (e) => {
    var { value } = e.target;
    if (value == "") {
      setDocs([]);
      setDocs(myProjectList);
    } else {
      value.length >= 3 && performSearch(value);
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    loading,
    loadingPagination,
    docs,
    lastMyProjectElementRef,
    handleChange,
    isProprietor,
  };
};
