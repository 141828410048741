import { types } from "../types/types";
import Swal from "sweetalert2";
import { configApi, urlApi } from "../helpers/env";
import { setPage403 } from "./page403";
import connectNetwork from "../helpers/connectNetwork";

export const createPost = (data) => {
  return async (dispatch) => {
    
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/createPost`, config);
      let response = await resp.json();

      if (response.ok) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 300,
          timerProgressBar: true

        })
        
        Toast.fire({
          icon: 'success',
          title: 'Successfully created'
        })
        dispatch(getPostCreate(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const createPostGroup = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/createPostGroup`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch(getPostCreateGroup(response.doc));
        Swal.close();
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updatePost = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updatePost`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getPostUpdate(response.doc));
        Swal.close();
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getPostById = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getPostById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getTempPost(response.doc));
      }

      if (resp.status == 403) {
        dispatch(setPage403());
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getPostGroupById = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getPostGroupById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getTempPost(response.doc));
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updatePostGroup = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updatePostGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getPostGroupUpdate(response.doc));
        Swal.close();
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const removePost = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/removePost`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getPostRemove(response.doc));
        Swal.close();
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const removePostGroup = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/removePostGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getPostGroupRemove(response.doc));
        Swal.close();
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getPostsPaginationsById = (userId, data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/loadPostsItemsById`, config);
      let response = await resp.json();
      if (response.ok) {
        const docs = {
          userData: userId,
          ...response.posts,
        };
        dispatch(getPostsById(docs));
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getPostsPaginations = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/loadPostsItems`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getPosts(response.posts));
        console.log(response.posts);
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getPostsPaginationsGroup = (groupId, data) => {
  return async (dispatch) => {
    dispatch(getInitialLoadingPostGroup(true));
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getPostsGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        const docs = {
          groupData: groupId,
          ...response.docs,
        };
        dispatch(getPostsGroup(docs));
        dispatch(getInitialLoadingPostGroup(false));
      }
    } catch (error) {
      Swal.close();
      dispatch(getInitialLoadingPostGroup(false));
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const onLikeCommentPost = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/onLikeCommentPost`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setConformLikePost(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const disLikeCommentPost = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/disLikeCommentPost`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setConformLikePost(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const onLikeCommentPostGroup = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/onLikeCommentPostGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setConformLikePostGroup(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const disLikeCommentPostGroup = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/disLikeCommentPostGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setConformLikePostGroup(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getInitialLoadingPost = (datos) => {
  return {
    type: types.loadingPost,
    payload: datos,
  };
};

export const getInitialLoadingPostGroup = (datos) => {
  return {
    type: types.loadingPostgroup,
    payload: datos,
  };
};

export const getPostCreate = (datos) => {
  return {
    type: types.createPost,
    payload: datos,
  };
};

export const getPostUpdate = (datos) => {
  return {
    type: types.updatePost,
    payload: datos,
  };
};

export const getPostRemove = (datos) => {
  return {
    type: types.removePost,
    payload: datos,
  };
};

export const getPostsById = (datos) => {
  return {
    type: types.getPostsById,
    payload: datos,
  };
};

export const getPosts = (datos) => {
  return {
    type: types.getPosts,
    payload: datos,
  };
};

export const getPostsLike = (datos) => {
  return {
    type: types.getLikePost,
    payload: datos,
  };
};

export const setConformLikePost = (datos) => {
  return {
    type: types.getConformLikePost,
    payload: datos,
  };
};

export const setLikePostGroup = (datos) => {
  return {
    type: types.getLikePostGroup,
    payload: datos,
  };
};

export const setConformLikePostGroup = (datos) => {
  return {
    type: types.getConformLikePostGroup,
    payload: datos,
  };
};

export const getTempPost = (datos) => {
  return {
    type: types.tempPost,
    payload: datos,
  };
};

export const isCreateReset = () => {
  return {
    type: types.isCreateReset,
  };
};

export const isUpdateReset = () => {
  return {
    type: types.isUpdateReset,
  };
};

export const isRemoveReset = () => {
  return {
    type: types.isRemoveReset,
  };
};

export const getIsChange = () => {
  return {
    type: types.isChange,
  };
};
export const getIsChangePostsGroup = () => {
  return {
    type: types.isChangePostsGroup,
  };
};

export const getIsChangeReset = () => {
  return {
    type: types.isChangeReset,
  };
};

//Group

export const getPostCreateGroup = (datos) => {
  return {
    type: types.createPostGroup,
    payload: datos,
  };
};

export const getPostGroupUpdate = (datos) => {
  return {
    type: types.updatePostGroup,
    payload: datos,
  };
};

export const getPostGroupRemove = (datos) => {
  return {
    type: types.removePostGroup,
    payload: datos,
  };
};

export const getPostsGroup = (datos) => {
  return {
    type: types.getPostsGroup,
    payload: datos,
  };
};

export const LoadingPostOn = () => {
  return {
    type: types.loadingMyPost,
  };
};
