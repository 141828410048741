import React from "react";
import ContentLoader from "react-content-loader";

const ViewOrganizationLoadingHeader = () => (
  <ContentLoader
    width="100%"
    height={300}
    // viewBox="0 0 100% 300"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
  >
    <rect x="4" y="0" rx="10" ry="10" width="100%" height="240" />
    <rect x="150" y="260" rx="2" ry="2" width="140" height="13" />
  </ContentLoader>
);

const ViewOrganizationLoadingCircleHeader = () => (
  <ContentLoader
    width="100%"
    height={390}
    // viewBox="0 0 100% 390"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
  >
    <circle cx="90" cy="250" r="40" />
  </ContentLoader>
);

const ViewOrganizationLoadingCircleBackHeader = () => (
  <ContentLoader
    width="100%"
    height={390}
    // viewBox="0 0 100% 390"
    backgroundColor="#ffffff"
    foregroundColor="#ffffff"
  >
    <circle cx="90" cy="250" r="45" />
  </ContentLoader>
);

const ViewOrganizationLoadingBodyLeft = () => (
  <ContentLoader viewBox="0 0 400 340">
    <rect x="5" y="14" rx="2" ry="2" width="350" height="10" />
    <rect x="5" y="34" rx="2" ry="2" width="250" height="10" />

    <rect x="100" y="91" rx="3" ry="3" width="185" height="14" />
    <circle cx="48" cy="98" r="30" />

    <rect x="100" y="171" rx="3" ry="3" width="185" height="14" />
    <circle cx="48" cy="178" r="30" />

    <rect x="100" y="261" rx="3" ry="3" width="185" height="14" />
    <circle cx="48" cy="268" r="30" />
  </ContentLoader>
);

const ViewOrganizationLoadingBodyRight = () => {
  return (
    <ContentLoader
      width={700}
      height={250}
      viewBox="0 0 700 250"
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      <rect x="70" y="32" rx="6" ry="6" width="483" height="15" />
      <circle cx="27" cy="40" r="15" />

      <rect x="72" y="72" rx="6" ry="6" width="420" height="15" />
      <circle cx="28" cy="80" r="15" />

      <rect x="72" y="118" rx="6" ry="6" width="483" height="15" />
      <circle cx="28" cy="126" r="15" />

      <rect x="73" y="158" rx="6" ry="6" width="444" height="15" />
      <circle cx="29" cy="167" r="15" />

      <rect x="73" y="198" rx="6" ry="6" width="483" height="15" />
      <circle cx="30" cy="206" r="15" />
    </ContentLoader>
  );
};

//  MOBILE


const ViewOrganizationLoadingBodyRightMobile = () => (
  <ContentLoader viewBox="0 0 400 300">
    <rect x="5" y="14" rx="2" ry="2" width="350" height="10" />
    <rect x="5" y="34" rx="2" ry="2" width="250" height="10" />

    <rect x="100" y="91" rx="3" ry="3" width="185" height="14" />
    <circle cx="48" cy="98" r="30" />

    <rect x="100" y="171" rx="3" ry="3" width="185" height="14" />
    <circle cx="48" cy="178" r="30" />

    <rect x="100" y="261" rx="3" ry="3" width="185" height="14" />
    <circle cx="48" cy="268" r="30" />
  </ContentLoader>
);

const ViewOrganizationLoadingBodyLeftMobile = () => {
  return (
    <ContentLoader
      width="100%"
      height={250}
      // viewBox="0 0 100% 250"
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      <rect x="70" y="32" rx="6" ry="6" width="67%" height="15" />
      <circle cx="27" cy="40" r="15" />

      <rect x="72" y="72" rx="6" ry="6" width="35%" height="15" />
      <circle cx="28" cy="80" r="15" />

      <rect x="72" y="118" rx="6" ry="6" width="67%" height="15" />
      <circle cx="28" cy="126" r="15" />

      <rect x="73" y="158" rx="6" ry="6" width="45%" height="15" />
      <circle cx="29" cy="167" r="15" />

      <rect x="73" y="198" rx="6" ry="6" width="67%" height="15" />
      <circle cx="30" cy="206" r="15" />
    </ContentLoader>
  );
};

export {
  ViewOrganizationLoadingHeader,
  ViewOrganizationLoadingBodyLeft,
  ViewOrganizationLoadingBodyRight,
  ViewOrganizationLoadingBodyLeftMobile,
  ViewOrganizationLoadingCircleHeader,
  ViewOrganizationLoadingCircleBackHeader,
  ViewOrganizationLoadingBodyRightMobile,
};
