import React from "react";
import { useSelector } from "react-redux";

export const ProfileDetailPerson = () => {
  const user = useSelector(( { user: state } ) => state.user);
  const { privacySettings: globalSettings } = useSelector((state) => state.infoGlobal);

  const dataInfoUser = {
    email: user.email,
    city: user.city ?? "",
    username: user.userName,
    address: user.address ?? "",
    phonenumber: user.phoneNumber,
    photoUrl: user.photoUrl?.url,
    country: user.country ?? "",
  };


  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">About</h4>
        {

            globalSettings?.settings?.map( ( gSetting ) => {
                const uSetting = user?.privatySettings?.find( uSetting => uSetting?.key.toLowerCase() == gSetting?.key.toLowerCase() );
                const type = uSetting?.key.toLowerCase();
                const name = dataInfoUser[type] != undefined ||  dataInfoUser[type] != ""  ? dataInfoUser[type]?.toLowerCase() : "";

               return gSetting?.enabled && uSetting?.enabled && name && 
               ( <div key= { gSetting?.title } className="card-body border-top-xs d-flex">
                    <i className={`${ gSetting?.icon } text-grey-500 me-3 font-lg`}></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                       { gSetting?.title }
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                        { name }
                        </span>
                    </h4>
                </div>
               )
            })
         
        }
      </div>
    </div>
  );
};

