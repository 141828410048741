import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Load from 'components/Load';

import { useMembers } from 'Hooks/members/useMembers';

const animatedComponents = makeAnimated();

export const Members = ({ values, onChange, isEdit }) => {
  
  const { users } = useMembers();

  return (
   ( values != null && values.length > 0 && isEdit ) 
    ?
      <Select
          name='members'
          onChange={ (value) => onChange({ target: { value, name: 'members'} }) }
          closeMenuOnSelect={ false }
          components={ animatedComponents }
          defaultValue={ values }
          formatOptionLabel={users => (
            <div className="country-option">
              <img src={users?.image} style={{ width: 36 }} alt="country-image" />
              <span className="pl-4">{users?.label}</span>
            </div>
          )}
          isMulti
          options={ users }
        />
    : ( !isEdit ) 
    ?
      <Select
          name='members'
          onChange={ (value) => onChange({ target: { value, name: 'members'} }) }
          closeMenuOnSelect={ false }
          components={ animatedComponents }
          isMulti
          menuShouldScrollIntoView={false}
          formatOptionLabel={users => (
            <div className="country-option">
              <img src={users?.image} style={{ width: 36 }} alt="country-image" />
              <span className="pl-4">{users?.label}</span>
            </div>
          )}
          options={ users }
        />
    : <Load/>
    
  );
}