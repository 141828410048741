import { getReplyCommentsForumCourse } from "actions/course";
import { getReplyCommentsForum } from "actions/project";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useGetReplyForum = () => {
  const { id: uid } = useParams();

  const dispatch = useDispatch();
  const {loadingReply} = useSelector( state => state.forumCommens );

  const getProjectCommentReplyForum = ( e, type, commentId, getReply) => {


    if (type == "project" && e.length > 0 && !getReply) {
        console.log(commentId);
      dispatch(getReplyCommentsForum({ projectId: uid, commentId }));
    }

    if(type == "course" && e.length > 0 && !getReply){
        dispatch(getReplyCommentsForumCourse({ courseId: uid, commentId }));
    }
  };

  return {
    getProjectCommentReplyForum,
    loadingReply,
  };
};
