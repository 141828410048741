// import { firebase_storage } from "../firebase/config";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// const uploadFilesProject = async (files, ruta, setProgress, setError) => {
//   const filesProyect = [];
//   let uploadComplete = Array.from(files).length;

//   return new Promise((resolve, reject) => {
//     return Array.from(files).map(async (file) => {
//       const storageRefe = ref(firebase_storage, `${ruta}/${file.name}`);
//       const uploadTask = uploadBytesResumable(storageRefe, file);
//       uploadTask.on(
//         "state_changed",
//         (snapshot) => {
//           let porcentage =
//             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//           setProgress(porcentage);
//         },
//         (error) => setError(error),
//         async () => {
//           const url = await getDownloadURL(uploadTask.snapshot.ref);
//           filesProyect.push(url);
//           uploadComplete--;
//           if (uploadComplete == 0) {
//             return resolve(filesProyect);
//           }
//         }
//       );
//     });
//   });
// };

// export default uploadFilesProject;

import { firebase } from "../firebase/config";

const uploadFilesProject = async (files, ruta, setProgress, setError) => {
  const filesProyect = [];
  let uploadComplete = Array.from(files).length;
  let porcentage = 0;
  return new Promise((resolve, reject) => {
    return Array.from(files).map(async (file) => {
      const type = file.type;
      const path = `${ruta}/${file.name}/`;

      const newMetadata = {
        contentType: type,
      };

      const storageRef = firebase.storage().ref(path);
      const state = storageRef.put(file, newMetadata);
      state.on(
        "state_changed",
        (snapshot) => {
          porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          let percent = Math.round(porcentage);
          setProgress(percent);
        },
        (error) => 
        {setError(error)
        },
        async () => {
          const url = await storageRef.getDownloadURL();
          filesProyect.push(url);
          uploadComplete--;
          if (uploadComplete == 0) {
            return resolve(filesProyect);
          }
        }
      );
    });
  });
};

export default uploadFilesProject;
