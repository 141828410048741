import React from "react";
import Header from "components/headers/Header";
import {
  UserPageLoadingBodyLeft,
  UserPageLoadingBodyRight,
  UserPageLoadingCircleBackHeader,
  UserPageLoadingCircleHeader,
  UserPageLoadingCirclePBackHeader,
  UserPageLoadingCirclePHeader,
  UserPageLoadingHeaderMobile,
} from "./UserPageLoadingComponents";

export const UserPageLoadingMobile = () => {
  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
            <div className="col-xl-12 mb-3">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <UserPageLoadingHeaderMobile />
                    <div style={{ position: 'absolute', zIndex: '2', top: 10 }}>
                      <UserPageLoadingCircleHeader/>
                    </div>
                    <div style={{ position: 'absolute', zIndex: '7', top: 60 }}>
                      <UserPageLoadingCirclePHeader/>
                    </div>
                    <div style={{ position: 'absolute', zIndex: '0', top: 10 }}>
                      <UserPageLoadingCircleBackHeader/>
                    </div>
                    <div style={{ position: 'absolute', zIndex: '4', top: 60 }}>
                      <UserPageLoadingCirclePBackHeader/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <UserPageLoadingBodyLeft />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <UserPageLoadingBodyRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
