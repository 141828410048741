import React from "react";
import { CommentPostViewNotImg } from "./CommentPostViewNotImg";
import { useMediaQuery } from "react-responsive";

export const PostImageNotAvailable = ({ post, type, typeLike, groupId }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });

  return (
    <div
      className={`${
        isDesktopOrLaptop
          ? "main-container-not-img-browser"
          : "main-container-not-img-mobile"
      } `}
    >
      {isDesktopOrLaptop && (
        <div className="comment-not-img-browser shadow-xs">
          <CommentPostViewNotImg
            post={post}
            positionImput={true}
            type={type}
            typeLike={typeLike}
            groupId={groupId}
          />
        </div>
      )}

      {isTabletOrMobile && (
        <div className="comment-not-img-mobile shadow-xs">
          <CommentPostViewNotImg
            post={post}
            positionImput={true}
            type={type}
            typeLike={typeLike}
            groupId={groupId}
          />
        </div>
      )}
    </div>
  );
};
