import React from 'react';
import { AddTypeShare } from 'components/shared/AddTypeShare';
import { useGroupShare } from 'Hooks/group/useGroupShare';


export const ShareGroup = ({notiClass, closeOpen}) => {
  const { onChangeFriendsShare, dataType, handleShare} = useGroupShare();

  return <>
           <div className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0  ${notiClass}`} aria-labelledby={`dropdownMenu`}>
               <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center" >Share <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2" onClick={ closeOpen }></i></h4>
               <AddTypeShare 
                 data={ dataType }
                 titleAddTypeShare= 'Select where you want to post'
                 name='type'
                 handleInputChange={onChangeFriendsShare}/>
               
                <div onClick={ handleShare } className="cursor-pointer align-items-center justify-content-center pl-3 pt-5 ">
                    <span className="text-center d-lg-block bg-success p-2 z-index-1 rounded-3 text-white font-xsss fw-700 ls-3">
                      Share the group
                    </span>
                 </div>
           </div>
        </>;
};
