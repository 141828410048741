import React from "react";

import Header from "../components/headers/Header";
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Load from '../components/Load';

import { useMyNotifications } from "../Hooks/notifcations/useMyNotifications";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LoadingNotification } from "../components/Loadings/LoadingNotification";


const Notification = () => {
    const { results, loading, loadingPagination, navigate, lastNotificationsElementRef , handleViewNotificationState, handleRemoveNotification, handleRemoveAllNotifications } = useMyNotifications();
    const  read =  'bg-lightblue theme-light-bg';
    const iconType = (type) => {
        if (type == "like" || type == "likeGroup") {
          return "feather-thumbs-up bg-primary-gradiant";
        } else if (type == "comments" || type == "commentsGroup") {
          return "feather-message-circle bg-success";
        } else if (type == "friendRequest") {
          return "feather-user-plus bg-primary-gradiant";
        } else if (type == "friendAcceptRequest") {
          return "feather-user-check bg-primary-gradiant";
        }else if(type == "joinGroup"){
          return "feather-plus-circle bg-warning";
        }else if(type == "joinProject"){
          return "feather-plus-circle bg-warning";
        }else if(type == 'joinCourse'){
          return "feather-plus-circle bg-warning";
        }else if(type == "invitationGroup"){
            return "feather-plus-circle bg-warning";
        }else if(type == "invitationProject"){
            return "feather-plus-circle bg-warning";
        }else if(type == 'invitationCourse'){
            return "feather-plus-circle bg-warning";
        }
      };

        return (

            <> 
                <Header />
                <Leftnav />
                <Rightchat />

         
                    <div className="main-content theme-dark-bg right-chat-active">
                    
                        <div className="middle-sidebar-bottom">
                            <div className="middle-sidebar-left">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                            <h2 className="fw-700 mb-4 mt-2 font-md text-grey-900 d-flex align-items-center">Notification
                                            <span className="circle-count bg-warning text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-2  mt-0">{ results ? results.length : 0 }</span> 
                                            {/* <a href="/defaultnotification" className="ms-auto btn-round-sm bg-greylight rounded-3"><i className="feather-hard-drive font-xss text-grey-500"></i></a> 
                                            <a href="/defaultnotification" className="ms-2 btn-round-sm bg-greylight rounded-3"><i className="feather-alert-circle font-xss text-grey-500"></i></a>  */}
                                            <div className="ms-auto pointer ms-2 btn-round-sm bg-greylight rounded-3"><i className="feather-trash-2 font-xss text-grey-500" onClick={ results && results.length > 0 ? handleRemoveAllNotifications : null }></i></div></h2>

                                            {
                                                loading && (
                                                    <LoadingNotification/>
                                                )
                                            }
                                            <ul className="notification-box">
                                                {results?.map((value , index) => {
                                                     if(results.length === index + 1){
                                                        return (
                                                            <li key={index} ref={ lastNotificationsElementRef } >
                                                                 <div className={ ` d-flex ${  value?.state ? '' : read }` }>
                                                                  <div style={{ flexGrow: 1 }} onClick={() => handleViewNotificationState(value) }>
                                                                        <Link to={ navigate(value) } className={`d-flex align-items-center p-3  rounded-3 `}>
                                                                            <img src={`assets/images/user.png`} alt="user" className="w45 me-3 " style={{ top: 5 }} />
                                                                            <i className={`text-white me-2  font-xssss notification-react ${ iconType(value.type) }`} style={{ top: 30 }}></i>
                                                                            <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20" ><strong>{value.userName}</strong>  {value.notification}<span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> {value.cresteDate}</span> </h6>
                                                                        </Link>
                                                                    </div>
                                                                   <div className="mt-3 ms-auto" style={{ paddingRight: 15 }} onClick={() => handleRemoveNotification(value) }>
                                                                         <i className="feather-x text-grey-500 font-xs ms-auto pointer"></i>
                                                                   </div>

                                                                </div>
                                                            </li>
                                                        )
                                                    }else{
                                                        return (
                                                            <li key={index} >
                                                                <div className={ ` d-flex ${  value?.state ? '' : read }` }>
                                                                   <div  style={{ flexGrow: 1 }} onClick={() => handleViewNotificationState(value) }>
                                                                        <Link to={ navigate(value) } className={`d-flex align-items-center p-3  rounded-3 `}>
                                                                            <img src={`assets/images/user.png`} alt="user" className="w45 me-3 " style={{ top: 5 }} />
                                                                            <i className={`text-white me-2  font-xssss notification-react ${ iconType(value.type) }`} style={{ top: 30 }}></i>
                                                                            <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20" ><strong>{value.userName}</strong>  {value.notification}<span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> {value.cresteDate}</span> </h6>
                                                                        </Link>
                                                                   </div>
                                                                   <div className="mt-3 ms-auto" style={{  paddingRight: 15 }} onClick={() => handleRemoveNotification(value) }>
                                                                         <i className="feather-x text-grey-500 font-xs ms-auto pointer"></i>
                                                                   </div>

                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                })}

                                                {
                                                    loadingPagination && <Load/>
                                                }

                                            </ul>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <Popupchat />
                    <Appfooter /> 
            </>
        );
    
}

export default Notification;