import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSuggestions } from "actions/getUsers";

export const useUserSuggestions = () => {
  const dispatch = useDispatch();
  const { userSuggestions, loading } = useSelector((state) => state.users);
  const [userSuggestionslist, setUserSuggestionslist] = useState(userSuggestions);
  // const [loading, setLoading] = useState(false);
  const { uid } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!userSuggestions) {
      dispatch(getUserSuggestions({ userId: uid }));
      // setLoading(true);
    }
  }, [userSuggestions]);

  useEffect(() => {
    if (userSuggestions) {
      setUserSuggestionslist(userSuggestions);
      //  setLoading(false);
    }
  }, [userSuggestions]);

  return {
    userSuggestionslist,
    loading,
  };
};
