import React from "react";
import Darkbutton from "../Darkbutton";

import { Link, NavLink } from "react-router-dom";
import { SearchFriends } from "components/searchFriends/SearchFriends";
import { useHeader } from "Hooks/useHeader";
import { imageExistsProfile } from "helpers/verifyImg";
import { NavBarRigthHeader } from "./components/NavBarRigth";

export const Header = () => {
  const {
    state,
    toggleOpen,
    ProfilePhoto,
    name,
    uid,
    newNotification,
    handleOpenNotification,
    countMessage,
  } = useHeader();

  const navClass = `${state.isOpen ? " nav-active" : ""}`;
  const buttonClass = `${state.isOpen ? " active" : ""}`;
  // const searchClass = `${state.isActive ? " show" : ""}`;
  // const notiClass = `${state.isNoti ? " show" : ""}`;

  return (
    <div className="nav-header bg-white shadow-xs border-0 z-100">
      <div className="nav-header">
        <div className="nav-top">
          <Link to="/home">
            <img
            className="ml-4"
              src="/assets/images/icono.png"
              style={{ objectFit: "contain", height: 50, width: 50 }}
              alt="DEAL"
            />
          </Link>
          {/* <Link to="/"><span className=" fredoka-font ls-3 fw-600 text-current font-xxl logo-text ">Deal </span> </Link> */}

          <Link
            to={`/PROFILE/${name}/${uid}`}
            className="mob-menu ms-auto me-2"
          >
            <i className="feather-user text-grey-900 font-sm btn-round-md bg-greylight"></i>
          </Link>
          <Link to="/defaultmessage" className="mob-menu  me-2 chat-active-btn">
            <i className="feather-message-circle text-grey-900 font-sm btn-round-md bg-greylight">
              {countMessage > 0 && (
                <span className="position-absolute top-10 start-20 translate-middle p-2  bg-warning border border-light rounded-circle">
                  <span className="visually-hidden">New alerts</span>
                </span>
              )}
            </i>
          </Link>
          <Link
            to="/defaultemailbox"
            className="mob-menu me-2"
            onClick={handleOpenNotification}
          >
            <i className="feather-bell font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-900 ">
              {newNotification && (
                <span className="position-absolute top-10 start-20 translate-middle p-2  bg-warning border border-light rounded-circle">
                  <span className="visually-hidden">New alerts</span>
                </span>
              )}
            </i>
          </Link>

          <button
            onClick={toggleOpen}
            className={`nav-menu me-0 ms-2 ${buttonClass}`}
          ></button>
        </div>

        <SearchFriends />

        <NavLink
          activeClassName="active"
          to="/home"
          className="p-2 text-center ms-3 menu-icon center-menu-icon"
        >
          <i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
        </NavLink>
        <NavLink
          activeClassName="active"
          to={`/PROFILE/${name}/${uid}`}
          className="p-2 text-center ms-0 menu-icon center-menu-icon"
        >
          <i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
        </NavLink>
        <NavLink
          activeClassName="active"
          to="/defaultmessage"
          className="p-2 text-center ms-0 menu-icon center-menu-icon"
        >
          <i className="feather-message-square font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 ">
            {countMessage > 0 && (
              <span className="position-absolute top-10 start-20 translate-middle p-2  bg-warning border border-light rounded-circle">
                <span className="visually-hidden">New alerts</span>
              </span>
            )}
          </i>
        </NavLink>
        <NavLink
          activeClassName="active"
          to="/groups"
          className="p-2 text-center ms-0 menu-icon center-menu-icon"
        >
          <i className="feather-users font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
        </NavLink>
        <NavLink
          activeClassName="active"
          to="/defaultemailbox"
          className="p-2 text-center ms-0 menu-icon center-menu-icon"
          onClick={handleOpenNotification}
        >
          <i className="feather-bell font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 ">
            {newNotification && (
              <span className="position-absolute top-10 start-20 translate-middle p-2  bg-warning border border-light rounded-circle">
                <span className="visually-hidden">New alerts</span>
              </span>
            )}
          </i>
        </NavLink>

        {/* <Darkbutton /> */}
        <div className="ms-auto">

          <Link to="/defaultsettings" className="p-0 ms-3 menu-icon">
            <img
              src={ProfilePhoto}
              onError={imageExistsProfile}
              alt="user"
              className="w40 mt--1"
            />
            
          </Link>
        </div>
      </div>
      <NavBarRigthHeader uid={uid} name={name} navClass={navClass} />
    </div>
  );
};

export default Header;
