import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteCommentsForumCourse } from "actions/course";
import { DeleteCommentsForum } from "actions/project";

export const useDeleteForum = (data, type) => {
    const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { uid: userId } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const remove = "Eliminar";
  const [formsValue, setforms] = useState({ textRemove: "" });

  const handleRemoveForum = async (e) => {
    e.preventDefault();

    if (formsValue.textRemove === "Eliminar" && data?.userId === userId) {
        if(type == "project"){
          const post = {
            projectId: data?.projectId,
            userId,
            uid: data?.uid
          };
      
          dispatch(DeleteCommentsForum(post))

        }else{
          const post = {
            courseId: data?.courseId,
            userId,
            uid: data?.uid
          };
          dispatch(DeleteCommentsForumCourse(post))
        }

      handleClose();
    }
  };

  const handlerform = (event) => {
    setforms({
      textRemove: event.target.value,
    });
  };
    return {
        handlerform,
        handleRemoveForum,
        remove,
        show,
        handleClose,
        handleShow,
        formsValue

    }
}
