import React from "react";
import ReactPlayer from "react-player";
import { EmptyData } from "components/shared/EmptyData";
import { useMediaQuery } from "react-responsive";

export const ViewCourseInformation = ({
  exitsMember,
  userIdActive,
  tempCourse,
}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });
  return (
    <>
      {exitsMember !== "success" && tempCourse?.userId != userIdActive && (
        <div className="row">
          <div className="col-xl-8 col-xxl-9 col-lg-8">
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
              <div className="card-body d-block p-4">
                <EmptyData
                  pathImg="../../assets/images/privateGroup.svg"
                  title="Private Course"
                />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-xxl-3 col-lg-4 ">
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
              <div className="card-body d-block p-4">
                <h4 className="fw-700 mb-3 font-xsss text-grey-900">About</h4>
                <div className="card-body border-top-xs d-flex">
                  <i
                    className={`feather-file-text text-grey-500 me-3 font-lg`}
                  ></i>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                    Description
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {tempCourse?.description}
                    </span>
                  </h4>
                </div>
                <div className="card-body border-top-xs d-flex">
                  <i className={`feather-lock text-grey-500 me-3 font-lg`}></i>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                    Course
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      Private
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {(exitsMember == "success" || tempCourse?.userId == userIdActive) && (
        <div className="row">
          <div className="col-xl-8 col-xxl-9 col-lg-8">
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
              <div className="card-body d-block p-4">
                {tempCourse?.url?.map((value, index) => (
                  <div key={index} className="pb-5">
                    <h2 className="fw-900 mb-3 font-xss text-grey-900 text-center">
                      {value?.titleVideo}
                    </h2>
                    <ReactPlayer url={value?.urlVideo} width="200" />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {isDesktopOrLaptop && (
            <div className="col-xl-4 col-xxl-3 col-lg-4 ">
              <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-block p-4">
                  <h4 className="fw-700 mb-3 font-xsss text-grey-900">About</h4>
                  <div className="card-body border-top-xs d-flex">
                    <i
                      className={`feather-file-text text-grey-500 me-3 font-lg`}
                    ></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                      Description
                      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                        {tempCourse?.description}
                      </span>
                    </h4>
                  </div>
                  <div className="card-body border-top-xs d-flex">
                    <i
                      className={`feather-lock text-grey-500 me-3 font-lg`}
                    ></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                      Course
                      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                        Private
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
