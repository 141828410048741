import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useProjectSuggestions } from "Hooks/project/useProjectSuggestions";
import { SuggestionProjectAndCourseLoading } from "components/Loadings/SuggestionProjectAndCourseLoading";
import { imageExistsProduct } from "helpers/verifyImg";

const ProjectSuggestions = () => {
  const { projectSuggestionslist, loading } = useProjectSuggestions();

  return <>

    {
      loading &&
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Project suggestions</h4>
                <Link to="/projects" className="fw-600 ms-auto font-xssss text-primary">See all</Link>
            </div>
                <div className="card-body d-flex align-items-center p-4" >
                    <SuggestionProjectAndCourseLoading/>
                </div>
         </div>
    }
  
    {
   projectSuggestionslist?.length > 0 &&
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
        <div className="card-body d-flex align-items-center p-4">
            <h4 className="fw-700 mb-0 font-xssss text-grey-900">Project suggestions</h4>
            <Link to="/projects" className="fw-600 ms-auto font-xssss text-primary">See all</Link>
        </div>
        {projectSuggestionslist?.map((value , index) => (
        
        <div key={index} className="card-body bg-transparent-card d-flex p-3 bg-greylight ms-3 me-3 rounded-3 mb-3 animate__animated animate__fadeIn">
                 <div className='row'>
                            <div className='col-9 d-flex'>
                                <figure className="avatar m-auto">
                                    <img src={`${value.pageCover}`} onError={ imageExistsProduct } alt="avater"  className="shadow-sm me-auto" style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
                                </figure>
                                <h4 className="fw-700 text-grey-900 font-xssss mt-2 ml-2">{value.title?.substring(0, 50)}... <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{value?.content?.substring(0, 20)}...</span></h4>
                            </div>
                            <div className='col-3 d-flex'>
                                <Link to={`/ViewProject/${value?.uid}`}className="btn-round-sm bg-white m-auto"><span className="feather-chevron-right font-xss text-grey-900"></span></Link>
                            </div>
                        </div>
        </div>
        
        ))}
     </div>
    }
  
  
   </>;
};

export default ProjectSuggestions;
