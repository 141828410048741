import React from 'react'
import { useBtnOptionMenu } from 'Hooks/useBtnOptionMenu';

import { EditForum } from './EditForum';
import { DeleteForum } from './DeleteForum';
import '../../../css/postOptions.css';

export const BtnOptionMenuForum = ({ data, type }) => {

    const { openModal, notiClass, wrapperRef } = useBtnOptionMenu();

    return (
        <div ref={ wrapperRef } className="ms-auto">
            <a id="dropdownMenu4" className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss pointer" data-toggle="dropdownMenu4" aria-haspopup="true" onClick={ openModal } aria-expanded="false"></a>

            <div className={`dropdown-menu p-3 rounded-xxl border-0 ms-auto mt-4 contentModal shadow-sm  ${notiClass}`}  aria-labelledby="dropdownMenu3">
              <ul>
                    <EditForum data={ data } type={type} />
                    <DeleteForum data={ data } type={type} /> 
              </ul>
            </div>

        </div>

    )
}
