import React from 'react'
import { CardRouteProfileOptions } from '../shared/CardRouteProfileOptions'
import { CreateCourseForm } from './CreateCourseForm'

export const CreateCourse = () => {
    return (
        <>
         <CardRouteProfileOptions title="Create Course"  component={  CreateCourseForm } />
        </>
    )
}
