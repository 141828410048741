import React from "react";
import Load from "components/Load";
import SearchAll from "components/shared/SearchAll";

import { useAllUsers } from "Hooks/user/useAllUsers";
import { CardListUsersStyle } from "../components/CardListUsersStyle";
import { AllUsersLoadingIndicator } from "../loading/AllUsersLoading";
import { EmptyData } from "components/shared/EmptyData";

const AllUsers = () => {
  const {
    docs,
    loading,
    loadingLoad,
    loadingPagination,
    lastUsersElementRef,
    userIdActive,
    searchText,
    searchResult,
    handleSearch,
    handleSearchText,
  } = useAllUsers();

  return (
    <>
      <SearchAll
        title="Users"
        placeHolder="Search user"
        onChange={handleSearchText}
        value={searchText}
        onKeyDown={(e) => {
          // e.preventDefault();
          if (e.key === "Enter") handleSearch(searchText);
        }}
      />

      {loading && !searchResult && (
        <AllUsersLoadingIndicator quantityElement={8} />
      )}

      {docs == null ? null : loadingLoad ? (
        <Load />
      ) : (
        <div className="row ps-2 pe-2">
          {searchResult && searchResult.length > 0 ? (
            searchResult?.map((value) => (
              <div
                key={value.objectID}
                ref={lastUsersElementRef}
                className="col-md-3 col-sm-4 pe-2 ps-2"
              >
                <CardListUsersStyle
                  uid={value.objectID}
                  photoUrl={value.photoUrl}
                  userName={value.userName}
                />
              </div>
            ))
          ) : (!loading && docs.length === 0) ||
            (!loading && searchResult && searchResult.length === 0) ? (
            <EmptyData
              pathImg="/assets/images/NoData.svg"
              title="There are no users"
            />
          ) : (
            docs?.map((value, index) => {
              if (value.uid == userIdActive) {
                return;
              }
              if (docs.length === index + 1) {
                return (
                  <div
                    key={index}
                    ref={lastUsersElementRef}
                    className="col-md-3 col-sm-4 pe-2 ps-2"
                  >
                    <CardListUsersStyle
                      uid={value.uid}
                      photoUrl={value.photoUrl}
                      userName={value.userName}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    ref={lastUsersElementRef}
                    className="col-md-3 col-sm-4 pe-2 ps-2"
                  >
                    <CardListUsersStyle
                      uid={value.uid}
                      photoUrl={value.photoUrl}
                      userName={value.userName}
                    />
                  </div>
                );
              }
            })
          )}
        </div>
      )}
      {loadingPagination && <Load />}
    </>
  );
};

export default AllUsers;
