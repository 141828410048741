import React from "react";
import Header from "../components/headers/Header";
import Appfooter from "../components/Appfooter";
import AllUsers from "../components/profile/all/AllUsers";

const User = () => {
  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <AllUsers />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default User;
