import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetCommentsPostById } from "actions/comments";
import { getPostGroupById } from "actions/post";

export const useCommentsPostGroup = () => {
  const { postId, groupId } = useParams();

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> SELECTOR
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { postsGroupLoad, tempPost, isUpdate } = useSelector(
    (state) => state.postsGroup
  );
  const { uid: userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> STATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [fileType, setFileType] = useState(null);
  const [ImageSelectedPrevious, setImageSelectedPrevious] = useState(null);
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETICION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    postId && dispatch(getPostGroupById({ uid: postId, groupId, userId }));
  }, [postId, postsGroupLoad]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POST
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    setLoading(true);
    if (postsGroupLoad.docs.length > 0 && !ImageSelectedPrevious) {
      const post = postsGroupLoad.docs.find((dc) => dc.uid === postId);
      setPost(post);
      setFileType(post?.attachments.includes("jpg"));
      setImageSelectedPrevious(post?.attachments);
      setLoading(false);
    } else if (tempPost) {
      setPost(tempPost);
      setFileType(tempPost?.attachments.includes("jpg"));
      setImageSelectedPrevious(tempPost?.attachments);
      setLoading(false);
    }
  }, [postsGroupLoad, tempPost]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS UPDATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isUpdate?.state && tempPost) {
      setPost(tempPost);
    }
  }, [isUpdate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER COMMENT RESET
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    return () => {
      dispatch(resetCommentsPostById());
    };
  }, []);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    post,
    ImageSelectedPrevious,
    fileType,
    loading,
    groupId,
  };
};
