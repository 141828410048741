import React from "react";
import Load from "../../Load";
import SearchAll from "../../shared/SearchAll";

import { useAllProjects } from "../../../Hooks/project/useAllProjects";
import { EmptyData } from "../../shared/EmptyData";
import { CardListMyProjectStyle } from "../components/CardListMyProjectStyle";
import { ProjectsLoadingIndicator } from "../loading/ProjectsLoading";

export const AllProjects = () => {
  const {
    docs,
    lastProjectsElementRef,
    loading,
    loadingLoad,
    loadingPagination,
    searchText,
    searchResult,
    handleSearch,
    handleSearchText,
  } = useAllProjects();

  return (
    <>
      <SearchAll
        title="Projects"
        placeHolder="Search Projects"
        onChange={handleSearchText}
        value={searchText}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSearch(searchText);
        }}
      />

      {loading && !searchResult && (
        <ProjectsLoadingIndicator isPropietor={false} quantityElement={8} />
      )}

      {docs == null ? null : loadingLoad ? (
        <Load />
      ) : (
        <div className="row ps-2 pe-2">
          {searchResult && searchResult.length > 0 ? (
            searchResult?.map((value) => (
              <div key={value.uid} className="col-md-4 col-sm-6 pe-2 ps-2">
                <CardListMyProjectStyle
                  uid={value.uid}
                  title={value.title}
                  pageCover={value.pageCover}
                  isPropietor={false}
                />
              </div>
            ))
          ) : (!loading && docs.length === 0) ||
            (!loading && searchResult && searchResult.length === 0) ? (
            <EmptyData
              pathImg="/assets/images/NoData.svg"
              title="There are no projects"
            />
          ) : (
            docs.map((value, index) => {
              if (docs.length === index + 1) {
                return (
                  <div
                    key={value.uid}
                    ref={lastProjectsElementRef}
                    className="col-md-4 col-sm-6 pe-2 ps-2"
                  >
                    <CardListMyProjectStyle
                      uid={value.uid}
                      title={value.title}
                      pageCover={value.pageCover}
                      isPropietor={false}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={value.uid} className="col-md-4 col-sm-6 pe-2 ps-2">
                    <CardListMyProjectStyle
                      uid={value.uid}
                      title={value.title}
                      pageCover={value.pageCover}
                      isPropietor={false}
                    />
                  </div>
                );
              }
            })
          )}
        </div>
      )}
      {loadingPagination && <Load />}
    </>
  );
};
