import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createGroup, setTempGroupReset } from "actions/group";
import { useForm } from "../userForms";
import uploadFile from "helpers/uploadFile";
import { useHistory } from "react-router-dom";
import { removeError, setError } from "../../actions/ui";
import { firebase } from "firebase/config";
import { closeLoading, successLoading } from "helpers/loadingCreate";

export const useCreateGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { uid: userId, name } = useSelector((state) => state.auth);
  const { msgError } = useSelector((state) => state.ui);
  const { createdFinished, tempGroup, loading, tempImg } = useSelector(
    (state) => state.group
  );
  const [isUpdate, setisUpdate] = useState(false);
  const [values, handleInputChange, reset] = useForm({
    title: "",
    description: "",
    members: [],
    private: true,
  });

  const [progress, setProgress] = useState(0);
  const [error, setErrorUrl] = useState("");

  const [coverPageSelectedPrevious, setCoverPageSelectedPrevious] =
    useState(null);
  const [coverPageSelectedFile, setCoverPageSelectedFile] = useState(null);

  //  Validations
  const isFormValidate = () => {
    if (values?.title.trim().length < 5) {
      dispatch(setError("Title too short"));
      return false;
    } else if (values.description.trim().length < 5) {
      dispatch(setError("Description too short"));

      return false;
    }
    dispatch(removeError());
    return true;
  };

  const changeImage = (e, type) => {
    if (e.target.files[0] !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      setCoverPageSelectedFile(e.target.files[0]);
      reader.onload = (e) => {
        e.preventDefault();
        setCoverPageSelectedPrevious(e.target.result);
      };
    }
  };

  const handleCreateGroup = async (e) => {
    e.preventDefault();
    if (isFormValidate()) {
      setisUpdate(true);
      successLoading('Creating group please wait...');

      const uid = firebase.firestore().collection("groups").doc().id;

      const { members: docsMembers, title, description } = values;
      const members = docsMembers.map((mbr) => {
        const userId = mbr["value"];
        return userId;
      });
      const group = { userId, members, title, description, private: true };
      let pageCover = "";
      if (coverPageSelectedFile) {
        pageCover = await uploadFile(
          coverPageSelectedFile,
          `/groups/${uid}/imageMain/coverPage.jpg`,
          setProgress,
          setErrorUrl
        );
      }
      dispatch(createGroup({ uid, ...group, pageCover }));
    }
  };

  useEffect(() => {
    if (createdFinished) {
      setisUpdate(false);
      closeLoading();
      reset();
      setCoverPageSelectedPrevious(null);
      setCoverPageSelectedFile(null);
      dispatch(setTempGroupReset());
      const firstName = name?.replaceAll(" ", "_");
      history.push(`/PROFILE/${firstName}/${userId}/groups/my-groups`);
    }
  }, [createdFinished]);

  return {
    values,
    handleInputChange,
    reset,
    handleCreateGroup,
    changeImage,
    coverPageSelectedPrevious,
    loading,
    msgError,
    isUpdate
  };
};
