import { types } from "../types/types";

const inicialState = {
  loading: false,
  loadingReply: true,
  postsForum: {
    userData: "dif",
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  isCreate: {
    state: false,
    uidCreate: null,
  },
  isUpdate: {
    state: false,
    uidUpdate: null,
  },
  isRemove: {
    state: false,
    uidRemove: null,
  },
};

export const forumReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.loadingCreateForum:
      return {
        ...state,
        loading: action.payload,
      };

      case types.loadinRplyForum:
      return {
        ...state,
        loadingReply: action.payload,
      };

      case types.CreateForum:
      return {
        ...state,
        loading: false,
        isCreate: {
          state: true,
          uidCreate: action.payload?.uid,
        },
        postsForum: state.postsForum && {
          userData: state.postsForum.userData,
          lastInResponse: state.postsForum.lastInResponse,
          isCollectionEmpty: state.postsForum.isCollectionEmpty,
          docs: [action.payload, ...state.postsForum.docs],
        },
      };

      case types.getCommentsForum:
      let hashpostsForumLoad = {};
      let isLoadedData =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getPostsForumIsCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;

      return {
        ...state,
        postsForum: isLoadedData
          ? state.postsForum
            ? {
                userData: action.payload?.userData,
                docs: [
                  ...state.postsForum?.docs,
                  ...action.payload?.docs,
                ].filter((o) =>
                  hashpostsForumLoad[o.uid]
                    ? false
                    : (hashpostsForumLoad[o.uid] = true)
                ),
                isCollectionEmpty: getPostsForumIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.postsForum?.userData,
              docs: state.postsForum?.docs,
              isCollectionEmpty: getPostsForumIsCollectionEmpty,
              lastInResponse: state.postsForum?.lastInResponse,
            },
      };

      case types.resetCreateComment:
      return {
        ...state,
        isCreate: {
          state: false,
          uidCreate: null,
        },
      };

      case types.isChangeCommentsForum:
        return {
          ...state,
          postsForum: {
            userData: "dif",
            docs: [],
            isCollectionEmpty: -1,
            lastInResponse: null,
          },
        };

        case types.updateForum:
      state.postsForum &&
       state.postsForum.docs.map(
         doc => doc.uid === action.payload.uid
         ? doc.content = action.payload.content
         : doc
       ) 
        // state.postsForum.docs.splice(
        //   state.postsForum.docs.findIndex(
        //     (dc) => dc.uid === action.payload.uid
        //   ),
        //   1,
        //   action.payload
        // );

      return {
        ...state,
        loading: false,
        isUpdate: {
          state: true,
          uidUpdate: action.payload?.uid,
        },
      };

      case types.updateForumReplyComment:
        return {
          ...state,
          postsForum: {
            ...state.postsForum,
            docs: state.postsForum?.docs?.map(
              doc => doc.uid === action.payload.commentId
              ? doc = {...doc, reply: doc?.reply ? [...doc?.reply, action.payload] : [action.payload]}
              : doc
            )
          }
        }

       case types.getFoumReplyComment:
         return{
           ...state,
           loadingReply: false,
           postsForum: {
             ...state.postsForum,
            docs: state.postsForum?.docs?.map(
              doc => doc.uid === action.payload[0]?.commentId
              ? doc = {...doc, reply: doc?.reply ? [...doc?.reply, ...action.payload] : [...action.payload]}
              : doc
            )
           }
         } 

    case types.deleteForum:
      state.postsForum &&
        state.postsForum.docs.splice(
          state.postsForum.docs.findIndex(
            (dc) => dc.uid === action.payload
          ),
          1
        );

      return {
        ...state,
        loading: false,
        isRemove: {
          state: true,
          uidRemove: action.payload,
        },
      };
      
      case types.updateForumReset:
      return {
        ...state,
        loading: false,
        isUpdate: {
          state: false,
          uidUpdate: null,
        },
      };

    case types.deleteForumReset:
      return {
        ...state,
        loading: false,
        isRemove: {
          state: false,
          uidRemove: null,
        },
      };
      
    default:
        return state;
    }
}