import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useBtnOptionMenu } from "Hooks/useBtnOptionMenu";
import { AddSkills } from "components/AddSkills";
import { ModalUserExperience } from "./ModalUserExperience";
import '../../../css/profileOptions.css';

export const BtnOptionMenuProfile = ({ routeProfile  }) => {
  const { openModal, notiClass, wrapperRef } = useBtnOptionMenu();

  return (
    <div ref={wrapperRef} className="ms-auto">
      <a
        id="dropdownMenu4"
        className="ti-more  d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-900"
        data-toggle="dropdownMenu4"
        aria-haspopup="true"
        onClick={openModal}
        aria-expanded="false"></a>

      <div className={`ms-auto dropdown-menu p-3 right-0 rounded-xxl border-0 contentModalProfile shadow-lg ${notiClass}`}
        style={{ width: "240px" }}
        aria-labelledby="dropdownMenu3">
        <h4 className="fw-700 font-xss mb-3">Option menu</h4>
        <Link className='li' to={`${routeProfile}/edit`}>
          <div className="border-0  ">
            <h5 className="font-xsss text-grey-900  mt-0 fw-700 d-block">
              <div className="d-flex">
                <i className="font-sm ti-pencil-alt text-grey-500"></i>
                <div className="ms-2 mt-1">Edit my information</div>
              </div>
            </h5>
          </div>
        </Link>
        <AddSkills />
         <ModalUserExperience />

        <Link className='li' to={`${routeProfile}/config`} style={{ borderBottom: '1px solid #00589b60' }}>
          <div className="border-0">
            <h5 className="font-xsss text-grey-900  mt-0 fw-700 d-block">
              <div className="d-flex">
                <i className="font-sm ti-settings  text-grey-500"></i>
                <div className="ms-2 mt-1">Privacy</div>
              </div>
            </h5>
          </div>
        </Link>
      </div>
    </div>
  );
};
