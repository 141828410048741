import React from "react";
import Header from "../components/headers/Header";
import Appfooter from "../components/Appfooter";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import { useHelpsbox } from "Hooks/settingInformation/useHelpbox";
import { EmptyData } from "components/shared/EmptyData";
import LoadCrcle from "components/shared/LoadCircle";

const Helpbox = () => {
  const { docInfo, docInfoLoading, handleSearch, searchResult } = useHelpsbox();

  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            {docInfoLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100vh - 200px)",
                }}
              >
                <div className="w100 h100" style={{ borderRadius: "50px" }}>
                  <LoadCrcle className="mt-6" />
                </div>
              </div>
            ) : (
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div
                    className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                    style={{
                      backgroundImage: `url("assets/images/help-bg.png")`,
                    }}
                  >
                    <div
                      className="card-body p-md-5 p-4 text-center"
                      style={{ backgroundColor: "rgba(0,85,255,0.8)" }}
                    >
                      <h2 className="fw-700 display2-size text-white display2-md-size lh-2">
                        {docInfo?.title}
                      </h2>
                      <p className="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">
                        {docInfo?.description}
                      </p>
                      <div className="form-group w-lg-75 mt-4 border-light border p-1 bg-white rounded-3 ms-auto me-auto">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="form-group icon-input mb-0">
                              <i className="ti-search font-xs text-grey-400"></i>
                              <input
                                type="text"
                                className="style1-input border-0 ps-5 font-xsss mb-0 text-grey-500 fw-500 bg-transparent"
                                placeholder="Search anythings.."
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                    <Accordion
                      className="accodion-style--1 accordion"
                      preExpanded={"0"}
                      
                    >
                      {searchResult && searchResult.length > 0 ? (
                        searchResult.map((info, index) => {
                          return (
                            <AccordionItem
                              key={index}
                              className="card shadow-xss"
                            >
                              <AccordionItemHeading className="card-header">
                                <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">
                                    {info?.question}
                                  </h5>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel className="card-body">
                                <p>{info?.answer}</p>
                              </AccordionItemPanel>
                            </AccordionItem>
                          );
                        })
                      ) : docInfo?.questions == 0 ? (
                        <EmptyData
                          pathImg="/assets/images/NoData.svg"
                          title="There are no projects"
                        />
                      ) : (
                        docInfo?.questions.map((info, index) => {
                          return (
                            <AccordionItem
                              key={index}
                              className="card shadow-xss"
                            >
                              <AccordionItemHeading className="card-header">
                                <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">
                                    {info?.question}
                                  </h5>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel className="card-body">
                                <p>{info?.answer}</p>
                              </AccordionItemPanel>
                            </AccordionItem>
                          );
                        })
                      )}
                    </Accordion>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Appfooter />
    </>
  );
};

export default Helpbox;
