export const urlApi = "https://us-central1-red-fup.cloudfunctions.net";
export const urlApiDev = 'http://localhost:5001/red-fup/us-central1';
export const newurl="https://us-central1-red-fup.cloudfunctions.net";
export const configApi = (data) => {
  if (data) {
    return {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
  }
  return {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
};
export const APPLICATION_ID = "S5S95U9WXU";
export const SEARCH_API_KEY = "45082b45b869208142f9c596bacfccec";

//BUSCAR USUARIOS
export const ALGOLIA_INDEX_USERS_GLOBAL = "RS-FUP-USERS-GLOBAL";

//BUSCAR MIS USUARIOS
export const ALGOLIA_INDEX_MYUSERS = "RS-FUP-MYUSERS";

//BUSCAR GROUPS
export const ALGOLIA_INDEX_GROUPS_GLOBAL = "RS-FUP-GROUPS-GLOBAL";

//BUSCAR PROJECTS
export const ALGOLIA_INDEX_PROJECTS_GLOBAL = "RS-FUP-PROJECTS-GLOBAL";

export const LIMIT = 10;
