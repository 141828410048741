import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getCourseMembersPendingById,
  NotificationsMembersCourse,
  updateCourseMembersById,
} from "actions/course";

export const useMembersNotificationsCourse = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loadingMembers, notificationsMembers } = useSelector(
    (state) => state.course
  );
  const { uid } = useSelector((state) => state.auth);
  const [Notifications, setNotifications] = useState(notificationsMembers);

  useEffect(() => {
    setNotifications(notificationsMembers);
  }, [notificationsMembers]);

  useEffect(() => {
    notificationsMembers?.length <= 0 &&
      dispatch(getCourseMembersPendingById({ courseId: id }));
  }, [id]);

  const Accept = (iduser) => {
    const data = {
      courseId: id,
      userId: iduser,
      stateMembers: {
        stateMembers: "success",
      },
    };
    dispatch(updateCourseMembersById(data));

    const values = [...notificationsMembers];
    values.splice(
      values.findIndex((value) => value.uid == iduser),
      1
    );
    dispatch(NotificationsMembersCourse(values));
  };

  return {
    loadingMembers,
    Notifications,
    Accept,
  };
};
