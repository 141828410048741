import React from "react";
import { useMembersNotificationsCourse } from "Hooks/course/useMembersNotificationCourse";
import { LoadingNotification } from "components/Loadings/LoadingNotification";

export const ViewCourseRequesMembers = () => {
  const { loadingMembers, Notifications, Accept } =
    useMembersNotificationsCourse();

  return (
    <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
      <h2 className="fw-700 mb-4 mt-2 font-md text-grey-900 d-flex align-items-center">
        Request
        <span className="circle-count bg-warning text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-2  mt-0">
          {Notifications?.length}
        </span>
      </h2>

      {loadingMembers && <LoadingNotification />}
      {!loadingMembers && (
        <ul className="notification-box">
          {Notifications?.map((value, index) => {
            if (Notifications.length === index + 1) {
              return (
                <li key={index}>
                  <div className={` d-flex bg-lightblue theme-light-bg`}>
                    <div style={{ flexGrow: 1 }}>
                      <div
                        className={`d-flex align-items-center p-3  rounded-3 `}
                      >
                        <img
                          src={value?.photoUrl}
                          alt="user"
                          className="w45 me-3 "
                          style={{ top: 5 }}
                        />
                        <h6 className="font-xsss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20">
                          <strong>{value?.userName}</strong>{" "}
                          {"want to join this group"}
                        </h6>
                      </div>
                    </div>
                    <div className="mt-3 ms-auto" style={{ paddingRight: 15 }}>
                      <button
                        className="btn btn-info "
                        onClick={(e) => Accept(value.userId, e)}
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      )}
    </div>
  );
};
