import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { imageExistsProfile } from 'helpers/verifyImg';
import { usePostView } from 'Hooks/post/usePostView';
import { UsePostViewLike } from 'Hooks/post/UsePostViewLike';
import { BtnOptionMenu } from '../BtnOptionMenu';

const PostViewShare = ({groupId, userId: postUserId, userName, time, des, avater, postImage, postVideo, id, like, comment, type, typeLike, typePost, Linkto  }) => {

  const  { userId, menuClass, handleViewNewPostById, toggleActive, loadMore, loadMoreComment, isLoadMoreComment } = usePostView(des);
  const {onDistLike, onLike} = UsePostViewLike(id, type, typeLike, groupId, postUserId);
  const data = { userId, userName, time, des, avater, postImage, postVideo, id, typePost, Linkto };
  const commemtCount = comment?.quantityComments; 

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mt-2 mb-3">
           
               <div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{backgroundImage: `url(${ postImage ?  postImage : '/assets/images/product.png'})`}}>
               {   
                   <div className='d-flex '>
                    <div className='bg-dark pt-2 pb-2 text-white text-center align-items-center' style={{ width: '100px', display: 'flex',  justifyContent: 'center' , borderRadius: '50px', textTransform: 'uppercase', fontSize: '10px'}}>
                       {
                           typePost
                       }
                    </div>
                     {
                       ( userId === postUserId ) && <div className='ms-auto'>
                           <BtnOptionMenu data={ data } groupId={ groupId } />
                       </div>
                     }  
                   </div>
               }
           </div>
            <div className="card-body d-block w-100 pe-4 pb-4 pt-0 text-left position-relative">
                <div className="clearfix"></div>
                <div className="card-body p-0 d-flex mt-4">
                    <figure className="avatar me-3"><img src={ avater } onError={ imageExistsProfile }  alt="avater" className="shadow-sm rounded-circle" style={{ width: "45px", height: "40px", borderRadius: "50px" }} /></figure>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1"> {userName} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {time}</span></h4>
                    <span className="position-absolute right-15 top-15 d-flex align-items-center">
                        <Link to={ Linkto }>
                            <div className="text-center p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white" style={{ cursor: 'pointer' }}>OPEN</div>
                        </Link>
                    </span>
                </div>
            </div>
            <div className="card-body me-lg-5 pl-3 pt-0 pb-0">
                <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 ">
                  {
                    loadMoreComment
                  }
                  {
                  (des?.length > 199  ) &&  <div className="load-more pointer text-info fs-6" onClick={ loadMore  }>
                      {
                         isLoadMoreComment ?  'See Less' :' See more'
                      } 
                    </div>
                  }
               </p>
            </div>
            <div className="card-body d-flex ">
              <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2" onClick={toggleActive}>
                 {
                   !like?.itsYouLike  && <button onClick={onLike} className="feather-thumbs-up border text-black bg-light-gradiant me-1 btn-round-xs font-xss"></button> 
                }  
                 {

                 like?.itsYouLike && <button onClick={onDistLike} className="feather-thumbs-up border text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></button> 
                 }  
                 
                     
                {/* <i className="feather-heart text-white bg-red-gradiant me-2 btn-round-xs font-xss"></i> */}
                {like?.quantityLike} Like</div>
                {/* <div className={`emoji-wrap pointer ${emojiClass}`}>
                    <ul className="emojis list-inline mb-0">
                        <li className="emoji list-inline-item"><i className="em em---1"></i> </li>
                        <li className="emoji list-inline-item"><i className="em em-angry"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-anguished"></i> </li>
                        <li className="emoji list-inline-item"><i className="em em-astonished"></i> </li>
                        <li className="emoji list-inline-item"><i className="em em-blush"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-clap"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-cry"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-full_moon_with_face"></i></li>
                    </ul>
                </div> */}
                

                <div className="align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss cursor-pointer">
                   
                   {
                      groupId && <Link to={`/POSTS/GROUP/${groupId}/${ id }`} onClick={ handleViewNewPostById }>
                                    <div className='d-flex'>
                                        <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
                                        <div className='mt-1'>
                                            <span className="d-none-xss">{ commemtCount } Comment</span>
                                        </div>
                                    </div>
                                </Link>
                   }

                   {
                    
                        !groupId && <Link to={`/POSTS/${ id }`} onClick={ handleViewNewPostById }>
                                    <div className='d-flex'>
                                        <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
                                        <div className='mt-1'>
                                            <span className="d-none-xss">{ commemtCount } Comment</span>
                                        </div>
                                    </div>
                                </Link>

                   }
                   
                </div>

                {/* <div className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ${menuClass}`} id={`dropdownMenu${id}`} data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}><i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i><span className="d-none-xs">Share</span></div> */}
                <div className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0 ${menuClass}`} aria-labelledby={`dropdownMenu${id}`}>
                    <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">Share <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2"></i></h4>
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-facebook"><i className="font-xs ti-facebook text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-twiiter"><i className="font-xs ti-twitter-alt text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-linkedin"><i className="font-xs ti-linkedin text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-instagram"><i className="font-xs ti-instagram text-white"></i></span></li>
                            <li><span className="btn-round-lg pointer bg-pinterest"><i className="font-xs ti-pinterest text-white"></i></span></li>
                        </ul>
                    </div>
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-tumblr"><i className="font-xs ti-tumblr text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-youtube"><i className="font-xs ti-youtube text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-flicker"><i className="font-xs ti-flickr text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-black"><i className="font-xs ti-vimeo-alt text-white"></i></span></li>
                            <li><span className="btn-round-lg pointer bg-whatsup"><i className="font-xs feather-phone text-white"></i></span></li>
                        </ul>
                    </div>
                    <h4 className="fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3">Copy Link</h4>
                    <i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"></i>
                    <input type="text" placeholder="https://socia.be/1rGxjoJKVF0" className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg" />
                </div>
            </div>
        </div>
        )
    
}

export default PostViewShare;