import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useModalRemoveElement } from "Hooks/project/useModalRemoveElement";

export const ModalRemoveElement = ({ nameElement, idElement }) => {
   
  const { handleShow, show, handleClose, handleimag, handlerform, formsValue, remove} = useModalRemoveElement(idElement);

  return (
    <>
        <i className="cursor-pointer font-sm ti-trash  text-grey-500 ml-4" onClick={ handleShow }>
            <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block ml-4">Remove</span>
        </i>

      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleimag}>
          <Modal.Header>
            <Modal.Title>Remove { nameElement }</Modal.Title>
            <button className="btn ti-close" type="button" onClick={handleClose}></button>
          </Modal.Header>
          <Modal.Body>
            <div name="removeElement" className="form-group">
              <input
                id="file-input"
                name="remove"
                onChange={handlerform}
                type="text"
                placeholder="Escriba la palabra Eliminar"
                className="form-control"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="outline-info" disabled={ formsValue?.textRemove != remove ? true : false } onClick={handleClose}>
              Eliminar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalRemoveElement;
