import React from "react";
import { ProjectsLoading } from "./ProjectsLoadingComponents";

export const ProjectsLoadingIndicator = ({ isPropietor = false,  quantityElement= 3 }) => {

  var items = Array.from({ length: quantityElement }, (_, i) => i + 1);

  return (
      <div className="row w-100 p-0 m-0" >
        {
          items.map((item) => {
           return <div key={item} className="col-md-4 col-sm-12">
              <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                <div className="card-body d-block w-100  pb-4 text-center">
                  <ProjectsLoading isPropietor={ isPropietor } />
                </div>
              </div>
            </div>;
          })
        }
      </div>
  );
};
