import React from "react";
import Load from "components/Load";

import { useEditGroup } from "Hooks/group/useEditGroup";
import { GroupInfoBasicForm } from "./GroupInfoBasicForm";
import { GroupPageCover } from "./GroupPageCover";

export const EditGroupForm = () => {
  const {
    loading,
    handleUpdateGroup,
    values,
    handleInputChange,
    coverPageSelectedPrevious,
    changeImage,
    isUpdate,
  } = useEditGroup();


  return (
    (loading) ? (
      <Load />
    ) : (
      <form onSubmit={handleUpdateGroup}>
        <GroupInfoBasicForm values={values} onChange={handleInputChange} />
        <GroupPageCover
          coverPageSelectedPrevious={coverPageSelectedPrevious}
          changeImage={changeImage}
        />
        {/* <AddMembers isEdit={ true } values={ values?.members } handleInputChange={ handleInputChange } />   */}
        <div className="pb-5">
          <button
            disabled={isUpdate}
            type="submit"
            className=" bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-blockbg-current text-center text-white font-xsss fw-600 p-3 mt-4 w175 rounded-3 d-inline-block  mb-5"
          >
            Edit Group
          </button>
        </div>
      </form>
    )
  )
};
