import Swal from "sweetalert2";


const PercertFilesAlert = () =>{
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,       
      })
      
      Toast.fire({
        icon: 'info',
        title: 'Uploading File'
      })
}

export default PercertFilesAlert;