import { types } from "../types/types";

const inicialState = {
  loading: false,
  courseLoad: {
    userData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  courseParticipateLoad: {
    userData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  createdFinished: false,
  userMember: false,
  lengthCourse: 0,
  membersCourse: [],
  loadingMembers: false,
  imgTemp: null,
  tempCourse: null,
  exitsMember: null,
  notificationsMembers: [],
  page403: false,
};

export const courseReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.getCourses:
      let hash = {};
      let isLoadedData =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getMyCoursesCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;
      return {
        ...state,
        loading: false,
        courseLoad: isLoadedData
          ? state.courseLoad
            ? {
                userData: action.payload.userData,
                docs: [
                  ...state.courseLoad?.docs,
                  ...action.payload?.docs,
                ].filter((o) => (hash[o.uid] ? false : (hash[o.uid] = true))),
                isCollectionEmpty: getMyCoursesCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.courseLoad?.userData,
              docs: state.courseLoad?.docs,
              isCollectionEmpty: getMyCoursesCollectionEmpty,
              lastInResponse: state.courseLoad?.lastInResponse,
            },
      };

    case types.getCoursesParticipate:
      let hashParticipate = {};
      let isLoadedDataParticipate =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getMyCoursesParticipateCollectionEmpty = action.payload
        ?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;
      return {
        ...state,
        loading: false,
        courseParticipateLoad: isLoadedDataParticipate
          ? state.courseParticipateLoad
            ? {
                userData: action.payload.userData,
                docs: [
                  ...state.courseParticipateLoad?.docs,
                  ...action.payload?.docs,
                ].filter((o) =>
                  hashParticipate[o.uid]
                    ? false
                    : (hashParticipate[o.uid] = true)
                ),
                isCollectionEmpty: getMyCoursesParticipateCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.courseParticipateLoad?.userData,
              docs: state.courseParticipateLoad?.docs,
              isCollectionEmpty: getMyCoursesParticipateCollectionEmpty,
              lastInResponse: state.courseParticipateLoad?.lastInResponse,
            },
      };
    case types.isChangeMyCourse:
      return {
        ...state,
        courseLoad: {
          userData: null,
          docs: [],
          isCollectionEmpty: -1,
          lastInResponse: null,
        },
      };
    case types.loadingCourses:
      return {
        ...state,
        loading: true,
      };

    case types.createCourse:
      let courses = [action.payload, ...state.courseLoad.docs];
      function SortArray(x, y) {
        if (x.title < y.title) {
          return -1;
        }
        if (x.title > y.title) {
          return 1;
        }
        return 0;
      }
      let resultCourses = courses.sort(SortArray);
      return {
        ...state,
        courseLoad: state.courseLoad && {
          lastInResponse: state.courseLoad.lastInResponse,
          isCollectionEmpty: state.courseLoad.isCollectionEmpty,
          docs: resultCourses,
        },
        createdFinished: true,
      };

    case types.tempCourse:
      return {
        ...state,
        loading: false,
        tempCourse: action.payload,
      };

    case types.tempCourseLoad:
      const course = state.courseLoad?.docs.find(
        (org) => org.uid == action.payload?.uid
      );
      return {
        ...state,
        loading: false,
        tempCourse: course,
      };

    case types.updateCourse:
      state.courseLoad &&
        state.courseLoad.docs.splice(
          state.courseLoad.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1,
          action.payload
        );

      return {
        ...state,
        loading: false,
        tempCourse: action.payload,
        createdFinished: true,
      };

    case types.resetCourse:
      return {
        ...state,
        loading: false,
        createdFinished: false,
      };

    case types.loadingCoursesoff:
      return {
        ...state,
        loading: false,
      };

    case types.getCourses:
      return {
        ...state,
        courses: action.payload,
      };

    case types.exitsMemberCourse:
      return {
        ...state,
        exitsMember: action.payload,
      };

    case types.loadingMembersCourse:
      return {
        ...state,
        loadingMembers: action.payload,
      };

    case types.membersCourse:
      function SortArrayMembers(x, y) {
        if (x.userName < y.userName) {
          return -1;
        }
        if (x.userName > y.userName) {
          return 1;
        }
        return 0;
      }
      return {
        ...state,
        membersCourse: [...action.payload].sort(SortArrayMembers),
      };

    case types.notificationsMemberscourse:
      return {
        ...state,
        notificationsMembers: [...action.payload],
      };

    default:
      return state;
  }
};
