import React from "react";
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";

export const ChatView = ({ Channels, client, filters, sort, style }) => {
  const customStyles = {
    "--grey-gainsboro": "#f7f7f7",
    "--bg-gradient-start": "#EEEEEE",
    "--white-snow": "#EEEEEE",
  };

  return (     
    <Chat client={client} theme="messaging light" customStyles={customStyles}>
      <ChannelList filters={filters} sort={sort} showChannelSearch={true} />
      <Channel channel={Channels}>
        <div className={ style }>
          <Window>
            <div style={{ height: '50px', maxHeight: '20px' }}>
                <ChannelHeader  />
            </div>
            <MessageList />
            <MessageInput />
          </Window>
        </div>
        <Thread />
      </Channel>
    </Chat>
  );
};
