import React from "react";
import { CommentPostViewBrowser } from "./CommentPostViewBrowser";
import { imageExists403 } from "helpers/verifyImg";
import ReactPlayer from "react-player";

export const PostImageAvailableBrowser = ({
  post,
  imageSelectedPrevious,
  type,
  typeLike,
  groupId,
}) => {
  return (
    <div className="d-flex">
      <div className="caja">
        <div className="box">
          {imageSelectedPrevious?.includes("mp4") ? (
            <ReactPlayer  width={"100%"} height={"100%"} controls={true} url={imageSelectedPrevious}></ReactPlayer>
          ) : (
            <img
              src={imageSelectedPrevious}
              alt="Img no disponible"
              onError={imageExists403}
            ></img>
          )}
        </div>
      </div>
      <div className="comment shadow-xs">
        <CommentPostViewBrowser
          post={post}
          type={type}
          typeLike={typeLike}
          groupId={groupId}
        />
      </div>
    </div>
  );
};
