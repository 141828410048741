import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  getProjectById,
  resetProject,
  updateProject,
} from "actions/project";
import uploadFilesProject from "helpers/uploadFileProject";
import uploadFile from "helpers/uploadFile";
import Swal from "sweetalert2";
import PercertFilesAlert from "helpers/PercentAlertFiles";

export const useEditProject = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  //
  const { Id } = useParams();
  const { uid: userIdActive, name } = useSelector((state) => state.auth);
  const { page, tempProject, loading } = useSelector((state) => state.project);
  const { percentFiles } = useSelector((state) => state.ui);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> STATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [FilesDelete, setFilesDelete] = useState([]);
  const [ImageSelectedFile, setImageSelectedFile] = useState(null);
  const [ImageSelectedPrevious, setImageSelectedPrevious] = useState(null);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone();
  const [listFiles, setListFile] = useState([]);
  const [formsValue, setFomrsValue] = useState({
    title: "",
    content: "",
    file: "",
    filePortada: "",
    description: "",
    members: "",
  });

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (Id) {
      dispatch(getProjectById({ uid: Id }));
    }
  }, [Id]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER PROJECT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (tempProject) {
      setForm();
    }
    return () => {
      setFomrsValue({});
    };
  }, [tempProject, tempProject?.files]);

  const setForm = () => {
    setFomrsValue({
      title: tempProject?.title,
      content: tempProject?.content,
      file: "",
      filePortada: tempProject?.pageCover,
      description: tempProject?.description,
      members: tempProject?.members,
    });
    setListFile(tempProject?.files);
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> ONCHANGE PROJECT FILE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const changeImage = (e) => {
    if (e.target.files[0] !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      setImageSelectedFile(e.target.files[0]);
      reader.onload = (e) => {
        e.preventDefault();
        setImageSelectedPrevious(e.target.result);
      };
    }
  };

  const handlerform = (event) => {
    const target = event.target;
    const value = target.type === "file" ? target.files : target.value;
    setFomrsValue({
      ...formsValue,
      [event.target.name]: value,
    });
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> EDIT DATA PROJECT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleEdit = async (e) => {
    e.preventDefault();
    let urlPageCover = "";
    let data = {};

    urlPageCover = await uploadCoverPageProject();
    await uploadExtraFile();

    const pageCover =
      ImageSelectedFile !== null ? urlPageCover : tempProject?.pageCover;

    data = {
      userId: userIdActive,
      uid: Id,
      title: formsValue?.title,
      content: formsValue?.content,
      description: formsValue?.description,
      pageCover: pageCover,
      files: listFiles,
      members: formsValue?.members,
    };

    dispatch(updateProject(data));
    setImageSelectedFile(null);
    urlPageCover = "";
    setProgress(0);
    setImageSelectedPrevious(null);
  };

  const uploadCoverPageProject = async () => {
    if (ImageSelectedFile) {
      return await uploadFile(
        ImageSelectedFile,
        `/project/${Id}/CoverPage.jpg`,
        setProgress,
        setError
      );
    }
    return "";
  };

  const uploadExtraFile = async () => {
    let urlFiles = [];
    if (acceptedFiles.length > 0) {
      PercertFilesAlert()
      urlFiles = await uploadFilesProject(
        acceptedFiles,
        `/project/${Id}`,
        setProgress,
        setError, 
      );

      if (urlFiles.length > 0) {
        listFiles.push(...urlFiles);
      }
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> DELETE FILE PROJECT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const deleteFile = (e, value) => {
    const newFile = [...listFiles];
    const newFilesDelete = [...FilesDelete];
    newFilesDelete.push(newFile.splice(value, 1));

    setFilesDelete(newFile);
    setListFile(newFile);
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> NAVIGATION MY PROJECTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (page === 3) {
      history.push(
        `/PROFILE/${name?.replaceAll(
          " ",
          "_"
        )}/${userIdActive}/projects/my-projects`
      );
      dispatch(resetProject());
    }
  }, [page]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> File Uploading Alert
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  
   

 

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    changeImage,
    handlerform,
    handleEdit,
    deleteFile,
    ImageSelectedPrevious,
    progress,
    listFiles,
    formsValue,
    acceptedFiles,
    isDragActive,
    loading,
    getInputProps,
    getRootProps,
  };
};
