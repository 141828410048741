import Swal from "sweetalert2";
import connectNetwork from "../helpers/connectNetwork";
import { configApi, urlApi } from "../helpers/env";
import { types } from "../types/types";

export const getUserExperience = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getOrganizationByIds`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setExperience(response.organizations));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getMyFriendList = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getMyFriendList`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setUsersList(response.docs));
      }
    } catch (error) {}
  };
};

export const membersOrganization = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getUsersByIds`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch({ type: types.users, payload: response.users });
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getAllUsers = (data) => {
  return async (dispatch) => {
    dispatch(setAllUserLoading());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getAllUsers`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setAllUsers(response.docs));
      }
    } catch (error) {}
  };
};

export const getUserSuggestions = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/usersSuggestion`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getUserSuggestion(response.docs));
      } else {
      }
    } catch (error) {}
  };
};

export const setExperience = (datos) => {
  return {
    type: types.usersExperience,
    payload: datos,
  };
};

export const setUsersList = (data) => {
  return {
    type: types.users,
    payload: data.map((user) => ({
      label: user.userName,
      value: user.userId,
      image: user.photoUrl,
    })),
  };
};

export const setAllUserLoading = () => {
  return {
    type: types.getAllUserLoading,
  };
};

export const setAllUsers = (datos) => {
  return {
    type: types.allUserResponse,
    payload: datos,
  };
};

export const setQuerySearch = (datos) => {
  return {
    type: types.getQuerySearch,
    payload: datos,
  };
};

export const getUserSuggestion = (data) => {
  return {
    type: types.userSuggestions,
    payload: data,
  };
};
