import React from "react";
import Header from "../components/headers/Header";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { AllProjects } from "../components/project/all/AllProjects";

const ProjectsPage = () => {
  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <AllProjects />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popupchat />
      <Appfooter />
    </>
  );
};

export default ProjectsPage;
