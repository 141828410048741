import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createOrganization, setOrganizationReset } from "actions/organization";
import uploadFile from "helpers/uploadFile";
import { useForm } from "../userForms";
import { firebase } from "firebase/config";
import { closeLoading, successLoading } from "helpers/loadingCreate";

export const useCreateOrganization = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { uid: userId, name } = useSelector((state) => state.auth);
  const { organizationCreateOrUpdateFinished, loading } = useSelector(
    (state) => state.organization
  );
  const [values, handleInputChange, reset] = useForm({
    name: "",
    mission: "",
    vision: "",
    logoUrl: "",
    description: "",
    slogan: "",
    active: true,
  });
  const [isUpdate, setisUpdate] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  const [logoSelectedPrevious, setlogoSelectedPrevious] = useState(null);
  const [logoSelectedFile, setlogoSelectedFile] = useState(null);

  const changeImage = (e, type) => {
    if (e.target.files[0] !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      setlogoSelectedFile(e.target.files[0]);
      reader.onload = (e) => {
        e.preventDefault();
        setlogoSelectedPrevious(e.target.result);
      };
    }
  };

  const handleCreateOrganization = async (e)  => {
    e.preventDefault();
    setisUpdate(true);
    successLoading("Creating organization please wait...");

    const uid = firebase.firestore().collection("organizations").doc().id;
    const createDate = new Date().toLocaleString();
    const lastUpdateDate = createDate;
    const data = {
      userId,
      ...values,
      lastUpdateDate,
      createDate,
    };
    let logoUrl = "";
    if (logoSelectedFile) {
      logoUrl = await uploadFile(
        logoSelectedFile,
        `/organizations/${uid}/logo.jpg`,
        setProgress,
        setError
      );
    }
    dispatch(createOrganization({ uid, ...data, logoUrl }));
  };

  useEffect(() => {
    if (organizationCreateOrUpdateFinished) {
      reset();
      setisUpdate(false);
      closeLoading();
      setlogoSelectedFile(null);
      setlogoSelectedPrevious(null);
      dispatch(setOrganizationReset());
      const firstName = name?.replaceAll(" ", "_");
      history.push(
        `/PROFILE/${firstName}/${userId}/organization/my-organization`
      );
    }
  }, [organizationCreateOrUpdateFinished]);

  return {
    handleCreateOrganization,
    changeImage,
    logoSelectedPrevious,
    values,
    handleInputChange,
    loading,
    isUpdate,
  };
};
