// import { ref, deleteObject } from "firebase/storage";
// import { firebase_storage } from "../firebase/config";

// const removeFileStorage = async (url) => {
//   try {
//     const usersCollection = ref(firebase_storage, url);
//     // await firebase_storage.storage().refFromURL(url).delete();
//     await deleteObject(usersCollection);
//     return true;
//   } catch (error) {
//     return false;
//   }
// };

// export default removeFileStorage;

import { firebase } from "../firebase/config";

const removeFileStorage = async (url) => {
  try {
    await firebase.storage().refFromURL(url).delete();
    return true;
  } catch (error) {
    return false;
  }
};

export default removeFileStorage;
