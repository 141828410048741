import React from 'react'
import ContentLoader from 'react-content-loader'

export const SuggestionProjectAndCourseLoading = props => {
  return (
    <ContentLoader
      height={350}
      width={320}
      viewBox="0 0 320 350"
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
      {...props}
    >
      <circle cx="60" cy="40" r="30" />
      <rect x="100" y="14" rx="3" ry="3" width="160" height="13" />

      <rect x="100" y="30" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="30" rx="3" ry="3" width="74" height="10" />

      <rect x="100" y="45" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="45" rx="3" ry="3" width="74" height="10" />

      <rect x="100" y="60" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="60" rx="3" ry="3" width="74" height="10" />


      <circle cx="285" cy="27" r="8" />


      <circle cx="60" cy="120" r="30" />
      <rect x="100" y="94" rx="3" ry="3" width="160" height="13" />

      <rect x="100" y="110" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="110" rx="3" ry="3" width="74" height="10" />

      <rect x="100" y="125" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="125" rx="3" ry="3" width="74" height="10" />

      <rect x="100" y="140" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="140" rx="3" ry="3" width="74" height="10" />


      <circle cx="285" cy="110" r="8" />


      <circle cx="60" cy="200" r="30" />
      <rect x="100" y="175" rx="3" ry="3" width="160" height="13" />

      <rect x="100" y="190" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="190" rx="3" ry="3" width="74" height="10" />

      <rect x="100" y="205" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="205" rx="3" ry="3" width="74" height="10" />

      <rect x="100" y="220" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="220" rx="3" ry="3" width="74" height="10" />


      <circle cx="285" cy="193" r="8" />

    </ContentLoader>
  )
}



