import { types } from "../types/types";
import Swal from "sweetalert2";
import { configApi, urlApi, urlApiDev } from "../helpers/env";
import connectNetwork from "../helpers/connectNetwork";

export const createOrganization = (data) => {
  return async (dispatch) => {
    dispatch(getInitialLoadingOrganization());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/createOrganization`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch(setCreateOrganization(response.organization));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateOrganizationById = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateOrganizationById`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch(setUpdateOrganization(response.organization));
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getOrganizations = (userId, data) => {
  return async (dispatch) => {
    dispatch(getInitialLoadingOrganization());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getOrganizationByUserId`, config);
      let response = await resp.json();

      if (response.ok) {
        const docs = {
          userData: userId,
          ...response.docs,
        };
        dispatch(getMyOrganizations(docs));
      }

      return response;
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
      return false;
    }
  };
};

export const getAllOrganizations = () => {
  return async (dispatch) => {
    dispatch(getInitialLoadingOrganization());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi();
      let resp = await fetch(`${urlApi}/getOrganizations`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch({
          type: types.getAllOrganization,
          payload: response.organizations.map((org) => ({
            label: org.name,
            value: org.uid,
            image: org.logoUrl,
          })),
        });
      }

      return response;
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
      return false;
    }
  };
};

export const getOrganizationById = (data) => {
  return async (dispatch) => {
    dispatch(getInitialLoadingOrganization());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getOrganizationById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setOrgnanizationById(response.organization));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getInitialLoadingOrganization = () => {
  return {
    type: types.loadingOrganization,
  };
};

export const setCreateOrganization = (datos) => {
  return {
    type: types.createOrganization,
    payload: datos,
  };
};

export const getMyOrganizations = (datos) => {
  return {
    type: types.getMyOrganization,
    payload: datos,
  };
};

export const getLoadingOrganizationRefresh = (datos) => {
  return {
    type: types.loadingOrganizationNavigationRefresh,
    payload: datos,
  };
};

export const removeTempOrganization = () => {
  return {
    type: types.removeTempOrganization,
  };
};

export const getIsChangeMyOrganization = () => {
  return {
    type: types.isChangeMyOrganization,
  };
};

export const setOrgnanizationById = (data) => {
  return {
    type: types.tempOrganization,
    payload: data,
  };
};

export const setOrgnanizationId = (uid) => {
  return {
    type: types.tempOrganizationLoad,
    payload: uid,
  };
};

export const setUpdateOrganization = (data) => {
  return {
    type: types.updateOrganization,
    payload: data,
  };
};

export const setOrganizationReset = () => {
  return {
    type: types.removeTempOrganization,
  };
};
