import React from "react";
import PropTypes from "prop-types";

import { Route } from "react-router-dom";
import { ConfigInformations } from "../options/ConfigInformations";
import { OrganizationRouteView } from "components/organization/OrganizationRouteView";
import { Groups } from "components/group/groups";
import { Courses } from "../../course/Courses";
import { FormEditUser } from "../options/FormEditUser";
import { UserPageInformationItem } from "../components/UserPageInformationItem";
import { Projects } from "../../project/Projects";
import { MyFriends } from "../options/MyFriends";

export const RouterUserPage = ({ routeProfile }) => {
  return (
    <div>
      <Route exact path={`${routeProfile}/`}>
        <UserPageInformationItem />
      </Route>
      <Route path={`${routeProfile}/edit`} component={FormEditUser}></Route>

      <Route
        path={`${routeProfile}/config`}
        component={ConfigInformations}
      ></Route>

      <Route path={`${routeProfile}/Friends`} component={MyFriends}></Route>

      <Route path={`${routeProfile}/projects`}>
        <Projects routeProfile={routeProfile} />
      </Route>

      <Route path={`${routeProfile}/organization`}>
        <OrganizationRouteView routeProfile={routeProfile} />
      </Route>

      <Route path={`${routeProfile}/groups`}>
        <Groups routeProfile={routeProfile} />
      </Route>

      <Route path={`${routeProfile}/courses`}>
        <Courses routeProfile={routeProfile} />
      </Route>
    </div>
  );
};

RouterUserPage.propTypes = {
  routeProfile: PropTypes.string.isRequired,
};
