import React from "react";

export const ViewAboutGroup = ({ tempGroup }) => {
  return (
      <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
        <div className="card-body d-block p-4">
          <h4 className="fw-700 mb-3 font-xsss text-grey-900">About</h4>
          <div className="card-body border-top-xs d-flex">
            <i className={`feather-file-text text-grey-500 me-3 font-lg`}></i>
            <h4 className="fw-700 text-grey-900 font-xssss mt-0">
              Description
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                {tempGroup?.description}
              </span>
            </h4>
          </div>
          <div className="card-body border-top-xs d-flex">
            <i className={`feather-lock text-grey-500 me-3 font-lg`}></i>
            <h4 className="fw-700 text-grey-900 font-xssss mt-0">
              Group
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                Private
              </span>
            </h4>
          </div>
        </div>
      </div>
  );
};
