import React from 'react'
import { Members } from '../members/Members';
import PropTypes from 'prop-types'

export const AddMembers = ({ isEdit, values, handleInputChange }) => {
    return (
        <div style={{ marginBottom: 100, padding: 15 }}>
            <label className="mont-font fw-600 font-xsss" htmlFor="Members">Select members (optional)</label>
             <Members
                 isEdit={ isEdit }
                 values={ values }
                 onChange={ handleInputChange }>
             </Members>
        </div>
    )
}

AddMembers.propTypes = {
    handleInputChange: PropTypes.func.isRequired
}
