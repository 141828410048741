import React from 'react'
import ContentLoader from 'react-content-loader'

export const LoadingNotification = props => {
  return (
    <ContentLoader
      speed={2}
      width={ 900 }
      height={400}
      viewBox="0 0 900 400"
      backgroundColor="#d9d9d9"
      foregroundColor="#ededed"
      {...props}
    >
      <rect x="70" y="15" rx="4" ry="4" width="85%" height="25" />
      <rect x="15" y="6" rx="4" ry="4" width="45" height="45" />
      <rect x="70" y="65" rx="4" ry="4" width="65%" height="25" />
      <rect x="15" y="55" rx="4" ry="4" width="45" height="45" />
      <rect x="70" y="115" rx="4" ry="4" width="85%" height="25" />
      <rect x="15" y="104" rx="4" ry="4" width="45" height="45" />

      <rect x="70" y="161" rx="4" ry="4" width="65%" height="25" />
      <rect x="15" y="153" rx="4" ry="4" width="45" height="45" />
      <rect x="70" y="210" rx="4" ry="4" width="85%" height="25" />
      <rect x="15" y="202" rx="4" ry="4" width="45" height="45" />
      <rect x="70" y="260" rx="4" ry="4" width="65%" height="25" />
      <rect x="15" y="251" rx="4" ry="4" width="45" height="45" />

    </ContentLoader>
  )
}

