import { types } from "../types/types";
import Swal from "sweetalert2";
import { configApi, urlApi, urlApiDev } from "../helpers/env";
import { setPage403 } from "./page403";
import connectNetwork from "../helpers/connectNetwork";

export const createGroup = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/createGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setCreateGroup(response.group));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateCreateGroup = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateCreateGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setUpdateGroup(response.group));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getGroupsById = (userId, data) => {
  return async (dispatch) => {
    dispatch(getInitialLoadingGroup());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getGroupsById`, config);
      let response = await resp.json();

      if (response.ok) {
        const docs = {
          userData: userId,
          ...response.docs,
        };
        dispatch(getGroups(docs));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getGroupsByIdParticipate = (userId, data) => {
  return async (dispatch) => {
    dispatch(getInitialLoadingGroup());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getGroupsParticipateById`, config);
      let response = await resp.json();

      if (response.ok) {
        const docs = {
          userData: userId,
          ...response.docs,
        };
        dispatch(setGroupsParticipate(docs));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getGroupsSuggestion = () => {
  return async (dispatch) => {
    dispatch(getInitialLoadingGroup());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi();
      let resp = await fetch(`${urlApi}/getPostGroupSuggestion`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch(getGroupsSuggestionList(response.docs));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getGroupById = (data) => {
  return async (dispatch) => {
    dispatch(getInitialLoadingGroup());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getGroupById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setGroupById(response.group));
      }

      if (resp.status == 403) {
        dispatch(setPage403());
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const inviteMembersGroup = (data) => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "warning",
    text: "Espere por favor...",
  });

  Swal.showLoading();
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/requestToJoinGroup`, config);
      let response = await resp.json();

      if (response.ok) {
        Swal.close();
        Swal.fire("Ok", "Members invited successfully", "success");
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const notificationGroupResponse = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/notificationGroupResponse`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(
          getGroupMembersById({
            userId: data?.userId,
            groupId: data?.groupId,
          })
        );
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getSizeMembers = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getSizeMembers`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(AllLenghtMembers(response.size));
      }
    } catch (error) {}
  };
};

export const getGroupMembersList = (data) => {
  return async (dispatch) => {
    dispatch(LoadingMembersGroup(true));
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getGroupMembersList`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(LoadingMembersGroup(false));
        dispatch(AllMembers(response.groupMembers));
      } else {
      }
    } catch (error) {}
  };
};

export const getGroupMembersById = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getGroupMembersById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(userMemberExist(response.exist));
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getAllGroups = (data) => {
  return async (dispatch) => {
    dispatch(setAllGroupLoading());
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getAllGroups`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getAllGroupResponse(response.groups));
      }
    } catch (error) {}
  };
};

export const getGroupsIBelongTo = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getGroupsIBelongTo`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(
          setGroupsIBelongTo(
            response.docs.map((group) => ({
              label: group.title,
              value: group.uid,
              image: group.pageCover,
            }))
          )
        );
      }
    } catch (error) {}
  };
};

export const getInitialLoadingGroup = () => {
  return {
    type: types.loadingGroup,
  };
};

export const setCreateGroup = (datos) => {
  return {
    type: types.createGroup,
    payload: datos,
  };
};

export const setUpdateGroup = (data) => {
  return {
    type: types.updateGroup,
    payload: data,
  };
};

export const setGroupById = (data) => {
  return {
    type: types.tempGroup,
    payload: data,
  };
};

export const setGroupId = (uid) => {
  return {
    type: types.tempGroupLoad,
    payload: uid,
  };
};

export const getAllGroupResponse = (datos) => {
  return {
    type: types.allGroupResponse,
    payload: datos,
  };
};

export const getGroups = (datos) => {
  return {
    type: types.getGroups,
    payload: datos,
  };
};

export const setGroupsParticipate = (datos) => {
  return {
    type: types.getGroupsParticipate,
    payload: datos,
  };
};

export const getGroupsSuggestionList = (datos) => {
  return {
    type: types.getGroupsSuggestionList,
    payload: datos,
  };
};

export const setTempGroupReset = () => {
  return {
    type: types.removeTempGroup,
  };
};

export const userMemberExist = (datos) => {
  return {
    type: types.userMemberGroup,
    payload: datos,
  };
};

export const AllLenghtMembers = (datos) => {
  return {
    type: types.allMembersGroup,
    payload: datos,
  };
};

export const AllMembers = (datos) => {
  return {
    type: types.allMembersDataGroup,
    payload: datos,
  };
};

export const LoadingMembersGroup = (datos) => {
  return {
    type: types.loadingMembersgroup,
    payload: datos,
  };
};

export const setAllGroupLoading = () => {
  return {
    type: types.getAllGroupLoading,
  };
};

export const getIsChangeMyGroup = () => {
  return {
    type: types.isChangeMyGroup,
  };
};

export const setGroupsIBelongTo = (data) => {
  return {
    type: types.getGroupsIBelongTo,
    payload: data,
  };
};
