import React from "react";
import Header from "components/headers/Header";
import {
  ViewProjectLoadingBodyLeftMobile,
  ViewProjectLoadingBodyRightMobile,
  ViewProjectLoadingHeaderMobile,
} from "./LoadingComponents";

export const ViewProjectLoadingMobile = () => {
  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <ViewProjectLoadingHeaderMobile/>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <ViewProjectLoadingBodyLeftMobile />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                  <div className="card-body d-block p-4">   
                   <ViewProjectLoadingBodyRightMobile/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
