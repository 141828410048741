import React from 'react'
import ContentLoader from 'react-content-loader'

export const SuggestionGroupLoading = props => (
  <ContentLoader
    width={450}
    height={400}
    viewBox="0 0 450 400"
    backgroundColor="#eaeced"
    foregroundColor="#ffffff"
    {...props}
  >
    <rect x="43" y="160" rx="4" ry="4" width="231" height="9" />
    <rect x="44" y="180" rx="3" ry="3" width="119" height="6" />
    <rect x="42" y="10" rx="10" ry="10" width="240" height="140" />

    <rect x="43" y="370" rx="4" ry="4" width="231" height="9" />
    <rect x="44" y="350" rx="3" ry="3" width="119" height="6" />
    <rect x="42" y="200" rx="10" ry="10" width="240" height="140" />

  </ContentLoader>
)


