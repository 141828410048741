import React from 'react';
import ContentLoader from 'react-content-loader';


const ViewGroupLoadingHeader = () => (
  <ContentLoader
    width="100%"
    height={390}
    viewBox="0 0 100% 390"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
  >
    <rect x="4" y="0" rx="10" ry="10" width="100%" height="240" />
    <rect x="5" y="280" rx="3" ry="3" width="200" height="15" />
    <rect x="5" y="300" rx="3" ry="3" width="260" height="10" />


    <rect x="840" y="270" rx="3" ry="3" width="100" height="40" />
    <rect x="770" y="270" rx="3" ry="3" width="50" height="40" />

    <rect x="0" y="330" rx="4" ry="4" width="100%" height="17" />
    <rect x="0" y="365" rx="4" ry="4" width="100%" height="17" />
    <rect x="0" y="343" rx="4" ry="4" width="40" height="33" />
    <rect x="420" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="300" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="540" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="670" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="150" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="790" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="800" y="343" rx="4" ry="4" width="200" height="33" />
  </ContentLoader>
);

const ViewGroupLoadingBodyLeft = () => (
  <ContentLoader viewBox="0 0 400 140">
    <rect x="5" y="14" rx="2" ry="2" width="70" height="10" />
    <rect x="0" y="40" rx="2" ry="2" width="400" height="500" />
  </ContentLoader>
);

const ViewGroupLoadingBodyRight = () => {
  return (
    <ContentLoader viewBox="0 0 400 305">
      <rect x="20" y="14" rx="2" ry="2" width="140" height="20" />

      <rect x="20" y="80" rx="5" ry="5" width="64" height="63" />
      <rect x="105" y="80" rx="5" ry="5" width="250" height="12" />
      <rect x="105" y="100" rx="5" ry="5" width="180" height="12" />
      <rect x="105" y="120" rx="5" ry="5" width="125" height="12" />

      <rect x="20" y="170" rx="5" ry="5" width="64" height="63" />
      <rect x="105" y="170" rx="5" ry="5" width="250" height="12" />
      <rect x="105" y="190" rx="5" ry="5" width="180" height="12" />
      <rect x="105" y="210" rx="5" ry="5" width="125" height="12" />
    </ContentLoader>
  );
};



//  MOBILE


const ViewGroupLoadingHeaderMobile = () => {    
  return (
    <ContentLoader
      display= 'flex'
      width={ window.innerWidth }
      height={390}
    //   viewBox="0 0 100% 390"
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
    <rect x="4" y="0" rx="10" ry="10" width="100%" height="240" />
    <rect x="5" y="280" rx="3" ry="3" width="200" height="15" />
    <rect x="5" y="300" rx="3" ry="3" width="260" height="10" />


    <rect x="840" y="270" rx="3" ry="3" width="100" height="40" />
    <rect x="770" y="270" rx="3" ry="3" width="50" height="40" />

      <rect x="0" y="330" rx="4" ry="4" width="100%" height="15" />
      <rect x="0" y="355" rx="4" ry="4" width="100%" height="15" />
      <rect x="0" y="333" rx="4" ry="4" width="10" height="33" />
      <rect x="100" y="333" rx="4" ry="4" width="10" height="33" />
      <rect x="200" y="333" rx="4" ry="4" width="10" height="33" />
      <rect x="300" y="333" rx="4" ry="4" width="10" height="33" />
      <rect x="400" y="333" rx="4" ry="4" width="20" height="33" />
      <rect x="500" y="333" rx="4" ry="4" width="20" height="33" />
      <rect x="600" y="333" rx="4" ry="4" width="20" height="33" />
      <rect x="700" y="333" rx="4" ry="4" width="20" height="33" />
      <rect x="800" y="333" rx="4" ry="4" width="200" height="33" />
    </ContentLoader>
  );
};


const ViewGroupLoadingBodyLeftMobile = () => (
  <ContentLoader viewBox="0 0 400 300">
    <rect x="130" y="14" rx="2" ry="2" width="140" height="20" />
    <rect x="0" y="40" rx="2" ry="2" width="400" height="500" />
  </ContentLoader>
);

export {
  ViewGroupLoadingHeader,
  ViewGroupLoadingBodyLeft,
  ViewGroupLoadingBodyRight,
  ViewGroupLoadingBodyLeftMobile,
  ViewGroupLoadingHeaderMobile
};
