import React from 'react'

export const OrganizationInfoBsicForm = ({ values, onChange }) => {
    return (
        <div className="col-xl-12 mb-3">

        <div className="form-group ">
          <label className="mont-font fw-600 font-xsss">Name</label>
          <input type="text" name="name" value={values?.name} className="form-control" onChange={ onChange } placeholder="Name" required></input >
        </div>

        <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Description</label>
            <input type="text" name="description" value={values?.description}  className="form-control" onChange={ onChange } placeholder="description" required></input >
        </div> 

        <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Slogan</label>
            <input type="text" name="slogan" value={values?.slogan} className="form-control" onChange={ onChange } placeholder="description" required></input >
        </div> 
        

         <div className="row text-center">
              <div className="col-md-6 ">
              <label className="mont-font fw-600 font-xsss">Mission</label>
                  <div className="form-group">
                      <textarea type="text" value={values?.mission} className="form-control"  name="mission" onChange={ onChange } placeholder="# Mission...!" style={{height: 400}}></textarea>
                  </div>
              </div>
              <div className="col-md-6 ">
              <label className="mont-font fw-600 font-xsss">Vision</label>
                  <div className="form-group">
                      <textarea type="text" value={values?.vision} className="form-control"  name="vision" onChange={ onChange } placeholder="# Vision...!" style={{height: 400}}></textarea>
                  </div>
              </div>
          </div>  

     </div>
    )
}
