import { types } from "../types/types";

const inicialState = {
  loading: true,
  postsGroupLoad: {
    groupData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  tempPost: null,
  isCreate: {
    state: false,
    uidCreate: null,
  },
  isUpdate: {
    state: false,
    uidUpdate: null,
  },
  isRemove: {
    state: false,
    uidRemove: null,
  },
  isLike: {
    uid: null,
    state: false,
  },
  loadingPostgroup: false,
};

export const postsGroupReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.getPostsGroup:
      let hashpostsGroupLoad = {};
      let isLoadedData =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getPostsGroupIsCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;

      return {
        ...state,
        postsGroupLoad: isLoadedData
          ? state.postsGroupLoad
            ? {
                groupData: action.payload?.groupData,
                docs: [
                  ...state.postsGroupLoad?.docs,
                  ...action.payload?.docs,
                ].filter((o) =>
                  hashpostsGroupLoad[o.uid]
                    ? false
                    : (hashpostsGroupLoad[o.uid] = true)
                ),
                isCollectionEmpty: getPostsGroupIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              groupData: state.postsGroupLoad?.groupData,
              docs: state.postsGroupLoad?.docs,
              isCollectionEmpty: getPostsGroupIsCollectionEmpty,
              lastInResponse: state.postsGroupLoad?.lastInResponse,
            },
        loading: false,
      };

    case types.getLikePostGroup:
      state.postsGroupLoad.docs.length > 0 &&
        state.postsGroupLoad.docs.splice(
          state.postsGroupLoad.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1,
          action.payload
        );

      return {
        ...state,
        isUpdate: {
          state: true,
          uidUpdate: action.payload.uid,
        },
        tempPost: action.payload 
      };

    case types.getConformLikePostGroup:
      return {
        ...state,
        isUpdate: {
          state: false,
          uidUpdate: null,
        },
      };

    case types.isChangePostsGroup:
      return {
        ...state,
        postsGroupLoad: {
          groupData: null,
          docs: [],
          isCollectionEmpty: -1,
          lastInResponse: null,
        },
      };

    case types.tempPost:
      return {
        ...state,
        tempPost: action.payload,
      };

    case types.isCreateReset:
      return {
        ...state,
        isCreate: {
          state: false,
          uidCreate: null,
        },
      };

    case types.isUpdateReset:
      return {
        ...state,
        isUpdate: {
          state: false,
          uidUpdate: null,
        },
      };

    case types.isRemoveReset:
      return {
        ...state,
        isRemove: {
          state: false,
          uidRemove: null,
        },
      };

    case types.createPostGroup:
      return {
        ...state,
        isCreate: {
          state: true,
          uidCreate: action.payload?.uid,
        },
        postsGroupLoad: state.postsGroupLoad && {
          lastInResponse: state.postsGroupLoad.lastInResponse,
          isCollectionEmpty: state.postsGroupLoad.isCollectionEmpty,
          docs: [action.payload, ...state.postsGroupLoad.docs],
        },
      };

    case types.updatePostGroup:
      state.postsGroupLoad &&
        state.postsGroupLoad.docs.splice(
          state.postsGroupLoad.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1,
          action.payload
        );

      return {
        ...state,
        isUpdate: {
          state: true,
          uidUpdate: action.payload?.uid,
        },
      };

    case types.removePostGroup:
      state.postsGroupLoad &&
        state.postsGroupLoad.docs.splice(
          state.postsGroupLoad.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1
        );

      return {
        ...state,
        isRemove: {
          state: true,
          uidRemove: action.payload?.uid,
        },
      };

    case types.loadingPostgroup:
      return {
        ...state,
        loadingPostgroup: action.payload,
      };

    default:
      return state;
  }
};
