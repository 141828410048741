import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getInitialLoadingOrganization,
  getLoadingOrganizationRefresh,
  getOrganizationById,
  setOrganizationReset,
  setOrgnanizationId,
  updateOrganizationById,
} from "actions/organization";
import uploadFile from "helpers/uploadFile";
import { closeLoading, successLoading } from "helpers/loadingCreate";

export const useEditOrganization = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: organizationVisited } = useParams();

  const { uid: userId, name } = useSelector((state) => state.auth);
  const {
    organizationCreateOrUpdateFinished,
    tempOrganization,
    organizationLoad,
    loading,
  } = useSelector((state) => state.organization);

  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  const initialForms = {
    name: "",
    mission: "",
    vision: "",
    description: "",
    slogan: "",
    members: [],
    active: true,
  };

  const [values, setValues] = useState(initialForms);
  const [logoSelectedPrevious, setlogoSelectedPrevious] = useState(null);
  const [logoSelectedFile, setlogoSelectedFile] = useState(null);

  useEffect(() => {
    if (
      organizationVisited &&
      organizationLoad?.docs.length > 0 &&
      !tempOrganization
    ) {
      dispatch(setOrgnanizationId({ uid: organizationVisited }));
    } else if (
      organizationVisited &&
      organizationLoad?.docs.length <= 0 &&
      !tempOrganization
    ) {
      dispatch(getOrganizationById({ uid: organizationVisited }));
    }
  }, [organizationVisited, organizationLoad?.docs, tempOrganization]);

  useEffect(() => {
    if (!tempOrganization && organizationLoad.length > 0) {
      const initialForms = organizationLoad.find(
        (org) => org.uid == organizationVisited
      );
      dispatch(getLoadingOrganizationRefresh(initialForms));
    } else if (!tempOrganization && organizationLoad.length <= 0) {
      dispatch(getOrganizationById({ uid: organizationVisited }));
    }
  }, []);

  useEffect(() => {
    if (tempOrganization) {
      setValues(tempOrganization?.doc);
      setlogoSelectedPrevious(tempOrganization?.doc?.logoUrl);
    }
  }, [tempOrganization]);

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const reset = () => {
    setValues(initialForms);
  };

  const changeImage = (e) => {
    if (e.target.files[0] !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      setlogoSelectedFile(e.target.files[0]);
      reader.onload = (e) => {
        e.preventDefault();
        setlogoSelectedPrevious(e.target.result);
      };
    }
  };

  const handleUpdateOrganization = async (e) => {
    e.preventDefault();
    // dispatch(getInitialLoadingOrganization());
    successLoading("Update organization please wait...");

    const lastUpdateDate = new Date().toLocaleString();

    if (!logoSelectedFile) {
      const data = {
        uid: organizationVisited,
        lastUpdateDate,
        ...values,
      };
      dispatch(updateOrganizationById(data));
    } else {
      uploadFile(
        logoSelectedFile,
        `/organizations/${tempOrganization?.uid}/logo.jpg`,
        setProgress,
        setError
      ).then((logoUrl) => {
        dispatch(
          updateOrganizationById({
            uid: organizationVisited,
            lastUpdateDate,
            ...values,
            logoUrl,
          })
        );
      });
    }
  };

  useEffect(() => {
    if (organizationCreateOrUpdateFinished) {
      reset();
      closeLoading();
      setlogoSelectedFile(null);
      setlogoSelectedPrevious(null);
      dispatch(setOrganizationReset());
      const firstName = name?.replaceAll(" ", "_");
      history.push(
        `/PROFILE/${firstName}/${userId}/organization/my-organization`
      );
    }
  }, [organizationCreateOrUpdateFinished]);

  return {
    handleUpdateOrganization,
    changeImage,
    logoSelectedPrevious,
    values,
    handleInputChange,
    loading,
  };
};
