import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useMediaQuery } from "react-responsive";

export const ViewGroupMenuItem = ({ uid }) => {

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });


  return (
    <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
      <ul
        className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
        id="pills-tab"
        role="tablist">
        <li className="list-inline-item me-5">
          <NavLink
            exact
            to={`/ViewGroup/${uid}`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab" >
            Information
          </NavLink>
        </li>
        <li className="list-inline-item me-5">
          <NavLink
            to={`/ViewGroup/${uid}/members`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab" >
            Members
          </NavLink>
        </li>

        {
            isTabletOrMobile &&
            <li className="list-inline-item me-5">
                <NavLink
                    to={`/ViewGroup/${uid}/about`}
                    activeClassName="active"
                    className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
                    data-toggle="tab" >
                    About
                </NavLink>
            </li>
        }
      </ul>
    </div>
  );
};
