import React from "react";
import { BtnOptionMenuComment } from "./components/BtnOptionMenuComment";

export const CommentPostViewItem = ({
  index,
  loading,
  userId,
  value,
  type,
  groupId,
}) => {
  return (
    <>
      {!loading && (
        <>
          <div className="card w-100 shadow-xss rounded-xxl border-0 p-2">
            <div className="d-flex">
              <figure className="avatar me-3">
                <img
                  src={value.publicUser.photoUrl}
                  alt="avater"
                  className="shadow-sm rounded-circle w45"
                />
              </figure>
              <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                {value.publicUser.userName}{" "}
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {value.createDate}
                </span>
              </h4>
              {userId == value.userId && (
                <div className="ms-auto">
                  <BtnOptionMenuComment
                    data={value}
                    index={index}
                    type={type}
                    groupId={groupId}
                  />
                </div>
              )}
            </div>
            <div className="boxDialog pl-4">
              <p>{value.content}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
