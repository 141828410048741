import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  acceptInvitationFriend,
  addFriend,
  cancelFriend,
  removeFriend,
  toBlockFriend,
  unlockFriend,
} from "actions/profile";
import { stateFriend } from "helpers/stateFriend";

export const useBtnAddFriends = () => {
  const dispatch = useDispatch();
  const { id: userToVisited } = useParams();
  const { tempFriendInvitation } = useSelector((state) => state.friend);
  const { uid: userIdActive } = useSelector((state) => state.auth);

  const [isFriend, setIsFriend] = useState(tempFriendInvitation?.state);
  const [friendInvitation, setFriendInvitation] = useState(tempFriendInvitation);
  const [i_invite, setI_invite] = useState(false);
  const [loading, setLoading] = useState(false);


  
  const onChangeAddFriend = () => {
    setIsFriend(stateFriend.loading.description);
    const data = {
      userFromId: userIdActive,
      userToId: userToVisited,
      state: stateFriend.pending.description,
      createAt: new Date().toLocaleString(),
      updateAt: new Date().toLocaleString(),
      data: {
        uid: "",
        type: "friendRequest",
        userFromId: userIdActive,
        params: {
          userToId: userToVisited,
        },
        notification: "",
        state: false,
        createDate: new Date().toLocaleString(),
      },
    };
    dispatch(addFriend(data));
  };

  const onChangeAcceptInvitationFriend = () => {
    if (tempFriendInvitation) {
      setIsFriend(stateFriend.loading.description);
      const data = {
        uid: tempFriendInvitation?.uid,
        state: stateFriend.accepted.description,
        users: [
          tempFriendInvitation?.userFromId,
          tempFriendInvitation?.userToId,
        ],
        updateAt: new Date().toLocaleString(),
        data: {
          uid: "",
          type: "friendAcceptRequest",
          userFromId: userIdActive,
          params: {
            userToId: userToVisited,
          },
          notification: "",
          state: false,
          createDate: new Date().toLocaleString(),
        },
      };
      dispatch(acceptInvitationFriend(data));
    }
  };

  const onChangeCancelFriend = () => {
    if (tempFriendInvitation) {
      setIsFriend(stateFriend.loading.description);
      const data = {
        uid: tempFriendInvitation?.uid,
      };
      dispatch(cancelFriend(data));
    }
  };

  const onChangeRemoveFriend = () => {
    if (tempFriendInvitation) {
      setIsFriend(stateFriend.loading.description);
      const data = {
        uid: tempFriendInvitation?.uid,
        users: [
          tempFriendInvitation?.userFromId,
          tempFriendInvitation?.userToId,
        ],
      };
      dispatch(removeFriend(data));
    }
  };

  const onChangeLockedFriend = () => {
    if (tempFriendInvitation) {
      setIsFriend(stateFriend.loading.description);
      const data = {
        uid: tempFriendInvitation?.uid,
        state: stateFriend.locked.description,
        stateLockedId: userIdActive,
        updateAt: new Date().toLocaleString(),
      };
      dispatch(toBlockFriend(data));
    }
  };

  const onChangeUnlockFriend = () => {
    if (tempFriendInvitation) {
      setIsFriend(stateFriend.loading.description);
      const data = {
        uid: tempFriendInvitation?.uid,
        stateLockedId: "",
        state: stateFriend.accepted.description,
        updateAt: new Date().toLocaleString(),
      };
      dispatch(unlockFriend(data));
    }
  };

  useEffect(() => {
    setLoading(true);
    if (tempFriendInvitation) {
      setIsFriend(tempFriendInvitation?.state);
      setFriendInvitation(tempFriendInvitation);
      setI_invite(tempFriendInvitation?.userFromId == userIdActive);
      setLoading(false);
    }

   
  }, [tempFriendInvitation]);

  

  return {
    loading,
    isFriend,
    i_invite,
    uid: userIdActive,
    friendInvitation,
    onChangeAddFriend,
    onChangeRemoveFriend,
    onChangeCancelFriend,
    onChangeLockedFriend,
    onChangeUnlockFriend,
    onChangeAcceptInvitationFriend,
  };
};
