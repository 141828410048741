import React from 'react'
import ContentLoader from 'react-content-loader'

export const LoadingCreate = () => {
    return (
    <ContentLoader
        backgroundColor="#f0f0f0"
        foregroundColor="#dedede"
        viewBox="0 0 1000 575" 
        height={575} width="100%">
           
        <rect x="0" y="0" rx="4" ry="4" width="1000" height="50" />
        <rect x="0" y="80" rx="4" ry="4" width="1000" height="50" />
        <rect x="0" y="160" rx="5" ry="5" width="1000" height="300" />
    </ContentLoader>
    )
}
