import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { imageExistsProduct } from "helpers/verifyImg";
;

export const CardListMyOrganizationStyle = ({ logoUrl, title, description, uid ,  isProprietor  = true}) => {
    return (
             <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                <div className="card-body d-block w-100 p-2 text-center">
                    <figure > <img src={ logoUrl } onError={imageExistsProduct} style={{objectFit: 'cover'}} alt="avater" className="float-right pb-1 bg-white "height="200" width="100%" /></figure>
                    <div className="clearfix"></div>
                    <h4 className="fw-700 font-xss mt-3 mb-0">{ title } </h4>
                    <h2 className="font-xsssss fw-500 mt-1 text-grey-500 d-block">{description} </h2>
                    <ul className="d-flex align-items-center justify-content-center mt-1">
                        {
                            isProprietor && (
                                <Link to={`/EditOrganization/${uid}`}>
                                <i  className="cursor-pointer font-sm ti-pencil-alt  text-grey-500"><span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">Edit</span></i>
                                </Link>
                            )
                        }
                        
                    </ul>
                    <Link to={ `/ViewOrganization/${ uid }` }>
                    <i  className="mt-4 p-0 btn p-2 lh-24 w200 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">View organization</i>
                    </Link>
                </div>
            </div>
        )
}
