import { format } from "date-fns";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createPost } from "actions/post";
import { postType } from "helpers/typeEvent";

export const useGroupShare = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { uid: userIdActive } = useSelector((state) => state.auth);
  const { tempGroup } = useSelector((state) => state.group);
  const [type, setType] = useState('');

  const data = [
    // {
    //   label: "Groups",
    //   value: 1,
    //   image: "",
    // },
    {
      label: "Friends",
      value: 2,
      image: "",
    },
  ];

  const onChangeFriendsShare = ({ target }) => {
    setType(target.value.label)
  };

  const handleShare = () => {
    if(type != ''){
      const date = format(new Date(), "dd/MM/yyyy HH:mm:ss" )
      const post = {
          content: tempGroup?.title,
          userId: userIdActive,
          attachments: tempGroup?.pageCover,
          comment: "",
          originalPostId: "",
          isShared: false,
          createDate: date,
          lastUpdateDate: date,
          active: true,
          createTime: new Date().valueOf(),
          type: postType.group.valueOf(),
          linkTo: `/ViewGroup/${id}`
        };
        dispatch(createPost(post));
    }
  };

  return {
    dataType: data,
    onChangeFriendsShare,
    handleShare
  };
};
