import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthRoutes } from "./AuthRoutes";
import { getUser, login } from "../actions/auth";
import { PrivateRoute } from "../routes/PrivateRoute";
import { PublicRoute } from "../routes/PublicRoute";
import { HomeRoutes } from "./HomeRoutes";
import { LogoDealLoading } from "../components/shared/LogoDealLoading";
import { firebase } from "../firebase/config";
import { useGetToken } from "../Hooks/notifcations/useGetToken";
import { ConnectUser } from "../helpers/chat";
import { MyFriendsUid } from "../actions/profile";
import connectNetwork from "../helpers/connectNetwork";
import { networkStatusAlert } from "../helpers/loadingCreate";
import '../css/networkStatus.css';
import { caching } from "helpers/deleteCache";
import { IsAdmin } from "actions/admins";

export const AppRoutes = () => {
  const dispatch = useDispatch();
  const [ckegking, setckegking] = useState(true);
  const [onLineConnect, setOnLineConnect] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const { getTken } = useGetToken();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user?.uid) {
        dispatch(login(user.uid, user.displayName, user.photoURL));
        ConnectUser(user?.uid, user?.displayName, user?.photoURL);
        dispatch(MyFriendsUid({ userId: user?.uid }));
        dispatch(IsAdmin({ uid: user?.uid }));
        setisLoggedIn(true);
        getTken(user.uid);
      } else {
        setisLoggedIn(false);
      }
      setckegking(false);
    });
  }, [dispatch, setckegking, setisLoggedIn]);

  useEffect(() => {
      navigator.connection.addEventListener('change', logNetworkInfo); 
  }, [navigator.onLine])

  const logNetworkInfo = async () => {
    const isOnline = await connectNetwork();
    if(!isOnline && !onLineConnect){
     setOnLineConnect(true);
     networkStatusAlert('Conexión perdida.', 'offline', false);
    }else if(isOnline && onLineConnect){
     setOnLineConnect(false);
     networkStatusAlert('Conexión restablecida.', 'online', true);
    }
  }

  useEffect(() => {
    caching()
  }, [])
  
  

  if (ckegking) {
    return <LogoDealLoading />;
  }


  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            exat
            isAuthenticated={isLoggedIn}
            path={`/auth`}
            component={AuthRoutes}
          />

          <PrivateRoute
            isAuthenticated={isLoggedIn}
            path="/"
            component={HomeRoutes}
          />

          <Redirect to={`/auth/login`} />
        </Switch>
      </div>
    </Router>
  );
};
