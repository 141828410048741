import React from 'react'
import { useBtnOptionMenu } from 'Hooks/useBtnOptionMenu';
import { EditPost } from './EditPost';
import { RemovePost } from './RemovePost';
import '../../css/postOptions.css';

export const BtnOptionMenu = ({ data, groupId }) => {

    const { openModal, notiClass, wrapperRef } = useBtnOptionMenu();

    return (
        <div ref={ wrapperRef } className="ms-auto">
            <div id="dropdownMenu4" className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss pointer" data-toggle="dropdownMenu4" aria-haspopup="true" onClick={ openModal } aria-expanded="false"></div>

            <div className={`dropdown-menu p-3 rounded-xxl border-0 ms-auto mt-4 contentModal shadow-sm  ${notiClass}`}  aria-labelledby="dropdownMenu3">
              <ul>
                    <EditPost data={ data } groupId={ groupId } />
                    <RemovePost data={ data } groupId={ groupId } /> 
              </ul>
            </div>

        </div>

    )
}
