import { types } from "../types/types";
import Swal from "sweetalert2";
import { configApi, urlApi, urlApiDev } from "../helpers/env";
import connectNetwork from "../helpers/connectNetwork";

export const GetGenerarReporte = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/GetGenerarReporte`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getGenerate(response.doc))
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const IsAdmin = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/IsAdmin`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(IsAdminActive(response.doc))
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};



export const getGenerate = (data) => {
    return {
      type: types.adminGetInformacionGeneral,
      payload: data,
    };
  };
  export const IsAdminActive = (data) => {
    return {
      type: types.isAdminActive,
      payload: data,
    };
  };