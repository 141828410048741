import React from "react";
import Load from "components/Load";

export const LoadExpanded = () => {
  return (
    <div style={{ height: "100%", minHeight: "100vh", paddingTop: "50%" }}>
      <Load />
    </div>
  );
};
