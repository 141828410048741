import React from "react";
import { Button, Modal, ModalFooter } from "react-bootstrap";
import { useEditComment } from "Hooks/post/useEditComment";

export const EditComment = ({ data, type, groupId, closeModal }) => {
  const { show, EditCommentPost, onChangeInput, handleShow, handleClose } =
    useEditComment(data, type, groupId, closeModal);

  return (
    <>
      <li onClick={handleShow} className="cursor-pointer d-flex">
        <div className="icon-li ">
          <i className="font-sm ti-pencil  text-grey"></i>
        </div>
        <div className="text-li">
          <span className="pb-1"> Edit comment </span>
        </div>
      </li>

      <Modal show={show} onHide={handleClose} size="lg">
        <form onSubmit={EditCommentPost}>
          <Modal.Header>
            <Modal.Title>Edit comment post </Modal.Title>
            <button
              className="btn ti-close"
              type="button"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <textarea
              type="text"
              className="form-control"
              name="comment"
              defaultValue={data?.content}
              onChange={onChangeInput}
            ></textarea>
          </Modal.Body>
          <ModalFooter>
            <Button type="submit" variant="outline-info" onClick={handleClose}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
