import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export const useProfileCardThree = () => {
  const { user, updateProfile } = useSelector((state) => state.user);

  const [profile, setProfile] = useState(user?.photoUrl);
  const [coverPage, setCoverPage] = useState(user?.photoCoverPageUrl);

  useEffect(() => {
    if (user) {
      setProfile(user?.photoUrl);
      setCoverPage(user?.photoCoverPageUrl);
    }
  }, [user]);

  useEffect(() => {
    if (updateProfile?.state) {
      if (updateProfile?.type == "coverPage") {
        setCoverPage("");
        setCoverPage(user?.photoCoverPageUrl);
      } else if (updateProfile?.type == "photoProfile") {
        setProfile("");
        setProfile(user?.photoUrl);
      }
    }
  }, [updateProfile, updateProfile?.state]);


  return {
    email: user?.email,
    ProfilePhoto: profile,
    coverPhoto: coverPage,
  };
};
