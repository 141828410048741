import { format } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGroupsIBelongTo } from "actions/group";
import { createPost, createPostGroup } from "actions/post";
import { postType } from "helpers/typeEvent";

export const useProjectShare = () => {
  const dispatch = useDispatch();

  const observer = useRef();
  const [docs, setDocs] = useState([]);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isPostListEmpty, setIsPostListEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);

  const { Id } = useParams();

  const { uid: userIdActive } = useSelector((state) => state.auth);
  const { tempProject } = useSelector((state) => state.project);
  const { groupsIBelongTo } = useSelector((state) => state.group);

  const [selectedType, setSelectedType] = useState("");
  const [selectedTypePublished, setSelectedTypePublished] = useState("");
  const [titleContent, setTitleContent] = useState(tempProject?.title);

  const data = [
    {
      label: "Groups",
      value: 1,
      image: "",
    },
    {
      label: "Friends",
      value: 2,
      image: "",
    },
  ];

  const elementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isPostListEmpty) {
          setLoadingPagination(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isPostListEmpty, lastInResponse]
  );

  useEffect(() => {
    dispatch(getGroupsIBelongTo({ userId: userIdActive }));
  }, []);

  const onChangeFriendsShare = ({ target }) => {
    setSelectedType(target.value.label);
  };

  const onChangePublishedShare = ({ target }) => {
    const group = target.value;
    setSelectedTypePublished(group.value);
  };

  const onChangeContent = ({ target }) => {
    setTitleContent(target.value);
  };

  const handleShare = () => {
    if (selectedType != "") {
      const date = format(new Date(), "dd/MM/yyyy HH:mm:ss");
      const post = {
        content: titleContent,
        userId: userIdActive,
        attachments: tempProject?.pageCover,
        comment: "",
        originalPostId: "",
        isShared: false,
        createDate: date,
        lastUpdateDate: date,
        active: true,
        createTime: new Date().valueOf(),
        type: postType.project.valueOf(),
        linkTo: `/ViewProject/${Id}`,
      };
      if (selectedType == "Friends") {
        dispatch(createPost(post));
      } else {
        dispatch(createPostGroup({ groupId: selectedTypePublished, ...post }));
      }
    }
  };

  return {
    selectedType,
    dataType: data,
    dataGroup: groupsIBelongTo,
    onChangeFriendsShare,
    onChangePublishedShare,
    handleShare,
    titleContent,
    onChangeContent,
    elementRef,
  };
};
