import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProfileNavItem } from "components/shared/ProfileNavItem";
import { useMediaQuery } from "react-responsive";

export const PageTitleProfile = ({
  name1,
  route1,
  name2,
  route2,
  title,
  titleButtom,
  route,
  isActiveButtom = true,
}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      {isDesktopOrLaptop && (
        <PageTitleProfileBrowser
          name1={name1}
          route1={route1}
          name2={name2}
          route2={route2}
          title={title}
          titleButtom={titleButtom}
          route={route}
          isActiveButtom={isActiveButtom}
        />
      )}
      {isTabletOrMobile && (
        <PageTitleProfileMobile
          name1={name1}
          route1={route1}
          name2={name2}
          route2={route2}
          title={title}
          titleButtom={titleButtom}
          route={route}
          isActiveButtom={isActiveButtom}
        />
      )}
    </>
  );
};

export const PageTitleProfileBrowser = ({
  name1,
  route1,
  name2,
  route2,
  title,
  titleButtom,
  route,
  isActiveButtom = true,
}) => {
  return (
    <div className="card border-1 w-100 d-block d-flex border-0 pl-3 pr-3 mb-3">
      <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
        {isActiveButtom && (
          <ProfileNavItem
            name1={name1}
            route1={route1}
            name2={name2}
            route2={route2}
          />
        )}
        {!isActiveButtom && title}
        {isActiveButtom && (
          <Link className="ms-auto" to={route}>
            <Button className="float-right ms-auto" variant="outline-info">
              {titleButtom}
            </Button>
          </Link>
        )}
      </h2>
    </div>
  );
};

export const PageTitleProfileMobile = ({
  name1,
  route1,
  name2,
  route2,
  title,
  titleButtom,
  route,
  isActiveButtom = true,
}) => {
  return (
    <div className="card  border-1 d-flex d-flex border-0 pt-4 mb-3">
      <h2 className=" d-flex font-md text-grey-900">
        {isActiveButtom && (
          <ProfileNavItem
            name1={name1}
            route1={route1}
            name2={name2}
            route2={route2}
          />
        )}
        {!isActiveButtom && title}
        {isActiveButtom && (
          <Link className="ms-auto mt-2" to={route}>
            <Button className="float-right ms-auto" variant="outline-info">
              <i className="feather-plus"> </i>
            </Button>
          </Link>
        )}
      </h2>
    </div>
  );
};
