import React from "react";
import { useGetForum } from "../../../Hooks/forum/useGetForum";
import { CardsCommentsForum } from "../../CardsCommentsForum";
import Load from "../../Load";
import { ModalCreatePostForum } from "../../ModalCreatePostForum";
import { EmptyData } from "../../shared/EmptyData";

export const ViewProjectForum = ({ id, existMember }) => {
  const type = "project"
  const {
    docs,
    lastPostsElementRef,
    load,
    loadingPagination,
    userId
  } = useGetForum(type);
  return (
    <>
      <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
        <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
          Comment on the project
        </h2>
      </div>
      {
         existMember == "success" &&
      <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
        <ModalCreatePostForum type={type} />
      </div>
      }
      <div>
          {
              (load && docs?.length <= 0) && <Load/>
          }
        {
            (docs?.length <= 0 && !load)&& <EmptyData pathImg="/assets/images/empty.svg" title='no publications'/>
        }

        {docs?.map((element) => {
          return (
            <div key={element.uid} ref={lastPostsElementRef}>
              <CardsCommentsForum data={element} type={type} userId={userId} />
            </div>
          );
        })}
      </div>
    </>
  );
};
