import React from 'react'
import { useBtnOptionMenu } from 'Hooks/useBtnOptionMenu';
import { ViewGroupDeleteMember } from './ViewGroupDeleteMember';
import { ViewGroupModalInviteMenbers } from './ViewGroupModalInviteMenbers';
import './../../css/view-group-options.css';

export const ViewGroupBtnOptionMenu = ({id, group, existMember, click  }) => {

    const { openModal, notiClass, wrapperRef } = useBtnOptionMenu();

    return (
        <div ref={ wrapperRef } className="ml-2">
            <a id="dropdownMenu4" className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss pointer" data-toggle="dropdownMenu4" aria-haspopup="true" onClick={ openModal } aria-expanded="false"></a>
            <div className={`dropdown-menu p-3 rounded-xxl border-0 ms-auto mt-4 content-modal-view-group shadow-lg  ${notiClass}`}  aria-labelledby="dropdownMenu3">
            <div style={{ borderBottom: '1px solid #00589b60' }}>
                <h6 className="fw-700 font-xss mb-3">Option menu</h6>
            </div>  
              <ul>
                 {id !== group?.userId && existMember === false && (
                   <li>
                      <div className=" cursor-pointer d-flex align-items-center justify-content-center pl-2 pr-3 right-15 top-0" style={{ borderBottom: '1px solid #00589b60' }}>
                      <div onClick={click}
                          className=" ms-auto d-none d-lg-block bg-success p-2 z-index-1 rounded-3 text-white font-xsss  fw-700 ls-3" >
                           Join the group
                      </div>
                      </div>
                   </li>
                  )}
                  {existMember && id !== group?.userId && (
                    <>
                        <ViewGroupModalInviteMenbers />
                        <ViewGroupDeleteMember />               
                    </>
                  )}
                  {id === group?.userId && (
                     <ViewGroupModalInviteMenbers />
                  )}

              </ul>
            </div>

        </div>

    )
}
