import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeExperienceOrganizationForProfile,
  setOrganizationExperienceToRemoveProfile,
} from "actions/profile";
import { successLoading } from "helpers/loadingCreate";

export const useViewUserExperience = () => {
  const dispatch = useDispatch();

  const { uid } = useSelector((state) => state.auth);
  const { experience } = useSelector(({ user: state }) => state.user);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [experienceIdToRemove, setExperienceIdToRemove] = useState([]);
  const [isRemove, setIsRemove] = useState(false);

  const deleteFile = (value) => {
    const { uid } = value;
    if (!experienceIdToRemove.includes(uid)) {
      setExperienceIdToRemove((ele) => ele.concat(uid));
    } else {
      const position = experienceIdToRemove.findIndex((dc) => dc.uid === uid);
      experienceIdToRemove.splice(position, 1);
    }
  };

  useEffect(() => {
    if (isRemove) {
      experienceIdToRemove.map((item) => {
        dispatch(setOrganizationExperienceToRemoveProfile(item));
      });
    }
  }, [experienceIdToRemove, isRemove]);

  const handleSubmit = (e) => {
    e.preventDefault();
    successLoading("Saving experience changes");

    setIsRemove(true);

    dispatch(
      removeExperienceOrganizationForProfile({
        uid,
        experienceIds: experienceIdToRemove,
      })
    );
  };

  return {
    handleShow,
    show,
    handleClose,
    deleteFile,
    handleSubmit,
    experience,
  };
};
