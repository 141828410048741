import React from "react";
import Load from "../Load";

import { useMyCourses } from "Hooks/course/useMyCourses";
import { EmptyData } from "../shared/EmptyData";
import { CardListCourseStyle } from "./components/CardListCourseStyle";
import { CoursesLoadingIndicator } from "./loading/CourseLoadingIndicator";
import { usePropietor } from "../../Hooks/usePropietor";
import { SearchAll2 } from "../shared/SearchAll2";

export const MyCourses = () => {
  const {
    docs,
    lastMyCourseElementRef,
    loadingLoad,
    loading,
    loadingPagination,
    searchText,
    searchResult,
    handleSearch,
    handleSearchText,
  } = useMyCourses();
  const { isPropietor } = usePropietor();

  return (
    <>
      <SearchAll2 
        placeHolder="Search courses"
        onChange={handleSearchText}
        value={searchText}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSearch(searchText);
        }}
      />
      {loading && docs?.length <= 0 && (
        <CoursesLoadingIndicator
          isPropietor={isPropietor}
          quantityElement={2}
        />
      )}
      
      {docs == null ? null : loadingLoad ? (
        <Load />
      ) : (
        <div className="row ps-2 pe-2">
          {searchResult && searchResult.length > 0 ? (
            searchResult?.map((value) => (
              <div
                key={value?.objectID}
                className="col-md-6 col-sm-6 pe-2 ps-2">
                <CardListCourseStyle
                  uid={value?.objectID}
                  title={value?.title}
                  pageCover={value?.pageCover}
                  isPropietor={false}
                />
              </div>
            ))
          ) : (!loading && docs.length === 0) ||
            (!loading && searchResult && searchResult.length === 0) ? (
            <EmptyData
              pathImg="/assets/images/NoData.svg"
              title="There are no courses"
            />
          ) : (
            docs.map((value, index) => {
              if (docs.length === index + 1) {
                return (
                  <div
                    key={value?.uid}
                    ref={lastMyCourseElementRef}
                    className="col-md-6 col-sm-6"
                  >
                    <CardListCourseStyle
                      uid={value?.uid}
                      title={value?.title}
                      pageCover={value?.pageCover}
                      isPropietor={isPropietor}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={index} className="col-md-6 col-sm-6">
                    <CardListCourseStyle
                      uid={value?.uid}
                      title={value?.title}
                      pageCover={value?.pageCover}
                      isPropietor={isPropietor}
                    />
                  </div>
                );
              }
            })
          )}
        </div>
      )}
      {loadingPagination && <Load />}
    </>
  );
};
