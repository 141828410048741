import { useDispatch, useSelector } from "react-redux";
import {
  disLikeCommentPost,
  disLikeCommentPostGroup,
  getPostsLike,
  onLikeCommentPost,
  onLikeCommentPostGroup,
  setLikePostGroup,
} from "actions/post";
import { typeEventLike, typeEventPost } from "helpers/typeEvent";

export const UsePostViewLike = (id, type, typeLike, idgroup, postUserId) => {
  const dispatch = useDispatch();
  const { posts, postsByIdLoad, tempPost } = useSelector(
    (state) => state.posts
  );
  const { postsGroupLoad } = useSelector((state) => state.postsGroup);
  const { uid: userId } = useSelector((state) => state.auth);

  const createDate = new Date().toLocaleString();
  const values = {
    idgroup,
    postId: id,
    userId,
    data: {
      uid: "",
      type: type == typeEventPost.global ? "like" : "likeGroup",
      userFromId: userId,
      createDate,
      params: {
        userToId: postUserId,
        postToId: id,
        groupToId: idgroup,
      },
      notification: "",
      state: false,
    },
  };

  const onLike = (e) => {
    e.preventDefault();
    switch (typeLike) {
      case typeEventLike.home:
        return onLikeHome();
      case typeEventLike.profile:
        return onLikeProfile();
      case typeEventLike.group:
        return onLikeGroup();
      case typeEventLike.temp:
        return onDistLikeOrLikeTemp(true);
      default:
        return onLikeHome();
    }
  };

  const onDistLike = (e) => {
    e.preventDefault();

    switch (typeLike) {
      case typeEventLike.home:
        return onDistLikeHome();
      case typeEventLike.profile:
        return onDistLikeProfile();
      case typeEventLike.group:
        return onDistLikeGroup();
      case typeEventLike.temp:
        return onDistLikeOrLikeTemp(false);
      default:
        return onDistLikeHome();
    }
  };

  const onLikeHome = () => {
    if (posts?.docs.length > 0) {
      const valueLike = posts?.docs.find((value) => value.uid == id);
      distLikeOrLikePostAndPostGroup(valueLike, true);
    }
  };

  const onLikeProfile = () => {
    if (postsByIdLoad?.docs.length > 0) {
      const valueLike = postsByIdLoad?.docs.find((value) => value.uid == id);
      distLikeOrLikePostAndPostGroup(valueLike, true);
    }
  };

  const onLikeGroup = () => {
    if (postsGroupLoad?.docs.length > 0) {
      const valueLike = postsGroupLoad?.docs.find((value) => value.uid == id);
      distLikeOrLikePostAndPostGroup(valueLike, true);
    }
  };

  // ==> DIST LIKE

  const onDistLikeHome = () => {
    if (posts?.docs.length > 0) {
      const valueLike = posts?.docs.find((value) => value.uid == id);
      distLikeOrLikePostAndPostGroup(valueLike, false);
    }
  };

  const onDistLikeProfile = () => {
    if (postsByIdLoad?.docs.length > 0) {
      const valueLike = postsByIdLoad?.docs.find((value) => value.uid == id);
      distLikeOrLikePostAndPostGroup(valueLike, false);
    }
  };

  const onDistLikeGroup = () => {
    if (postsGroupLoad?.docs.length > 0) {
      const valueLike = postsGroupLoad?.docs.find((value) => value.uid == id);
      distLikeOrLikePostAndPostGroup(valueLike, false);
    }
  };

  const onDistLikeOrLikeTemp = (itsYouLike) => {
    if (tempPost) {
      tempPost.like = {
        itsYouLike: itsYouLike,
        quantityLike: itsYouLike
          ? tempPost?.like?.quantityLike + 1
          : tempPost?.like?.quantityLike - 1,
      };
      if (itsYouLike) {
        if (type == typeEventPost.global) {
          dispatch(onLikeCommentPost(values));
          dispatch(getPostsLike(tempPost));
        } else {
          dispatch(onLikeCommentPostGroup(values));
          dispatch(setLikePostGroup(tempPost));
        }
      } else {
        if (type == typeEventPost.global) {
          dispatch(disLikeCommentPost(values));
          dispatch(getPostsLike(tempPost));
        } else {
          dispatch(disLikeCommentPostGroup(values));
          dispatch(setLikePostGroup(tempPost));
        }
      }
    }
  };

  const distLikeOrLikePostAndPostGroup = (valueLike, itsYouLike) => {
    if (valueLike) {
      valueLike.like = {
        itsYouLike: itsYouLike,
        quantityLike: itsYouLike
          ? valueLike?.like?.quantityLike + 1
          : valueLike?.like?.quantityLike - 1,
      };
      if (itsYouLike) {
        if (type == typeEventPost.global) {
          delete values["idgroup"];
          delete values?.data?.params["groupToId"];
          dispatch(onLikeCommentPost(values));
          dispatch(getPostsLike(valueLike));
        } else {
          dispatch(onLikeCommentPostGroup(values));
          dispatch(setLikePostGroup(valueLike));
        }
      } else {
        if (type == typeEventPost.global) {
          delete values["idgroup"];
          delete values?.data?.params["groupToId"];
          dispatch(disLikeCommentPost(values));
          dispatch(getPostsLike(valueLike));
        } else {
          dispatch(disLikeCommentPostGroup(values));
          dispatch(setLikePostGroup(valueLike));
        }
      }
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULT EVENT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    onDistLike,
    onLike,
  };
};
