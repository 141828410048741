import { types } from "../types/types";

const inicialState = {
  loading: false,
  organizationLoad: {
    userData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  isChange: false,
  tempOrganization: null,
  allOrganization: [],
  organizationCreateOrUpdateFinished: false,
};

export const organizationReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.loadingOrganization:
      return {
        ...state,
        loading: true,
      };

    case types.createOrganization:
      return {
        ...state,
        loading: false,
        organizationLoad: state.organizationLoad && {
          lastInResponse: state.organizationLoad.lastInResponse,
          isCollectionEmpty: state.organizationLoad.isCollectionEmpty,
          docs: [action.payload, ...state.organizationLoad.docs],
        },
        organizationCreateOrUpdateFinished: true
      };

    case types.updateOrganization:
      state.organizationLoad &&
        state.organizationLoad.docs.splice(
          state.organizationLoad.docs.findIndex(
            (org) => org.uid === action.payload?.uid
          ),
          1,
          action.payload
        );
      return {
        ...state,
        loading: false,
        organizationCreateOrUpdateFinished: true,
      };

    case types.getMyOrganization:
      let hashMyOrganizations = {};
      let isLoadedData =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getMyOrganizationIsCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;
      return {
        ...state,
        loading: false,
        organizationLoad: isLoadedData
          ? state.organizationLoad
            ? {
                userData: action.payload.userData,
                docs: [
                  ...state.organizationLoad?.docs,
                  ...action.payload?.docs,
                ].filter((o) =>
                  hashMyOrganizations[o.uid]
                    ? false
                    : (hashMyOrganizations[o.uid] = true)
                ),
                isCollectionEmpty: getMyOrganizationIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.organizationLoad?.userData,
              docs: state.organizationLoad?.docs,
              isCollectionEmpty: getMyOrganizationIsCollectionEmpty,
              lastInResponse: state.organizationLoad?.lastInResponse,
            },
      };

    case types.tempOrganization:
      return {
        ...state,
        loading: false,
        tempOrganization: action.payload,
      };

    case types.tempOrganizationLoad:
      const org = state.organizationLoad?.docs.find(
        (org) => org.uid == action.payload?.uid
      );
      return {
        ...state,
        loading: false,
        tempOrganization: { doc: org },
      };

    case types.removeTempOrganization:
      return {
        ...state,
        loading: false,
        tempOrganization: null,
        createdFinished: false,
      };

    case types.isChangeMyOrganization:
      return {
        ...state,
        organizationLoad: {
          userData: null,
          docs: [],
          isCollectionEmpty: -1,
          lastInResponse: null,
        },
      };

    case types.getAllOrganization:
      return {
        ...state,
        allOrganization: [...action.payload],
        loading: false,
      };

    default:
      return state;
  }
};
