import React from "react";
import Header from "../components/headers/Header";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { useUserPage } from "../Hooks/useUserPage";
import UserPageView from "../components/profile/UserPageView";
import { stateFriend } from "../helpers/stateFriend";
import { UserPageLocked } from "../components/profile/options/UserPageLocked";

const UserPage = () => {
  let { name, uid, photoUrl, accountFriend, userLoading } = useUserPage();
  name = name?.replaceAll(" ", "_");
  const routeProfile = `/PROFILE/${name}/${uid}`;

  return (
    <>
      <Header />

      {accountFriend?.state != stateFriend.locked.description ? (
        <UserPageView
          loading={userLoading}
          name={name}
          routeProfile={routeProfile}
          photoUrl={photoUrl}
        />
      ) : (
        <UserPageLocked />
      )}
      <Popupchat />
      <Appfooter />
    </>
  );
};

export default UserPage;
