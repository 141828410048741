import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const usePropietor = () => {
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> SELECTORS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { uid: userIdVisited } = useSelector((state) => state.navigateAuth);
  const { uid: userIdActive } = useSelector((state) => state.auth);
  const {isadmin: admin} = useSelector( state => state.admin );
  const [isPropietor, setIsPropietor] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC IS PROPIETOR
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (userIdVisited == userIdActive) {
      setIsPropietor(true);
    } else {
      setIsPropietor(false);
    }
  }, [userIdVisited, userIdActive]);


    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC IS Admin
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    isPropietor,
    admin
  };
};
