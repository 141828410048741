import React from "react";
import { CommentPostViewMobile } from "./CommentPostViewMobile";
import { imageExists403 } from "helpers/verifyImg";
import ReactPlayer from "react-player";

export const PostImageAvailableMobile = ({
  post,
  imageSelectedPrevious,
  type,
  typeLike,
  groupId,
}) => {
  return (
    <div className="main-container-mobile">
      <div className="up shadow-xs">
        <div className="container-img-mobile">
          <div className="box-mobile">
          {imageSelectedPrevious?.includes("mp4") ? (
          
          <ReactPlayer controls={true} url={imageSelectedPrevious}></ReactPlayer>
          
        ) : (
          <img
            src={imageSelectedPrevious}
            alt="Img no disponible"
            onError={imageExists403}
          ></img>
        )}
          </div>
        </div>
      </div>
      <hr />
      <div className="comment-mobile shadow-xs">
        <CommentPostViewMobile
          post={post}
          type={type}
          typeLike={typeLike}
          groupId={groupId}
        />
      </div>
    </div>
  );
};
