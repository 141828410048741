import React from "react";
import { useHistory } from "react-router-dom";
import { useSearchFriends } from "Hooks/profile/useSearchFriends";
import { AutoCompleteText } from "./AutoCompleteText";

export const SearchFriends = () => {
  const { handleChange, results, value, handleClickSearh } = useSearchFriends();
  const navigate = useHistory();

  const onSelect = ({ objectID, userName }) => {
    userName = userName.replaceAll(' ', '_');
    navigate.push(`/PROFILE/${userName}/${objectID}`);
  };

  

  return (
    <AutoCompleteText
      value={value}
      onSelect={onSelect}
      items={results ? results : []}
      onChange={handleChange}
      handleClickSearh={handleClickSearh}
    />
  );
};
