import connectNetwork from "../helpers/connectNetwork";
import { configApi, urlApi } from "../helpers/env";
import { types } from "../types/types";
import Swal from "sweetalert2";
import { closeLoading } from "../helpers/loadingCreate";

export const UpdateUser = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();

    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateUserById`, config);
      let response = await resp.json();
      if (response.ok) {
        Swal.close();
        Swal.fire("Ok", "Datos Actualizados correctamente", "success");
        dispatch(setUpdateProfile(response.user));
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const addExperienceOrganizationForProfile = (data, type) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();

    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(
        `${urlApi}/addExperienceOrganizationForProfile`,
        config
      );
      let response = await resp.json();
      if (response.ok) {
        Swal.close();
        Swal.fire("Ok", "Datos Actualizados correctamente", "success");
        if (type == "create") {
          dispatch(setCreateProfileExperience(response?.experience));
        } else {
          dispatch(setUpdateProfileExperience(response?.experience));
        }
      }

      if (resp.status == 401) {
        Swal.fire("Error", `${response.error}`, "error");
      }
    } catch (error) {
      Swal.close();
      if (error == "Failed to fetch") {
        console.log("encontrado ==> ", "Failed to fetch");
      }
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const removeExperienceOrganizationForProfile = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(
        `${urlApi}/removeExperienceOrganizationForProfile`,
        config
      );
      let response = await resp.json();
      if (response.ok) {
        closeLoading();
      }
    } catch (error) {
      closeLoading();
      if (error == "Failed to fetch") {
        console.log("encontrado ==> ", "Failed to fetch");
      }
    }
  };
};

export const addFriend = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/addFriend`, config);
      let response = await resp.json();
      response.ok && dispatch(getTempFriendInvitation(response.doc));
    } catch (error) {}
  };
};

export const acceptInvitationFriend = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/acceptInvitationFriend`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getTempFriendInvitation(response.doc));
      }
    } catch (error) {}
  };
};

export const removeFriend = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/removeFriend`, config);
      let response = await resp.json();
      response.ok && dispatch(getTempFriendInvitation(response.doc));
    } catch (error) {}
  };
};

export const cancelFriend = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/cancelFriend`, config);
      let response = await resp.json();
      response.ok && dispatch(getTempFriendInvitation(response.doc));
    } catch (error) {}
  };
};

export const toBlockFriend = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/toBlockFriend`, config);
      let response = await resp.json();
      response.ok && dispatch(getTempFriendInvitation(response.doc));
    } catch (error) {}
  };
};

export const unlockFriend = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/unlockFriend`, config);
      let response = await resp.json();
      response.ok && dispatch(getTempFriendInvitation(response.doc));
    } catch (error) {}
  };
};

export const getFriendInvitation = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getFriendInvitationById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getTempFriendInvitation(response.doc));
      }
    } catch (error) {}
  };
};

export const getContactsById = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getMyFriendsSize`, config);
      let response = await resp.json();
      response.ok && dispatch(getMyFriendsSize(response.size));
    } catch (error) {}
  };
};

export const getFriendsById = (userId, data) => {
  return async (dispatch) => {
    dispatch(LoadingMyFriends());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getMyFriends`, config);
      let response = await resp.json();

      if (response.ok) {
        const docs = {
          userData: userId,
          ...response.docs,
        };
        dispatch(getMyFriends(docs));
        dispatch(LoadingMyFriendsoff());
      }
    } catch (error) {
      dispatch(LoadingMyFriendsoff());
    }
  };
};

export const MyFriendsUid = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getMyFriendsId`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(userFriends(response.docs));
      }
    } catch (error) {}
  };
};

export const setUpdateProfile = (datos) => {
  return {
    type: types.getUpdateProfile,
    payload: datos,
  };
};

export const setCreateProfileExperience = (datos) => {
  return {
    type: types.getCreateProfileExperience,
    payload: datos,
  };
};

export const setUpdateProfileExperience = (datos) => {
  return {
    type: types.getUpdateProfileExperience,
    payload: datos,
  };
};

export const setOrganizationExperienceToRemoveProfile = (datos) => {
  return {
    type: types.getOrganizationExperienceToRemoveProfile,
    payload: datos,
  };
};

export const getTempFriendInvitation = (datos) => {
  return {
    type: types.tempFriendInvitation,
    payload: datos,
  };
};

export const getMyFriendsSize = (datos) => {
  return {
    type: types.getMyFriendsSize,
    payload: datos,
  };
};

export const getMyFriends = (datos) => {
  return {
    type: types.getMyFriends,
    payload: datos,
  };
};

export const getIsChangeMyFriend = () => {
  return {
    type: types.isChangeMyFriend,
  };
};

export const getIsChangeMyFriendReset = () => {
  return {
    type: types.isChangeMyFriendReset,
  };
};

export const LoadingMyFriends = () => {
  return {
    type: types.loadingMyfriends,
  };
};

export const LoadingMyFriendsoff = () => {
  return {
    type: types.loadingMyfriendsoff,
  };
};

export const userFriends = (datos) => {
  return {
    type: types.usersFriends,
    payload: datos,
  };
};
