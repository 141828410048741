import React from "react";
import { ProfileAllUsersLoading } from "./AllUsersLoadingComponents";

export const AllUsersLoadingIndicator = ({ quantityElement }) => {
  var items = Array.from({ length: quantityElement }, (_, i) => i + 1);
  return (
    <>
      <div className="row">
        {items.map((item) => {
          return (
            <div key={item} className="col-md-3 col-sm-4 pe-2 ps-2">
              <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                  <ProfileAllUsersLoading />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
