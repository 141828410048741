import { types } from "../types/types";
import Swal from "sweetalert2";
import { configApi, urlApi } from "../helpers/env";
import connectNetwork from "../helpers/connectNetwork";

export const getMyNotifications = (data) => {
  return async (dispatch) => {
    dispatch(setLoadingAllNotification());
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getMyNotifications`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setAllNotifications(response.docs));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getMyNotification = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getMyNotificationById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setNotificationById(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateToken = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateToken`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setToken(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const changeNotificationStateById = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/changeNotificationStateById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setChangeNotificationStateById(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const removeNotificationById = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/removeNotificationById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setRemoveNotificationById(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const removeAllNotifications = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/removeAllNotifications`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setRemoveAllNotifications());
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const inviteMembers = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/requestToInvitation`, config);
      let response = await resp.json();

      if (response.ok) {
        Swal.close();
        Swal.fire("Ok", "Members invited successfully", "success");
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const setToken = (datos) => {
  return {
    type: types.getToken,
    payload: datos,
  };
};

export const setAllNotifications = (datos) => {
  return {
    type: types.allNotificationsResponse,
    payload: datos,
  };
};

export const setNotificationById = (datos) => {
  return {
    type: types.newNotification,
    payload: datos,
  };
};

export const setNewNotificationReset = () => {
  return {
    type: types.newNotificationReset,
  };
};

export const setChangeNotificationStateById = (data) => {
  return {
    type: types.changeNotificationStateById,
    payload: data,
  };
};

export const setRemoveNotificationById = (data) => {
  return {
    type: types.removeNotificationById,
    payload: data,
  };
};

export const setRemoveNotificationByIdReset = (data) => {
  return {
    type: types.removeNotificationByIdReset,
  };
};

export const setRemoveAllNotifications = () => {
  return {
    type: types.removeAllNotifications,
  };
};

export const setLoadingAllNotification = () => {
  return {
    type: types.loadingAllNotification,
  };
};

export const setNotificationActive = () => {
  return {
    type: types.newNotificationActive,
  };
};
