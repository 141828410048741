import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ViewProjectOptionsItemBrowser } from "./ViewProjectOptionsItemBrowser";
import { ViewProjectOptionsItemMobile } from "./ViewProjectOptionsItemMobile";
import { useDispatch, useSelector } from "react-redux";
import { AddMemberProject, updateProjectMembersById } from "../../../../actions/project";
import Swal from "sweetalert2";


export const ViewProjectOptions = ({
  iduser,
  idproject,
  statusMember,
  nameproject,
  openModal,
  projectValue,
}) => {
  const [exist, setexist] = useState(statusMember);
  const [state, setstate] = useState({ loading: false });
  useEffect(() => {
    setexist(statusMember);
    setstate({ loading: false });
  }, [idproject, statusMember]);
  const { uid, name } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const createDate = new Date().toLocaleString();

  //Unirse al grupo
  const click = () => {
    const data = {
      userFromId: iduser,
      projectId: idproject,
      stateMembers: "pending",
      userToId: uid,
      content: `user ${name} wants to join the project ${nameproject}`,
      notificationType: "project",
      stateView: false,
      data: {
        uid: "",
        type: "joinProject",
        userFromId: uid,
        createDate,
        params: {
          userToId: iduser,
          projectToId: idproject,
        },
        notification: "",
        state: false,
      },
    };
    dispatch(AddMemberProject(data));
    setstate({ loading: true });
  };
  // salir del grupo en estado pendiente
  const clickPending = () => {
    const data = {
      projectId: idproject,
      userId: uid,
      stateMembers: {
        stateMembers: "exit",
      },
    };
    dispatch(updateProjectMembersById(data));
    setstate({ loading: true });
  };
  // Salir del grupo en estado aprovado
  const clickExit = () => {
    Swal.fire({
      title: "Are you sure you want to leave the project?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      const data = {
        projectId: idproject,
        userId: uid,
        stateMembers: {
          stateMembers: "exit",
        },
      };
      if (result.isConfirmed) {
        dispatch(updateProjectMembersById(data));
        setstate({ loading: true });
      }
    });
  };

  const { loading } = state;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });

  return (
    <>
      <div className="d-flex mt-4">
        <div className={`pointer d-flex ms-auto align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss`}
          id={`dropdownMenu`}
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={openModal}
          style={{ width: '120px' }}>
          <i className="feather-share-2 ms-auto text-grey-900 text-dark btn-round-sm font-lg"></i>
          <span className="d-none-xs"></span>
        </div>
        {isDesktopOrLaptop && (
          <ViewProjectOptionsItemBrowser
            exist={exist}
            click={click}
            loading={loading}
            clickExit={clickExit}
            clickPending={clickPending}
            isActive={projectValue?.userId == uid}
          />
        )}
        {isTabletOrMobile && (
          <ViewProjectOptionsItemMobile
            exist={exist}
            click={click}
            clickPending={clickPending}
            clickExit={clickExit}
            isActive={projectValue?.userId == uid}
          />
        )}
      </div>
    </>
  );
};
