import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getIsChangePostsGroup,
  getPostsPaginationsGroup,
  isCreateReset,
  isRemoveReset,
  isUpdateReset,
} from "actions/post";
import { LIMIT } from "helpers/env";

export const usePostViewGroup = (idgroup) => {
  const dispatch = useDispatch();
  const observer = useRef();
  const { id: uid } = useParams();

  const { postsGroupLoad, isCreate, isUpdate, isRemove, loadingPostgroup } =
    useSelector((state) => state.postsGroup);

  const { name, photoUrl, uid: userId } = useSelector((state) => state.auth);

  const [docs, setDocs] = useState([]);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isPostListEmpty, setIsPostListEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      limit: LIMIT,
      action: action,
      groupId: idgroup,
      userId,
      lastInResp: lastInResp,
    };
    dispatch(getPostsPaginationsGroup(uid, data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastPostsElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isPostListEmpty) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isPostListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> CHANGE GROUP ID
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (postsGroupLoad?.groupData && uid != postsGroupLoad?.groupData) {
      dispatch(getIsChangePostsGroup());
    }
  }, [uid]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (
      (postsGroupLoad?.docs.length <= 0 &&
        postsGroupLoad?.isCollectionEmpty == -1) ||
      (postsGroupLoad?.docs.length <= 0 &&
        postsGroupLoad?.isCollectionEmpty == 0)
    ) {
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
      setLoading(true);
    }
  }, [postsGroupLoad?.docs]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (postsGroupLoad?.docs.length > 0) {
      postsGroupLoad.docs.map((element) => {
        if (!docs.includes(element)) {
          setDocs((ele) => ele.concat(element));
        }
      });
      setlastInResponse(postsGroupLoad?.lastInResponse);
      setIsPostListEmpty(
        postsGroupLoad?.isCollectionEmpty === 0 ? true : false
      );
      setLoading(false);
      setLoadingPagination(false);
    } else if (
      postsGroupLoad?.docs.length <= 0 &&
      postsGroupLoad?.isCollectionEmpty == 0
    ) {
      setIsPostListEmpty(true);
      setDocs([]);
      setLoading(false);
      setLoadingPagination(false);
    } else if (
      postsGroupLoad?.docs.length <= 0 &&
      postsGroupLoad?.isCollectionEmpty == -1
    ) {
      setDocs([]);
      setLoading(false);
      setLoadingPagination(false);
    }
  }, [postsGroupLoad, uid, isPostListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS CREATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isCreate?.state) {
      const element = postsGroupLoad?.docs.find(
        (dc) => dc.uid == isCreate?.uidCreate
      );
      if (!docs.includes(element)) setDocs((ele) => [element, ...ele]);
    }
    dispatch(isCreateReset());
  }, [isCreate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS UPDATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isUpdate?.state) {
      setDocs((items) => {
        const position = docs.findIndex((dc) => dc.uid === isUpdate?.uidUpdate);
        const newElement = postsGroupLoad?.docs.find(
          (dc) => dc.uid === isUpdate?.uidUpdate
        );
        docs.splice(position, 1, newElement);
        return items;
      });

      dispatch(isUpdateReset());
    }
  }, [isUpdate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS REMOVE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isRemove?.state) {
      setDocs((items) => {
        const position = docs.findIndex((dc) => dc.uid === isRemove?.uidRemove);
        docs.splice(position, 1);
        return items;
      });

      dispatch(isRemoveReset());
    }
  }, [isRemove?.state]);



  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    loadingPostgroup,
    docs,
    name,
    photoUrl,
    lastPostsElementRef,
    loadingPagination,
  };
};
