import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { startLogout } from "actions/auth";

export const useSettings = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.notifications);
  const { uid: userIdActive } = useSelector((state) => state.auth);

  const handleLogout = (e) => {
    e.preventDefault();
    const data = {
      token,
      userId: userIdActive,
    };
    dispatch(startLogout(data));
  };

  return {
    handleLogout,
  };
};
