import { types } from "../types/types";

const inicialState = {
    page403: false
};

export const page403Reducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.getPage403:
      return {
        ...state,
        page403: true,
      };
    case types.getPage403Reset:
      return {
        ...state,
        page403: false,
      };

    default:
      return state;
  }
};
