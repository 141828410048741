import React from "react";
import PropTypes from "prop-types";

const SearchAll = ({ title, placeHolder, onChange, value, onKeyDown }) => {
  return (
    <div className="card w-100 d-block d-flex border-0 p-4 mb-3">
      <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
        {title}
        <div className="pt-0 pb-0 ms-auto">
          <div className="search-form-2 ms-2">
            <i className="ti-search font-xss"></i>
            <input
              type="text"
              className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
              placeholder={placeHolder}
              onChange={onChange}
              value={value}
              onKeyDown={onKeyDown}
            />
          </div>
        </div>
      </h2>
    </div>
  );
};

SearchAll.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

export default SearchAll;
