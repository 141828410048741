import React from "react";
import Load from "../../Load";
import SearchAll from "../../shared/SearchAll";

import { CardListCourseStyle } from "../components/CardListCourseStyle";
import { useCoursesAll } from "../../../Hooks/course/useAllCourses";
import { EmptyData } from "../../shared/EmptyData";
import { CoursesLoadingIndicator } from "../loading/CourseLoadingIndicator";

export const AllCourses = () => {
  const {
    docs,
    lastCoursesElementRef,
    loadingLoad,
    loading,
    loadingPagination,
    searchText,
    searchResult,
    handleSearch,
    handleSearchText,
  } = useCoursesAll();

  return (
    <>
      <SearchAll
        title="Courses"
        placeHolder="Search Courses"
        onChange={handleSearchText}
        value={searchText}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSearch(searchText);
        }}
      />

      {loading && !searchResult && (
        <CoursesLoadingIndicator isPropietor={false} quantityElement={8} />
      )}

      {docs == null ? null : loadingLoad ? (
        <Load />
      ) : (
        <div className="row ps-2 pe-2">
          {searchResult && searchResult.length > 0 ? (
            searchResult?.map((value) => (
              <div
                key={value?.objectID}
                className="col-md-6 col-sm-6 pe-2 ps-2"
              >
                <CardListCourseStyle
                  uid={value?.objectID}
                  title={value?.title}
                  pageCover={value?.pageCover}
                  isProprietor={false}
                />
              </div>
            ))
          ) : (!loading && docs.length === 0) ||
            (!loading && searchResult && searchResult.length === 0) ? (
            <EmptyData
              pathImg="/assets/images/NoData.svg"
              title="There are no courses"
            />
          ) : (
            docs.map((value, index) => {
              if (docs.length === index + 1) {
                return (
                  <div
                    key={value?.uid}
                    ref={lastCoursesElementRef}
                    className="col-md-6 col-sm-6 pe-2 ps-2"
                  >
                    <CardListCourseStyle
                      uid={value?.uid}
                      title={value?.title}
                      pageCover={value?.pageCover}
                      isProprietor={false}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={index} className="col-md-6 col-sm-6 pe-2 ps-2">
                    <CardListCourseStyle
                      uid={value?.uid}
                      title={value?.title}
                      pageCover={value?.pageCover}
                      isProprietor={false}
                    />
                  </div>
                );
              }
            })
          )}
        </div>
      )}
      {loadingPagination && <Load />}
    </>
  );
};
