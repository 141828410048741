import React from 'react'
import { CardRouteProfileOptions } from 'components/shared/CardRouteProfileOptions'
import { EditProject } from './EditProject'

export const EditProjectView = () => {
    return (
        <>
        <CardRouteProfileOptions title="Edit Project" route='/userpage/projects/my-projects' component={  EditProject } ></CardRouteProfileOptions>
    </>
    )
}
