import React from "react";
import { OrganizationLoading } from "./OrganizationLoadingComponents";

export const OrganizationLoadingIndicator = ({ quantityElement= 3 }) => {

  var items = Array.from({ length: quantityElement }, (_, i) => i + 1);

  return (
      <div className="row">
        {
          items.map((item) => {
           return <div key={item} className="col-md-4 col-sm-12">
              <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                  <OrganizationLoading />
                </div>
              </div>
            </div>;
          })
        }
      </div>
  );
};
