import React from "react";

import { useMyCoursesParticipate } from "Hooks/course/useMyCoursesParticipate";
import Load from "../Load";
import { EmptyData } from "../shared/EmptyData";
import { CardListCourseStyle } from "./components/CardListCourseStyle";
import { CoursesLoadingIndicator } from "./loading/CourseLoadingIndicator";

export const MyCoursesParticipate = () => {
  const { docs, loading, loadingPagination, lastMyCourseElementRef, isProprietor } =
    useMyCoursesParticipate();
  return (
    <div className="row ps-2 pe-1">
      {loading && docs?.length <= 0 && <CoursesLoadingIndicator quantityElement={2} />}
      {!loading && docs?.length <= 0 && (
        <EmptyData
          pathImg="/assets/images/NoData.svg"
          title="There are no courses created"
        />
      )}

      {docs?.map((value, index) => {
        if (docs.length === index + 1) {
          return (
            <div
              key={index}
              ref={lastMyCourseElementRef}
              className="col-md-6 col-sm-6 pe-2 ps-2"
            >
              <CardListCourseStyle
                uid={value?.uid}
                title={value?.title}
                pageCover={value?.pageCover}
                isProprietor={!isProprietor}
              />
            </div>
          );
        } else {
          return (
            <div key={index} className="col-md-6 col-sm-6 pe-2 ps-2">
              <CardListCourseStyle
                uid={value?.uid}
                title={value?.title}
                pageCover={value?.pageCover}
                isProprietor={!isProprietor}
              />
            </div>
          );
        }
      })}
      {loadingPagination && <Load />}
    </div>
  );
};
