import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContactsById } from "actions/profile";

export const useProfileContact = () => {
  const dispatch = useDispatch();

  let { photoUrl, name, uid } = useSelector((state) => state.auth);
  name = name.replaceAll(" ", "_");
  const { MyFriendsSize } = useSelector((state) => state.friend);
  const [contactsMyFriends, setContactsMyFriends] = useState(MyFriendsSize);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (uid) {
      uid && dispatch(getContactsById({ uid }));
    }
  }, [uid]);

  useEffect(() => {
    setLoading(true);
    if (MyFriendsSize && !contactsMyFriends) {
      setContactsMyFriends(MyFriendsSize);
      setLoading(false);
    }
  }, [MyFriendsSize, contactsMyFriends, loading]);

  return {
    loading,
    uid,
    photoUrl,
    name,
    contactsMyFriends,
  };
};
