import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserPrivate } from "actions/auth";

export const ConfigInformations = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const ADDRESS = user.privatySettings?.find(
    (ps) => ps.key.toLowerCase() === "address"
  );
  const PHONENUMBER = user.privatySettings?.find(
    (ps) => ps.key.toLowerCase() === "phonenumber"
  );
  const EMAIL = user.privatySettings?.find(
    (ps) => ps.key.toLowerCase() === "email"
  );
  const USERNAME = user.privatySettings?.find(
    (ps) => ps.key.toLowerCase() === "username"
  );
  const CITY = user.privatySettings?.find(
    (ps) => ps.key.toLowerCase() === "city"
  );
  const COUNTRY = user.privatySettings?.find(
    (ps) => ps.key.toLowerCase() === "country"
  );

  const [formsValue, setfomrs] = useState({
    email: user.email,
    city: user.city ?? "",
    userName: user.userName,
    address: user.address ?? "",
    phoneNumber: user.phoneNumber,
    country: user.country ?? "",
    privateuserName: USERNAME?.enabled ?? false,
    privateemail: EMAIL?.enabled ?? false,
    privatecountry: COUNTRY?.enabled ?? false,
    privatephoneNumber: PHONENUMBER?.enabled ?? false,
    privatecity: CITY?.enabled ?? false,
    privateaddress: ADDRESS?.enabled ?? false,
  });

  useEffect(() => {
    setfomrs({
      email: user.email,
      city: user.city ?? "",
      userName: user.userName,
      address: user.address ?? "",
      phoneNumber: user.phoneNumber,
      country: user.country ?? "",
      privateuserName: USERNAME?.enabled ?? false,
      privateemail: EMAIL?.enabled ?? false,
      privatecountry: COUNTRY?.enabled ?? false,
      privatephoneNumber: PHONENUMBER?.enabled ?? false,
      privatecity: CITY?.enabled ?? false,
      privateaddress: ADDRESS?.enabled ?? false,
    });
  }, [user]);

  const handlerform = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfomrs({
      ...formsValue,
      [event.target.name]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      uid: user.uid,
      privatySettings: [
        {
          enabled: formsValue.privatecity,
          key: "city",
        },
        {
          enabled: formsValue.privateuserName,
          key: "username",
        },
        {
          enabled: formsValue.privateaddress,
          key: "direction",
        },
        {
          enabled: formsValue.privatephoneNumber,
          key: "phonenumber",
        },
        {
          enabled: formsValue.privatecountry,
          key: "country",
        },
        {
          enabled: formsValue.privateemail,
          key: "email",
        },
      ],
    };

    dispatch(UpdateUserPrivate(data));
  };

  return (
    <div className="card w-100 border-0 p-3 bg-white shadow-xss rounded-xxl">
      <div className="p-1">
        <span>Ocultar Informacion Personal</span>
      </div>

      <form onSubmit={handleLogin}>
        <div className="row">
          <div className="col-lg-4 mb-3">
            <div className="row">
              <div className="col-2 d-flex">
                <div className="m-auto">
                  <input
                    name="privateuserName"
                    type="checkbox"
                    checked={formsValue.privateuserName}
                    onChange={handlerform}
                  />
                </div>
              </div>

              <div className="col-10 form-group">
                <label className="mont-font fw-600 font-xsss">Name</label>
                <input
                  disabled
                  value={formsValue.userName}
                  name="userName"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-3">
            <div className="row">
              <div className="col-2 d-flex">
                <div className="m-auto">
                  <input
                    name="privateemail"
                    type="checkbox"
                    checked={formsValue.privateemail}
                    onChange={handlerform}
                  />
                </div>
              </div>
              <div className="col-10 form-group">
                <label className="mont-font fw-600 font-xsss">Email</label>
                <input
                  value={formsValue.email}
                  name="email"
                  disabled
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-3">
            <div className="row">
              <div className="col-2 d-flex">
                <div className="m-auto">
                  <input
                    name="privatephoneNumber"
                    type="checkbox"
                    checked={formsValue.privatephoneNumber}
                    onChange={handlerform}
                  />
                </div>
              </div>
              <div className="col-10 form-group">
                <label className="mont-font fw-600 font-xsss">
                  PhoneNumber
                </label>
                <input
                  disabled
                  value={formsValue.phoneNumber}
                  name="phoneNumber"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-3">
            <div className="row">
              <div className="col-2 d-flex">
                <div className="m-auto">
                  <input
                    name="privatecountry"
                    type="checkbox"
                    checked={formsValue.privatecountry}
                    onChange={handlerform}
                  />
                </div>
              </div>
              <div className="col-10 form-group">
                <label className="mont-font fw-600 font-xsss">country</label>
                <input
                  disabled
                  value={formsValue.country}
                  name="country"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-3">
            <div className="row">
              <div className="col-2 d-flex">
                <div className="m-auto">
                  <input
                    name="privatecity"
                    type="checkbox"
                    checked={formsValue.privatecity}
                    onChange={handlerform}
                  />
                </div>
              </div>
              <div className="col-10 form-group">
                <label className="mont-font fw-600 font-xsss">City</label>
                <input
                  disabled
                  value={formsValue.city}
                  name="city"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-3">
            <div className="row">
              <div className="col-2 d-flex">
                <div className="m-auto">
                  <input
                    name="privateaddress"
                    type="checkbox"
                    checked={formsValue.privateaddress}
                    onChange={handlerform}
                  />
                </div>
              </div>
              <div className="col-10 form-group">
                <label className="mont-font fw-600 font-xsss">Addres</label>
                <input
                  disabled
                  value={formsValue.address}
                  name="addres"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 pb-5 mt-2 ps-0">
          <button type="submit" className="btn btn-secondary">
            show information
          </button>
        </div>
      </form>
    </div>
  );
};
