import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import algoliasearch from "algoliasearch/lite";
import {
  getGroupsByIdParticipate,
  getIsChangeMyGroup,
} from "actions/group";
import {
  ALGOLIA_INDEX_GROUPS_GLOBAL,
  APPLICATION_ID,
  LIMIT,
  SEARCH_API_KEY,
} from "helpers/env";
import { useSearchAlgolia } from "../useSearchAlgolia";

const client = algoliasearch(APPLICATION_ID, SEARCH_API_KEY);
const index = client.initIndex(ALGOLIA_INDEX_GROUPS_GLOBAL);

export const useMyGroupsParticipate = () => {
  const dispatch = useDispatch();
  const observer = useRef();

  const { uid: userIdVisited } = useSelector((state) => state.navigateAuth);
  const { uid: userIdActive } = useSelector((state) => state.auth);
  const { groupParticipateLoad, loading } = useSelector(
    (state) => state.group
  );
  const [myGroupList, setMyGroupList] = useState([]);
  const [results, setResults] = useState(null);

  const [lastInResponse, setlastInResponse] = useState(null);
  const [isGroupListEmpty, setIsGroupListEmpty] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [isProprietor, setIsProprietor] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { performSearch, results: hit } = useSearchAlgolia(
    index,
    ALGOLIA_INDEX_GROUPS_GLOBAL
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC IS PROPRIETOR
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (userIdVisited == userIdActive) {
      setIsProprietor(true);
    } else {
      setIsProprietor(false);
    }
  }, [userIdVisited, userIdActive]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      userId: userIdVisited,
      limit: LIMIT,
      action: action,
      lastInResp: lastInResp,
    };
    dispatch(getGroupsByIdParticipate(userIdVisited, data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastMyGroupsElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isGroupListEmpty) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isGroupListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> CHANGE USER PROFILE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (
      groupParticipateLoad?.userData &&
      userIdVisited != groupParticipateLoad?.userData
    ) {
      dispatch(getIsChangeMyGroup());
    }
  }, [userIdVisited]);
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (
      (groupParticipateLoad?.docs.length <= 0 &&
        groupParticipateLoad?.isCollectionEmpty == -1) ||
      (groupParticipateLoad?.docs.length <= 0 &&
        groupParticipateLoad?.isCollectionEmpty == 0)
    ) {
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
      // setLoading(true);
    }
  }, [groupParticipateLoad?.docs]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER GROUPS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (groupParticipateLoad?.docs.length > 0) {
      groupParticipateLoad.docs.map((element) => {
        if (!myGroupList.includes(element)) {
          setMyGroupList((ele) => ele.concat(element));
        }
      });
      setlastInResponse(groupParticipateLoad?.lastInResponse);
      setIsGroupListEmpty(
        groupParticipateLoad?.isCollectionEmpty === 0 ? true : false
      );
      // setLoading(false);
      setLoadingPagination(false);
    } else if (
      groupParticipateLoad?.docs.length <= 0 &&
      groupParticipateLoad?.isCollectionEmpty == 0
    ) {
      setIsGroupListEmpty(true);
      setMyGroupList([]);
      // setLoading(false);
      setLoadingPagination(false);
    } else if (
      groupParticipateLoad?.docs.length <= 0 &&
      groupParticipateLoad?.isCollectionEmpty == -1
    ) {
      setMyGroupList([]);
      // setLoading(false);
      setLoadingPagination(false);
    }
  }, [groupParticipateLoad, userIdVisited, isGroupListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER GROUPS Y SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (hit && hit.length > 0) {
      setResults(hit);
    } else {
      setResults(myGroupList);
    }
  }, [hit, results, myGroupList]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC ONCHANGE SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleChange = (e) => {
    var { value } = e.target;
    if (value == "") {
      setResults(null);
      setResults(myGroupList);
    } else {
      value.length >= 3 && performSearch(value);
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===>RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    loading,
    loadingPagination,
    results,
    lastMyGroupsElementRef,
    handleChange,
    isProprietor,
  };
};
