import React from 'react'
import ContentLoader from 'react-content-loader'

export const SuggestionUserLoading = props => {
  return (
    <ContentLoader
      height={350}
      width={320}
      viewBox="0 0 320 350"
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
      {...props}
    >
      <circle cx="60" cy="40" r="30" />
      <rect x="100" y="14" rx="3" ry="3" width="160" height="13" />

      <rect x="100" y="30" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="30" rx="3" ry="3" width="74" height="10" />

      <rect x="30" y="80" rx="10" ry="10" width="100" height="30" />



      <circle cx="60" cy="160" r="30" />
      <rect x="100" y="134" rx="3" ry="3" width="160" height="13" />

      <rect x="100" y="150" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="150" rx="3" ry="3" width="74" height="10" />

      <rect x="30" y="200" rx="10" ry="10" width="100" height="30" />


      <circle cx="60" cy="280" r="30" />
      <rect x="100" y="254" rx="3" ry="3" width="160" height="13" />

      <rect x="100" y="270" rx="3" ry="3" width="10" height="10" />
      <rect x="120" y="270" rx="3" ry="3" width="74" height="10" />

      <rect x="30" y="330" rx="10" ry="10" width="100" height="30" />
 


    </ContentLoader>
  )
}



