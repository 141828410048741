import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateCommentsForumCourse } from "actions/course";
import { UpdateCommentsForum } from "actions/project";


export const useEditForum = (data, type) => {
    const { uid: userId, photoUrl } = useSelector((state) => state.auth);

    const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  
  const [forms, setForms] = useState({
    content: data?.content,
  });

  const changeTextArea = (e) => {
    e.preventDefault();
    setForms({ content: e.target.value });
  };

  const hanledEditForum = async (e) => {
    e.preventDefault();
    const { content } = forms;
     if(type == "project"){
       const post = {
         content,
         projectId: data?.projectId,
         userId,
         uid: data?.uid
       };
   
       dispatch(UpdateCommentsForum(post));
     }else{
      const post = {
        content,
        courseId: data?.courseId,
        userId,
        uid: data?.uid
      };
      dispatch(UpdateCommentsForumCourse(post));

     }

  }


    return {
    show,
    handleClose, 
    handleShow,
    forms,
    photoUrl,
    changeTextArea,
    hanledEditForum
    }
}
