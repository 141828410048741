import React from "react";
import Header from "components/headers/Header";

import { useViewOrganization } from "Hooks/organization/useViewOrganization";
import { GoBackNavigate } from "components/shared/GoBack";
import { ViewOrganizationJob } from "./components/ViewOrganizationJob";
import { ViewOrganizationDescription } from "./components/ViewOrganizationDescription";
import { imageExistsProductUser } from "helpers/verifyImg";
import { ViewOrganizationLoadingMobile } from "../loading/ViewOrganizationLoadingMobile";
import { ViewOrganizationLoadingBrowser } from "../loading/ViewOrganizationLoadingBrowser";
import { useMediaQuery } from "react-responsive";

export const ViewOrganization = () => {
  const { tempOrganization, loading, navigate, page403 } =
    useViewOrganization();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });

  if(page403){
    navigate.push('/notfound')
  }
  
  return (
    <>
      {loading ? (
        <>
          {isDesktopOrLaptop ? (
            <ViewOrganizationLoadingBrowser />
          ) : (
            <ViewOrganizationLoadingMobile />
          )}
        </>
      ) : (
        <>
          {!loading && tempOrganization && (
            <>
              <Header />
              <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                  <div className="middle-sidebar-left pe-0">
                    <div className="row">
                      <div className="col-xl-12 mb-3">
                        <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                          <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                            <GoBackNavigate onClick={() => navigate.goBack()} />
                            <img
                              src="https://newevolutiondesigns.com/images/freebies/texture-facebook-cover-preview-67.jpg"
                              style={{ objectFit: "cover" }}
                              width="1200"
                              height="250"
                              alt="avater"
                            />
                          </div>

                          <div className="card-body p-0 position-relative">
                            <figure
                              className="avatar position-absolute w100 z-index-1"
                              style={{ top: "-55px", left: "30px" }}
                            >
                              <img
                                src={tempOrganization?.doc.logoUrl}
                                alt="avater"
                                className="float-right border p-1 bg-white rounded-circle w-100"
                                style={{ objectFit: "cover" }}
                                width="100"
                                height="100"
                                onError={imageExistsProductUser}
                              />
                            </figure>
                          </div>

                          <h4
                            className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15"
                            style={{
                              fontSize:
                                "calc([15] + ([20] - [15]) * ((100vw - [300]) / ([1250] - [300])))",
                              textTransform: "capitalize",
                            }}
                          >
                            {tempOrganization?.doc?.name}
                          </h4>
                        </div>
                      </div>
                    </div>

                    {tempOrganization?.members?.length > 0 ? (
                      <>
                        {isDesktopOrLaptop ? (
                          <>
                            <div className="row">
                              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                <ViewOrganizationJob
                                  members={tempOrganization?.members}
                                />
                              </div>

                              <div className="col-xl-8 col-xxl-9 col-lg-8">
                                <ViewOrganizationDescription
                                  organization={tempOrganization?.doc}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="row">
                            <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                              <ViewOrganizationDescription
                                organization={tempOrganization?.doc}
                              />
                            </div>

                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                              <ViewOrganizationJob
                                members={tempOrganization?.members}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="row">
                        <div className="col-12">
                          <ViewOrganizationDescription
                            organization={tempOrganization?.doc}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
