import algoliasearch from "algoliasearch/lite";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setQuerySearch } from "actions/getUsers";

const client = algoliasearch(
  process.env.REACT_APP_APPLICATION_ID,
  process.env.REACT_APP_SEARCH_API_KEY
);

const searchIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_INDEX_USERS_GLOBAL
);

export const useSearchFriends = () => {
  const dispatch = useDispatch();

  const { uid: userIdActive } = useSelector((state) => state.auth);
  const [results, setResults] = useState(null);
  const [value, setValue] = useState("");

  const handleChange = async (e) => {
    const { value } = e.target;
    setValue(value);
    if (value == "") {
      setResults(null);
    } else if (value.length > 3) {
      const option = {
        filters: `NOT objectID:${userIdActive}`,
        hitsPerPage: 5,
      };
      const result = await searchIndex.search(value, option);
      setResults(result.hits);
    }
  };
  const handleClickSearh = () => {
    dispatch(setQuerySearch(value));
  };

  return {
    handleChange,
    results,
    value,
    handleClickSearh,
  };
};
