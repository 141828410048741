import { setQuerySearch } from 'actions/getUsers'
import { imageExistsProfile } from 'helpers/verifyImg'
import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/AutoCompleteText.css'

export const AutoCompleteText = ({ items= [], value, onChange, onSelect, handleClickSearh}) => {

    return (
            <form className="float-left header-search ms-3">
                <div className="form-group mb-0 icon-input">
                <i className="feather-search font-sm text-grey-400"></i>     
                    <input type="text" placeholder="Start typing to search.." className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg input"  onChange={ onChange } />
                    <div className='AutoCompleteText bg-grey ' style={ items.length > 0 ? { boxShadow: '0 0 0 1px  rgba(0,0,0,.1), 0 2px 4px 1px rgba(0,0,0,.28)' } : {}}>
                        <ul style={ items.length >  0 ? { borderTop: '1px solid grey' } : {}}>
                            {
                                items.map(item => <li key={ item.objectID } onClick={ () => onSelect(item) }> 
                                    <div  className='circleImg'> 
                                        <img src={ item.photoUrl } onError={ imageExistsProfile } ></img> 
                                        <div className='mt-1 ml-2'> { item.userName } </div>
                                    </div>
                                </li>)
                            }
                            { 
                                (items.length > 0) && 
                                    <Link to="/users" className='options active' onClick={() => handleClickSearh(value)}>
                                    <div className='NavLink'>
                                        <i className=" icon feather-search font-sm bg-greylight btn-round-sm theme-dark-bg text-blue-500"></i> 
                                        <span className='text'> Busca { value } </span>
                                    </div>
                                    </Link>
                            }
                        </ul>
                    </div>
                </div>
            </form>
       
    )
}
