import { types } from "../types/types";

export const setPage403 = () => {
    return {
      type: types.getPage403,
    };
  };
  
  export const setPage403Reset = () => {
    return {
      type: types.getPage403Reset,
    };
  };
  