import { types } from "../types/types";

const inicialState ={
    loading : false,
    msgError : null,
    percentFiles: 0

}



export const uiReducer = (state = inicialState, action )=>{
   
    switch (action.type) {
        case types.uiSetError:
            return{
               ...state,
               msgError: action.payload
            }

         case    types.RemoveError:
             return{
                 ...state,
                 msgError: null
             }
             case    types.UploadingFiles:
             return{
                 ...state,
                 percentFiles: action.payload
             }
    
        default:
            return state
    }


}