import React from "react";
import Select from "react-select";
import { ChatView } from "./ChatView";
import {imageExistsProfile} from "helpers/verifyImg"
import "./css/chat.css";

export const ChatViewBrowser = ({
  animatedComponents,
  onChangeMembers,
  createChannel,
  membersusers,
  Channels,
  client,
  filters,
  sort,
}) => {
  return (
    <>
      <div className="main-content right-chat-active">
        <div className="browser main-content-sidebar middle-sidebar-left pe-0">
          <div className="d-flex">
            <div className="active-chat" style={{zIndex: 999}}>
              <Select
                onChange={onChangeMembers}
                closeMenuOnSelect={true}
                components={animatedComponents}
                placeholder="Select a user to start a chat"
                options={membersusers}
                formatOptionLabel={(users) => (
                  <div className="country-option">
                    <img
                      src={users?.image}
                      style={{ width: 36 }}
                      alt="country-image"
                      onError={imageExistsProfile}
                    />
                    <span className="pl-4">{users?.label}</span>
                  </div>
                )}
              />
            </div>
            <div className="">
              <button className="btn btn-info active-chat-browser-item-button" onClick={createChannel}>
                Init chat
              </button>
            </div>
          </div>
          <div className="chat-browser">
            <ChatView
              Channels={Channels}
              client={client}
              filters={filters}
              sort={sort}
              style='channel-browser'
            />
          </div>
        </div>
      </div>
    </>
  );
};
