import { format } from "date-fns";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CreateCommentsForumCourse } from "actions/course";
import { CreateCommentsForum } from "actions/project";


export const useForum = (type) => {
  const { id } = useParams();
    const [forms, setForms] = useState({
        content: "",
      });
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {uid, photoUrl} = useSelector( state => state.auth );
    const dispatch = useDispatch();

      const changeTextArea = (e) => {
        e.preventDefault();
        setForms({ content: e.target.value });
      };

      const hanledCreateComment = async (e) => {
        e.preventDefault();
        const date = format(new Date(), "dd/MM/yyyy HH:mm:ss" )
        const { content } = forms;
        if(type == "project"){
          const data = {
            content,
            userId: uid,
            projectId: id,
            createDate: date
          };
         dispatch(CreateCommentsForum(data));
        }else{
          const data = {
            content,
            userId: uid,
            courseId: id,
            createDate: date
          };
         dispatch(CreateCommentsForumCourse(data));

        }
       handleClose();
    }


    return {
        photoUrl,
        show,
        changeTextArea,
        handleClose,
        handleShow,
        hanledCreateComment
    }
}
