import { SpinnerCustom } from "components/shared/SpinnerCustom";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import "../../css/ModalPreviewVideo.css"

export const PresentationVideoInfomation = ({ urlVideo, loading }) => {
  const [modalShow, setModalShow] = useState(true);
  return (
    <>
      {loading ? (
        <div className="vh-100" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SpinnerCustom/>
        </div>
      ) :

      <Modal
        className="special_modal"
        show={modalShow}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title >
              <h3 style={{color: "white"}}> Presentation Video</h3>
           
          </Modal.Title>
          <button
            className="btn ti-close"
            type="button"
            style={{color: "white"}}
            onClick={() => setModalShow(false)}
            closeButton
          ></button>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer
            playing={true}
            width={"100%"}
            height={"100%"}
            controls={true}
            url={urlVideo}
            onEnded={() => setModalShow(false)}
          ></ReactPlayer>
        </Modal.Body>
        
      </Modal>
}
    </>
      
  );
};
