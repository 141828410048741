import React from "react";
import ReactMarkdown from "react-markdown";
import { EmptyData } from "../../shared/EmptyData";

export const ViewProjectInformationItem = ({ exitsMember, projectValue }) => {

  const validateIcon = (value) => {
    if (value.includes("png")) {
      return "png";
    } else if (value.includes("pdf")) {
      return "pdf";
    } else if (value.includes("docx")) {
      return "docx";
    } else if (value.includes("jpeg")) {
      return "jpeg";
    } else if (value.includes("jpg")) {
      return "jpg";
    } else if (value.includes("pptx")) {
      return "pptx";
    }  else if (value.includes("mp4")) {
      return "mp4";}
    else {
      return "otro";
    }
  };

  return (
    <>
      {exitsMember != "success" && (
        <div className="row">
          <div className="col-xl-12 col-xxl-12 col-lg-12">
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
              <div className="card-body d-block p-4">
                <h1 className="text-center">Introduction</h1>
                <div className="container">
                  <p
                    style={{ textAlign: "justify" }}
                    className="font-xsss fw-500 text-grey-500 d-block"
                  >
                    {projectValue?.description}
                  </p>
                </div>
                <EmptyData
                  pathImg="../../assets/images/privateGroup.svg"
                  title="Private Project"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {exitsMember == "success" && (
        <div className="row">
          <div
            className="col-xl-8 col-xxl-9 col-lg-8 mb-3"
            style={{ height: "100%", display: "flex" }}
          >
            <div className="card w-100 border-0 p-3 bg-white shadow-xss rounded-xxl">
              <h1 className="text-center">Introduction</h1>
              <div className="container">
                <p
                  style={{ textAlign: "justify" }}
                  className="font-xsss fw-500 text-grey-500 d-block"
                >
                  {projectValue?.description}
                </p>
              </div>

              {projectValue?.content && (
                <h1 className="pt-3 text-center">Content</h1>
              )}
              <div className="container" style={{ textAlign: "justify" }}>
                <ReactMarkdown>{projectValue?.content}</ReactMarkdown>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-xxl-3 col-lg-4 ">
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
              <div className="card-body d-block p-4">
                <h4 className="fw-700 mb-3 font-xsss text-grey-900">Annexes</h4>
                <div className="card-body border-top-xs">
                  <div className="row">
                    <div className="col-sm">
                      {projectValue?.files.length <= 0 && (
                        <div className="text-center">
                          <h6> You do not have annexes </h6>{" "}
                        </div>
                      )}
                      {projectValue?.files.map((value, index) => (
                        <a
                          key={index}
                          className=" fw-700 text-900 font-xsss "
                          href={value}
                          target="_blank"
                        >
                          {validateIcon(value) == "pdf" && (
                            <i data-toggle="tooltip" data-placement="top" title=".pdf"
                              className={`feather-file-text me-3 `}
                              style={{ fontSize: "50px", color: "red" }}
                            ></i>
                          )}
                          {(validateIcon(value) ==
                            "png" || validateIcon(value) == "jpg" || validateIcon(value) == "jpeg") && (
                            <i data-toggle="tooltip" data-placement="top" title={"."+validateIcon(value)}
                              className={`cursor-pointer feather-image  me-3 `}
                              style={{ fontSize: "50px", color: "#afa6d5" }}
                            ></i>
                          )}
                          {validateIcon(value) == "docx" && (
                            <i data-toggle="tooltip" data-placement="top" title=".docx"
                              className={` cursor-pointer feather-file-text  me-3 `}
                              style={{ fontSize: "50px", color: "#00579b" }}
                            ></i>
                          )}
                          {validateIcon(value) == "pptx" && (
                            <i data-toggle="tooltip" data-placement="top" title=".pptx"
                              className={`feather-file-text  me-3 `}
                              style={{ fontSize: "50px", color: "#ffc204" }}
                            ></i>
                          )}
                          {validateIcon(value) == "mp4" && (
                            <i data-toggle="tooltip" data-placement="top" title=".mp4"
                              className={`feather-film  me-3 `}
                              style={{ fontSize: "50px", color: "#3D4FFF" }}
                            ></i>
                          )}
                          {validateIcon(value) == "otro" && (
                            <i
                              className={`feather-file  text-grey-500 me-3 `}
                              style={{ fontSize: "50px" }}
                            ></i>
                          )}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
