import { types } from "../types/types";

const inicialState = {
  loading: true,
  tempProjectCreate: null,
  tempProject: null,
  projectLoad: {
    userData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  projectParticipateLoad: {
    userData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  page: 1,
  exitsMember: null,
  loadingMember: false,
  membersProject: [],
  isRemove: {
    state: false,
    uidRemove: null,
    urlPageCover: null,
    urlFiles: null,
  },
  notificationsMembers: [],
};

export const projectReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.getMyProject:
      let hashMyProjects = {};
      let isLoadedData =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getMyProjectIsCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;

      return {
        ...state,
        loading: false,
        projectLoad: isLoadedData
          ? state.projectLoad
            ? {
                userData: action.payload.userData,
                docs: [
                  ...state.projectLoad?.docs,
                  ...action.payload?.docs,
                ].filter((o) =>
                  hashMyProjects[o.uid] ? false : (hashMyProjects[o.uid] = true)
                ),
                isCollectionEmpty: getMyProjectIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.projectLoad?.userData,
              docs: state.projectLoad?.docs,
              isCollectionEmpty: getMyProjectIsCollectionEmpty,
              lastInResponse: state.projectLoad?.lastInResponse,
            },
      };

    case types.getMyProjectParticipate:
      let hashMyProjectsParticipate = {};
      let isLoadedDataParticipate =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getMyProjectParticipateIsCollectionEmpty = action.payload
        ?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;

      return {
        ...state,
        loading: false,
        projectParticipateLoad: isLoadedDataParticipate
          ? state.projectParticipateLoad
            ? {
                userData: action.payload.userData,
                docs: [
                  ...state.projectParticipateLoad?.docs,
                  ...action.payload?.docs,
                ].filter((o) =>
                  hashMyProjectsParticipate[o.uid]
                    ? false
                    : (hashMyProjectsParticipate[o.uid] = true)
                ),
                isCollectionEmpty: getMyProjectParticipateIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.projectParticipateLoad?.userData,
              docs: state.projectParticipateLoad?.docs,
              isCollectionEmpty: getMyProjectParticipateIsCollectionEmpty,
              lastInResponse: state.projectParticipateLoad?.lastInResponse,
            },
      };

    case types.isChangeMyProject:
      return {
        ...state,
        projectLoad: {
          userData: null,
          docs: [],
          isCollectionEmpty: -1,
          lastInResponse: null,
        },
      };

    case types.loadingproject:
      return {
        ...state,
        loading: true,
      };

    case types.createProject:
      return {
        ...state,
        tempProjectCreate: action.payload,
        loading: false,
        page: 2,
      };

    case types.createUpdateEnd:
      let projects = [action.payload, ...state.projectLoad.docs];
      function SortArray(x, y) {
        if (x.title < y.title) {
          return -1;
        }
        if (x.title > y.title) {
          return 1;
        }
        return 0;
      }
      let resultProjects = projects.sort(SortArray);
      return {
        ...state,
        page: 3,
        projectLoad: state.projectLoad && {
          lastInResponse: state.projectLoad.lastInResponse,
          isCollectionEmpty: state.projectLoad.isCollectionEmpty,
          docs: resultProjects,
        },
      };

    case types.updateProject:
      state.projectLoad &&
        state.projectLoad.docs.splice(
          state.projectLoad.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1,
          action.payload
        );

      return {
        ...state,
        page: 3,
        loading: false,
      };

    case types.resetproject:
      return {
        ...state,
        page: 1,
      };

    case types.exitMemberProject:
      return {
        ...state,
        exitsMember: action.payload,
      };

    case types.removeProject:
      state.projectLoad &&
        state.projectLoad.docs.splice(
          state.projectLoad.docs.findIndex(
            (dc) => dc.uid === action.payload?.uid
          ),
          1
        );

      return {
        ...state,
        isRemove: {
          state: true,
          uidRemove: action.payload?.uid,
          urlPageCover: action.payload?.pageCover,
          urlFiles: action.payload?.files,
        },
      };

    case types.isRemoveProjectReset:
      return {
        ...state,
        isRemove: {
          state: false,
          uidRemove: null,
        },
      };

    case types.loadingMembersProject:
      return {
        ...state,
        loadingMember: action.payload,
      };

    case types.MembersListProject:
      function SortArrayMembers(x, y) {
        if (x.userName < y.userName) {
          return -1;
        }
        if (x.userName > y.userName) {
          return 1;
        }
        return 0;
      }

      return {
        ...state,
        membersProject: [...action.payload].sort(SortArrayMembers),
      };

    case types.NotificationsMembers:
      return {
        ...state,
        notificationsMembers: [...action.payload],
      };

    case types.tempProject:
      return {
        ...state,
        loading: false,
        tempProject: action.payload,
      };

    default:
      return state;
  }
};
