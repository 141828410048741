import React from "react";
import { Modal, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useModalUserExperience } from "Hooks/profile/useModalUserExperience";
import "react-datepicker/dist/react-datepicker.css";
import { imageExistsProduct } from 'helpers/verifyImg';

export const ModalUserExperience = () => {
  const {
    handleShow,
    show,
    handleClose,
    handleSubmit,
    msgError,
    onChangeInput,
    seSelect,
    allOrganization,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useModalUserExperience();

  return (
    <div className="li">
      <div onClick={handleShow} className="cursor-pointer  border-0  ">
        <h5 className="font-xsss text-grey-900  mt-0 fw-700 d-block">
          <div className="d-flex">
            <i className="font-sm ti-cup  text-grey-500"></i>
            <div className="ms-2 mt-1">Add experience</div>
          </div>
        </h5>
      </div>

      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
          {msgError && (
            <div className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0 ">
              {msgError}
            </div>
          )}
          <Modal.Header>
            <Modal.Title>Add Experience</Modal.Title>
            <button
              className="btn ti-close"
              type="button"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <label className="fw-700 mb-0 font-xssss text-grey-900">Job</label>
            <input
              type="text"
              className="form-control"
              name="job"
              placeholder="example: retail sales manager"
              onChange={onChangeInput}
            ></input>

            <label className="fw-700 mb-0 font-xssss text-grey-900">
              Organization*
            </label>

            <Select
              placeholder="search"
              onChange={(select) => seSelect(select)}
              options={allOrganization}
              formatOptionLabel={(allOrganization) => (
                <div className="country-option">
                  <img
                    src={allOrganization?.image}
                    style={{ width: 36 }}
                    alt="country-image"
                    onError={ imageExistsProduct }
                  />
                  <span className="pl-4">{allOrganization?.label}</span>
                </div>
              )}
            />

            <Link to={"/CreateOrganization"}>
              <div className="pt-2 pb-2">
                <Button className="float-right" variant="outline-info">
                  + Create an organization
                </Button>
              </div>
            </Link>

            <label className="pt-3 fw-700 mb-0 font-xssss text-grey-900">
              Start date
            </label>
            <ReactDatePicker
              selected={startDate}
              className="form-control"
              onChange={(date) => setStartDate(date)}
              peekNextMonth
              showMonthDropdown
              maxDate={new Date()}
              showYearDropdown
              dropdownMode="select"
            />

            <label className="fw-700 mb-0 font-xssss text-grey-900">
              End date
            </label>
            <ReactDatePicker
              selected={endDate}
              className="form-control"
              onChange={(date) => setEndDate(date)}
              peekNextMonth
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="outline-info">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
