import React from "react";
import Load from "components/Load";

import { useMyOrganizations } from "Hooks/organization/useMyOrganizations";
import { useScroll } from "Hooks/scroll/useScroll";
import { EmptyData } from "components/shared/EmptyData";
import { CardListMyOrganizationStyle } from "./components/CardListMyOrganizationStyle";
import { PageTitleProfileOrganization } from "./components/PageTitleProfileOrganization";
import { OrganizationLoadingIndicator } from "./loading/OrganizationLoading";
import { usePropietor } from "Hooks/usePropietor";
import { SearchAll2 } from "components/shared/SearchAll2";

export const MyOrganization = () => {
  const {
    docs,
    lastMyOrganizationElementRef,
    loadingLoad,
    loading,
    loadingPagination,
    searchText,
    searchResult,
    handleSearch,
    handleSearchText,
  } = useMyOrganizations();
  const { isPropietor } = usePropietor();

  const title = isPropietor ? "My Organizations" : "Organizations";
  const { divRref } = useScroll();

  return (
    <div ref={divRref}>
      <div className="d-flex pt-0 pb-0 mb-5 m-3">
        <h2 className="fw-700  font-md text-grey-900 d-flex align-items-center">
          {title}
        </h2>
        <div className="ms-auto">
          <PageTitleProfileOrganization
            isActiveButtom={isPropietor}
            title=""
            titleButtom="Create Organization"
            route="/CreateOrganization"
          />
        </div>
      </div>

      <SearchAll2
        placeHolder="Search organizations"
        onChange={handleSearchText}
        value={searchText}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSearch(searchText);
        }}
      />

      {loading && docs?.length <= 0 && (
        <OrganizationLoadingIndicator quantityElement={3} />
      )}

      {docs == null ? null : loadingLoad ? (
        <Load />
      ) : (
        <div className="row ps-2 pe-2">
          {searchResult && searchResult.length > 0 ? (
            searchResult?.map((value) => (
              <div key={value?.objectID} className="col-md-4 col-sm-6 pe-2 ps-2">
                <CardListMyOrganizationStyle
                  uid={value?.objectID}
                  title={value?.name}
                  description={value?.description}
                  logoUrl={value?.logoUrl}
                  isPropietor={isPropietor}
                />
              </div>
            ))
          ) : (!loading && docs.length === 0) ||
            (!loading && searchResult && searchResult.length === 0) ? (
            <EmptyData
              pathImg="/assets/images/NoData.svg"
              title="There are no courses"
            />
          ) : (
            docs.map((value, index) => {
              if (docs.length === index + 1) {
                return (
                  <div
                    key={value?.uid}
                    ref={lastMyOrganizationElementRef}
                    className="col-md-4 col-sm-6 pe-2 ps-2"
                  >
                    <CardListMyOrganizationStyle
                      uid={value?.uid}
                      title={value?.name}
                      description={value?.description}
                      logoUrl={value?.logoUrl}
                      isPropietor={isPropietor}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={value.uid} className="col-md-4 col-sm-6 pe-2 ps-2">
                    <CardListMyOrganizationStyle
                      uid={value?.uid}
                      title={value?.name}
                      description={value?.description}
                      logoUrl={value?.logoUrl}
                      isPropietor={isPropietor}
                    />
                  </div>
                );
              }
            })
          )}
        </div>
      )}
      {loadingPagination && <Load />}
    </div>
  );
};
