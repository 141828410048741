import { getInformation } from "actions/settingInformations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useInfoSetting = () => {
  const dispatch = useDispatch();
  const { infoIndicator } = useSelector((state) => state.infoGlobal);

  const [docInfo, setDocInfo] = useState(null);
  const [docInfoLoading, setDocInfoLoading] = useState(null);

  useEffect(() => {
    if (!infoIndicator) {
      setDocInfoLoading(true);
      dispatch(getInformation());
    }
  }, []);

  useEffect(() => {
    if (infoIndicator) {
      setDocInfoLoading(false);
      setDocInfo(infoIndicator);
    }
    return () => setDocInfo(null);
  }, [infoIndicator]);

  return {
    docInfo,
    docInfoLoading
  };
};
