import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../../Hooks/userForms";
import { removeError, setError } from "../../../actions/ui";
import { updateProject } from "../../../actions/project";

export const ViewProjectModalAddAchievements = ({ uidProject, listado }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { msgError } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const [achievements, setachievements] = useForm({
    title: "",
    description: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValidate()) {
      const data ={
        uid: uidProject,
        achievements: listado?.length > 0 ? [...listado, achievements] : [achievements]
      }



      dispatch(updateProject(data));
      setShow(false);
    }
  };

  const isFormValidate = () => {
    if (achievements?.title.trim().length < 3) {
      dispatch(setError("Very short title"));
      return false;
    } else if (achievements?.description.trim().length < 5) {
      dispatch(setError("very short description"));
      return false;
    }

    dispatch(removeError());

    return true;
  };

  return (
    <>
      <Button
        onClick={handleShow}
        className="float-right ms-auto"
        variant="outline-info"
      >
        + Add Achievements
      </Button>

      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>Add Achievements</Modal.Title>
            <button
              className="btn ti-close"
              type="button"
              onClick={handleClose}
              closeButton
            ></button>
          </Modal.Header>
          <Modal.Body>
            {msgError && (
              <div className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0 ">
                {msgError}
              </div>
            )}

            <label className="fw-700 mb-0 font-xssss text-grey-900">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              name="title"
              placeholder="example: retail sales manager"
              onChange={setachievements}
            ></input>

            <label className="fw-700 mb-0 font-xssss text-grey-900">
              Description
            </label>
            <textarea
              type="text"
              className="form-control"
              name="description"
              placeholder="example: retail sales manager"
              onChange={setachievements}
              maxLength="200"
            ></textarea>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="outline-info" >
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
