import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import "../../../css/scroll-horizontal.css";

export const MenuItemProfile = ({ routeProfile }) => {
  const elRef = useRef(null);

  const useScroll = () => {
    const executeScroll = () => elRef.current.scrollIntoView();
    return [executeScroll, elRef];
  };

  return (
    <div className="card-body w-100 shadow-none mb-0 p-0 border-top-xs">
      <ul
        className=" nav-tabs h55 product-info-tab border-bottom-0 ps-4 horizontal-slide"
        id="pills-tab"
        role="tablist"
      >
        <li className="list-inline-item me-5 span2">
          <NavLink
            exact
            to={`${routeProfile}/`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab"
          >
            Information
          </NavLink>
        </li>
        <li className="list-inline-item me-5 span2">
          <NavLink
            to={`${routeProfile}/friends`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab"
          >
            Friends
          </NavLink>
        </li>
        <li className="list-inline-item me-5 span2">
          <NavLink
                isActive={(match, location) => {
                  let pathStrings = location.pathname.split("/my-");
                  if (match) {
                    return true;
                  } else if ("my-groups".includes(pathStrings[pathStrings.length - 1])) {
                    return true;
                  } else if ("my-groups-participate".includes(pathStrings[pathStrings.length - 1])) {
                    return true;
                  } else {
                    return false;
                  }
                }}
            to={`${routeProfile}/groups/my-groups`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab"
          >
            Groups
          </NavLink>
        </li>
        <li className="list-inline-item me-5 span2">
          <NavLink
            to={`${routeProfile}/organization/my-organization`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab"
          >
            Organizations
          </NavLink>
        </li>
        <li className="list-inline-item me-5 span2">
          <NavLink
             isActive={(match, location) => {
              let pathStrings = location.pathname.split("/my-");
              if (match) {
                return true;
              } else if ("my-projects".includes(pathStrings[pathStrings.length - 1])) {
                return true;
              } else if ("my-projects-participate".includes(pathStrings[pathStrings.length - 1])) {
                return true;
              } else {
                return false;
              }
            }}
            to={`${routeProfile}/projects/my-projects`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab"
          >
            Projects
          </NavLink>
        </li>

        <li
          className="list-inline-item me-5 span2"
          ref={elRef}
          onClick={useScroll}
        >
          <NavLink
            isActive={(match, location) => {
              let pathStrings = location.pathname.split("/my-");
              if (match) {
                return true;
              } else if ("my-courses".includes(pathStrings[pathStrings.length - 1])) {
                return true;
              } else if ("my-courses-participate".includes(pathStrings[pathStrings.length - 1])) {
                return true;
              } else {
                return false;
              }
            }}
            to={`${routeProfile}/courses/my-courses`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab"
          >
            Courses
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
