import { types } from "../types/types";

const inicialState = {
  infoIndicator: null,
  privacySettings: null,
  helpsInfo: null,
};

export const globalInfoReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.getBasicInfo:
      return {
        ...state,
        privacySettings: action.payload,
      };
    case types.getInformationIndicators:
      return {
        ...state,
        infoIndicator: action.payload,
      };
    case types.getHelpsInfo:
      return {
        ...state,
        helpsInfo: action.payload,
      };

    default:
      return state;
  }
};
