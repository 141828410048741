import React from "react";
import Header from "../components/headers/Header";

import { AllGroups } from "../components/group/all/AllGroups";

const GroupsPage = () => {
  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <AllGroups />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      {/* <Appfooter />  */}
    </>
  );
};

export default GroupsPage;
