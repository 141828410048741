import { types } from "../types/types";

const inicialState = {
  user: {
    experience: [],
  },
  updateProfile: {
    state: false,
    type: "",
  },
  MyFriendsSize: null,
  myFriends: [],
  loading: false,
  users: [],
};

export const userReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.getUpdateProfile: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case types.getUpdateProfileImage: {
      return {
        ...state,
        user: action.payload?.user,
        updateProfile: {
          state: true,
          type: action.payload?.type,
        },
      };
    }

    case types.getUpdateProfileImageReset: {
      return {
        ...state,
        updateProfile: {
          state: false,
          type: "",
        },
      };
    }

    case types.getCreateProfileExperience: {
      let experiences;
      if (state.user.experience) {
        experiences = [...state.user.experience, action.payload];
      } else {
        experiences = [action.payload];
      }

      return {
        ...state,
        user: {
          ...state.user,
          experience: experiences,
        },
      };
    }

    case types.getUpdateProfileExperience: {
      if (state.user.experience) {
        const position = state.user?.experience.findIndex(
          (dc) => dc.organizationId === action.payload.organizationId
        );
        state.user.experience.splice(position, 1, action.payload);
      }
      return {
        ...state,
      };
    }

    case types.getOrganizationExperienceToRemoveProfile: {
      if (state.user.experience) {
        const position = state.user?.experience.findIndex(
          (dc) => dc.uid === action.payload.uid
        );
        state.user.experience.splice(position, 1);
      }
      return {
        ...state,
      };
    }

    case types.setCoverimage: {
      return {
        ...state,
        updateCoverPage: state.updateCoverPage + 1,
      };
    }

    case types.SetProfileImage: {
      return {
        ...state,
        updateProfilePage: state.updateProfilePage + 1,
      };
    }

    case types.userLoading: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.userLoadingoff: {
      return {
        ...state,
        loading: false,
      };
    }

    case types.usersExperience: {
      return {
        ...state,
        experiences: [...action.payload],
      };
    }

    case types.users:
      return {
        ...state,
        users: action.payload,
      };

    default:
      return state;
  }
};
