import connectNetwork from "helpers/connectNetwork";
import { configApi, urlApi } from "helpers/env";
import { types } from "types/types";

export const getInformation = () => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi();
      let resp = await fetch(`${urlApi}/privacySettingsInfo`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setInformationIndicators(response?.doc));
      }
    } catch (error) {
      return null;
    }
  };
};

export const getInformationHelps = () => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi();
      let resp = await fetch(`${urlApi}/settingsInfoHelps`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setInformationHelps(response?.doc));
      }
    } catch (error) {
      return null;
    }
  };
};

export const setInformationIndicators = (datos) => {
  return {
    type: types.getInformationIndicators,
    payload: datos,
  };
};

export const setInformationHelps = (datos) => {
  return {
    type: types.getHelpsInfo,
    payload: datos,
  };
};
