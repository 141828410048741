import { useEffect, useRef, useState, useCallback } from "react";
import algoliasearch from "algoliasearch/lite";

import { useDispatch, useSelector } from "react-redux";
import { LIMIT } from "helpers/env";
import { getAllGroups } from "actions/group";

const client = algoliasearch(
  process.env.REACT_APP_APPLICATION_ID,
  process.env.REACT_APP_SEARCH_API_KEY
);
const searchIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_INDEX_GROUPS_GLOBAL
);

let theRequestWasMade = false;

export const useSearchGroups = () => {
  const dispatch = useDispatch();
  const observer = useRef();

  const { uid } = useSelector((state) => state.auth);
  const { allGroups, loading } = useSelector((state) => state.groups);
  const [docs, setDocs] = useState([]);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isGroupsListEmpty, setIsGroupsListEmpty] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);

  const [loadingLoad, setLoadingLoad] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      limit: LIMIT,
      action: action,
      lastInResp: lastInResp,
    };
    dispatch(getAllGroups(data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastGroupsElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isGroupsListEmpty && !searchResult) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isGroupsListEmpty, lastInResponse, searchResult]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (
      !theRequestWasMade &&
      allGroups?.docs.length <= 0 &&
      allGroups?.isCollectionEmpty == -1
    ) {
      theRequestWasMade = true;
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
    }
  }, [allGroups]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER GROUPS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (allGroups?.docs.length > 0) {
      allGroups.docs.map((element) => {
        if (!docs.includes(element)) {
          setDocs((ele) => ele.concat(element));
        }
      });
      setlastInResponse(allGroups?.lastInResponse);
      setIsGroupsListEmpty(allGroups?.isCollectionEmpty === 0);
      setLoadingPagination(false);
    } else if (
      allGroups?.docs.length <= 0 &&
      allGroups?.isCollectionEmpty == -1
    ) {
      setDocs([]);
      setLoadingPagination(false);
    }
  }, [allGroups, isGroupsListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC ONCHANGE SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target?.value);
  };

  const handleSearch = async (queryText) => {
    try {
      setLoadingLoad(true);
      const result = await searchIndex.search(queryText);
      setSearchResult(result.hits);
      setLoadingLoad(false);
    } catch (error) {
      setSearchResult([]);
      setLoadingLoad(false);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchResult(null);
    }
  }, [searchText]);
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===>RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    docs,
    loading,
    lastGroupsElementRef,
    loadingLoad,
    loadingPagination,
    searchResult,
    searchText,
    handleSearchText,
    handleSearch,
  };
};
