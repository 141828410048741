import React from "react";
import Load from "components/Load";
import PostViewGroupShare from "./components/PostViewGroupShare";
import PostViewShare from "./components/PostViewShare";
import PostView from "./PostView";

import {
  postType,
  typeEventLike,
  typeEventPost,
} from "../../helpers/typeEvent";
import { usePostViewList } from "../../Hooks/post/usePostViewList";
import { EmptyData } from "../shared/EmptyData";

const PostViewList = () => {
  const { loading, loadingPagination, docs, lastPostsElementRef } =
    usePostViewList();

  if (!loading && docs.length <= 0) {
    return <EmptyData title="Not publications" />;
  }

  return (
    <>
      {docs?.map((element, index) => {
        if (docs.length === index + 1) {
          if (element?.type == undefined) {
            return (
              <div key={element?.uid} ref={lastPostsElementRef}>
                <PostView
                  id={element?.uid}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.home}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                  friendId={element?.friend}
                  nameFriend={element?.friendProfile?.userName}
                />
              </div>
            );
          } else if (
            element?.type == postType?.project ||
            element?.type == postType?.course
          ) {
            return (
              <div key={element?.uid} ref={lastPostsElementRef}>
                <PostViewShare
                  id={element?.uid}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.home}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                />
              </div>
            );
          } else {
            return (
              <div key={element?.uid} ref={lastPostsElementRef}>
                <PostViewGroupShare
                  id={element?.uid}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.home}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                />
              </div>
            );
          }
        } else {
          if (element?.type == undefined) {
            return (
              <div key={element?.uid}>
                <PostView
                  id={element?.uid}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.home}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                  friendId={element?.friend}
                  nameFriend={element?.friendProfile?.userName}
                />
              </div>
            );
          } else if (
            element?.type == postType?.project ||
            element?.type == postType?.course
          ) {
            return (
              <div key={element?.uid}>
                <PostViewShare
                  id={element?.uid}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.home}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                />
              </div>
            );
          } else {
            return (
              <div key={element?.uid}>
                <PostViewGroupShare
                  id={element?.uid}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.global}
                  typeLike={typeEventLike.home}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                />
              </div>
            );
          }
        }
      })}
      {(loadingPagination || loading) && (
        <div className="mb-5">
          <Load />
        </div>
      )}
    </>
  );
};

export default PostViewList;
