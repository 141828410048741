import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupsSuggestion } from "actions/group";

let theRequestWasMade = false;


export const useGroupSuggestions = () => {
  const dispatch = useDispatch();

  const { groupsSuggestion, loadingSuggestion: loading } = useSelector(
    (state) => state.groups
  );
  const [groupsSuggestionList, setGroupsSuggestionslist] = useState(groupsSuggestion);

  useEffect(() => {
    if (!theRequestWasMade && groupsSuggestion?.length <= 0) {
      dispatch(getGroupsSuggestion());
      theRequestWasMade = true;
    }
  }, [groupsSuggestion]);

  useEffect(() => {
    if (groupsSuggestion?.length > 0 && groupsSuggestionList?.length <= 0) {
      setGroupsSuggestionslist(groupsSuggestion);
    }
  }, [groupsSuggestion, groupsSuggestionList]);

  return {
    loading,
    groupsSuggestionList,
  };
};
