import React from "react";
import { useSelector } from "react-redux";

export const CardLoadingFiles = () => {
  const { percentFiles } = useSelector((state) => state.ui);
  return (
    <div>
        {
            (percentFiles > 0 && percentFiles < 100) &&  
      <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
        <div className="card-body  ">
          <div className="card-body p-0">
            <a className="font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center">
              <i className="btn-round-sm font-xs text-primary feather-file-plus me-2 bg-greylight"></i>
              Uploading Files
            </a>
          </div>

          <div className="d-flex pt-2">
            <progress
              max={100}
              style={{ width: "90%" }}
              value={percentFiles}
            ></progress>
            <ul style={{ lineHeight: "1", margin: "auto" }}>
              {percentFiles + "%"}
            </ul>
          </div>
        </div>
      </div>
        
        }
    </div>
  );
};
