import React from "react";

export const ViewOrganizationDescription = ({ organization }) => {
  return (
    <div className="card w-100 border-0 p-3 bg-white shadow-xss rounded-xxl">
      <h4 className="fw-900 mb-3 font-xss text-grey-900">About</h4>
      {organization?.description && (
        <div>
          <h4 className="fw-700 mb-3 font-xsss text-grey-900">Description</h4>
          <h6>{organization?.description}</h6>
        </div>
      )}
      {organization?.slogan && (
        <div>
          <h4 className="fw-700 mb-3 font-xsss text-grey-900">Slogan</h4>
          <h6>{organization?.slogan}</h6>
        </div>
      )}
      {organization?.mission && (
        <div>
          <h4 className="fw-700 mb-3 font-xsss text-grey-900">Mission</h4>
          <h6>{organization?.mission}</h6>
        </div>
      )}
      {organization?.vision && (
        <div>
          <h4 className="fw-700 mb-3 font-xsss text-grey-900">Vision</h4>
          <h6>{organization?.vision}</h6>
        </div>
      )}
    </div>
  );
};
