import React from "react";
import PropTypes from "prop-types";

import { Route, Redirect} from "react-router-dom";
import { ViewProjectInformationItem } from "../all/ViewProjectInformationItem";
import { ViewProjectModalAddAchievements } from "../all/ViewProjectModalAddAchievemnts";
import { ViewProjectRequest } from "../all/ViewProjectRequest";
import { ViewProjectForum } from "../all/ViewProjectForum";
import { ViewMembersProject } from "../all/ViewMembersProject";
import { ViewProjectAchievements } from "../all/ViewProjectAchievements";

export const RouterViewProject = ({
  projectId,
  idUser,
  exitsMember,
  projectValue,
  loadingMember,
}) => {
  return (
    <div>
      <Route exact path="/ViewProject/:id">
        <ViewProjectInformationItem
          exitsMember={exitsMember}
          projectValue={projectValue}
        />
      </Route>
      <Route path={`/ViewProject/:id/members`}>
        <ViewMembersProject
          idUser={idUser}
          idUserOwner={projectValue?.userId}
          projectId={projectValue?.uid}
          loading={loadingMember}
        />
      </Route>
      <Route path={`/ViewProject/:id/achievements`}>
        <ViewProjectAchievements
          uidProject={projectId}
          uidUser={projectValue?.userId}
        />
      </Route>
      { idUser == projectValue?.userId &&
      <Route path={`/ViewProject/:id/requests`}>
        <ViewProjectRequest />
      </Route>
       
      }
      <Route path={`/ViewProject/:id/forum`}>
        <ViewProjectForum id={projectId} existMember={exitsMember} />
      </Route>
    </div>
  );
};

RouterViewProject.propTypes = {
  projectId: PropTypes.string.isRequired,
  idUser: PropTypes.string.isRequired,
  exitsMember: PropTypes.string.isRequired,
  projectValue: PropTypes.object.isRequired,
  loadingMember: PropTypes.bool.isRequired,
};
