import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectSuggestions } from "actions/project";

let theRequestWasMade = false;

export const useProjectSuggestions = () => {
  const dispatch = useDispatch();
  const { projectSuggestions, loadingSuggestion: loading } = useSelector((state) => state.projects);
  const [projectSuggestionslist, setProjectSuggestionslist] = useState(projectSuggestions);

  useEffect(() => {
    if (!theRequestWasMade && projectSuggestions?.length <= 0 ) {
      dispatch(getProjectSuggestions());
      theRequestWasMade = true;
    }
  }, []);

  useEffect(() => {
    if (projectSuggestions?.length > 0  && projectSuggestionslist?.length <= 0) {
      setProjectSuggestionslist(projectSuggestions);
    }
  }, [projectSuggestions, projectSuggestionslist]);

  return {
    projectSuggestionslist,
    loading,
  };
};