import Select from "react-select";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUser } from "actions/profile";
import { removeError, setError } from "actions/ui";

export const AddSkills = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { uid } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user.user);
  const {msgError} = useSelector( state => state.ui );
  const dispatch = useDispatch();
  const [skills, setskills] = useState({
    skill: [],
    skillsImput: [],
  });
  const animatedComponents = makeAnimated();
  const [inputValidate, setinputValidate] = useState("")

  function onChange(checkedValues) {
    setskills({
      ...skills,
      skill: checkedValues,
    });
  }

  //  Validations
  const  isFormValidate = () =>{
    const pattern = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g;

    if(inputValidate.trim().length >= 1 && !pattern.test(inputValidate)){
      dispatch(setError("Must not have special or numeric characters"));
      return false
    }else if(skills.skillsImput.length < 1 && skills.skill.length < 1 ){
      dispatch(setError("you must select or add a skill"));
        return false;
    }
  
    dispatch(removeError());

     return true;
 }
  function onChangeInput(e) {
    const data = e.target.value;
    setinputValidate(data);
    var nameArr = data.split(",");
    if (e.target.value) {
      setskills({
        ...skills,
        skillsImput: nameArr,
      });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const nameArr2 = skills.skillsImput.map((datos) => ({
      label: datos,
      value: datos,
    }));
    const data = {
      uid,
      skills: user?.skills ? [...skills.skill, ...nameArr2, ...user?.skills] : [...skills.skill, ...nameArr2]  ,
    };
    if(isFormValidate()){
      dispatch(UpdateUser(data));
     handleClose();
    }
  };

  const options = [
    { label: "Diseño de ingenieria", value: "Diseño de ingenieria" },
    { label: "Gestion de la ingenieria", value: "Gestion de la ingenieria" },
    { label: "Electronica", value: "Electronica" },
    { label: "Diseño", value: "Diseño" },
    { label: "Programación", value: "Programación" },
    { label: "Arquitectura de software", value: "Arquitectura de software" },
    { label: "Hardware", value: "Hardware" },
    { label: "Ingenieria electrica", value: "Ingenieria electrica" },
    { label: "Control del movimiento", value: "Control del movimiento" },
    { label: "Scada", value: "Scada" },
    { label: "Ingenieria", value: "Ingenieria" },
    { label: "Docente", value: "Docente" },
    { label: "Estudiante", value: "Estudiante" },
  ];

  return (
    <div className="li">
      <div
        onClick={handleShow}
        className="cursor-pointer border-0  "
      >
        <h5 className="font-xsss text-grey-900  mt-0 fw-700 d-block">
        <div className="d-flex">
          <i className="font-sm ti-medall  text-grey-500"></i> 
          <div className="ms-2 mt-1">
          Add skills
          </div>
        </div>
        </h5>
      </div>


      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
        { 
            msgError && 
           <div className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0 ">
        {msgError}
           </div>}
          <Modal.Header>
            <Modal.Title>Add skills</Modal.Title>
            <button
              className="btn ti-close"
              type="button"
              onClick={handleClose}
              closeButton
            ></button>
          </Modal.Header>
          <Modal.Body>
            <Select
              onChange={onChange}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={options}
            />
            <label className="fw-700 mb-0 font-xssss text-grey-900">
              Add New Skill
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="ej:(Ingeniro,Abogado,Docente)"
              onChange={onChangeInput}
            ></input>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="outline-info" >
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
