import { types } from "../types/types";
import Swal from "sweetalert2";
import { configApi, urlApi, urlApiDev } from "../helpers/env";
import {
  AddReplyForum,
  CreateCommetForum,
  DeleteForum,
  getPaginaCommetForum,
  getReplyForum,
  LoadingForum,
  loadingReply,
  UpdateForum,
} from "./project";
import { setPage403 } from "./page403";
import connectNetwork from "../helpers/connectNetwork";

export const createCourse = (data) => {
  return async (dispatch) => {
    dispatch(loadingCourses());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/addCourses`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setCreateCourse(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getCoursesById = (userId, data) => {
  return async (dispatch) => {
    dispatch(loadingCourses());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getCoursesById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(loadingCoursesOff());
        const docs = {
          userData: userId,
          ...response.docs,
        };
        dispatch(getCourses(docs));
      }
    } catch (error) {
      dispatch(loadingCoursesOff());
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getCoursesParticipateById = (userId, data) => {
  return async (dispatch) => {
    dispatch(loadingCourses());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getCoursesParticipateById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(loadingCoursesOff());
        const docs = {
          userData: userId,
          ...response.docs,
        };
        dispatch(setCoursesParticipate(docs));
      }
    } catch (error) {
      dispatch(loadingCoursesOff());
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};
export const getCourseById = (data) => {
  return async (dispatch) => {
    dispatch(loadingCourses());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getCourseById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setCourseById(response.doc));
      }
      if (resp.status == 403) {
        dispatch(setPage403());
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateCourseById = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateCourseById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setUpdateCourse(response.course));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getCourseSuggestions = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/courseSuggestion`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getCoursesSuggestion(response.docs));
      }
    } catch (error) {}
  };
};

export const AddMemberCourse = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/notificationCourseRequest`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(ExistMembersCourse(response.stateMembers));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getCourseMembersById = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getCourseMembersById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(ExistMembersCourse(response.stateMembers));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateCourseMembersById = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateCourseMembersById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(ExistMembersCourse(response.stateMembers));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getCourseMembersList = (data) => {
  return async (dispatch) => {
    dispatch(LoadingMembersCourse(true));
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getCourseMembersList`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(LoadingMembersCourse(false));
        dispatch(AllMembersCourse(response.courseMembers));
      }
    } catch (error) {}
  };
};

export const getAllCourses = (data) => {
  return async (dispatch) => {
    dispatch(LoadingAllCourse(true));
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getAllCourses`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getAllCoursesResponse(response.docs));
        dispatch(LoadingAllCourse(false));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
      dispatch(LoadingAllCourse(false));
    }
  };
};

export const getCourseMembersPendingById = (data) => {
  return async (dispatch) => {
    dispatch(LoadingMembersCourse(true));
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getCourseMembersPendingById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(LoadingMembersCourse(false));
        dispatch(NotificationsMembersCourse(response.docs));
      }
    } catch (error) {
      dispatch(LoadingMembersCourse(false));
    }
  };
};

export const CreateCommentsForumCourse = (data) => {
  Swal.fire({
    allowOutsideClick: false,
    icon: "warning",
    text: "Espere por favor...",
  });

  Swal.showLoading();

  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/createForumCommentCourse`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(CreateCommetForum(response.doc));
        Swal.close();
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Publicado Correctamente",
          toast: true,
          timer: 3000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getPostsForumCourse = (data, uid) => {
  return async (dispatch) => {
    dispatch(LoadingForum(true));
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getPostsForumCourse`, config);
      let response = await resp.json();
      if (response.ok) {
        const docs = {
          userData: uid,
          ...response.docs,
        };
        dispatch(getPaginaCommetForum(docs));
        dispatch(LoadingForum(false));
      }
    } catch (error) {
      Swal.close();
      dispatch(LoadingForum(false));
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const UpdateCommentsForumCourse = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateCommetsForumCourse`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(UpdateForum(response.doc));
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Actualizado Correctamente",
          toast: true,
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const DeleteCommentsForumCourse = (data) => {
  return async (dispatch) => {
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/DeleteCommetsForumCourse`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(DeleteForum(response.doc));
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Actualizado Correctamente",
          toast: true,
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const CreateReplyCommentsForumCourse = (data) => {
  return async (dispatch) => {

    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
     //VERIFY CONNECTION
     const isOnLine = await connectNetwork();
     if (!isOnLine) {
       return;
     }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/createReplyForumCommentCourse`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(AddReplyForum(response.doc));
        Swal.close();
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Publicado Correctamente",
          toast: true,
          timer: 3000,
          showConfirmButton: false
        });
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getReplyCommentsForumCourse = (data) => {
  return async (dispatch) => {

     //VERIFY CONNECTION
     const isOnLine = await connectNetwork();
     if (!isOnLine) {
       return;
      }
      dispatch(loadingReply(true))
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getReplyForumCommentCourse`, config);
      let response = await resp.json();
      console.log(response);
      if (response.ok) {
        dispatch(getReplyForum(response.docs));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};


export const setCreateCourse = (data) => {
  return {
    type: types.createCourse,
    payload: data,
  };
};

export const setCourseById = (data) => {
  return {
    type: types.tempCourse,
    payload: data,
  };
};

export const setCourseId = (uid) => {
  return {
    type: types.tempCourseLoad,
    payload: uid,
  };
};

export const setTempCourse = (data) => {
  return {
    type: types.tempCourse,
    payload: data,
  };
};

export const setUpdateCourse = (data) => {
  return {
    type: types.updateCourse,
    payload: data,
  };
};

export const setTempCourseReset = () => {
  return {
    type: types.resetCourse,
  };
};

export const loadingCourses = () => {
  return {
    type: types.loadingCourses,
  };
};

export const loadingCoursesOff = () => {
  return {
    type: types.loadingCoursesoff,
  };
};

export const getCourses = (data) => {
  return {
    type: types.getCourses,
    payload: data,
  };
};

export const setCoursesParticipate = (data) => {
  return {
    type: types.getCoursesParticipate,
    payload: data,
  };
};

export const getCoursesSuggestion = (data) => {
  return {
    type: types.courseSuggestions,
    payload: data,
  };
};

export const getAllCoursesResponse = (datos) => {
  return {
    type: types.allCourses,
    payload: datos,
  };
};

export const ExistMembersCourse = (data) => {
  return {
    type: types.exitsMemberCourse,
    payload: data,
  };
};

export const LoadingMembersCourse = (datos) => {
  return {
    type: types.loadingMembersCourse,
    payload: datos,
  };
};

export const AllMembersCourse = (datos) => {
  return {
    type: types.membersCourse,
    payload: datos,
  };
};

export const getIsChangeMyCourse = () => {
  return {
    type: types.isChangeMyCourse,
  };
};

export const NotificationsMembersCourse = (datos) => {
  return {
    type: types.notificationsMemberscourse,
    payload: datos,
  };
};

export const LoadingAllCourse = (datos) => {
  return {
    type: types.loagingAllCourse,
    payload: datos,
  };
};
