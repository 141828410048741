export const imageExistsProfile = (e) => {
  e.target.onerror = null;
  e.target.src = "/assets/images/profile.png";
};

export const imageExistsProduct = (e) => {
  e.target.onerror = null;
  e.target.src = "/assets/images/1200x450.png";
};

export const imageExistsProductUser = (e) => {
  e.target.onerror = null;
  e.target.src = "/assets/images/user.png";
};

export const imageExistsSliderInformation = (e) => {
  e.target.onerror = null;
  e.target.src = "/assets/images/principal.png";
};


export const imageExistsProductDiv = async (imgUrl) => {
  const r = await new Promise((resolve, reject) => {
    fetch(imgUrl, {
      mode: "no-cors",
    })
      .then((d) => {
        if (d.status == 403 ||  d.status == 0){
          resolve(false);
        }else {
          resolve(true);
        }
      })
      .catch(() => {
        resolve(false);
      });
  });

  return r;
};

export const imageExists403 = (e) => {
  e.target.onerror = null;
  e.target.src = "/assets/images/403.svg";
};
