import React from 'react'
import PropTypes from 'prop-types'

export const OrganizationLogo = ({ logoSelectedPrevious, changeImage }) => {

    return (
        <>
            { 
             (logoSelectedPrevious != null ?
               <div className="col-lg-12 mb-3">
                    <span className="mont-font fw-600 font-xsss">Upload Logo</span>
                    <div className="card mt-3 border-0">
                        <div className="card-body d-flex justify-content-between align-items-end p-0" >
                            <div className="form-group mb-0 w-100" style={{ textAlign: 'center' }}>
                                <input type="file" name="file" id="file" className="input-file" onChange={ changeImage } />
                                <label htmlFor="file" >
                                <img src= { logoSelectedPrevious } className="rounded-6 text-center bg-white btn-tertiary js-labelFile p-1 w-20 border-dashed" style={ {  width: 200, height: 200, borderRadius: 100, objectFit: 'cover' } }></img>
                                </label>
                            </div>
                        </div>
                    </div>
               </div>
             : <div className="col-lg-12 mb-3">
                    <span className="mont-font fw-600 font-xsss">Upload Logo</span>
                    <div className="card mt-3 border-0">
                        <div className="card-body d-flex justify-content-between align-items-end p-0" >
                            <div className="form-group mb-0 w-100" style={{ textAlign: 'center' }}>
                                <input type="file" name="file" id="file" className="input-file" onChange={ changeImage } />
                                <label htmlFor="file" >
                                <div className="rounded-6 text-center bg-white btn-tertiary js-labelFile p-4 w-20 border-dashed" style={ {  width: 200, height: 200, borderRadius: 100 } }>
                                    <i className="ti-cloud-down large-icon  d-block p-4"></i>
                                    <span className="js-fileName">Drag and drop or click to replace</span>
                                </div>
                                </label>
                            </div>
                        </div>
                    </div>
                 </div>)

            }
        </>
    )
}


OrganizationLogo.propTypes = {
    changeImage: PropTypes.func.isRequired
}
