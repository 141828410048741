import React from 'react'
import { Modal } from "react-bootstrap";
import { useEditPost } from 'Hooks/post/useEditPost';
import { ModaPostlFilePrevious } from './ModaPostlFilePrevious';
import { ModalCreatePostOption } from './ModalCreatePostOption';
import '../../css/postOptions.css';

export const EditPost = ({ data, groupId }) => {

    const {photoUrl,forms: values, handleShow, show, handleClose, hanledCreatePost, removeFile, ImageSelectedPrevious, fileType, changeImage, changeTextArea } = useEditPost(data, groupId);
    const style = ImageSelectedPrevious != null ? { overflow: 'scroll', height: '500px' } : { overflowY: 'scroll' }

    return (
        <>
          <li onClick={handleShow} className="cursor-pointer d-flex">
             <div className='icon-li '>
               <i className="font-sm ti-pencil  text-grey"></i> 
             </div> 
             <div className='text-li'>
                 <span className='pb-1'> Edit post </span>
             </div>
          </li>
   
          <Modal 
            show={show} 
            onHide={handleClose}
            size="lg"
            >
            <form onSubmit={hanledCreatePost}>
              <Modal.Header>
                <Modal.Title>Edit post </Modal.Title>
                <button className="btn ti-close" type="button" onClick={handleClose}></button>
              </Modal.Header>
              <Modal.Body>
                <div style={style}>
                    <div className="card-body p-0 mt-1 position-relative">
                       <figure className="avatar position-absolute ms-2 mt-1 top-5"><img src={ photoUrl != null ? photoUrl : "assets/images/user.png"} alt="icon" className="shadow-sm rounded-circle w30" /></figure>
                       <textarea name="message" className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder="What's on your mind?" value={ values?.content }  onChange={ changeTextArea }></textarea>
                    </div> 
                    <ModaPostlFilePrevious  
                      ImageSelectedPrevious = { ImageSelectedPrevious }
                      fileType= { fileType } 
                      removeFile= { removeFile } />
                </div>
              <ModalCreatePostOption 
                 titleBtn=' Edit Post'
                 changeImage = { changeImage } 
                 handleClose = { handleClose } />
              </Modal.Body>
            </form>
          </Modal>
        </>
      );
}


