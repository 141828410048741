
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { existMember, getProjectById, getProjectMembersById, isChangeCommentsForums } from "actions/project";


export const useViewProject = () => {
  const dispatch = useDispatch();
  const { Id } = useParams();
  const { exitsMember, loadingMember, tempProject, loading } = useSelector((state) => state.project);
  const { page403 } = useSelector((state) => state.page403);
  const { uid } = useSelector((state) => state.auth);
  const [projectValue, setProjectValue] = useState(tempProject);
  const idUserProject = tempProject?.userId;

  useEffect(() => {
    tempProject?.uid !== Id && dispatch(getProjectById({ uid: Id }));
    tempProject?.uid !== Id && dispatch(existMember(null));
    tempProject?.uid !== Id &&  dispatch(isChangeCommentsForums());
    tempProject?.uid !== Id &&
      dispatch(
        getProjectMembersById({
          projectId: Id,
          userId: uid,
        })
      );

    
  }, []);

  useEffect(() => {
    if (tempProject) {
      setProjectValue(tempProject);
    }
  }, [loading]);

  return {
    Id,
    uid,
    projectValue,
    exitsMember,
    loading,
    loadingMember,
    idUserProject,
    page403
  };
};
