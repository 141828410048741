import React from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import Load from "components/Load";

import { Modal, Button } from "react-bootstrap";
import { useModalEditExperience } from "Hooks/profile/useModalEditExperience";
import { imageExistsProduct } from 'helpers/verifyImg';

export const ModalEditExperience = ({ uidExperience }) => {
  const {
    handleShow,
    show,
    handleSubmit,
    handleClose,
    select,
    inputJob,
    onChangeInput,
    allOrganization,
    endDate,
    setEndDate,
    seSelect,
    startDate,
    setStartDate,
  } = useModalEditExperience(uidExperience);

  return (
    <>
      <i
        onClick={handleShow}
        className="cursor-pointer btn-round-sm font-xs ms-auto text-primary feather-edit-3  bg-greylight"
      ></i>

      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>Edit Experience</Modal.Title>
            <button
              className="btn ti-close"
              type="button"
              onClick={handleClose}
              >
              </button>
          </Modal.Header>
          <Modal.Body>
            {select && (
              <div>
                <label className="fw-700 mb-0 font-xssss text-grey-900">
                  Job
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="job"
                  value={inputJob}
                  placeholder="example: retail sales manager"
                  onChange={onChangeInput}
                ></input>

                <label className="fw-700 mb-0 font-xssss text-grey-900">
                  Organization*
                </label>

                <Select
                  placeholder="search"
                  defaultValue={select}
                  onChange={(selecte) => seSelect(selecte)}
                  formatOptionLabel={(allOrganization) => (
                    <div className="country-option">
                      <img
                        src={allOrganization?.image}
                        style={{ width: 36 }}
                        alt="country-image"
                        onError={ imageExistsProduct }
                      />
                      <span className="pl-4">{allOrganization?.label}</span>
                    </div>
                  )}
                  options={allOrganization}
                />

                <label className="pt-3 fw-700 mb-0 font-xssss text-grey-900">
                  Start date
                </label>
                <ReactDatePicker
                  selected={startDate}
                  className="form-control"
                  onChange={(date) => setStartDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />

                <label className="fw-700 mb-0 font-xssss text-grey-900">
                  End date
                </label>
                <ReactDatePicker
                  selected={endDate}
                  className="form-control"
                  onChange={(date) => setEndDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />
              </div>
            )}

            {!select && <Load/>}
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="outline-info" onClick={handleClose}>
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
