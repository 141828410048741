import React from "react";
import ProfileCardThree from "components/profile/ProfileCardThree";
import { RouterUserPage } from "./router/RouterUserPage";
import { UserPageLoadingBrowser } from "./loading/UserPageLoadingBrowser";
import { UserPageLoadingMobile } from "./loading/UserPageLoadingMobile";
import { useMediaQuery } from "react-responsive";


const UserPageView = ({ name, routeProfile, photoUrl, loading }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });
  return (
    <>
      {loading  ? (
        <>
          {isDesktopOrLaptop ? (
            <UserPageLoadingBrowser />
          ) : (
            <UserPageLoadingMobile />
          )}
        </>
      ) : (
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12 mb-3">
                  <ProfileCardThree
                    name={name}
                    routeProfile={routeProfile}
                    photoUrl={photoUrl}
                  />
                </div>
                <div className="col-xl-12 mb-3">
                 <RouterUserPage routeProfile={ routeProfile }/>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default UserPageView;
