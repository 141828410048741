import { types } from "../types/types";
import Swal from "sweetalert2";
import { configApi, urlApi, urlApiDev } from "../helpers/env";
import { setPage403 } from "./page403";
import connectNetwork from "../helpers/connectNetwork";

export const createProject = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
     //VERIFY CONNECTION
     const isOnLine = await connectNetwork();
     if (!isOnLine) {
       return;
     }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/createProject`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch(getCreate(response.project));
        Swal.close();
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getMyProjects = (userId, data) => {
  return async (dispatch) => {
    dispatch(Loading());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getMyProjectsById`, config);
      let response = await resp.json();

      if (response.ok) {
        const docs = {
          userData: userId,
          ...response.docs,
        };
        dispatch(getMyProject(docs));
      }

      return response;
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
      return false;
    }
  };
};

export const getMyProjectsParticipate = (userId, data) => {
  return async (dispatch) => {
    dispatch(Loading());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getProjectsParticipateById`, config);
      let response = await resp.json();

      if (response.ok) {
        const docs = {
          userData: userId,
          ...response.docs,
        };
        dispatch(setMyProjectParticipate(docs));
      }

      return response;
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
      return false;
    }
  };
};

export const getProjectById = (data) => {
  return async (dispatch) => {
    dispatch(Loadingview());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getProjectById`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch(getView(response.project));
      }
      if (resp.status == 403) {
        dispatch(setPage403());
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateProjectEnd = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
    //VERIFY CONNECTION
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateProjectById`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch(getCreateUpdateEnd(response.project));
        Swal.close();
        Swal.fire("Ok", "Proyecto Creado correctamente", "success");
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateProject = (data) => {
  return async (dispatch) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
     //VERIFY CONNECTION
     const isOnLine = await connectNetwork();
     if (!isOnLine) {
       return;
     }

    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateProjectById`, config);
      let response = await resp.json();

      if (response.ok) {
        dispatch(getUpdateProject(response.project));
        Swal.close();
        Swal.fire("Ok", "Proyecto Creado correctamente", "success");
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const removeProjectById = (data) => {
  return async (dispatch) => {
  
    dispatch(Loadingview());
     //VERIFY CONNECTION
     const isOnLine = await connectNetwork();
     if (!isOnLine) {
       return;
     }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/removeProjectById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(removeProject(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const AddMemberProject = (data) => {
  return async (dispatch) => {
    //    dispatch(Loadingview());
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/notificationProjectRequest`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(existMember(response.stateMembers));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getProjectMembersById = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getProjectMembersById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(existMember(response.stateMembers));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateProjectMembersById = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateProjectMembersById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(existMember(response.stateMembers));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getProjectSuggestions = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/projectSuggestion`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getProjectSuggestion(response.docs));
      }
    } catch (error) {}
  };
};

export const getAllProjects = (data) => {
  return async (dispatch) => {
    dispatch(LoadingMembersAllProject(true));
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getAllProjects`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(getAllProjectsResponse(response.docs));
        dispatch(LoadingMembersAllProject(false));
      }
    } catch (error) {
      dispatch(LoadingMembersAllProject(false));
    }
  };
};

export const getProjectMembersList = (data) => {
  return async (dispatch) => {
    dispatch(LoadingMembersProject(true));
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getProjectMembersList`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(LoadingMembersProject(false));
        dispatch(AllMembersProject(response.projectMembers));
      }
    } catch (error) {}
  };
};

export const getProjectMembersPendingById = (data) => {
  return async (dispatch) => {
    dispatch(LoadingMembersProject(true));
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getProjectMembersPendingById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(LoadingMembersProject(false));
        dispatch(NotificationsMembers(response.docs));
      }
    } catch (error) {
      dispatch(LoadingMembersProject(false));
    }
  };
};

export const CreateCommentsForum = (data) => {
  return async (dispatch) => {

    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
     //VERIFY CONNECTION
     const isOnLine = await connectNetwork();
     if (!isOnLine) {
       return;
     }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/createForumComment`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(CreateCommetForum(response.doc));
        Swal.close();
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Publicado Correctamente",
          toast: true,
          timer: 3000,
          showConfirmButton: false
        });
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const CreateReplyCommentsForum = (data) => {
  return async (dispatch) => {

    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      text: "Espere por favor...",
    });

    Swal.showLoading();
     //VERIFY CONNECTION
     const isOnLine = await connectNetwork();
     if (!isOnLine) {
       return;
     }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/createReplyForumComment`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(AddReplyForum(response.doc));
        Swal.close();
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Publicado Correctamente",
          toast: true,
          timer: 3000,
          showConfirmButton: false
        });
      }
    } catch (error) {
      Swal.close();
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getReplyCommentsForum = (data) => {
  return async (dispatch) => {

     //VERIFY CONNECTION
     const isOnLine = await connectNetwork();
     if (!isOnLine) {
       return;
     }
     dispatch(loadingReply(true))
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getReplyForumComment`, config);
      let response = await resp.json();
      console.log(response);
      if (response.ok) {
        dispatch(getReplyForum(response.docs));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getPostsForumProject = (data, uid) => {
  return async (dispatch) => {
    dispatch(LoadingForum(true));
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/getPostsForumProject`, config);
      let response = await resp.json();
      if (response.ok) {
        const docs = {
          userData: uid,
          ...response.docs,
        };
        dispatch(getPaginaCommetForum(docs));
        dispatch(LoadingForum(false));
      }
    } catch (error) {
      Swal.close();
      dispatch(LoadingForum(false));
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const UpdateCommentsForum = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateCommetsForum`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(UpdateForum(response.doc));
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Actualizado Correctamente",
          toast: true,
          timer: 3000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const DeleteCommentsForum = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/DeleteCommetsForum`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(DeleteForum(response.doc));
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Actualizado Correctamente",
          toast: true,
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getAllProjectsResponse = (datos) => {
  return {
    type: types.projectsGlobal,
    payload: datos,
  };
};

export const getMyProject = (datos) => {
  return {
    type: types.getMyProject,
    payload: datos,
  };
};

export const setMyProjectParticipate = (datos) => {
  return {
    type: types.getMyProjectParticipate,
    payload: datos,
  };
};

export const Loading = () => {
  return {
    type: types.loadingproject,
  };
};

export const getView = (datos) => {
  return {
    type: types.tempProject,
    payload: datos,
  };
};

export const getCreate = (datos) => {
  return {
    type: types.createProject,
    payload: datos,
  };
};

export const getUpdateProject = (datos) => {
  return {
    type: types.updateProject,
    payload: datos,
  };
};

export const getCreateUpdateEnd = (datos) => {
  return {
    type: types.createUpdateEnd,
    payload: datos,
  };
};

export const Loadingview = () => {
  return {
    type: types.loadingproject,
  };
};

export const existMember = (data) => {
  return {
    type: types.exitMemberProject,
    payload: data,
  };
};
export const resetProject = () => {
  return {
    type: types.resetproject,
  };
};

export const getProjectSuggestion = (data) => {
  return {
    type: types.projectSuggestions,
    payload: data,
  };
};

export const removeProject = (datos) => {
  return {
    type: types.removeProject,
    payload: datos,
  };
};

export const isRemoveProjectReset = () => {
  return {
    type: types.isRemoveProjectReset,
  };
};

export const getIsChangeMyProject = () => {
  return {
    type: types.isChangeMyProject,
  };
};
export const LoadingMembersProject = (datos) => {
  return {
    type: types.loadingMembersProject,
    payload: datos,
  };
};

export const AllMembersProject = (datos) => {
  return {
    type: types.MembersListProject,
    payload: datos,
  };
};

export const NotificationsMembers = (datos) => {
  return {
    type: types.NotificationsMembers,
    payload: datos,
  };
};

export const LoadingMembersAllProject = (datos) => {
  return {
    type: types.loadingAllProject,
    payload: datos,
  };
};

export const LoadingForum = (datos) => {
  return {
    type: types.loadingCreateForum,
    payload: datos,
  };
};

export const CreateCommetForum = (datos) => {
  return {
    type: types.CreateForum,
    payload: datos,
  };
};

export const getPaginaCommetForum = (datos) => {
  return {
    type: types.getCommentsForum,
    payload: datos,
  };
};

export const RessetCommentsCreateForum = () => {
  return {
    type: types.resetCreateComment,
  };
};

export const isChangeCommentsForums = () => {
  return {
    type: types.isChangeCommentsForum,
  };
};

export const UpdateForum = (data) => {
  return {
    type: types.updateForum,
    payload: data,
  };
};

export const DeleteForum = (data) => {
  return {
    type: types.deleteForum,
    payload: data,
  };
};

export const UpdateForumReset = () => {
  return {
    type: types.updateForumReset,
  };
};

export const DeleteForumReset = () => {
  return {
    type: types.deleteForumReset,
  };
};

export const AddReplyForum = (reply) => {
  return {
    type: types.updateForumReplyComment,
    payload: reply
  };
};

export const getReplyForum = (reply) => {
  return {
    type: types.getFoumReplyComment,
    payload: reply
  };
};

export const loadingReply = (load) => {
  return {
    type: types.loadinRplyForum,
    payload: load
  };
};

