import React from "react";
import CommentsHeaders from "./CommentsHeaders";
import Load from "components/Load";

import { useHistory } from "react-router-dom";
import { useCommentsPostGroup } from "Hooks/post/useCommentsPostGroup";
import { PostImageAvailableBrowser } from "./components/PostImageAvailableBrowser";
import { PostImageAvailableMobile } from "./components/PostImageAvailableMobile";
import { PostImageNotAvailable } from "./components/PostImageNotAvailable";
import { useMediaQuery } from "react-responsive";
import { typeEventLike, typeEventPost } from "helpers/typeEvent";
import "../../../css/PostViewImgStyle.css";

export const CommentsPostGroup = () => {
  const { ImageSelectedPrevious, fileType, post, loading, groupId } =
    useCommentsPostGroup();
  const navigate = useHistory();
  const onBack = () => navigate.goBack();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });

  if (loading) {
    return (
      <>
        <CommentsHeaders onBack={onBack} />
        <div
          className="col-sm-12 col-md-6 overflow-auto m-auto"
          style={{ paddingTop: 100, height: "100vh" }}
        >
          <Load />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <CommentsHeaders onBack={onBack} />
        </div>
        <div className="col-12">
          {ImageSelectedPrevious != "" && post?.attachments != "" ? (
            <>
              {isDesktopOrLaptop && (
                <PostImageAvailableBrowser
                  post={post}
                  imageSelectedPrevious={ImageSelectedPrevious}
                  type={typeEventPost.group}
                  typeLike={typeEventLike.temp}
                  groupId={groupId}
                />
              )}

              {isTabletOrMobile && (
                <PostImageAvailableMobile
                  post={post}
                  imageSelectedPrevious={ImageSelectedPrevious}
                  type={typeEventPost.group}
                  typeLike={typeEventLike.temp}
                  groupId={groupId}
                />
              )}
            </>
          ) : (
            <PostImageNotAvailable
              post={post}
              type={typeEventPost.group}
              typeLike={typeEventLike.temp}
              groupId={groupId}
            />
          )}
        </div>
      </div>
    </>
  );
};
