import React, { useState } from "react";
import Swal from "sweetalert2";
import uploadFile from "helpers/uploadFile";

import { useDispatch, useSelector } from "react-redux";
import { updateProfilePhoto } from "actions/auth";
import { useVerify } from "Hooks/useVerify";

export const ModalUpdatePhotoProfile = () => {
  const dispatch = useDispatch();
  const verify = useVerify();

  const { uid } = useSelector((state) => state.auth);

  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  const uploadFileAlert = async () => {
    const { value: file } = await Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload your profile picture",
      },
    });

    if (file) {
      Swal.fire({
        allowOutsideClick: false,
        icon: "warning",
        text: "Espere por favor...",
      });

      Swal.showLoading();
      const url = await uploadFile(
        file,
        `profilesFolder/${uid}/profile.jpg`,
        setProgress,
        setError
      );

      const data = { uid, url };
      dispatch(updateProfilePhoto(data, "photoProfile"));

      if (url && url != "") {
        const reader = new FileReader();
        reader.onload = (e) => {
          Swal.fire({
            title: "Your uploaded picture",
            imageUrl: e.target.result,
            imageAlt: "The uploaded picture",
          });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <>
      {verify && (
        <li
          onClick={uploadFileAlert}
          className="cursor-pointer nav-content-bttn open-font"
        >
          <i className="feather-edit btn-round-md bg-primary-gradiant me-3"></i>
          <span> </span>
        </li>
      )}
    </>
  );
};

export default ModalUpdatePhotoProfile;
