import { types } from "../types/types";
import Swal from "sweetalert2";
import { configApi, urlApi } from "../helpers/env";
import connectNetwork from "../helpers/connectNetwork";

export const createComment = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    // dispatch(Loading());
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/addCommentPost`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(CreateComent(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const createCommentGroup = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }

    // dispatch(Loading());
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/addCommentPostGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(CreateComent(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getAllCommentGroup = (data, { isLoading = true }) => {
  return async (dispatch) => {

    isLoading && dispatch(Loading());

    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/loadPostsCommentsByIdGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(GetAll(response.docs));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const getAllComment = (data, { isLoading = true }) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/loadPostsCommentsById`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(GetAll(response.docs));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateCommentPostGroup = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateCommentPostGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(updateComment(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const updateCommentPost = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/updateCommentPost`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(updateComment(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const removeCommentPost = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/removeCommentPost`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setRemoveComment(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const removeCommentPostGroup = (data) => {
  return async (dispatch) => {
    const isOnLine = await connectNetwork();
    if (!isOnLine) {
      return;
    }
    try {
      let config = configApi(data);
      let resp = await fetch(`${urlApi}/removeCommentPostGroup`, config);
      let response = await resp.json();
      if (response.ok) {
        dispatch(setRemoveComment(response.doc));
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  };
};

export const CreateComent = (data) => {
  return {
    type: types.createComents,
    payload: data,
  };
};

export const updateComment = (data) => {
  return {
    type: types.updateComment,
    payload: data,
  };
};

export const setUpdateResetComment = () => {
  return {
    type: types.isUpdateReset,
  };
};

export const setRemoveResetComment = () => {
  return {
    type: types.isRemoveReset,
  };
};


export const Loading = () => {
  return {
    type: types.loadingComents,
  };
};

export const GetAll = (data) => {
  return {
    type: types.getComments,
    payload: data,
  };
};

export const Temp = (data) => {
  return {
    type: types.tempComment,
    payload: data,
  };
};

export const setRemoveComment = (data) => {
  return {
    type: types.getRemoveComment,
    payload: data,
  };
};

export const resetEditComments = () => {
  return {
    type: types.resetComment,
  };
};

export const resetCommentsPostById = () => {
  return {
    type: types.resetCommentsPostById,
  };
};

export const viewNewPostById = () => {
  return {
    type: types.viewNewPost,
  };
};
