import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export const PageTitleProfileOrganization = ({
  title,
  titleButtom,
  route,
  isActiveButtom = true,
}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      {isDesktopOrLaptop && (
        <PageTitleProfileBrowser
          title={title}
          titleButtom={titleButtom}
          route={route}
          isActiveButtom={isActiveButtom}
        />
      )}
      {isTabletOrMobile && (
        <PageTitleProfileMobile
          title={title}
          titleButtom={titleButtom}
          route={route}
          isActiveButtom={isActiveButtom}
        />
      )}
    </>
  );
};

export const PageTitleProfileBrowser = ({
  title,
  titleButtom,
  route,
  isActiveButtom = true,
}) => {
  return (
    <div className="card border-1 w-100 d-block d-flex border-0">
      <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
        {!isActiveButtom && title}
        {isActiveButtom && (
          <Link className="ms-auto" to={route}>
            <Button className="float-right ms-auto" variant="outline-info">
              {titleButtom}
            </Button>
          </Link>
        )}
      </h2>
    </div>
  );
};

export const PageTitleProfileMobile = ({
  title,
  route,
  isActiveButtom = true,
}) => {
  return (
    <div className="card  border-1 d-flex d-flex border-0 pt-4 mb-3">
      <h2 className=" d-flex font-md text-grey-900">
        {!isActiveButtom && title}
        {isActiveButtom && (
          <Link className="ms-auto mt-2" to={route}>
            <Button className="float-right ms-auto" variant="outline-info">
              <i className="feather-plus"> </i>
            </Button>
          </Link>
        )}
      </h2>
    </div>
  );
};
