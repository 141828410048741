import React from 'react'
import PropTypes from 'prop-types'

export const CourseCoverPage = ({ coverPageSelectedPrevious, changeImage }) => {
    return (
        <>
             {  coverPageSelectedPrevious != null ?
                  <div className="col-lg-12 mb-3">
                  <span className="mont-font fw-600 font-xsss">Upload PageCover</span>
                  <div className="card mt-3 border-0">
                      <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                              <input type="file" name="file2" id="file2" className="input-file" onChange={ (e) => changeImage(e) } />
                              <label htmlFor="file2" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-1 w-100 border-dashed">
                                <img src= { coverPageSelectedPrevious } style={ { width: '100%', height: 130, objectFit: 'cover' } }></img>
                              </label>
                          </div>
                      </div>
                     </div>
                  </div>
                : <div className="col-lg-12 mb-3">
                    <span className="mont-font fw-600 font-xsss">Upload PageCover</span>
                    <div className="card mt-3 border-0">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                            <div className="form-group mb-0 w-100">
                                <input type="file" name="file2" id="file2" className="input-file" onChange={ (e)=> changeImage(e)  } />
                                <label htmlFor="file2" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                    <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                    <span className="js-fileName">Drag and drop or click to replace</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

CourseCoverPage.propTypes = {
    changeImage: PropTypes.func.isRequired
}
