import React from "react";
import { ProfileMyFriendLoading } from "./ProfileMyFriendLoadingComponents";
import { useMediaQuery } from "react-responsive";

export const ViewProjectLoading = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });

  return (
    <>
      <div className="row">
        {isDesktopOrLaptop && (
          <>
            <div className="col-md-3 col-sm-4 pe-2 ps-2">
              <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                  <ProfileMyFriendLoading />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-4 pe-2 ps-2">
              <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                  <ProfileMyFriendLoading />
                </div>
              </div>
            </div>
          </>
        )}
        {(isDesktopOrLaptop || isTabletOrMobile) && (
          <>
            <div className="col-md-3 col-sm-4 pe-2 ps-2">
              <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                  <ProfileMyFriendLoading />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-4 pe-2 ps-2">
              <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                  <ProfileMyFriendLoading />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
