import React from 'react'
import Load from 'components/Load';

import { useCreateOrganization } from 'Hooks/organization/useCreateOrganization';
import { AddMembers } from 'components/shared/AddMembers';
import { OrganizationInfoBsicForm } from './OrganizationInfoBsicForm';
import { OrganizationLogo } from './OrganizationLogo';

export const CreateOrganizationForm = () => {

    const { loading, handleCreateOrganization, changeImage, logoSelectedPrevious, values, handleInputChange, isUpdate } = useCreateOrganization(); 
  
    return (
        (loading)? <Load/>
       : (
            <form onSubmit={handleCreateOrganization}>
                <OrganizationLogo logoSelectedPrevious={ logoSelectedPrevious } changeImage={ changeImage }/>
                <OrganizationInfoBsicForm values={ values } onChange= { handleInputChange }/>
                {/* <AddMembers handleInputChange={ handleInputChange } />   */}
                <div className="pb-5">
                  <button disabled={ isUpdate } type="submit" className=" bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-blockbg-current text-center text-white font-xsss fw-600 p-3 w200 rounded-3 d-inline-block ">Create Organization</button>
                </div>
            </form>
         )
    )
}
