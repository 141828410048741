import { types } from "../types/types";

const inicialState = {
  MyFriendsSize: 0,
  friendLoad: {
    userData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  loading: false,
  tempFriend: null,
  tempFriendInvitation: null,
  usersFriends: [],
};

export const friendReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.tempFriendInvitation: {
      return {
        ...state,
        tempFriendInvitation: action.payload,
      };
    }

    case types.getMyFriendsSize: {
      return {
        ...state,
        MyFriendsSize: action.payload,
      };
    }

    case types.getMyFriends: {
      let hashMyFriends = {};
      let isLoadedData =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getMyFriendssCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;
      return {
        ...state,
        friendLoad: isLoadedData
          ? state.friendLoad
            ? {
                userData: action.payload?.userData,
                docs: [
                  ...state.friendLoad?.docs,
                  ...action.payload?.docs,
                ],
                // .filter((o) =>
                //   hashMyFriends[o.uid] ? false : (hashMyFriends[o.uid] = true)
                // ),
                isCollectionEmpty: getMyFriendssCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.friendLoad?.userData,
              docs: state.friendLoad?.docs,
              isCollectionEmpty: getMyFriendssCollectionEmpty,
              lastInResponse: state.friendLoad?.lastInResponse,
            },
      };
    }

    case types.isChangeMyFriend:
      return {
        ...state,
        friendLoad: {
          userData: null,
          docs: [],
          isCollectionEmpty: -1,
          lastInResponse: null,
        },
      };

    case types.isChangeMyFriendReset:
      return {
        ...state,
        isChange: false,
      };

    case types.loadingMyfriends:
      return {
        ...state,
        loading: true,
      };

    case types.loadingMyfriendsoff:
      return {
        ...state,
        loading: false,
      };

    case types.usersFriends:
      return {
        ...state,
        usersFriends: action.payload,
      };

    default:
      return state;
  }
};
