import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export const TypeShare = ({ data, name, onChange, ref }) => {
  return (
    <>
      {ref && (
        <Select
          styles={{ zIndex: "auto" }}
          name={name}
          placeholder={name}
          onChange={(value) => onChange({ target: { value, name } })}
          closeMenuOnSelect={true}
          components={animatedComponents}
          formatOptionLabel={(data) => (
            <div className="country-option">
              <span className="pl-1">{data?.label}</span>
            </div>
          )}
          options={data}
          ref={ref}
        />
      )}
      ,
      {!ref && (
        <Select
          styles={{ zIndex: "auto" }}
          name={name}
          placeholder={name}
          onChange={(value) => onChange({ target: { value, name } })}
          closeMenuOnSelect={true}
          components={animatedComponents}
          formatOptionLabel={(data) => (
            <div className="country-option">
              <span className="pl-1">{data?.label}</span>
            </div>
          )}
          options={data}
        />
      )}
    </>
  );
};
