import React from "react";
import { useViewUserExperience } from "Hooks/profile/useViewUserExperience";
import { ProfileModalExperience } from "components/profile/components/ProfileModalExperience";
import { CardListOrganizationExperience } from "./CardListOrganizationExperience";
import { useVerify } from "Hooks/useVerify";

export const ViewUserExperience = () => {
  const {
    handleShow,
    show,
    handleClose,
    deleteFile,
    handleSubmit,
    experience,
  } = useViewUserExperience();
  const verify = useVerify();

  return (
    <>
      {experience?.length > 0 && (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
          <div className="card-body d-flex align-items-center p-4">
            <h4 className="fw-700 mb-0 font-xssss text-grey-900">
              Experiences
            </h4>
            {verify && (
              <i
                onClick={handleShow}
                className="cursor-pointer btn-round-sm font-xs ms-auto text-primary feather-trash-2 me-2 bg-greylight"
              ></i>
            )}
          </div>

          {experience?.map((value) => (
            <div key={`${value?.uid}`}>
              <CardListOrganizationExperience value={value} />
            </div>
          ))}

          <ProfileModalExperience
            show={show}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            listFiles={experience}
            deleteFile={deleteFile}
          />
        </div>
      )}
    </>
  );
};
