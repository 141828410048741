import React from "react";
import Load from "components/Load";
import SearchAll from "components/shared/SearchAll";

import { useSearchGroups } from "Hooks/group/useAllGroups";
import { EmptyData } from "components/shared/EmptyData";
import { CardListGroupStyle } from "components/group/components/CardListGroupStyle";
import { MyGroupLoadingIndicator } from "components/group/loading/MyGroupLoadingIndicator";

export const AllGroups = () => {
  const {
    docs,
    lastGroupsElementRef,
    loading,
    loadingLoad,
    loadingPagination,
    searchText,
    searchResult,
    handleSearch,
    handleSearchText,
  } = useSearchGroups();

  return (
    <>
      <SearchAll
        title="Groups"
        placeHolder="Search groups"
        onChange={handleSearchText}
        value={searchText}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSearch(searchText);
        }}
      />

      {loading && !searchResult && (
        <MyGroupLoadingIndicator isPropietor={false} quantityElement={8} />
      )}

      {docs == null ? null : loadingLoad ? (
        <Load />
      ) : (
        <div className="row ps-2 pe-1 mb-4">
          {searchResult && searchResult.length > 0 ? (
            searchResult?.map((value) => (
              <div
                key={value?.objectID}
                className="col-md-6 col-sm-6 pe-2 ps-2"
              >
                <CardListGroupStyle
                  uid={value?.uid}
                  pageCover={value?.pageCover}
                  title={value?.title}
                  email={value?.email}
                  isPropietor={false}
                />
              </div>
            ))
          ) : (!loading && docs.length === 0) ||
            (!loading && searchResult && searchResult.length === 0) ? (
            <EmptyData
              pathImg="/assets/images/NoData.svg"
              title="There are no groups"
            />
          ) : (
            docs.map((value, index) => {
              if (docs.length === index + 1) {
                return (
                  <div
                    key={value?.uid}
                    ref={lastGroupsElementRef}
                    className="col-md-6 col-sm-6 pe-2 ps-2"
                  >
                    <CardListGroupStyle
                      uid={value?.uid}
                      pageCover={value?.pageCover}
                      title={value?.title}
                      email={value?.email}
                      isPropietor={false}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={value?.uid} className="col-md-6 col-sm-6 pe-2 ps-2">
                    <CardListGroupStyle
                      uid={value?.uid}
                      pageCover={value?.pageCover}
                      title={value?.title}
                      email={value?.email}
                      isPropietor={false}
                    />
                  </div>
                );
              }
            })
          )}
        </div>
      )}
      {loadingPagination && <Load />}
    </>
  );
};
