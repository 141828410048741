import React, { useState } from 'react'
import { useRef, useEffect } from "react";

export const useBtnOptionMenu = () => {

    const [openModalView, setOpenModalView] = useState({
        modal: false
      });
    
      const openModal = () => {
        setOpenModalView({
            modal: !openModalView.modal
        })
      }

      const closeModal = () => {
        setOpenModalView({
            modal: false
        })
      }

    const notiClass = `${openModalView.modal ? " show" : ""}`;

    const wrapperRef = useRef(null);

    const  handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            closeModal();
        }
    }

      useEffect(() => {
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
              document.removeEventListener("mousedown", handleClickOutside);
          };
      }, [wrapperRef]);



      return {
        openModal,
        notiClass,
        wrapperRef,
        closeModal
      }
    
}

