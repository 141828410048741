import { types } from "../types/types";

const inicialState = {
  loading: true,
  loadingMyPost: true,
  posts: {
    userData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  postsByIdLoad: {
    userData: null,
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  tempPost: null,
  isCreate: {
    state: false,
    uidCreate: null,
  },
  isUpdate: {
    state: false,
    uidUpdate: null,
  },
  isRemove: {
    state: false,
    uidRemove: null,
  },
  isChange: false,
};

export const postsReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.loadingPost:
      return {
        ...state,
        loading: true,
      };

    case types.createPost:
      return {
        ...state,
        isCreate: {
          state: true,
          uidCreate: action.payload?.uid,
        },
        posts: state.posts && {
          lastInResponse: state.posts.lastInResponse,
          isCollectionEmpty: state.posts.isCollectionEmpty,
          docs: [action.payload, ...state.posts.docs],
        },
        postsByIdLoad: state.postsByIdLoad && {
          lastInResponse: state.postsByIdLoad.lastInResponse,
          isCollectionEmpty: state.postsByIdLoad.isCollectionEmpty,
          docs: [action.payload, ...state.postsByIdLoad.docs],
        },
      };

    case types.getPostsById:
      let hashPostsById = {};
      let isLoadedData =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getPostsByIdIsCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;

      function SortArray(x, y) {
        if (x.createDate > y.createDate) {
          return -1;
        }
        if (x.createDate < y.createDate) {
          return 1;
        }
        return 0;
      }
      return {
        ...state,
        loadingMyPost: false,
        postsByIdLoad: isLoadedData
          ? state.postsByIdLoad
            ? {
                userData: action.payload.userData,
                docs: [...state.postsByIdLoad?.docs, ...action.payload?.docs]
                  .filter((o) =>
                    hashPostsById[o.uid] ? false : (hashPostsById[o.uid] = true)
                  )
                  .sort(SortArray),
                isCollectionEmpty: getPostsByIdIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.postsByIdLoad?.userData,
              docs: state.postsByIdLoad?.docs,
              isCollectionEmpty: getPostsByIdIsCollectionEmpty,
              lastInResponse: state.postsByIdLoad?.lastInResponse,
            },
      };

    case types.isChange:
      return {
        ...state,
        loadingMyPost : true,
        postsByIdLoad: {
          userData: null,
          docs: [],
          isCollectionEmpty: -1,
          lastInResponse: null,
        },
      };
    case types.getPosts:
      let hashPosts = {};
      let isLoadedDataGetPosts =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let getPostsIsCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;

      return {
        ...state,
        posts: isLoadedDataGetPosts
          ? state.posts
            ? {
                docs: [...state.posts.docs, ...action.payload?.docs].filter(
                  (o) => (hashPosts[o.uid] ? false : (hashPosts[o.uid] = true))
                ),
                isCollectionEmpty: getPostsIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              userData: state.posts?.userData,
              docs: state.posts?.docs,
              isCollectionEmpty: getPostsIsCollectionEmpty,
              lastInResponse: null,
            },
        loading: false,
      };

    case types.getLikePost:
      state.posts.docs.length > 0 &&
        state.posts.docs.splice(
          state.posts.docs.findIndex((dc) => dc.uid === action.payload.uid),
          1,
          action.payload
        );
      return {
        ...state,
        isUpdate: {
          state: true,
          uidUpdate: action.payload.uid,
        },
        tempPost: state?.tempPost ? action.payload : state?.tempPost,
      };

    case types.getConformLikePost:
      return {
        ...state,
        isUpdate: {
          state: false,
          uidUpdate: null,
        },
      };

    case types.updatePost:
      state.posts &&
        state.posts.docs.splice(
          state.posts.docs.findIndex((dc) => dc.uid === action.payload.uid),
          1,
          action.payload
        );

      state.postsByIdLoad &&
        state.postsByIdLoad.docs.splice(
          state.postsByIdLoad.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1,
          action.payload
        );

      return {
        ...state,
        isUpdate: {
          state: true,
          uidUpdate: action.payload?.uid,
        },
      };

    case types.tempPost:
      return {
        ...state,
        tempPost: action.payload,
      };

    case types.removePost:
      state.posts &&
        state.posts.docs.splice(
          state.posts.docs.findIndex((dc) => dc.uid === action.payload.uid),
          1
        );

      state.postsByIdLoad &&
        state.postsByIdLoad.docs.splice(
          state.postsByIdLoad.docs.findIndex(
            (dc) => dc.uid === action.payload.uid
          ),
          1
        );

      return {
        ...state,
        isRemove: {
          state: true,
          uidRemove: action.payload?.uid,
        },
      };

    case types.isCreateReset:
      return {
        ...state,
        isCreate: {
          state: false,
          uidCreate: null,
        },
      };

    case types.isUpdateReset:
      return {
        ...state,
        isUpdate: {
          state: false,
          uidUpdate: null,
        },
      };

    case types.isRemoveReset:
      return {
        ...state,
        isRemove: {
          state: false,
          uidRemove: null,
        },
      };

    case types.loadingMyPost:
      return {
        ...state,
        loadingMyPost: true,
      };

    default:
      return state;
  }
};
