import React, { useState, useEffect } from "react";
import Darkbutton from "components/Darkbutton";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { imageExistsProfile } from "helpers/verifyImg";

const CommentsHeaders = ({ onBack }) => {
  const { photoUrl, name } = useSelector((state) => state.auth);
  const { updateCoverPage, updateProfilePage } = useSelector(
    (state) => state.user
  );
  const [currentTimeStamp, setCurrentTimeStamp] = useState(
    new Date().getTime()
  );

  useEffect(() => {
    setCurrentTimeStamp(new Date().getTime());
  }, [updateCoverPage, updateProfilePage]);

  const ProfilePhoto = photoUrl.includes("https://storage.googleapis")
    ? photoUrl
      ? photoUrl + `&version=` + currentTimeStamp
      : `https://ui-avatars.com/api/?name=${name}`
    : photoUrl;

  return (
    <div className="nav-header bg-white shadow-xs border-0">
      <div className="nav-top" style={{ width: "100%" }}>
        <div className="d-flex " style={{ marginRight: "auto" }}>
          <div
            className=" mt-1 ml-2 "
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              width: 70,
              height: "50px",
              cursor: "pointer",
            }}
            onClick={onBack}>
            <i className="feather-x font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500"></i>
          </div>
          <img
            className="mt-1"
            src="/assets/images/icono.png"
            style={{ objectFit: "contain", height: 50, width: 100 }}
            alt="DEAL"
          />
        </div>

        <div
          className=" d-flex ml-auto"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            width: "100px",
            height: "50px",
            marginLeft: "auto",
          }}
        >
          <Darkbutton />
          <Link to="/defaultsettings" className="p-0 ms-3  mb-1 menu-icon">
            <img src={ProfilePhoto} onError={ imageExistsProfile } alt="user" className="w40 mb-1" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CommentsHeaders;
