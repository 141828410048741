import React from "react";
import Load from "../Load";

import { useCreateGroup } from "Hooks/group/useCreateGroup";
import { AddMembers } from "components/shared/AddMembers";
import { GroupInfoBasicForm } from "./GroupInfoBasicForm";
import { GroupPageCover } from "./GroupPageCover";

export const CreateGroupForm = () => {
  const {
    loading,
    handleCreateGroup,
    values,
    handleInputChange,
    coverPageSelectedPrevious,
    changeImage,
    msgError,
    isUpdate,
  } = useCreateGroup();

  return loading ? (
    <div style={{ height: "100%", minHeight: "100vh", paddingTop: "50%" }}>
      <Load />
    </div>
  ) : (
    <form onSubmit={handleCreateGroup}>
      {msgError && (
        <div className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0 ">
          {msgError}
        </div>
      )}

      <GroupInfoBasicForm values={values} onChange={handleInputChange} />
      <GroupPageCover
        coverPageSelectedPrevious={coverPageSelectedPrevious}
        changeImage={changeImage}
      />
      <AddMembers handleInputChange={handleInputChange} />
      <div className="pb-5">
        <button
          disabled={isUpdate}
          type="submit"
          className=" bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-blockbg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block mb-5 ">
          Create Group
        </button>
      </div>
    </form>
  );
};
