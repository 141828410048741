import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateCommentPost,
  updateCommentPostGroup,
} from "actions/comments";

export const useEditComment = (comment, type, groupId, closeModal) => {
  const [show, setShow] = useState(false);
  const [Input, setInput] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  function onChangeInput(e) {
    const data = e.target.value;
    setInput(data);
  }

  const EditCommentPost = (e) => {
    e.preventDefault();
    if (type == "global") {
      const data = {
        uid: comment?.uid,
        postId: comment?.postId,
        content: Input,
      };
      dispatch(updateCommentPost(data));
    } else {
      const data = {
        uid: comment?.uid,
        postId: comment?.postId,
        content: Input,
        groupId,
      };
      dispatch(updateCommentPostGroup(data));
    }
    closeModal();
  };

  return {
    show,
    EditCommentPost,
    onChangeInput,
    handleShow,
    handleClose,
  };
};
