import React from 'react'
import { BtnOptionMenuFriends } from './BtnOptionMenuFreinds';
import { useBtnAddFriends } from 'Hooks/friends/useBtnAddFriends';
import { stateFriend } from 'helpers/stateFriend';
import '../css/friends.css';

export const BtnAddFriends = () => {

    const { 
        loading,
        isFriend, 
        i_invite, 
        onChangeAddFriend, 
        onChangeRemoveFriend,
        onChangeCancelFriend, 
        onChangeLockedFriend, 
        onChangeUnlockFriend, 
        onChangeAcceptInvitationFriend } = useBtnAddFriends();

    if(loading){
        return <div className="btnAddFriendsLoading"> 
        <i className="spinner-border"></i>
    </div>
    }

    return (
       <>
            {
                (isFriend == stateFriend.no_data.description || isFriend == stateFriend.cancel.description || isFriend == stateFriend.remove.description ) &&  
                <div className="btnAddFriends" onClick={ onChangeAddFriend }> 
                    <i className="feather-user-plus font-sm text-grey"></i>
                    <span className='ms-2'>add my friends </span>
                </div>
            }
            {
                (isFriend == stateFriend.loading.description) && 
                <div className="btnAddFriendsLoading"> 
                    <i className="spinner-border"></i>
                </div>
            }
            {
                (isFriend == stateFriend.pending.description && i_invite ) && 
                <div className="btnAddFriends" onClick={ onChangeCancelFriend }> 
                    <i className="feather-user-x font-sm text-grey"></i>
                    <span className='ms-2'> cancel invite </span>
                </div>

            }
            {
                (isFriend == stateFriend.pending.description && !i_invite ) && 
                <div className="btnAddFriends" onClick={ onChangeAcceptInvitationFriend }> 
                    <i className="feather-user-check font-sm text-grey"></i>
                    <span className='ms-2'> accept invitation </span>
                </div>
            }
            {
                 (isFriend == stateFriend.locked.description) && 
                 <div className="btnAddFriends" onClick={ onChangeUnlockFriend }> 
                     <i className="feather-user-minus font-sm text-grey"></i>
                     <span className='ms-2'> unblock friend </span>
                 </div>

            }
            {
            (isFriend == stateFriend.accepted.description) && 
            <BtnOptionMenuFriends  removeFriend={ onChangeRemoveFriend }  lockedFriend= { onChangeLockedFriend }/>
            }
       </>
        
    )

}
