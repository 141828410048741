import React from "react";

export const LogoDealLoading = () => {
  return (
    <div className="card"
      style={{ height: "100vh" }}> 
      <div className="m-auto">
      <img className="mb-2"
        src={"/assets/images/LOGO-DEAL.png"}
        style={{ width: 400  }}>
      </img>
      <div className="dot-typing ml-auto mr-auto"></div>
      </div>
    </div>
  );
};
