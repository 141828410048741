import React from 'react'
import { useBtnOptionMenu } from 'Hooks/useBtnOptionMenu';
import { EditComment } from '../EditComment';
import { RemoveComment } from '../RemoveComment';
import { useMediaQuery } from "react-responsive";
import '../../../../css/commentByIdOptions.css'

export const BtnOptionMenuComment = ({ data, index, type, groupId }) => {

    const { openModal, notiClass, wrapperRef, closeModal } = useBtnOptionMenu();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 980px)",
      });

    return (

            <div ref={ wrapperRef } className="ms-auto">
                <a id="dropdownMenu4" className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss pointer" data-toggle="dropdownMenu4" aria-haspopup="true" onClick={ openModal } aria-expanded="false"></a>
                <div className={`dropdown-menu p-3 rounded-xxl border-0 ms-auto  shadow-lg  ${ isDesktopOrLaptop ? "contentCommentModal" : "contentCommentModal-mobile" }  shadow-sm  ${notiClass}`}  aria-labelledby="dropdownMenu3">
               
                <div style={{ borderBottom: '1px solid #00589b60' }}>

                <h6 className="fw-700 font-xss mb-3">Option menu</h6>
                </div>
                <ul>
                    <div style={{ borderBottom: '1px solid #00589b60' }}>
                        <EditComment data={ data } index={index} type={type} groupId={groupId} closeModal={closeModal} />
                    </div>
                    <div style={{ borderBottom: '1px solid #00589b60' }}>
                        <RemoveComment data={ data } groupId={ groupId } closeModal={closeModal} />
                    </div>
                </ul>
                </div>
            </div>

    )
}