import { types } from "../types/types";

const inicialState = {
  allProjects: {
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  projectSuggestions: [],
  loading: false,
  loadingSuggestion: true,

};

export const projectsReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.projectSuggestions:
      return {
        ...state,
        projectSuggestions: action.payload,
        loadingSuggestion: false
      };

    case types.projectsGlobal:
      let hash = {};
      function SortArray(x, y) {
        if (x.title < y.title) {
          return -1;
        }
        if (x.title > y.title) {
          return 1;
        }
        return 0;
      }
      return {
        ...state,
        allProjects:
          action.payload && action.payload?.isCollectionEmpty > 0
            ? state.allProjects
              ? {
                  docs: [...state.allProjects.docs, ...action.payload.docs]
                    .filter((o) => (hash[o.uid] ? false : (hash[o.uid] = true)))
                    .sort(SortArray),
                  isCollectionEmpty: action.payload?.isCollectionEmpty,
                  lastInResponse: action.payload?.lastInResponse,
                }
              : action.payload
            : {
                docs: state.allProjects.docs,
                isCollectionEmpty: action.payload?.isCollectionEmpty,
                lastInResponse: action.allProjects?.lastInResponse,
              },
      };

    case types.loadingAllProject:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
