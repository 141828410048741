import React from "react";
import { ViewCourseModalInviteMenbers } from "./ViewCourseModalInviteMembers";

export const ViewCourseOptionsItemBrowser = ({
  exist,
  click,
  loading,
  clickExit,
  clickPending,
  isActive,
}) => {
  return (
    <>
      {!isActive && (
        <>
          {exist == "exit" && (
            <div className=" cursor-pointer d-flex align-items-center justify-content-center pl-3  right-15 top-0 me-2">
              <a
                onClick={click}
                className=" ms-auto d-none d-lg-block bg-success p-2 z-index-1 rounded-3 text-white font-xsss  fw-700 ls-3"
              >
                {loading && <i className="spinner-border" />}
                {!loading && "Join the course"}
              </a>
            </div>
          )}

          {exist === "pending" && (
            <div className=" cursor-pointer d-flex align-items-center justify-content-center p-3 right-15 top-0 me-2">
              <a
                onClick={clickPending}
                className=" ms-auto d-none d-lg-block bg-info p-2 z-index-1 rounded-3 text-white font-xsss  fw-700 ls-3"
              >
                {loading && <i className="spinner-border" />}
                {!loading && "Pending approval"}
              </a>
            </div>
          )}

          {exist === "success" && (
            <div className="d-flex ms-auto" style={{ width: 280 }}>
              <ViewCourseModalInviteMenbers />
              <div className=" cursor-pointer d-flex align-items-center justify-content-center p-3 right-15 top-0 me-2">
                <a
                  onClick={clickExit}
                  className=" ms-auto d-none d-lg-block bg-danger p-2 z-index-1 rounded-3 text-white font-xsss  fw-700 ls-3"
                >
                  {loading && <i className="spinner-border" />}
                  {!loading && "Exit course"}
                </a>
              </div>
            </div>
          )}
        </>
      )}
      {isActive && <ViewCourseModalInviteMenbers />}
    </>
  );
};
