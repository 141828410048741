import React from "react";
import Load from "../Load";

import { useCreateCourse } from "Hooks/course/useCreateCourse";
import { CourseCoverPage } from "./CourseCoverPage";

export const CreateCourseForm = () => {

  const {
    handleSubmit,
    handleChangeInput,
    changeImage,
    handlerform,
    coverPageSelectedPrevious,
    inputFields,
    handleAddFields,
    handleRemoveFields,
    loading,
    msgError,
    isUpdate,
  } = useCreateCourse();

  return (
      <>
        {loading && (
          <div
            style={{ height: "100%", minHeight: "100vh", paddingTop: "50%" }}
          >
            <Load />
          </div>
        )}

        {!loading && (
          <form onSubmit={handleSubmit}>
            {msgError && (
              <div className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0 ">
                {msgError}
              </div>
            )}
            <div className="form-group ">
              <label className="mont-font fw-600 font-xsss">Title</label>
              <input
                type="text"
                name="title"
                className="form-control"
                placeholder="Title"
                onChange={handlerform}
              ></input>
            </div>
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Description</label>
              <input
                type="text"
                name="description"
                className="form-control"
                placeholder="description"
                onChange={handlerform}
              ></input>
            </div>

            <CourseCoverPage
              coverPageSelectedPrevious={coverPageSelectedPrevious}
              changeImage={changeImage}
            ></CourseCoverPage>

            {/* Input Dinamicos*/}
            <h4 className="text-center mont-font fw-600 font-xss">
              Add New Content
            </h4>
            {inputFields.map((inputField, index) => (
              <div key={index} className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Title Video
                </label>
                <div>
                  <input
                    name="titleVideo"
                    className="form-control"
                    placeholder="Title video"
                    onChange={(event) =>
                      handleChangeInput(inputField.urlVideo, event)
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">
                    Url Video
                  </label>
                  <input
                    name="urlVideo"
                    placeholder="ej: https://www.youtube.com/watch?v=Kcy9S94kYgI"
                    className="form-control"
                    onChange={(event) =>
                      handleChangeInput(inputField.urlVideo, event)
                    }
                  />
                </div>

                <div className="form-group d-flex">
                  {inputFields.length > 1 && (
                    <i
                      onClick={() => handleRemoveFields(inputField.urlVideo)}
                      className="feather-minus font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "
                    ></i>
                  )}
                  <i
                    onClick={handleAddFields}
                    className="feather-plus font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "
                  ></i>
                </div>
              </div>
            ))}
           <div className="pb-5">
            <button
              disabled={isUpdate}
              type="submit"
              className=" bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-blockbg-current text-center text-white font-xsss fw-600 p-3  w200 rounded-3 d-inline-block ">
              Send
            </button>
           </div>
          </form>
        )}
      </>
  );
};
