import React from "react";
import { MyGroupLoading } from "./MyGroupLoaddingComponents";

export const MyGroupLoadingIndicator = ({
  isPropietor= false,
  quantityElement = 4,
}) => {

  var items = Array.from({length: quantityElement}, (_, i) => i + 1)


  return (
    <div className="row">
      {items.map((item) => {
        return (
          <div key={item} className="col-md-6 col-sm-2 pe-2 ps-2">
            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
              <div className="card-body d-block w-100 ps-4 text-left position-relative">
                <MyGroupLoading isPropietor={isPropietor} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
