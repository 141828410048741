import React from "react";
import PropTypes from 'prop-types'

import { Route } from "react-router-dom";
import { MyProjects } from "../MyProjects";
import { MyProjectsParticipate } from "../MyProjectsParticipate";


export const RouterProjects = ({ routeProfile }) => {
  return (
    <div>
      <Route  exact path={`${routeProfile}/projects/my-projects`} component={MyProjects} />
      <Route  path={`${routeProfile}/projects/my-projects-participate`} component={MyProjectsParticipate} />
    </div>
  );
};

RouterProjects.propTypes = {
    routeProfile: PropTypes.string.isRequired,
}