import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useMediaQuery } from "react-responsive";
import "../../../../css/scroll-horizontal.css";

export const ViewCourseMenuItem = ({ uid, userId, userIdActive }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });

  return (
    <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
      <ul
        className="nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4 horizontal-slide"
        id="pills-tab"
        role="tablist"
      >
        <li className="list-inline-item me-5 span2">
          <NavLink
            exact
            to={`/ViewCourse/${uid}`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab"
          >
            Information
          </NavLink>
        </li>
        <li className="list-inline-item me-5 span2">
          <NavLink
            to={`/ViewCourse/${uid}/members`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab"
          >
            Members
          </NavLink>
        </li>
        {userId == userIdActive && (
          <li className="list-inline-item me-5 span2">
            <NavLink
              to={`/ViewCourse/${uid}/requests`}
              activeClassName="active"
              className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
              data-toggle="tab"
            >
              Request
            </NavLink>
          </li>
        )}
        <li className="list-inline-item me-5 span2">
          <NavLink
            to={`/ViewCourse/${uid}/forum`}
            activeClassName="active"
            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
            data-toggle="tab"
          >
            Forum
          </NavLink>
        </li>
        {isTabletOrMobile && (
          <li className="list-inline-item me-5 span2">
            <NavLink
              to={`/ViewCourse/${uid}/about`}
              activeClassName="active"
              className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block "
              data-toggle="tab"
            >
              About
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};
