import { useGetReplyForum } from "Hooks/forum/childrenForum/useGetReplyForum";
import { useReplyForum } from "Hooks/forum/childrenForum/useReplyForum";
import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import Load from "./Load";
import { BtnOptionMenuForum } from "./project/components/BtnOptionMenuForum";

export const CardsCommentsForum = ({ data, type, userId }) => {
  const { changeTextArea, hanledCreateComment } = useReplyForum(type, data.uid);
  const { getProjectCommentReplyForum, loadingReply } = useGetReplyForum();
  const getReply = data?.reply?.length > 0 ? true : false;

  return (
    <div>
      <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
        <div className="card-body p-0 d-flex">
          <figure className="avatar me-3">
            <img
              src={data?.user?.photoUrl}
              alt="avater"
              className="shadow-sm rounded-circle w45"
            />
          </figure>
          <h4 className="fw-700 text-grey-900 font-xssss mt-1">
            {" "}
            {data?.user?.userName}{" "}
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
              {" "}
              {data?.createDate}
            </span>
          </h4>
          {userId == data?.userId && (
            <BtnOptionMenuForum data={data} type={type} />
          )}
        </div>

        <div className="card-body p-0 me-lg-5">
          <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
            {`${data?.content?.substring(0, 200)} ${
              data?.content?.length >= 200 ? "..." : ""
            } `}
            {data?.content?.length >= 200 ? (
              <a href="/defaultvideo" className="fw-600 text-primary ms-2">
                See more
              </a>
            ) : (
              ""
            )}
          </p>
        </div>
        <Accordion
          onChange={(e) =>
            getProjectCommentReplyForum(e, type, data.uid, getReply)
          }
          className="accodion-style--1 accordion m-auto w-100"
          allowZeroExpanded={true}
        >
          <AccordionItem className="card" uuid={"data"}>
            <AccordionItemHeading
              className="card-header "
              style={{ backgroundColor: "#eff1f2", borderRadius: 20 }}
            >
              <AccordionItemButton>
                <h5 className="fw-600 pt-2 pb-2 mb-0">View Comments</h5>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="card-body p-0">
              {loadingReply && <Load />}

              {(data?.reply && !loadingReply) && (
                <div
                  style={{ maxHeight: "300px", overflow: "auto", margin: "10px" }}
                >
                  {data?.reply?.map((value) => (
                    <CarReply key={value.uid} data={value} />
                  ))}
                </div>
              ) 
            }
              {
                  (!data.reply && !loadingReply) && 
                  <p className="text-center pt-2">
                    No comments, be the first to comment
                  </p>
                
              }

              <form onSubmit={hanledCreateComment}>
                <div className="d-flex">
                  <textarea
                    style={{
                      borderRadius: 20,
                      resize: "none",
                      padding: "8px",
                      width: "90%",
                      height: "50px",
                      margin: "4px",
                    }}
                    name="content"
                    onChange={changeTextArea}
                    placeholder="reply"
                  >
                   
                  </textarea>
                  <button
                    type="submit"
                    className="btn- btn-info m-auto"
                    style={{
                      color: "white",
                      height: "50px",
                      borderRadius: 20,
                      padding: "5px",
                    }}
                  >
                    Comment
                  </button>
                </div>
              </form>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

const CarReply = ({ data }) => {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0">
      <div className="card-body p-0 d-flex">
        <figure className="avatar me-3">
          <img
            src={data?.user?.photoUrl}
            alt="avater"
            className="shadow-sm rounded-circle w45"
          />
        </figure>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          {" "}
          {data?.user?.userName}{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            {" "}
            {data?.createDate}
          </span>
        </h4>
      </div>

      <div className="card-body p-0 me-lg-5">
        <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
          {`${data?.content?.substring(0, 200)} ${
            data?.content?.length >= 200 ? "..." : ""
          } `}
          {data?.content?.length >= 200 ? (
            <a href="/defaultvideo" className="fw-600 text-primary ms-2">
              See more
            </a>
          ) : (
            ""
          )}
        </p>
      </div>
    </div>
  );
};
