import React from "react";

import { CreateOrganizationForm } from "./CreateOrganizationForm";
import { CardRouteProfileOptions } from "components/shared/CardRouteProfileOptions";

export const CreateOrganization = () => {
  return (
    <CardRouteProfileOptions
      title="Create Organization"
      component={CreateOrganizationForm}
    />
  );
};
