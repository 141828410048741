import React from 'react'
import { useBtnOptionMenu } from 'Hooks/useBtnOptionMenu';

export const BtnOptionMenuFriends = ({ removeFriend, lockedFriend }) => {

    const { openModal, notiClass, wrapperRef } = useBtnOptionMenu();
    

    return (
        <div ref={ wrapperRef } className="ms-auto">
            <div id="dropdownMenu4" className="btnFriends"  data-toggle="dropdownMenu4" aria-haspopup="true" onClick={ openModal } aria-expanded="false"> 
                <i className="feather-user font-sm  text-grey"></i>
                <span className='ms-1'> friends </span>
            </div>

            <div className={`dropdown-menu p-3 rounded-xxl border-0 ms-auto shadow-sm contentModal ${notiClass}`}  aria-labelledby="dropdownMenu3">
                <ul>  
                    <li onClick={ removeFriend }> 
                        <div className='icon-li '>
                            <i className="feather-user-x font-sm  text-grey"></i>
                        </div> 
                        <div className='text-li'>
                            <span className='pb-1'> Remove friend </span>
                        </div>
                    </li>
                    <li onClick={ lockedFriend } style={{ borderBottom: '1px solid #00589b60' }}>              
                        <div className='icon-li'>
                            <i className="feather-user-minus font-sm  text-grey"></i>
                        </div> 
                        <div className='text-li'>
                            <span className='pb-1'> block friend  </span>
                        </div>
                    </li>

                </ul>
            </div>

        </div>

    )
}
