import React from "react";
import { Route } from "react-router-dom";
import { MyGroupsParticipate } from "components/group/MyGroupsParticipate";
import { MyGroups } from "components/group/MyGroups";
import PropTypes from "prop-types";

export const RouterProfileGroup = ({ routeProfile }) => {
  return (
    <div>
      <Route
        exact
        path={`${routeProfile}/groups/my-groups`}
        component={MyGroups}
      />
      <Route
        path={`${routeProfile}/groups/my-groups-participate`}
        component={MyGroupsParticipate}
      />
    </div>
  );
};

RouterProfileGroup.propTypes = {
  routeProfile: PropTypes.string.isRequired,
};
