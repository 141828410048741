import { types } from "../types/types";

const inicialState = {
  allGroups: {
    docs: [],
    isCollectionEmpty: -1,
    lastInResponse: null,
  },
  groupsSuggestion: [],
  loading: false,
  loadingSuggestion: true
};

export const groupsReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.getAllGroupLoading:
      return {
        ...state,
        loading: true,
      };

    case types.getGroupsSuggestionList:
      return {
        ...state,
        groupsSuggestion: action.payload,
        loadingSuggestion: false
      };

    case types.allGroupResponse:
      let hash = {};
      let isLoadedData =
        action.payload && action.payload?.isCollectionEmpty > 0;
      let allGroupsIsCollectionEmpty = action.payload?.isCollectionEmpty
        ? action.payload?.isCollectionEmpty
        : 0;

      function SortArray(x, y) {
        if (x.title < y.title) {
          return -1;
        }
        if (x.title > y.title) {
          return 1;
        }
        return 0;
      }
      return {
        ...state,
        allGroups: isLoadedData
          ? state.allGroups
            ? {
                docs: [...state.allGroups.docs, ...action.payload?.docs]
                  .filter((o) => (hash[o.uid] ? false : (hash[o.uid] = true)))
                  .sort(SortArray),
                isCollectionEmpty: allGroupsIsCollectionEmpty,
                lastInResponse: action.payload?.lastInResponse,
              }
            : action.payload
          : {
              docs: state.allGroups?.docs,
              isCollectionEmpty: allGroupsIsCollectionEmpty,
              lastInResponse: state.allUsers?.lastInResponse,
            },
        loading: false,
      };

    default:
      return state;
  }
};
