import React from "react";
import PropTypes from "prop-types";

import { usePropietor } from "Hooks/usePropietor";
import { useScroll } from "Hooks/scroll/useScroll";
import { PageTitleProfile } from "components/PageTitleProfile";
import { RouterProjects } from "./routes/RouterProjects";

export const Projects = ({ routeProfile }) => {
  const { isPropietor } = usePropietor();
  const { divRref } = useScroll();

  return (
    <div ref={divRref}
      className="card w-100 border-0 p-3 bg-white shadow-xss rounded-xxl" >
      <PageTitleProfile
        name1="My projects"
        route1={`${routeProfile}/projects/my-projects`}
        name2="Projects where I participate"
        route2={`${routeProfile}/projects/my-projects-participate`}
        isActiveButtom={isPropietor}
        title="Projects"
        titleButtom="Create Projects"
        route="/CreateProject"
      />
      <RouterProjects routeProfile={routeProfile} />
    </div>
  );
};

Projects.propTypes = {
  routeProfile: PropTypes.string.isRequired,
};
