import React, { useCallback, useState } from "react";
import { useProfileCardThree } from "Hooks/useProfileCardThree";
import { BtnOptionMenuProfile } from "./components/BtnOptionMenuProfile";
import { useVerify } from "Hooks/useVerify";
import { MenuItemProfile } from "./components/MenuItemProfile";
import { imageExistsProduct, imageExistsProfile } from "helpers/verifyImg";
import { BtnAddFriends } from "./components/BtnAddFriends";

import { ModalUpdatePhotoProfile } from "./components/ModalUpdatePhotoProfile";
import { ModalUpdatePhotoCover } from "./components/ModalUpdatePhotoCover";
import ImageViewer from 'react-simple-image-viewer';

export const ProfileCardThree = ({ name, photoUrl, routeProfile }) => {
  const { email, coverPhoto, ProfilePhoto } = useProfileCardThree();
  const userName = name.replaceAll("_", " ");
  const verify = useVerify();

  
  const [isViewerOpen, setIsViewerOpenPortada] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const images = coverPhoto ? [coverPhoto, ProfilePhoto] : [ProfilePhoto];

  const openImageViewerPortada = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpenPortada(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpenPortada(false);
  };



  return (
    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
      <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
        {coverPhoto ? (
          <img
            src={coverPhoto}
            style={{ objectFit: "cover",  cursor: "pointer" }}
            onClick={() => openImageViewerPortada(0)}
            width="1200"
            height="250"
            alt="avater"
            onError={imageExistsProduct}
          />
        ) : (
          <img
            src='/assets/images/1200x450.png'
            style={{ objectFit: "cover" }}
            width="1200"
            height="250"
            alt="avater"
            onError={imageExistsProduct}
          />
        )}

       
        <div className="image-upload">
          <ModalUpdatePhotoCover />
        </div>
      </div>

      <div className="card-body p-0 position-relative">
        <figure
          className="avatar position-absolute w100 z-index-1"
          style={{ top: "-40px", left: "30px" }}
        >
          <img
            src={ProfilePhoto}
            alt="avater"
            onClick={() => images.length >= 2 ? openImageViewerPortada(1) : openImageViewerPortada(0)}
            className=" p-1 bg-white rounded-circle"
            style={{ width: "110px", height: "110px", borderRadius: "50px", cursor: "pointer" }}
            onError={imageExistsProfile}
          />
          <div className="image-profile-upload">
            <ModalUpdatePhotoProfile />
          </div>
        </figure>

        <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
          {userName}
          <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
            {email}
          </span>
        </h4>
        <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2">
          {verify && <BtnOptionMenuProfile routeProfile={routeProfile} />}
          {!verify && <BtnAddFriends />}
        </div>
      </div>
      <MenuItemProfile routeProfile={routeProfile} />

      {isViewerOpen && (
        <div style={{position: "absolute", zIndex: "999"}}>

        <ImageViewer
          src={ images }
          currentIndex={currentImage}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          onClose={ closeImageViewer }
          />
          </div>
      )}
    </div>
  );
};

export default ProfileCardThree;
