import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPostsPaginations,
  isCreateReset,
  isRemoveReset,
  isUpdateReset,
} from "actions/post";
import { LIMIT } from "helpers/env";

export const usePostViewList = () => {
  const dispatch = useDispatch();
  const observer = useRef();

  const { posts, isCreate, isUpdate, isRemove } = useSelector(
    (state) => state.posts
  );
  const { name, photoUrl, uid } = useSelector((state) => state.auth);

  const [docs, setDocs] = useState(posts?.docs);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isPostListEmpty, setIsPostListEmpty] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [isFeching, setIsFeching] = useState(false);
  const [loading, setLoading] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      limit: LIMIT,
      action: action,
      lastInResp: lastInResp,
      userId: uid,
    };
    dispatch(getPostsPaginations(data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastPostsElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isPostListEmpty ) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isPostListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (posts?.docs.length <= 0 && posts?.isCollectionEmpty == -1) {
      if(isFeching){
        return;
      }
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
      setIsFeching(true);
      setLoading(true);
    }
  }, [posts?.docs]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (posts?.docs.length > 0) {
      posts.docs.map((element) => {
        if (!docs.includes(element)) {
          setDocs((ele) => ele.concat(element));
        }
      });
      setlastInResponse(posts?.lastInResponse);
      setIsPostListEmpty(posts?.isCollectionEmpty === 0);
      setLoadingPagination(false);
      setLoading(false);
    }
  }, [posts, posts?.docs, isPostListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS CREATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isCreate?.state) {
      const element = posts?.docs.find((dc) => dc.uid == isCreate?.uidCreate);
      if (!docs.includes(element)) setDocs((ele) => [element, ...ele]);
    }
    dispatch(isCreateReset());
  }, [isCreate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS UPDATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isUpdate?.state) {
      setDocs((items) => {
        const position = docs.findIndex((dc) => dc.uid === isUpdate?.uidUpdate);
        const newElement = posts?.docs.find(
          (dc) => dc.uid === isUpdate?.uidUpdate
        );
        docs.splice(position, 1, newElement);
        return items;
      });

      dispatch(isUpdateReset());
    }
  }, [isUpdate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS REMOVE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isRemove?.state) {
      setDocs((items) => {
        const position = docs.findIndex((dc) => dc.uid === isRemove?.uidRemove);
        docs.splice(position, 1);
        return items;
      });

      dispatch(isRemoveReset());
    }
  }, [isRemove?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    loading,
    loadingPagination,
    docs,
    name,
    photoUrl,
    lastPostsElementRef,
  };
};
