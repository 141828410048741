import React from "react";
import { useVerify } from "Hooks/useVerify";
import { ModalEditExperience } from "./ModalEditExperience";
import { Link } from "react-router-dom";
import { imageExistsProduct } from "helpers/verifyImg";

export const CardListOrganizationExperience = ({ value }) => {
  const verify = useVerify();

  return (
    <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden border-top-xs mt-1">
      <div className="row mt-2">
        <div className="d-flex col-12">
          <img
            src={value?.organizationInfo?.logoUrl}
            alt="avater"
            className="border me-3 bg-white rounded-circle"
            width="40"
            height="40"
            onError={imageExistsProduct}
          />
          <div className="ro2">
            <div className="col-12">
              <h4
                className="cursor-pointer fw-700 text-grey-900 font-xssss mt-2 "
                style={{ flex: 1 }}
              >
                {value?.organizationInfo?.name}
              </h4>
            </div>
            <div className="col-12">
              <h4>
                <span
                  className="font-xssss fw-500 mt-1 lh-3 text-grey-500"
                  style={{ flex: 1 }}
                >
                  {value?.job}
                </span>
                <span
                  className=" d-block fw-600 mt-1 lh-3 text-grey-500"
                  style={{ fontSize: 9 }}
                >
                  {value?.startDate} - {value?.endDate}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="d-flex col-12">
          {verify && <ModalEditExperience uidExperience={value?.uid} />}
          <Link
            to={`/ViewOrganization/${value?.organizationInfo?.organizationId}`}
            className="cursor-pointer btn-round-sm font-xs ml-2 text-primary feather-eye bg-greylight"
          ></Link>
        </div>
      </div>
    </div>
  );
};
