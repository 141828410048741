import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  getCourseById,
  setCourseId,
  setTempCourseReset,
  updateCourseById,
} from "actions/course";
import { closeLoading, successLoading } from "helpers/loadingCreate";
import uploadFile from "helpers/uploadFile";

export const UseEditCourse = () => {
  const { Id: courseVisited } = useParams();
  const history = useHistory();
  const { courseLoad, createdFinished, tempCourse, loading } = useSelector(
    (state) => state.course
  );
  let { uid: userId, name } = useSelector((state) => state.auth);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [isUpdate, setisUpdate] = useState(false);

  const dispatch = useDispatch();
  const [inputFields, setInputFields] = useState([
    { titleVideo: "", urlVideo: "" },
  ]);

  const [formDetail, setformDetail] = useState({
    title: "",
    description: "",
    pageCover: "",
  });

  useEffect(() => {
    if (courseVisited && courseLoad?.docs.length > 0) {
      dispatch(setCourseId({ uid: courseVisited }));
    } else if (courseVisited && courseLoad?.docs.length <= 0 && !tempCourse) {
      dispatch(getCourseById({ uid: courseVisited }));
    }
  }, [courseVisited, courseLoad?.docs, tempCourse]);

  useEffect(() => {
    setformDetail({
      title: tempCourse?.title,
      description: tempCourse?.description,
      pageCover: tempCourse?.pageCover,
    });

    setCoverPageSelectedPrevious(tempCourse?.pageCover);
    setInputFields(tempCourse?.url);
  }, [tempCourse]);

  const [coverPageSelectedPrevious, setCoverPageSelectedPrevious] =
    useState(null);
  const [coverPageSelectedFile, setCoverPageSelectedFile] = useState(null);

  const changeImage = (e, type) => {
    if (e.target.files[0] !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      setCoverPageSelectedFile(e.target.files[0]);
      reader.onload = (e) => {
        e.preventDefault();
        setCoverPageSelectedPrevious(e.target.result);
      };
    }
  };

  const handlerform = (event) => {
    const target = event.target;
    const value = target.value;
    setformDetail({
      ...formDetail,
      [event.target.name]: value,
    });
  };

  // ENVIO DE DATA
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isUpdate) return;
    setisUpdate(true);
    successLoading("Updating course please wait...");

    let pageCover = "";
    if (coverPageSelectedFile) {
      pageCover = await uploadFile(
        coverPageSelectedFile,
        `/course/${tempCourse?.uid}/imageMain/coverPage.jpg`,
        setProgress,
        setError
      );
      dispatch(
        updateCourseById({
          ...formDetail,
          uid: tempCourse?.uid,
          url: inputFields,
          pageCover,
        })
      );
    } else {
      dispatch(
        updateCourseById({
          ...formDetail,
          uid: tempCourse?.uid,
          url: inputFields,
        })
      );
    }
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.urlVideo) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { firstName: "", urlVideo: "" }]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.urlVideo === id),
      1
    );
    setInputFields(values);
  };

  useEffect(() => {
    if (createdFinished) {
      setisUpdate(false);
      closeLoading();
      setCoverPageSelectedPrevious(null);
      setCoverPageSelectedFile(null);
      dispatch(setTempCourseReset());
      name = name?.replaceAll(" ", "_");
      history.push(`/PROFILE/${name}/${userId}/courses/my-courses`);
    }
  }, [createdFinished]);

  return {
    handleRemoveFields,
    handleAddFields,
    handleChangeInput,
    handleSubmit,
    handlerform,
    inputFields,
    formDetail,
    coverPageSelectedPrevious,
    changeImage,
    loading,
    isUpdate,
  };
};
