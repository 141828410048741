import { types } from "../types/types";

const inicialState = {
  countMessage: 0,
  
};

export const chatReducer = (state = inicialState, action) => {
  switch (action.type) {
    case types.chatCount:
      return {
        ...state,
        countMessage: action.payload,
      };


    default:
      return state;
  }
};
