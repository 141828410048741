import React from "react";
import PropTypes from "prop-types";

import { useScroll } from "Hooks/scroll/useScroll";
import { PageTitleProfile } from "components/PageTitleProfile";
import { RouterProfileGroup } from "./routes/RouterProfileGroup";
import { usePropietor } from "Hooks/usePropietor";

export const Groups = ({ routeProfile }) => {
  const { isPropietor } = usePropietor();
  const { divRref } = useScroll();

  return (
    <div
      ref={divRref}
      className="card w-100 border-0 p-3 bg-white shadow-xss rounded-xxl"
    >
      <div className="row ps-2 pe-1">
        <PageTitleProfile
          name1="My groups"
          route1={`${routeProfile}/groups/my-groups`}
          name2="Groups where I participate"
          route2={`${routeProfile}/groups/my-groups-participate`}
          isActiveButtom={isPropietor}
          title="Groups"
          titleButtom="Create Group"
          route="/CreateGroup"
        />
      </div>
      <RouterProfileGroup routeProfile={routeProfile} />
    </div>
  );
};

Groups.propTypes = {
  routeProfile: PropTypes.string.isRequired,
};
