import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useEditForum } from 'Hooks/forum/useEditForum';


export const EditForum = ({data, type}) => {
    const {photoUrl,forms: values, handleShow, show, handleClose, hanledEditForum, changeTextArea } = useEditForum(data, type);
    const style = { overflowY: 'scroll' }

    return (
        <>
          <li onClick={handleShow} className="cursor-pointer d-flex">
             <div className='icon-li '>
               <i className="font-sm ti-pencil  text-grey"></i> 
             </div> 
             <div className='text-li'>
                 <span className='pb-1'> Edit suggestion </span>
             </div>
          </li>
   
          <Modal 
            show={show} 
            onHide={handleClose}
            size="lg"
            >
            <form onSubmit={hanledEditForum}>
              <Modal.Header>
                <Modal.Title>Edit suggestion </Modal.Title>
                <button className="btn ti-close" type="button" onClick={handleClose}></button>
              </Modal.Header>
              <Modal.Body>
                <div style={style}>
                    <div className="card-body p-0 mt-1 position-relative">
                       <figure className="avatar position-absolute ms-2 mt-1 top-5"><img src={ photoUrl != null ? photoUrl : "assets/images/user.png"} alt="icon" className="shadow-sm rounded-circle w30" /></figure>
                       <textarea name="message" className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder="What's on your mind?" value={ values?.content }  onChange={ changeTextArea }></textarea>
                    </div> 
                  </div>
              </Modal.Body>
              <Modal.Footer>
            <Button type="submit" variant="outline-info"  onClick={handleClose}>
              Edit
            </Button>
          </Modal.Footer>
            </form>
          </Modal>
        </>
      );
}
