import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import algoliasearch from "algoliasearch/lite";
import { LIMIT } from "../../helpers/env";
import { useState } from "react";
import { getCoursesById, getIsChangeMyCourse } from "../../actions/course";

const client = algoliasearch(
  process.env.REACT_APP_APPLICATION_ID,
  process.env.REACT_APP_SEARCH_API_KEY
);

const searchIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_INDEX_COURSES_GLOBAL
);

export const useMyCourses = () => {
  const dispatch = useDispatch();
  const observer = useRef();

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> SELECTORS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { uid: userIdVisited } = useSelector((state) => state.navigateAuth);
  const { uid: userIdActive } = useSelector((state) => state.auth);
  const { courseLoad, loading } = useSelector((state) => state.course);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> STATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [docs, setDocs] = useState([]);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isMyCourseListEmpty, setIsMyCourseListEmpty] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);

  const [loadingLoad, setLoadingLoad] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      limit: LIMIT,
      action: action,
      lastInResp: lastInResp,
      userId: userIdVisited,
    };
    dispatch(getCoursesById(userIdVisited, data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastMyCourseElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isMyCourseListEmpty) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isMyCourseListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> CHANGE USER PROFILE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (courseLoad?.userData && userIdVisited != courseLoad?.userData) {
      dispatch(getIsChangeMyCourse());
    }
  }, [userIdVisited]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (
      (courseLoad?.docs.length <= 0 && courseLoad?.isCollectionEmpty == -1) ||
      (courseLoad?.docs.length <= 0 && courseLoad?.isCollectionEmpty == 0)
    ) {
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
      // setLoading(true);
    }
  }, [courseLoad?.docs]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER MY PROJECT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (courseLoad?.docs.length > 0) {
      courseLoad.docs.map((element) => {
        if (!docs.includes(element)) {
          setDocs((ele) => ele.concat(element));
        }
      });
      setlastInResponse(courseLoad?.lastInResponse);
      setIsMyCourseListEmpty(courseLoad?.isCollectionEmpty === 0);
      setLoadingPagination(false);
    } else if (
      courseLoad?.docs.length <= 0 &&
      courseLoad?.isCollectionEmpty == 0
    ) {
      setIsMyCourseListEmpty(true);
      setDocs([]);
      setLoadingPagination(false);
    } else if (
      courseLoad?.docs.length <= 0 &&
      courseLoad?.isCollectionEmpty == -1
    ) {
      setDocs([]);
      setLoadingPagination(false);
    }
  }, [courseLoad, userIdVisited, isMyCourseListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC  SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target?.value);
  };

  const handleSearch = async (queryText) => {
    try {
      setLoadingLoad(true);
      const option = {
        filters: `userId:${userIdActive}`,
        hitsPerPage: 50,
      };
      const result = await searchIndex.search(queryText, option);
      setSearchResult(result.hits);
      setLoadingLoad(false);
    } catch (error) {
      setLoadingLoad(false);
      setSearchResult([]);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchResult(null);
    }
  }, [searchText]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    docs,
    lastMyCourseElementRef,
    loadingLoad,
    loading,
    loadingPagination,
    handleSearchText,
    searchText,
    handleSearch,
    searchResult,
  };
};
