import React from 'react'
import { Route } from 'react-router'
import { MyOrganization } from './MyOrganization'

export const OrganizationRouteView = ({ routeProfile }) => {

    return (
       <div className="card w-100 border-0 p-3 bg-white shadow-xss rounded-xxl"> 
            <Route path={`${routeProfile}/organization/my-organization`} component={ MyOrganization } />
        </div>
    )
}
