import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createProject, resetProject } from "actions/project";
import { removeError, setError } from "actions/ui";

export const useCreateProject = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { uid, name } = useSelector((state) => state.auth);
  const { page } = useSelector((state) => state.project);
  const {msgError} = useSelector( state => state.ui );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> STATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [formsValue, setfomrs] = useState({
    title: "",
    content: "",
    file: [],
    filePortada:
      "https://firebasestorage.googleapis.com/v0/b/fup-lumen-rs-desarrollo.appspot.com/o/profilesFolder%2FDefaultProfile%2Fno_logo.png?alt=media&token=3afcf4aa-0353-4ab3-a6da-2566e6bd53fd",
    description: "",
    stateProject: true,
  });

  //  Validations
  const  isFormValidate = () =>{
    if(formsValue?.title.trim().length < 5){
      dispatch(setError("Title too short"));
      return false
    }else if(formsValue?.description.trim().length < 200){
      dispatch(setError("Description too short"));

        return false;
    }else if(formsValue.content.trim().length < 50){
      dispatch(setError("content too short"));

        return false;
    }
  
    dispatch(removeError());

     return true;
 }

  // Memvers
  const [members, setMembers] = useState([]);
  const dataMembers = {
    values: members,
  };

  const onChangeMembers = (data) => {
    setMembers(data);
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (page === 3) {
      history.push(`/PROFILE/${name?.replaceAll(' ', '_')}/${uid}/projects/my-projects`);
      dispatch(resetProject());
    }
  }, [page]);


  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> ONCHANGE PROJECT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleImg = async (e) => {
    e.preventDefault();

    const listMember = members?.target?.value;
    const createDate = new Date().toLocaleString();

    const data = {
      userId: uid,
      title: formsValue.title,
      content: formsValue.content,
      description: formsValue.description,
      pageCover: formsValue.filePortada,
      files: formsValue.file,
      members: listMember,
      createDate,
      state: formsValue.stateProject,
    };
    if(isFormValidate()){
      dispatch(createProject(data));
    }
  };

  const handlerform = (event) => {
    const target = event.target;
    const value = target.type === "file" ? target.files : target.value;
    setfomrs({
      ...formsValue,
      [event.target.name]: value,
    });
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    handlerform,
    handleImg,
    onChangeMembers,
    formsValue,
    dataMembers,
    page,
    msgError
  };
};
