export const typeEventLike = {
  home: "home",
  profile: "profile",
  group: "group",
  temp: "temp",
};

export const typeEventPost = {
  global: "global",
  group: "group",
};


export const postType = {
  course: "course",
  project: "project",
  group: "group",
  none: "none",
};
