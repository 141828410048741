import React from "react";
import Header from "../../headers/Header";
import {
  ViewGroupLoadingHeader,
  ViewGroupLoadingBodyLeft,
  ViewGroupLoadingBodyRight
} from "./ViewGroupLoadingComponents";

export const ViewGroupLoadingBrowser = () => {
  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <ViewGroupLoadingHeader />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <ViewGroupLoadingBodyLeft />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                  <div className="card-body d-block p-4">   
                   <ViewGroupLoadingBodyRight/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
