import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetCommentsPostById } from "actions/comments";
import { getPostById } from "actions/post";


let isFetching = false;
export const useCommentsPost = (type) => {
  const dispatch = useDispatch();
  const { postId } = useParams();

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> SELECTOR
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const { posts, tempPost, isUpdate } = useSelector((state) => state.posts);
  const { uid: userId } = useSelector((state) => state.auth);
  const { page403 } = useSelector((state) => state.page403);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> STATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [fileType, setFileType] = useState(null);
  const [ImageSelectedPrevious, setImageSelectedPrevious] = useState(null);
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETICION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (!isFetching && posts.docs.length <= 0 && postId && !tempPost) {
      dispatch(getPostById({ uid: postId, userId }));
      isFetching = true;
      setLoading(true);
    }
  }, [postId, posts.docs]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POST
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (posts.docs.length > 0 && !ImageSelectedPrevious) {
      const post = posts.docs.find((dc) => dc.uid === postId);
      setPost(post);
      setFileType(post?.attachments.includes("jpg"));
      setImageSelectedPrevious(post?.attachments);
      setLoading(false);
    } else if (tempPost) {
      setPost(tempPost);
      setFileType(tempPost?.attachments.includes("jpg"));
      setImageSelectedPrevious(tempPost?.attachments);
      setLoading(false);
    }
  }, [posts.docs, tempPost]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS UPDATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isUpdate?.state && tempPost) {
      setPost(tempPost);
    }
  }, [isUpdate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER COMMENT RESET
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    return () => {
      dispatch(resetCommentsPostById());
    };
  }, []);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULT
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    post,
    ImageSelectedPrevious,
    fileType,
    loading,
    page403,
  };
};
