import React from "react";
import Header from "../components/headers/Header";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import { AllCourses } from "../components/course/all/AllCourses";
// import { AllCourse } from "../components/course/All/AllCourse";

const CoursesPage = () => {
  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <AllCourses />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </>
  );
};

export default CoursesPage;
