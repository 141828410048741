import { types } from "../types/types";

const inicialState = {
    uid: '',
    name: '',
    photoUrl: ''
  };
  
export const navigateReducer = (state = inicialState, action) =>{

    switch (action.type) {
        case  types.navigate:
          return{
            uid: action.payload.uid,
            name: action.payload.name,
            photoUrl: action.payload.photoUrl
          }    
        default:
            return state;
    }
}