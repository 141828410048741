import React from 'react'
import ContentLoader from 'react-content-loader'

export const LoadingCommentsPost = () => {
    return (
        <ContentLoader
        backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
    viewBox="0 0 1000 575" 
    height='420' width="100%">
           
        <circle cx="30" cy="30" r="30" />
        <rect x="70" y="40" rx="4" ry="4" width="200" height="13" />
        <rect x="70" y="60" rx="4" ry="4" width="300" height="8" />
        <rect x="0" y="80" rx="5" ry="5" width="100%" height="100" />
      </ContentLoader> 
    )
}
