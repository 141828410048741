import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getGroupById,
  setGroupId,
  setTempGroupReset,
  updateCreateGroup,
} from "actions/group";
import { closeLoading, successLoading } from "helpers/loadingCreate";
import uploadFile from "helpers/uploadFile";

export const useEditGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: groupVisited } = useParams();

  const { uid, name } = useSelector((state) => state.auth);

  const { createdFinished, tempGroup, groupLoad, loading } = useSelector(
    (state) => state.group
  );

  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  const [coverPageSelectedPrevious, setCoverPageSelectedPrevious] =
    useState(null);
  const [coverPageSelectedFile, setCoverPageSelectedFile] = useState(null);
  const [isUpdate, setisUpdate] = useState(false);

  const initialForms = {
    title: "",
    description: "",
    members: [],
    private: true,
  };
  const [values, setValues] = useState(initialForms);

  useEffect(() => {
    if (groupVisited && groupLoad?.docs.length > 0 && !tempGroup) {
      dispatch(setGroupId({ uid: groupVisited }));
    } else if (groupVisited && groupLoad?.docs.length <= 0 && !tempGroup) {
      dispatch(getGroupById({ uid: groupVisited }));
    }
  }, [groupVisited, groupLoad?.docs, tempGroup]);

  useEffect(() => {
    if (tempGroup) {
      setValues(tempGroup);
      setCoverPageSelectedPrevious(tempGroup?.pageCover);
    }
  }, [tempGroup]);

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const reset = () => {
    setValues(initialForms);
  };

  const changeImage = (e) => {
    if (e.target.files[0] !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      setCoverPageSelectedFile(e.target.files[0]);
      reader.onload = (e) => {
        e.preventDefault();
        setCoverPageSelectedPrevious(e.target.result);
      };
    }
  };

  const handleUpdateGroup = async (e) => {
    e.preventDefault();
    if (isUpdate) return;
    setisUpdate(true);
    successLoading("Updating group please wait...");

    let pageCover = "";
    if (coverPageSelectedFile) {
      pageCover = await uploadFile(
        coverPageSelectedFile,
        `/groups/${tempGroup.uid}/imageMain/coverPage.jpg`,
        setProgress,
        setError
      );
      dispatch(updateCreateGroup({ uid: groupVisited, ...values, pageCover }));
    } else {
      dispatch(updateCreateGroup({ uid: groupVisited, ...values }));
    }
  };

  useEffect(() => {
    if (createdFinished) {
      setisUpdate(false);
      closeLoading();
      reset();
      setCoverPageSelectedPrevious(null);
      setCoverPageSelectedFile(null);
      dispatch(setTempGroupReset());
      const firstName = name?.replaceAll(" ", "_");
      history.push(`/PROFILE/${firstName}/${uid}/groups/my-groups`);
    }
  }, [createdFinished]);

  return {
    values,
    handleInputChange,
    handleUpdateGroup,
    loading,
    coverPageSelectedPrevious,
    changeImage,
    isUpdate,
  };
};
