import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { imageExistsProduct, imageExistsProfile } from "helpers/verifyImg";
import { usePostView } from "Hooks/post/usePostView";
import { UsePostViewLike } from "Hooks/post/UsePostViewLike";
import { BtnOptionMenu } from "./BtnOptionMenu";
import ReactPlayer from "react-player";

const PostView = ({
  groupId,
  userId: postUserId,
  userName,
  time,
  des,
  avater,
  postImage,
  postVideo,
  id,
  like,
  comment,
  type,
  typeLike,
  typePost,
  Linkto,
  friendId,
  nameFriend,
}) => {
  const {
    userId,
    handleViewNewPostById,
    toggleActive,
    loadMore,
    loadMoreComment,
    isLoadMoreComment,
  } = usePostView(des);
  const { onDistLike, onLike } = UsePostViewLike(
    id,
    type,
    typeLike,
    groupId,
    postUserId
  );
  const data = {
    userId,
    userName,
    time,
    des,
    avater,
    postImage,
    postVideo,
    id,
    typePost,
    Linkto,
  };
  const commemtCount = comment?.quantityComments;

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });
  }

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3 animate__animated animate__fadeIn">
      <div className="card-body p-0 d-flex">
        <figure className="avatar me-3">
          <img
            src={avater}
            onError={imageExistsProfile}
            alt="avater"
            className="shadow-sm rounded-circle"
            style={{ width: "45px", height: "45px", borderRadius: "50px" }}
          />
        </figure> 
        
        {
           friendId ? (<HeaderPostShareFriend nameFriend={nameFriend} postUserId={postUserId} time={time} userName={userName} friendId={friendId}/>) :
        <h4 className="mt-1">
          <Link to={`/PROFILE/${userName?.replaceAll(" ", "_")}/${postUserId}`}>
            <h1 className="fw-700 text-grey-900 font-xssss"> {userName} </h1>
          </Link>

          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            {time}
          </span>
        </h4>
        }

        {
            (friendId && friendId === userId) &&
            <BtnOptionMenu data={data} groupId={groupId} />
        }

        {(userId === postUserId && !friendId) && (
          <BtnOptionMenu data={data} groupId={groupId} />
        )}

        
      </div>
       
      {postImage.includes("mp4") ? (
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
          <ReactPlayer
            playing={true}
            width={"100%"}
            controls={true}
            muted={true}
            url={postImage}
          ></ReactPlayer>
        </div>
      ) : (
        ""
      )}
      <div className="card-body p-0 me-lg-5">
        <div
          dangerouslySetInnerHTML={{ __html: urlify(des) }}
          className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2"
        >
          {/* {
                      urlify(des)
                  } */}
        </div>
      </div>
      {postImage.includes("jpg") ? (
        <div className="card-body d-block p-0 mb-3">
          <div className="row ps-2 pe-2">
            <div className="col-sm-12 p-1">
              <Link
                to={groupId ? `/POSTS/GROUP/${groupId}/${id}` : `/POSTS/${id}`}
              >
                <img
                  src={postImage}
                  onError={imageExistsProduct}
                  className="rounded-3 w-100"
                  alt="post"
                />
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="card-body d-flex p-0">
        <div
          className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
          onClick={toggleActive}
        >
          {!like?.itsYouLike && (
            <button
              onClick={onLike}
              className="feather-thumbs-up border text-black bg-light-gradiant me-1 btn-round-xs font-xss"
            ></button>
          )}
          {like?.itsYouLike && (
            <button
              onClick={onDistLike}
              className="feather-thumbs-up border text-white bg-primary-gradiant me-1 btn-round-xs font-xss"
            ></button>
          )}
          {like?.quantityLike} Like
        </div>

        <div className="align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss cursor-pointer">
          {groupId && (
            <Link
              to={`/POSTS/GROUP/${groupId}/${id}`}
              onClick={handleViewNewPostById}
            >
              <div className="d-flex">
                <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
                <div className="mt-1">
                  <span className="d-none-xss">{commemtCount} Comment</span>
                </div>
              </div>
            </Link>
          )}

          {!groupId && (
            <Link to={`/POSTS/${id}`} onClick={handleViewNewPostById}>
              <div className="d-flex">
                <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
                <div className="mt-1">
                  <span className="d-none-xss">{commemtCount} Comment</span>
                </div>
              </div>
            </Link>
          )}
        </div>

        {/* <div className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ${menuClass}`} id={`dropdownMenu${id}`} data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}><i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i><span className="d-none-xs">Share</span></div>
                <div className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0 ${menuClass}`} aria-labelledby={`dropdownMenu${id}`}>
                    <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">Share <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2"></i></h4>
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-facebook"><i className="font-xs ti-facebook text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-twiiter"><i className="font-xs ti-twitter-alt text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-linkedin"><i className="font-xs ti-linkedin text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-instagram"><i className="font-xs ti-instagram text-white"></i></span></li>
                            <li><span className="btn-round-lg pointer bg-pinterest"><i className="font-xs ti-pinterest text-white"></i></span></li>
                        </ul>
                    </div>
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-tumblr"><i className="font-xs ti-tumblr text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-youtube"><i className="font-xs ti-youtube text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-flicker"><i className="font-xs ti-flickr text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-black"><i className="font-xs ti-vimeo-alt text-white"></i></span></li>
                            <li><span className="btn-round-lg pointer bg-whatsup"><i className="font-xs feather-phone text-white"></i></span></li>
                        </ul>
                    </div>
                    <h4 className="fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3">Copy Link</h4>
                    <i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"></i>
                    <input type="text" placeholder="https://socia.be/1rGxjoJKVF0" className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg" />
                </div> */}
      </div>
    </div>
  );
};

export default PostView;


const HeaderPostShareFriend = ({userName, postUserId, time, nameFriend, friendId}) =>{

 return <>
 <div className="mt-1 d-flex">

    <div>
 <Link to={`/PROFILE/${userName?.replaceAll(" ", "_")}/${friendId}`}>
   <h1 className="fw-700 text-grey-900 font-xssss"> {userName}
    </h1> 
   
 </Link>
   <h5 className=" font-xssss fw-500  text-grey-500 mt-1"> {time} </h5>
    </div>
   <h5 className=" font-xssss fw-500   text-grey-500 mr-3 "> shared with </h5>



    <Link
      to={`/PROFILE/${nameFriend?.replaceAll(" ", "_")}/${postUserId}`}
      >
      <h1 className="fw-700 text-grey-900 font-xssss">
        {" "}
        {nameFriend}{" "}
      </h1>
    </Link>

</div>




    


</>

}