import React from 'react'
import { Button } from "react-bootstrap";

export const ModalCreatePostOption = ({ changeImage, handleClose, titleBtn }) => {
    return (
        <div className='create-post' style={{ marginTop: '20px' }}>
        <div className='body d-flex' >

            <input type="file" name="logo" id="file" style={{
                width:'0.1px',
                height: '0.1px',
                opacity:' 0',
                overflow: 'hidden',
                position: 'absolute'}}
                onChange={ changeImage }
             />
            <label htmlFor="file">
                <div style={{ marginTop: 'auto' }}>
                    <div className="d-flex cursor-pointer align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark mb-2">
                        <i className="font-md text-success feather-image me-2"></i>
                        <span className="d-none-xs">Photo/Video</span>
                    </div>
                </div>
            </label>   

            <div className='btn-create-post' style={{ marginTop: 'auto', marginLeft: 'auto'}}>
                <Button type="submit" variant="primary"  style={{ color: '#fff', borderRadius: '50px' }} onClick={handleClose}>
                    { titleBtn }
                </Button>
            </div>
        </div>
    </div>
    )
}
