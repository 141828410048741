import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import algoliasearch from "algoliasearch/lite";
import { getFriendsById, getIsChangeMyFriend } from "actions/profile";
import { LIMIT } from "helpers/env";

const client = algoliasearch(
  process.env.REACT_APP_APPLICATION_ID,
  process.env.REACT_APP_SEARCH_API_KEY
);

const searchIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_INDEX_MYFRIENDS
);

export const useMyFriend = () => {
  const dispatch = useDispatch();
  const observer = useRef();

  const { uid: userIdVisited } = useSelector((state) => state.navigateAuth);
  const { uid: userIdActive } = useSelector((state) => state.auth);
  const { friendLoad, loading } = useSelector((state) => state.friend);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> STATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [docs, setDocs] = useState([]);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isFriendListEmpty, setIsFriendListEmpty] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);

  const [loadingLoad, setLoadingLoad] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      uid: userIdVisited,
      limit: LIMIT,
      action: action,
      lastInResp: lastInResp,
    };
    dispatch(getFriendsById(userIdVisited, data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastFriendElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isFriendListEmpty) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isFriendListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> CHANGE USER PROFILE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (friendLoad?.userData && userIdVisited != friendLoad?.userData) {
      dispatch(getIsChangeMyFriend());
    }
  }, [userIdVisited]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION DB
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (
      (friendLoad?.docs.length <= 0 && friendLoad?.isCollectionEmpty == -1) ||
      (friendLoad?.docs.length <= 0 && friendLoad?.isCollectionEmpty == 0)
    ) {
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
    }
  }, [friendLoad?.docs]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER MY FRIENDS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (friendLoad?.docs.length > 0) {
      friendLoad.docs.map((element) => {
        if (!docs.includes(element)) {
          setDocs((ele) => ele.concat(element));
        }
      });
      setlastInResponse(friendLoad?.lastInResponse);
      setIsFriendListEmpty(friendLoad?.isCollectionEmpty === 0);
      setLoadingPagination(false);
    } else if (
      friendLoad?.docs.length <= 0 &&
      friendLoad?.isCollectionEmpty == 0
    ) {
      setIsFriendListEmpty(true);
      setDocs([]);
      setLoadingPagination(false);
    } else if (
      friendLoad?.docs.length <= 0 &&
      friendLoad?.isCollectionEmpty == -1
    ) {
      setDocs([]);
      setLoadingPagination(false);
    }
  }, [friendLoad, userIdVisited, isFriendListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC  SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target?.value);
  };

  const handleSearch = async (queryText) => {
    try {
      setLoadingLoad(true);
      const option = {
        filters: `userFromId:${userIdVisited} OR userToId:${userIdVisited}`,
        hitsPerPage: 50,
      };
      const result = await searchIndex.search(queryText, option);
      setSearchResult(result.hits);
      setLoadingLoad(false);
    } catch (error) {
      setLoadingLoad(false);
      setSearchResult([]);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchResult(null);
    }
  }, [searchText]);
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===>RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    docs,
    lastFriendElementRef,
    loadingLoad,
    loading,
    loadingPagination,
    handleSearchText,
    searchText,
    handleSearch,
    searchResult,
    userIdActive,
    userIdVisited,
  };
};
