import React from 'react'
import PropTypes from 'prop-types'

export const GroupInfoBasicForm = ({ values, onChange }) => {

    return (
        <div className="col-xl-12 mb-3">
        <div className="form-group ">
            <label className="mont-font fw-600 font-xsss">Title</label>
            <input id='titlegroup' type="text" name="title" value={ values.title } className="form-control" onChange={ onChange } placeholder="title group" ></input >
        </div>

        <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Description</label>
            <input id='descriptiongroup' type="text" name="description" value={ values.description }  className="form-control" onChange={ onChange } placeholder="description group" ></input >
        </div>
    </div>
    )
}

GroupInfoBasicForm.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}
