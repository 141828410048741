import Swal from "sweetalert2";

export const successLoading = (title) => {
  Swal.fire({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timerProgressBar: true,
    icon: "info",
    title,
  });
};


export const errorLoading = (title) => {
  Swal.fire({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timerProgressBar: true,
    icon: "error",
    title,
    timer: 2000,
  });
};

export const networkStatusAlert = (title, type, activeTimer) => {
  const render =
    type == "online"
      ? `
          <div class="d-flex" style="height: 50px;">
              <div style="height: 30px;">
                <img src="assets/images/wifi.svg" style="margin-right: 10px;"></img>
              </div>
              <div style="height: 30px;">
                <p style="color: black; font-size: 15px;">${title}</p>
              </div>
          </div>
        `
      : `
        <div class="d-flex" style="height: 50px;">
            <div style="height: 30px;">
                <img src="assets/images/wifioff.svg" style="color: white; margin-right: 10px;"></img>
            </div>
            <div style="height: 30px;">
                <p style="color: white; font-size: 15px;">${title}\n por favor verifica tu internet.</p>
            </div>
        </div>
`;
  Swal.fire(
    activeTimer
      ? {
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timerProgressBar: true,
          heightAuto: true,
          // icon:  type == 'online' ? 'info' : 'error',
          timer: 2000,
          background: type == "online" ? "#11c90e" : "#f70e0e",
          html: render,
        }
      : {
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timerProgressBar: true,
          heightAuto: true,
          background: type == "online" ? "#01ff00" : "#f70e0e",
          html: render,
        }
  );
};

export const closeLoading = () => {
  Swal.close();
};
