import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getMyFriendList } from 'actions/getUsers';

export const useMembers = () => {
    const dispatch = useDispatch();
    const { uid } = useSelector( state => state.auth );
    const { users } = useSelector( state => state.user );

  
    useEffect(() => {
      dispatch(getMyFriendList({ userId: uid }));
    }, [])

    return { users }
}
