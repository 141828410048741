import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getProjectMembersPendingById, NotificationsMembers, updateProjectMembersById } from "../../actions/project";

export const useMembersNotifications = () => {
  const dispatch = useDispatch();
  const {id}= useParams();
  const { loadingMember, notificationsMembers } = useSelector((state) => state.project);
  const { uid } = useSelector((state) => state.auth);
  const [Notifications, setNotifications] = useState(notificationsMembers);

  useEffect(() => {
    setNotifications(notificationsMembers);
  }, [notificationsMembers])

  useEffect(() => {
   notificationsMembers?.length <= 0 &&  dispatch(getProjectMembersPendingById({projectId: id}))
  }, [id]);

  const Accept = (iduser) =>{
    const data={
        projectId: id,
        userId: iduser,
        stateMembers:{
          stateMembers: "success"
        }
      }
      dispatch(updateProjectMembersById(data));

      const values = [...notificationsMembers];
       values.splice(
         values.findIndex(value => value.uid == iduser),
         1
       );
        dispatch(NotificationsMembers(values));
  }



  return {
    loadingMember,
    Notifications,
    Accept,
  };
};
