import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Appfooter = () => {

    const {photoUrl, name} = useSelector( state => state.auth );
    const { updateCoverPage, updateProfilePage } = useSelector(state => state.user);
    const [currentTimeStamp, setCurrentTimeStamp] = useState(new Date().getTime());
    
    useEffect(() => {
        setCurrentTimeStamp(new Date().getTime());
    }, [updateCoverPage, updateProfilePage])
    
    const ProfilePhoto = photoUrl.includes("https://storage.googleapis") ? (photoUrl ? photoUrl + `&version=` + currentTimeStamp : `https://ui-avatars.com/api/?name=${name}`): photoUrl;
   
        return (
            <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
                <Link to="/home" className="nav-content-bttn nav-center"><i className="feather-home"></i></Link>
                <Link to="/projects" className="nav-content-bttn"><i className="feather-briefcase"></i></Link>
                <Link to="/COURSES" className="nav-content-bttn" data-tab="chats"><i className="feather-book-open"></i></Link>           
                <Link to="/USERS" className="nav-content-bttn"><i className="feather-users"></i></Link>
                <Link to="/defaultsettings" className="nav-content-bttn"><img src={ ProfilePhoto } alt="user" className="w30 shadow-xss" /></Link>
            </div>        
        );
    
}

export default Appfooter;