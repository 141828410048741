import React from 'react'
import {
    Redirect,
    Switch,
    Route,
} from "react-router-dom";
import Home from '../pages/Home';
import Badge from '../pages/Badge';
import Storie from '../pages/Stories';
import User from '../pages/User';
import Emailopen from '../pages/Emailopen';
import Settings from '../pages/Settings';
import Account from '../pages/Account';
import Contactinfo from '../pages/Contactinfo';
import Socialaccount from '../pages/Socialaccount';
import Password from '../pages/Password';
import Payment from '../pages/Payment';
import Notification from '../pages/Notification';
import Helpbox from '../pages/Helpbox';

import ShopOne from '../pages/ShopOne';
import ShopTwo from '../pages/ShopTwo';
import ShopThree from '../pages/ShopThree';
import Singleproduct from '../pages/Singleproduct';
import Cart from '../pages/Cart';
import Checkout from '../pages/Checkout';
import {Chats} from '../pages/Chat';
import Live from '../pages/Live';
import Job from '../pages/Job';
import Event from '../pages/Event';
import Hotel from '../pages/Hotel';
import Videos from '../pages/Videos';
import Comingsoon from '../pages/Comingsoon';


import UserPage from '../pages/UserPage';
import Authorpage from '../pages/Authorpage';
import Hotelsingle from '../pages/Hotelsingle';
import Analytics from '../pages/Analytics';
import { CreateGroup } from '../components/group/CreateGroup';
import { EditGroup } from '../components/group/EditGroup';
import { ViewGroup } from '../components/group/ViewGroup';
import { CreateOrganization } from '../components/organization/CreateOrganization';
import { EditOrganization } from '../components/organization/EditOrganization';
import { CreateProyect } from '../components/project/CreateProyect';
import { EditProjectView } from '../components/project/EditProjectView';
import { ViewProject } from '../components/project/ViewProject';
import { CommentsPost } from '../components/post/comment/CommentsPost';
import { CommentsPostGroup } from '../components/post/comment/CommentsPostGroup';
import { CreateCourse } from '../components/course/CreateCourse';
import { ViewCourse } from '../components/course/ViewCourse';
import { EditCourse } from '../components/course/EditCourse';
import GroupsPage from '../pages/GroupsPage';
import GroupPage from '../pages/GroupPage';
import ProjectsPage from '../pages/ProjectsPage';
import CoursesPage from '../pages/CoursesPage';
import { ViewOrganization } from '../components/organization/all/ViewOrganization';
import { Notfound } from '../pages/Notfound';



export const HomeRoutes = () => {
    return (
        <div>
            <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/users`} component={ User }/>


                    {/* <Route exact path={`${process.env.PUBLIC_URL}/defaultbadge`} component={Badge}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/defaultstorie`} component={Storie}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/defaultemailbox`} component={Notification}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/defaultemailopen`} component={Emailopen}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/defaultsettings`} component={Settings}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/defaultvideo`} component={Videos}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/defaultanalytics`} component={Analytics}/>


                    {/* <Route  path={`/edit`} component={FormEditUser}/> */}
                    
                    
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/accountinformation`} component={Account}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/contactinformation`} component={Contactinfo}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/socialaccount`} component={Socialaccount}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/password`} component={Password}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/payment`} component={Payment}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/defaultnotification`} component={Notification}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/helpbox`} component={Helpbox}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/forgot`} component={Forgot}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/notfound`} component={Notfound}/>

                    {/* <Route exact path={`${process.env.PUBLIC_URL}/shop1`} component={ShopOne}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/shop2`} component={ShopTwo}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/shop3`} component={ShopThree}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/singleproduct`} component={Singleproduct}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/cart`} component={Cart}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/checkout`} component={Checkout}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/defaultmessage`} component={Chats}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/defaultlive`} component={Live}/>        */}
                    
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/defaultjob`} component={Job}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/defaultevent`} component={Event}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/defaulthotel`} component={Hotel}/>   */}
                    <Route exact path={`${process.env.PUBLIC_URL}/grouppage`} component={GroupPage}/>
                    <Route path={`${process.env.PUBLIC_URL}/PROFILE/:name/:id`} component={UserPage}/>
                    <Route path={`${process.env.PUBLIC_URL}/ViewOrganization/:id`} component={ViewOrganization}/>
                    
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/authorpage`} component={Authorpage}/>   */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/comingsoon`} component={Comingsoon}/>   */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/defaulthoteldetails`} component={Hotelsingle}/> */}

                    {/* Author Profile Menu Options */}
                    <Route exact path={`${process.env.PUBLIC_URL}/CreateGroup`} component={ CreateGroup }/>
                    <Route exact path={`${process.env.PUBLIC_URL}/EditGroup/:id`} component={ EditGroup }/>
                    <Route  path={`${process.env.PUBLIC_URL}/ViewGroup/:id`} component={ ViewGroup }/>
                    <Route exact path={`${process.env.PUBLIC_URL}/groups`} component={GroupsPage}/>

                    {/* ORGANIZATION */}
                    <Route exact path={`${process.env.PUBLIC_URL}/CreateOrganization`} component={ CreateOrganization }/>
                    <Route exact path={`${process.env.PUBLIC_URL}/EditOrganization/:id`} component={ EditOrganization }/>
                    
                    {/* Project */}
                    <Route exact path={`${process.env.PUBLIC_URL}/CreateProject`} component={ CreateProyect }/>
                    <Route exact path={`${process.env.PUBLIC_URL}/EditProject/:Id`} component={ EditProjectView }/>
                    <Route  path={`${process.env.PUBLIC_URL}/ViewProject/:Id`} component={ ViewProject }/>
                    <Route exact path={`${process.env.PUBLIC_URL}/projects`} component={ProjectsPage}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/POSTS/:postId`} component={ CommentsPost }/>
                    <Route exact path={`${process.env.PUBLIC_URL}/POSTS/GROUP/:groupId/:postId`} component={ CommentsPostGroup }/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/Home/:name/My-friends`} component={ FiendsListView }/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/Home/:name/:id`} component={ FiendsListView }/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/Home/:id`} component={ CommentsPost }/>

                     {/* Courses */}
                     <Route exact path={`${process.env.PUBLIC_URL}/CreateCourse`} component={ CreateCourse }/>
                     <Route  path={`${process.env.PUBLIC_URL}/ViewCourse/:Id`} component={ ViewCourse }/>
                     <Route  path={`${process.env.PUBLIC_URL}/EditCourse/:Id`} component={ EditCourse }/>
                     <Route exact path={`${process.env.PUBLIC_URL}/courses`} component={ CoursesPage }/>

                    <Redirect to="/"

                    
                />
            </Switch>
        </div>
    )
}
