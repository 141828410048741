import React from "react";
import { stateFriend } from "helpers/stateFriend";
import { useBtnAddFriends } from "Hooks/friends/useBtnAddFriends";
import "../css/friends.css";

export const UserPageLocked = () => {
  const { isFriend, onChangeUnlockFriend, friendInvitation, uid } =
    useBtnAddFriends();

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
              <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                <div className="content">
                  <img src="/assets/images/locked.svg"></img>
                  {isFriend == stateFriend.locked.description &&
                  friendInvitation?.stateLockedId == uid ? (
                    <div
                      className="btnAddFriends spacing"
                      onClick={onChangeUnlockFriend}
                    >
                      <i className="feather-user-minus font-sm text-grey"></i>
                      <span className="ms-2"> unblock friend </span>
                    </div>
                  ) : (
                    isFriend == stateFriend.loading.description && (
                      <div className="btnAddFriendsLoading spacing">
                        <i className="spinner-border"></i>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
