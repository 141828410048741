import { format } from "date-fns";
import { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  createComment,
  createCommentGroup,
  getAllComment,
  getAllCommentGroup,
  setRemoveResetComment,
  setUpdateResetComment,
} from "actions/comments";
import { LIMIT } from "helpers/env";
import { typeEventPost } from "helpers/typeEvent";

export const usePostComents = (type, groupId, post) => {
  const observer = useRef();
  const { postId } = useParams();

  const initialState = {
    isOpen: false,
    isActive: false,
  };
  const [state, setState] = useState(initialState);
  const toggleOpen = () => setState({ isOpen: !state.isOpen });

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> SELECTORS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { loading, commentsLoad, isUpdate, isRemove, isReset } = useSelector(
    (state) => state.comments
  );
  const { uid } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> STATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [forms, setForms] = useState({ content: "" });
  const [docs, setDocs] = useState([]);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isCommentListEmpty, setIsCommentListEmpty] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [loadMoreComment, setLoadMoreComment] = useState("");
  const [isLoadMoreComment, setIsLoadMoreComment] = useState(false);
  const [isfetching, setIsfetching] = useState(false);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp, isLoading }) => {
    if (type == typeEventPost.global) {
      const data = {
        limit: LIMIT,
        action: action,
        lastInResp: lastInResp,
        postId: postId,
      };
      dispatch(getAllComment(data, { isLoading }));
    } else {
      const data = {
        limit: LIMIT,
        action: action,
        lastInResp: lastInResp,
        postId: postId,
        groupId: groupId,
      };

      dispatch(getAllCommentGroup(data, { isLoading }));
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOAD MORE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (post?.content.length > 200) {
      setLoadMoreComment(post?.content.substr(0, 70));
    } else {
      setLoadMoreComment(post?.content);
    }
  }, [post?.content]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastCommentElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isCommentListEmpty) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
            isLoading: false,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isCommentListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (commentsLoad?.docs.length <= 0 && commentsLoad.isCollectionEmpty != 0) {
      if (isfetching) {
        return;
      }
      query({
        action: "get",
        lastInResp: lastInResponse,
        isLoading: true,
      });
      setIsfetching(true);
    }
  }, [commentsLoad]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (commentsLoad?.docs.length > 0) {
      commentsLoad.docs.map((element) => {
        if (!docs.includes(element)) {
          setDocs((ele) => ele.concat(element));
        }
      });
      setlastInResponse(commentsLoad?.lastInResponse);
      setIsCommentListEmpty(commentsLoad?.isCollectionEmpty === 0);
      setLoadingPagination(false);
    } else if (
      commentsLoad?.docs.length <= 0 &&
      commentsLoad?.isCollectionEmpty == 0
    ) {
      setIsCommentListEmpty(true);
      setDocs([]);
      setLoadingPagination(false);
    } else if (
      commentsLoad?.docs.length <= 0 &&
      commentsLoad?.isCollectionEmpty == -1
    ) {
      setDocs([]);
      setLoadingPagination(false);
    }
  }, [commentsLoad, isCommentListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS UPDATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isUpdate?.state) {
      setDocs((items) => {
        const position = docs.findIndex((dc) => dc.uid === isUpdate?.uidUpdate);
        const newElement = commentsLoad?.docs.find(
          (dc) => dc.uid === isUpdate?.uidUpdate
        );
        docs.splice(position, 1, newElement);
        return items;
      });

      dispatch(setUpdateResetComment());
    }
  }, [isUpdate?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS IS REMOVE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (isRemove?.state) {
      setDocs((items) => {
        const position = docs.findIndex((dc) => dc.uid === isRemove?.uidRemove);
        docs.splice(position, 1);
        return items;
      });

      dispatch(setRemoveResetComment());
    }
  }, [isRemove?.state]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC FORM COMMENTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const changeInput = (e) => {
    e.preventDefault();
    setForms({ content: e.target.value });
  };
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER POSTS COMMENTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const HandleSubmitComents = (e) => {
    e.preventDefault();
    const { content } = forms;
    const date = format(new Date(), "dd/MM/yyyy HH:mm:ss");

    const data = {
      userId: uid,
      postId,
      content,
      createDate: date,
      data: {
        uid: "",
        type: "comments",
        userFromId: uid,
        params: {
          userToId: post?.userId,
          postToId: postId,
        },
        notification: "",
        state: false,
        createDate: date,
      },
    };

    if (type == "global") {
      data.content.length > 1 && dispatch(createComment(data));
    } else {
      data.content.length > 1 &&
        dispatch(
          createCommentGroup({
            userId: uid,
            postId,
            content,
            createDate: date,
            groupId,
            data: {
              uid: "",
              type: "commentsGroup",
              userFromId: uid,
              params: {
                userToId: post?.userId,
                postToId: postId,
                groupToId: groupId,
              },
              notification: "",
              state: false,
              createDate: date,
            },
          })
        );
    }
    setForms({ content: "" });
  };

  const loadMore = () => {
    if (!isLoadMoreComment) {
      setLoadMoreComment(post?.content);
      setIsLoadMoreComment(true);
    } else {
      setIsLoadMoreComment(false);
      if (post?.content.length > 200) {
        setLoadMoreComment(post?.content.substr(0, 70));
      } else {
        setLoadMoreComment(post?.content);
      }
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    HandleSubmitComents,
    loading,
    changeInput,
    ListComments: docs,
    lastCommentElementRef,
    loadingPagination,
    contentComment: forms.content,
    loadMore,
    loadMoreComment,
    isLoadMoreComment,

    userId: uid,
    toggleOpen,
  };
};
