import React from 'react'
import { useCreateFileProject } from 'Hooks/project/useCreateFileProject';

export const FilesProject = () => {
    
   const { handleImg, changeImage, files, ImageSelectedPrevious, loading, progress,
    getRootProps, getInputProps, isDragActive, progressCoverPage, acceptedFiles } = useCreateFileProject()

    
    return (
        <>
          <form onSubmit={handleImg}>
              <label className="mont-font fw-600 font-xsss" >Add cover image</label>
              <div className="Container pt-4 pb-4" style={{width: '200px', height: '240px', textAlign: 'center', alignContent: 'center', display: 'flex', margin:'auto'}}>
                <img src= { ImageSelectedPrevious ?? "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png" } className=" border float-right p-1 bg-white rounded-circle w-100" width='200px'  height="200px"  style={{ width: '200px', objectFit:  'cover', height: "200px"}}></img>
                <div className="">
                    <input type="file" name="logo" id="file" style={{
                            width:'0.1px',
                            height: '0.1px',
                            opacity:' 0',
                            overflow: 'hidden',
                            position: 'absolute'}}
                            onChange={ changeImage }/>
                    <label htmlFor="file">
                    <li className="cursor-pointer nav-content-bttn open-font">
                        <i className="feather-edit btn-round-md color-light bg-primary-gradiant me-3" style={{ color: '#fff' }}></i>
                    </li> 
                    </label>         
                </div>
              </div>

                    {
                      (ImageSelectedPrevious) &&
                      <div className="text-center">
                        <progress  value={progressCoverPage}></progress>
                      </div>
                    }

              <section className="container p-3">
                    <div {...getRootProps({className: 'dropzone'})}>
                      <input {...getInputProps()} />
                      { 
                        isDragActive ? 
                          <div className="container p-3 text-center" style={{ width: 500, height: 70, border: "dotted" }}>
                          < p > Drop files here ... </ p > 
                          </div>    
                                : 
                                <div className="container  text-center" style={{ width: 500, height: 70, border: "dotted" }}>
                          < p > Drag 'n' drop some files here, or click to select files </ p >
                          </div>
                      } 
                    </div>
                    <aside>
                      <h4>Files</h4>
                      <ul>{files}</ul>

                      {
                        (acceptedFiles.length !== 0) &&
                        <div className="text-center">
                          <progress  value={progress}>{progress}</progress>
                        </div>
                      }
                    </aside>
                  </section> 

                <button disabled={loading} type="submit" className=" bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-blockbg-current text-center text-white font-xsss fw-600 p-3 w200 rounded-3 d-inline-block ">Create project</button>

            </form>    
        </>
    )
}
