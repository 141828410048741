import { useState } from "react";
import { useDispatch } from "react-redux";
import { removeProjectById } from "actions/project";

export const useModalRemoveElement = (idElement) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const remove = "Eliminar";
  const [formsValue, setforms] = useState({ textRemove: "" });

  const handleimag = async (e) => {
    e.preventDefault();

    if (formsValue.textRemove === "Eliminar") {
      dispatch(removeProjectById({ uid: idElement }));
    }
  };

  const handlerform = (event) => {
    setforms({
      textRemove: event.target.value,
    });
  };

  return {
    handleShow,
    show,
    handleClose,
    handleimag,
    handlerform,
    formsValue,
    remove,
  };
};
