import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInformationHelps } from "actions/settingInformations";

export const useHelpsbox = () => {
  const dispatch = useDispatch();
  const { helpsInfo } = useSelector((state) => state.infoGlobal);

  const [docInfo, setDocInfo] = useState(null);
  const [docInfoLoading, setDocInfoLoading] = useState(null);
  const [searchResult, setSearchResult] = useState(null);

  useEffect(() => {
    if (!helpsInfo) {
      setDocInfoLoading(true);
      dispatch(getInformationHelps());
    }
  }, []);

  useEffect(() => {
    if (helpsInfo) {
      setDocInfoLoading(false);
      setDocInfo(helpsInfo);
    }
    return () => setDocInfo(null);
  }, [helpsInfo]);

  const handleSearch = ({ target }) => {
    const valueSearch = target.value;
    const result = docInfo.questions.filter((value) => {
      return value.question.toLowerCase().includes(valueSearch.toLowerCase());
    });
    setSearchResult(result);
  };

  return {
    docInfo,
    docInfoLoading,
    handleSearch,
    searchResult,
  };
};
