import { useEffect, useRef, useState, useCallback } from "react";
import algoliasearch from "algoliasearch/lite";
import { useDispatch, useSelector } from "react-redux";
import { LIMIT } from "helpers/env";
import { getAllCourses } from "actions/course";

const client = algoliasearch(
  process.env.REACT_APP_APPLICATION_ID,
  process.env.REACT_APP_SEARCH_API_KEY
);
const searchIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_INDEX_COURSES_GLOBAL
);

let theRequestWasMade = false;

export const useCoursesAll = () => {
  const dispatch = useDispatch();
  const observer = useRef();

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> SELECTORS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const { uid: userIdActive } = useSelector((state) => state.auth);
  const { allCourses, loading } = useSelector((state) => state.courses);
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> STATE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [docs, setDocs] = useState(allCourses?.docs);
  const [lastInResponse, setlastInResponse] = useState(null);
  const [isCoursesListEmpty, setIsCoursesListEmpty] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);

  const [loadingLoad, setLoadingLoad] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> QUERY
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const query = ({ action, lastInResp }) => {
    const data = {
      limit: LIMIT,
      action: action,
      lastInResp: lastInResp,
    };
    dispatch(getAllCourses(data));
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PAGINATIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const lastCoursesElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isCoursesListEmpty) {
          setLoadingPagination(true);
          query({
            action: "next",
            lastInResp: lastInResponse,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isCoursesListEmpty, lastInResponse]
  );

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> PETITION BD
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (!theRequestWasMade && allCourses?.docs.length <= 0 && !lastInResponse) {
      theRequestWasMade = true;
      query({
        action: "get",
        lastInResp: lastInResponse,
      });
    }
  }, [allCourses, lastInResponse]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER Courses
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (allCourses?.docs.length > 0 && !isCoursesListEmpty) {
      allCourses.docs.map((element) => {
        if (!docs.includes(element)) {
          setDocs((ele) => ele.concat(element));
        }
      });
      setlastInResponse(allCourses?.lastInResponse);
      setIsCoursesListEmpty(allCourses?.isCollectionEmpty === 0);
      setLoadingPagination(false);
    } else if (
      allCourses?.docs.length <= 0 &&
      allCourses?.isCollectionEmpty == -1
    ) {
      setDocs([]);
      setLoadingPagination(false);
    }
  }, [allCourses, isCoursesListEmpty]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOGIC RENDER Courses Y SEARCH
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target?.value);
  };

  const handleSearch = async (queryText) => {
    try {
      setLoadingLoad(true);
      const result = await searchIndex.search(queryText);
      setSearchResult(result.hits);
      setLoadingLoad(false);
    } catch (error) {
      setSearchResult([]);
      setLoadingLoad(false);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchResult(null);
    }
  }, [searchText]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> RESULTS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return {
    docs,
    lastCoursesElementRef,
    loadingLoad,
    loading,
    loadingPagination,
    searchResult,
    searchText,
    handleSearchText,
    handleSearch,
  };
};
