import React from 'react'
import PropTypes from 'prop-types'

export const EmptyData = ({ title, pathImg }) => {
    return (
        <div className="card w-100 text-center rounded-xxl border-0 p-4 mb-3 mt-3">
            <img  src={ pathImg != '' && pathImg != null ? pathImg : '/assets/images/empty.svg' } style={{ width: 200, margin: 'auto', paddingTop: 100, paddingBottom: 100 }}></img>
            <span> { title.toUpperCase() } </span>
        </div>
    );
}

EmptyData.propTypes = {
    title: PropTypes.string.isRequired,
    pathImg: PropTypes.string
}
