import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { imageExistsProduct } from 'helpers/verifyImg';
import { usePostView } from 'Hooks/post/usePostView';
import { UsePostViewLike } from 'Hooks/post/UsePostViewLike';

const PostViewGroupShare = ({groupId, userId: postUserId, userName, time, des, avater, postImage, postVideo, id, like, comment, type, typeLike, typePost, Linkto  }) => {

  const  { userId, handleViewNewPostById, toggleActive} = usePostView(des);
  const {onDistLike, onLike} = UsePostViewLike(id, type, typeLike, groupId, postUserId);
//   const data = { userId, userName, time, des, avater, postImage, postVideo, id, typePost, Linkto };
  const commemtCount = comment?.quantityComments;

    return (
        <div className="card w110 d-block border-0 shadow-xss rounded-xxl overflow-hidden mb-3 wrap ">
             <div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{backgroundImage: `url("${postImage}")`}}>
                 <div className='bg-dark pt-2 pb-2 text-white text-center align-items-center' style={{ width: '100px', display: 'flex',  justifyContent: 'center' , borderRadius: '50px', textTransform: 'uppercase', fontSize: '10px'}}>
                        {
                            typePost
                        }
                 </div>
                 <div className="clearfix"></div>
                 {/* <h4 className="fw-700 font-xsss mt-2 mb-1">{userName} </h4> */}
                 <Link to={Linkto} className="btn-round-sm bg-white ms-auto mt-2" style={{ position: 'absolute' , top: 0, right: 10 }} ><span className="feather-chevron-right font-xss text-grey-900"></span> </Link>
                 <div className="clearfix mb-2"></div>
             </div>
             <div className="card-body d-block w-100  pt-0 text-left position-relative">
                <div className="clearfix"></div>
                <div className="card-body d-flex pt-4 ">
                    <figure className="avatar me-3"><img src={ avater } onError={ imageExistsProduct } alt="avater" className="shadow-sm rounded-circle" style={{ width: "45px", height: "40px", borderRadius: "50px" }} /></figure>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1"> {userName} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {time}</span></h4>
                </div>
             </div>
             <div className='card-body me-lg-5 pl-3 pt-0 pb-0'>
             <span className="fw-500 text-grey-500 lh-26 font-xssss w-100 d-flex p-2">
                        <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2" onClick={toggleActive}>
                            {
                            !like?.itsYouLike  && <button onClick={onLike} className="feather-thumbs-up border text-black bg-light-gradiant me-1 btn-round-xs font-xss"></button> 
                            }  
                            {

                            like?.itsYouLike && <button onClick={onDistLike} className="feather-thumbs-up border text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></button> 
                            }      
                            {like?.quantityLike} Like
                        </div>
                        <div className="align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss cursor-pointer">
                   
                                {
                                    groupId && <Link to={`/POSTS/GROUP/${groupId}/${ id }`} onClick={ handleViewNewPostById }>
                                                    <div className='d-flex'>
                                                        <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
                                                        <div className='mt-1'>
                                                            <span className="d-none-xss">{ commemtCount } Comment</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                }

                                {
                                    !groupId && <Link to={`/POSTS/${ id }`} onClick={ handleViewNewPostById }>
                                                    <div className='d-flex'>
                                                        <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
                                                        <div className='mt-1'>
                                                            <span className="d-none-xss">{ commemtCount } Comment</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                }
                                
                         </div>
                    </span>
             </div>
             {/* <div className="card-body d-flex  bg-danger">
                <div className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0 ${menuClass}`} aria-labelledby={`dropdownMenu${id}`}>
                    <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">Share <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2"></i></h4>
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-facebook"><i className="font-xs ti-facebook text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-twiiter"><i className="font-xs ti-twitter-alt text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-linkedin"><i className="font-xs ti-linkedin text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-instagram"><i className="font-xs ti-instagram text-white"></i></span></li>
                            <li><span className="btn-round-lg pointer bg-pinterest"><i className="font-xs ti-pinterest text-white"></i></span></li>
                        </ul>
                    </div>
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-tumblr"><i className="font-xs ti-tumblr text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-youtube"><i className="font-xs ti-youtube text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-flicker"><i className="font-xs ti-flickr text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-black"><i className="font-xs ti-vimeo-alt text-white"></i></span></li>
                            <li><span className="btn-round-lg pointer bg-whatsup"><i className="font-xs feather-phone text-white"></i></span></li>
                        </ul>
                    </div>
                    <h4 className="fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3">Copy Link</h4>
                    <i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"></i>
                    <input type="text" placeholder="https://socia.be/1rGxjoJKVF0" className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg" />
                </div>
            </div> */}
         </div>
    )
    
}

export default PostViewGroupShare;