import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMembers } from "../members/useMembers";
import makeAnimated from "react-select/animated";
import { Allusers, client } from "helpers/chat";
import { errorLoading } from "helpers/loadingCreate";
import { userFriends } from "actions/profile";


export const useChat = () => {
  const { usersFriends } = useSelector((state) => state.friend);
  const { uid } = useSelector((state) => state.auth);
  const sort = { last_message_at: -1 };



  useEffect(() => {
    if (usersFriends) {
      Allusers(usersFriends);
    }
  }, [usersFriends]);

  const filters = { type: "messaging", members: { $in: [uid] } };
  const { users: friends } = useMembers();
  const [membersusers, setmembersusers] = useState([]);

  useEffect(() => {
    if (friends) {
      setmembersusers(friends);
    }
  }, [friends]);

  const animatedComponents = makeAnimated();

  const [member, setMember] = useState([]);
  const [Channels, setChannels] = useState(null);
  // const dispatch = useDispatch();
  const onChangeMembers = (data) => {
    setMember(data);
  };

  const createChannel = async () => {
    if (member) {
      try {
        const channel = client.channel("messaging", `${uid}${member.value}`, {
          members: [uid, member.value],
        });
        await channel.create();
        setChannels(channel);
      } catch (error) {
        console.log(error);
        errorLoading("Error al crear el chat");
      }
    }
  };

  return {
    Channels,
    onChangeMembers,
    animatedComponents,
    friends,
    client,
    filters,
    sort,
    createChannel,
    membersusers,
  };
};
