import React  from 'react'
import Load from 'components/Load';

import { useEditOrganization } from 'Hooks/organization/useEditOrganization';
import { OrganizationInfoBsicForm } from './OrganizationInfoBsicForm';
import { OrganizationLogo } from './OrganizationLogo';

export const EditOrganizationForm = () => {

    const{ loading, handleUpdateOrganization, changeImage, logoSelectedPrevious, values, handleInputChange } = useEditOrganization();

    return (
        (loading )? <Load/>
        : (
            <form onSubmit={handleUpdateOrganization}>
               <OrganizationLogo logoSelectedPrevious={ logoSelectedPrevious } changeImage={ changeImage }/>
               <OrganizationInfoBsicForm values={ values } onChange= { handleInputChange }/>
               {/* <AddMembers isEdit={ true } values={ values?.members } handleInputChange={ handleInputChange } />   */}
               <div className="pb-5">
                 <button type="submit" className=" bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-blockbg-current text-center text-white font-xsss fw-600 p-3 w200 rounded-3 d-inline-block ">Edit Organization</button>
               </div>
            </form>
          )
    )
}
