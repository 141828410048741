import React from "react";
import Load from "components/Load";
import SearchAll from "components/shared/SearchAll";

import { useMyFriend } from "Hooks/profile/useMyFriend";
import { EmptyData } from "components/shared/EmptyData";
import { useScroll } from "Hooks/scroll/useScroll";
import { CardListUsersStyle } from "../components/CardListUsersStyle";
import { ViewProjectLoading } from "../loading/ProfileMyFriendLoading";
import { usePropietor } from "Hooks/usePropietor";

export const MyFriends = () => {
  const {
    docs,
    loading,
    loadingLoad,
    loadingPagination,
    lastFriendElementRef,
    handleSearchText,
    searchText,
    handleSearch,
    searchResult,
    userIdVisited
  } = useMyFriend();
  const { isPropietor } = usePropietor();

  const title = isPropietor ? "My Friends" : "Friends";
  const { divRref } = useScroll();

  return (
    <>
      <div
        ref={divRref}
        className="card w-100 border-0 p-3 bg-white shadow-xss rounded-xxl"
      >
        <SearchAll
          title={title}
          placeHolder="Search friends"
          onChange={handleSearchText}
          value={searchText}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSearch(searchText);
          }}
        />

        {loading && docs?.length === 0 && <ViewProjectLoading />}

        {docs == null ? null : loadingLoad ? (
          <Load />
        ) : (
          <div className="row ps-2 pe-2">
            {searchResult && searchResult.length > 0 ? (
              searchResult?.map((value) => (
                <div
                  key={value?.objectID}
                  className="col-md-3 col-sm-4 pe-2 ps-2">
                  {
                  userIdVisited != value?.userFromId ? (
                    <CardListUsersStyle
                      uid={value?.userFromId}
                      userName={value?.fromUserName}
                      photoUrl={value.fromPhotoUrl}
                    />
                  ) : (
                    <CardListUsersStyle
                      uid={value.userToId}
                      userName={value.toUserName}
                      photoUrl={value.toPhotoUrl}
                    />
                  )
                  }
                </div>
              ))
            ) : (!loading && docs.length === 0) ||
              (!loading && searchResult && searchResult.length === 0) ? (
              <EmptyData
                pathImg="/assets/images/NoData.svg"
                title="There are no friends"
              />
            ) : (
              docs.map((value, index) => {
                if (docs.length === index + 1) {
                  return (
                    <div
                      key={index}
                      ref={lastFriendElementRef}
                      className="col-md-3 col-sm-4 pe-2 ps-2"
                    >
                      <CardListUsersStyle
                        uid={value.userId}
                        userName={value.userName}
                        photoUrl={value.photoUrl}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2">
                      <CardListUsersStyle
                        uid={value.userId}
                        userName={value.userName}
                        photoUrl={value.photoUrl}
                      />
                    </div>
                  );
                }
              })
            )}
          </div>
        )}
        {loadingPagination && <Load />}
      </div>
    </>
  );
};
