import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { authReducer } from "../reducers/authReducer";
import thunk from "redux-thunk";
import { uiReducer } from "../reducers/uiReducers";
import { projectReducer } from "../reducers/projectReducer";
import { globalInfoReducer } from "../reducers/globalInfoReducer";
import { usersReducer } from "../reducers/usersDataReducer";
import { organizationReducer } from "../reducers/organizationReducer";
import { groupReducer } from "../reducers/groupReducer";
import { postsReducer } from "../reducers/postReducer";
import { userReducer } from "../reducers/userDataReducer";
import { navigateReducer } from "../reducers/navigateReducer";
import { courseReducer } from "../reducers/courseReducer";
import { friendReducer } from "../reducers/friendReducer";
import { projectsReducer } from "../reducers/projectsReducer";
import { coursesReducer } from "../reducers/coursesReducer";
import { commentsReducer } from "../reducers/commentsReducer";
import { groupsReducer } from "../reducers/groupsReducer";
import { postsGroupReducer } from "../reducers/postGroupReducer";
import { notificationReducer } from "../reducers/notificationReducer";
import { forumReducer } from "../reducers/forumReducer";
import { chatReducer } from "../reducers/chatReducer";
import { page403Reducer } from "../reducers/page403Reducer";
import { adminReducer } from "reducers/adminReducer";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const reducers = combineReducers({
  navigateAuth: navigateReducer,
  auth: authReducer,
  ui: uiReducer,

  user: userReducer,
  users: usersReducer,

  project: projectReducer,
  projects: projectsReducer,

  infoGlobal: globalInfoReducer,
  organization: organizationReducer,
  
  group: groupReducer,
  groups: groupsReducer,

  posts: postsReducer,
  postsGroup: postsGroupReducer,

  course: courseReducer,
  courses: coursesReducer,

  friend: friendReducer,

  comments: commentsReducer,

  notifications : notificationReducer,

  forumCommens: forumReducer,

  chat: chatReducer,
  
  page403: page403Reducer,

  admin: adminReducer,
});

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
