import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useDeleteForum } from 'Hooks/forum/useDeleteForum';

export const DeleteForum = ({data, type}) => {
     
  const { handleShow, show, handleClose, handleRemoveForum, handlerform, formsValue, remove} = useDeleteForum( data, type );

  return (
    <>
      <li onClick={handleShow} className="cursor-pointer d-flex" style={{ borderBottom: '1px solid #00589b60' }}>
          <div className='icon-li '>
              <i className="font-sm ti-trash  text-grey"></i> 
          </div> 
           <div className='text-li'>
               <span className='pb-1'> Remove suggestion </span>
          </div>
      </li>

      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleRemoveForum}>
          <Modal.Header>
            <Modal.Title>Remove suggestion { data?.des?.substring(0,10) }</Modal.Title>
            <button className="btn ti-close" type="button" onClick={ handleClose }></button>
          </Modal.Header>
          <Modal.Body>
            <div name="removeElement" className="form-group">
              <input
                id="file-input"
                name="remove"
                onChange={handlerform}
                type="text"
                placeholder="Escriba la palabra Eliminar"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="outline-info" disabled={ formsValue?.textRemove != remove ? true : false } onClick={handleClose}>
              Eliminar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
