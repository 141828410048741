import React from "react";
import ContentLoader from "react-content-loader";

const UserPageLoadingHeader = () => (
  <ContentLoader
    width="100%"
    height={390}
    // viewBox="0 0 100% 300"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
  >
    <rect x="4" y="0" rx="10" ry="10" width="100%" height="240" />
    <rect x="170" y="260" rx="2" ry="2" width="140" height="13" />

    <rect x="94%" y="270" rx="3" ry="3" width="50" height="40" />

    <rect x="0" y="330" rx="4" ry="4" width="100%" height="17" />
    <rect x="0" y="365" rx="4" ry="4" width="100%" height="17" />
    <rect x="0" y="343" rx="4" ry="4" width="40" height="33" />
    <rect x="420" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="300" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="540" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="670" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="150" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="790" y="343" rx="4" ry="4" width="20" height="33" />
    <rect x="800" y="343" rx="4" ry="4" width="200" height="33" />
  </ContentLoader>
);

const UserPageLoadingCircleHeader = () => (
  <ContentLoader
    width="100%"
    height={390}
    // viewBox="0 0 100% 390"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
  >
    <circle cx="90" cy="250" r="60" />
  </ContentLoader>
);

const UserPageLoadingCircleBackHeader = () => (
  <ContentLoader
    width="100%"
    height={390}
    // viewBox="0 0 100% 390"
    backgroundColor="#ffffff"
    foregroundColor="#ffffff"
  >
    <circle cx="90" cy="250" r="65" />
  </ContentLoader>
);

const UserPageLoadingCirclePHeader = () => (
  <ContentLoader
    width="100%"
    height={390}
    // viewBox="0 0 100% 390"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
  >
    <circle cx="118" cy="250" r="25" />
  </ContentLoader>
);

const UserPageLoadingCirclePBackHeader = () => (
  <ContentLoader
    width="100%"
    height={390}
    // viewBox="0 0 100% 390"
    backgroundColor="#ffffff"
    foregroundColor="#ffffff"
  >
    <circle cx="118" cy="250" r="30" />
  </ContentLoader>
);

const UserPageLoadingBodyLeft = () => (
  <ContentLoader viewBox="0 0 400 340">
    <rect x="5" y="14" rx="2" ry="2" width="100" height="10" />
    {/* <rect x="5" y="34" rx="2" ry="2" width="250" height="10" /> */}

    <rect x="100" y="91" rx="3" ry="3" width="185" height="14" />
    <circle cx="48" cy="98" r="30" />

    <rect x="100" y="171" rx="3" ry="3" width="185" height="14" />
    <circle cx="48" cy="178" r="30" />

    <rect x="100" y="261" rx="3" ry="3" width="185" height="14" />
    <circle cx="48" cy="268" r="30" />
  </ContentLoader>
);

const UserPageLoadingBodyRight = () => {
  return (
    <ContentLoader
      width="100%"
      height={210}
      // viewBox="0 0 700 250"
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      <rect x="0" y="14" rx="2" ry="2" width="140" height="10" />
      <rect x="0" y="40" rx="2" ry="2" width="100%" height="500" />
    </ContentLoader>
  );
};

//  MOBILE

const UserPageLoadingHeaderMobile = () => (
  <ContentLoader
    display="flex"
    width={window.innerWidth}
    height={390}
    //   viewBox="0 0 100% 390"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
  >
   <rect x="4" y="0" rx="10" ry="10" width="100%" height="240" />
    <rect x="170" y="260" rx="2" ry="2" width="140" height="13" />

    {/* <rect x="94%" y="270" rx="3" ry="3" width="50" height="40" /> */}

    <rect x="0" y="330" rx="4" ry="4" width="100%" height="15" />
    <rect x="0" y="355" rx="4" ry="4" width="100%" height="15" />
    <rect x="0" y="333" rx="4" ry="4" width="10" height="33" />
    <rect x="100" y="333" rx="4" ry="4" width="10" height="33" />
    <rect x="200" y="333" rx="4" ry="4" width="10" height="33" />
    <rect x="300" y="333" rx="4" ry="4" width="10" height="33" />
    <rect x="400" y="333" rx="4" ry="4" width="20" height="33" />
    <rect x="500" y="333" rx="4" ry="4" width="20" height="33" />
    <rect x="600" y="333" rx="4" ry="4" width="20" height="33" />
    <rect x="700" y="333" rx="4" ry="4" width="20" height="33" />
    <rect x="800" y="333" rx="4" ry="4" width="200" height="33" />
  </ContentLoader>
);


export {
  UserPageLoadingHeader,
  UserPageLoadingHeaderMobile,
  UserPageLoadingBodyLeft,
  UserPageLoadingBodyRight,
  UserPageLoadingCircleHeader,
  UserPageLoadingCircleBackHeader,
  UserPageLoadingCirclePHeader,
  UserPageLoadingCirclePBackHeader,
};
