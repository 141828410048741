import React from "react";
import ContentLoader from "react-content-loader";

const ProfileMyFriendLoading = () => (
  <ContentLoader
    width="100%"
    height={200}
    viewBox="0 0 300 200"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede">
    <circle cx="50%" cy="58" r="45"/>
    <rect x="33%" y="113" rx="5" ry="5" width="100" height="10" />
    <rect x="25%" y="160" rx="20" ry="20"  width="150" height="30" />
  </ContentLoader>
);

export { ProfileMyFriendLoading };
