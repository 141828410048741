import React from "react";
import Header from "../../headers/Header";
import {
  ViewGroupLoadingBodyLeftMobile,
  ViewGroupLoadingBodyRigthMobile,
  ViewGroupLoadingHeaderMobile,
} from "./ViewGroupLoadingComponents";

export const ViewGroupLoadingMobile = () => {
  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <ViewGroupLoadingHeaderMobile/>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    <ViewGroupLoadingBodyLeftMobile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
