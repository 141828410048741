import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { notificationGroupResponse } from "actions/group";
import { useMediaQuery } from "react-responsive";

export const ViewGroupDeleteMember = () => {

  const { uid } = useSelector((state) => state.auth);
  const { tempGroup } = useSelector((state) => state.group);
  const dispatch = useDispatch();

  

 const handleShowAlert = ()=>{
    Swal.fire({
        title: 'Are you sure you want to leave the group?',
        
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: 'red'
      }).then((result) => {
        const data = {
            groupId: tempGroup?.uid,
            userId: uid,
            state: false
          }
          if (result.isConfirmed) {
            dispatch(notificationGroupResponse(data));
        } 
      })
 }

 const isDesktopOrLaptop = useMediaQuery({
  query: "(min-width: 980px)",
});

  return (
    <>
    {
      isDesktopOrLaptop ?
          <div
            onClick={handleShowAlert}
            className="cursor-pointer align-items-center justify-content-center p-3 top-0 ">
            <a className=" d-none d-lg-block bg-danger p-2 z-index-1 rounded-3 text-white font-xsss fw-700 ls-3">
              Leave group
            </a>
          </div>
         :
         <li onClick={handleShowAlert} className="cursor-pointer d-flex" style={{ borderBottom: '1px solid #00589b60' }}>
            <div className='icon-li '  >
                  <i className="font-sm feather-x text-grey"></i> 
            </div> 
            <div className='text-li'>
                <span className='pb-1'> Leave group </span>
            </div>
          </li>
    }
     
     
    </>
  );
};
