import React from "react";
import Header from "../headers/Header";

import { GoBackNavigate } from "../shared/GoBack";
import { useViewCourse } from "Hooks/course/useViewCourse";
import { ShareCourse } from "./ShareCourse";
import { useBtnOptionMenu } from "Hooks/useBtnOptionMenu";
import { ViewCourseMenuItem } from "./all/components/ViewCourseMenuItem";
import { RouterViewCourse } from "./routes/RouterViewCourse";
import { ViewCourseOptions } from "./all/components/ViewCourseOptions";
import { ViewCourseLoadingMobile } from "./loading/LoadingMobile";
import { useMediaQuery } from "react-responsive";
import { ViewCourseLoadingBrowser } from "./loading/LoadingBrowser";
import { imageExistsProduct } from "helpers/verifyImg";

export const ViewCourse = () => {
  const {
    userIdActive,
    courseIdVisited,
    loading,
    tempCourse,
    exitsMember,
    loadingMembers,
    navigate,
    page403,
  } = useViewCourse();
  const { openModal, notiClass, wrapperRef, closeModal } = useBtnOptionMenu();
  const active = exitsMember == "success" || tempCourse?.userId == userIdActive;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 980px)",
  });

  if (page403) {
    navigate.push("/notfound");
  }

  return (
    <>
      {loading ? (
        <>
          {isDesktopOrLaptop ? (
            <ViewCourseLoadingBrowser />
          ) : (
            <ViewCourseLoadingMobile />
          )}
        </>
      ) : (
        <>
          <Header />
          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12 mb-3">
                    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                      <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                        <GoBackNavigate onClick={() => navigate.goBack()} />
                        <img
                          src={tempCourse?.pageCover}
                          style={{ objectFit: "cover" }}
                          width="1200"
                          height="250"
                          alt="avater"
                          onError={imageExistsProduct}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-7">
                          <h4 className="fw-700 font-sm mt-2 mb-lg-1 mb-1 pt-2 pl-4">
                            {tempCourse?.title}
                          </h4>
                          {/* <span className="pl-4 text-grey-900 font-xssss mt-0">
                            {"Published " + project?.createDate}
                          </span> */}
                        </div>
                        <div className="col-md-5">
                          <ViewCourseOptions
                            idcourse={courseIdVisited}
                            statusMember={exitsMember}
                            nameCourse={tempCourse?.title}
                            courseValue={tempCourse}
                            userIdActive={userIdActive}
                            openModal={openModal}
                          />
                          <ShareCourse
                            notiClass={notiClass}
                            closeOpen={closeModal}
                          />
                        </div>
                      </div>

                      <ViewCourseMenuItem
                        uid={tempCourse?.uid}
                        userId={tempCourse?.userId}
                        userIdActive={userIdActive}
                      />
                    </div>
                  </div>
                </div>

                <RouterViewCourse
                  courseIdVisited={courseIdVisited}
                  exitsMember={exitsMember}
                  userIdActive={userIdActive}
                  tempCourse={tempCourse ?? {}}
                  loadingMembers={loadingMembers}
                  active={active}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
