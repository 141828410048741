import React from 'react';
import { Link } from 'react-router-dom';
import { useGroupSuggestions } from 'Hooks/group/useGroupSuggestions';
import { SuggestionGroupLoading } from 'components/Loadings/SuggestionGroupLoading';

const GroupSuggestions = () => {

    const{ loading, groupsSuggestionList } = useGroupSuggestions();

        return (
            <>
              {
                 loading && 
                   <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3" >
                   <div className="card-body d-flex align-items-center p-4">
                       <h4 className="fw-700 mb-0 font-xssss text-grey-900">Group suggestions</h4>
                       <Link to="/groups" className="fw-600 ms-auto font-xssss text-primary"> <span>See all</span> </Link>
                   </div>
                   <SuggestionGroupLoading/>
               </div>
              }
              {
                groupsSuggestionList?.length > 0 &&
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3" >
                    <div className="card-body d-flex align-items-center p-4">
                        <h4 className="fw-700 mb-0 font-xssss text-grey-900">Group suggestions</h4>
                        <Link to="/groups" className="fw-600 ms-auto font-xssss text-primary"> <span>See all</span> </Link>
                    </div>
                    {
                        groupsSuggestionList?.map((value , index) => (
                        <div key={index}  className="card w110 d-block border-0 shadow-xss rounded-xxl overflow-hidden mb-3 me-3 ml-3 wrap animate__animated animate__fadeIn">
                            <div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{backgroundImage: `url("${value.pageCover  ? value.pageCover : '/assets/images/1200x450.png' }")`}}></div>
                            <div className="card-body d-block w-100 ps-4 pe-4  text-center">
                                <div className="clearfix"></div>
                                <h4 className="fw-700 font-xsss mt-2 mb-1">{value.title} </h4>
                                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">{value.email}</p>
                                <Link to={`/ViewGroup/${value?.uid}`} className="btn-round-sm bg-white ms-auto mt-2" style={{ position: 'absolute' , top: 0, right: 10 }} ><span className="feather-chevron-right font-xss text-grey-900"></span> </Link>
                                <div className="clearfix mb-2"></div>
                            </div>
                        </div>
                        ))
                    }
                </div>
              }
            </>
       );
}

export default GroupSuggestions;