import React from 'react';
import { AddTypeShare } from '../shared/AddTypeShare';
import { useCourseShare } from 'Hooks/course/useCourseShare';;

export const ShareCourse = ({notiClass, closeOpen}) => {
  const { onChangeFriendsShare, dataType, handleShare, selectedType, dataGroup, onChangeContent, titleContent, onChangePublishedShare} = useCourseShare();

  const share = () => {
    handleShare(); 
    closeOpen();
  }
  return <>
           <div className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0 ${notiClass}`} aria-labelledby={`dropdownMenu`} style={{ zIndex: '2' }}>
               <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center" >Share <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2" onClick={ closeOpen }></i></h4>
               <AddTypeShare 
                 data={ dataType }
                 titleAddTypeShare= 'Select where you want to post'
                 name='type'
                 handleInputChange={onChangeFriendsShare}/>
                 {
                   selectedType !== '' && (
                    <>

                      {
                       selectedType === 'Groups' && (
                         <AddTypeShare 
                           data={ dataGroup }
                           titleAddTypeShare= 'Select group'
                           name='group'
                           handleInputChange={onChangePublishedShare}/>
                       )
                      }

                        <div className="form-group" style={{ paddingLeft: 15, paddingRight: 15 }}>
                            <label className="mont-font fw-600 font-xsss">Description</label>
                            <textarea
                              type="text"
                              name="description"
                              value={titleContent || ""}
                              className="form-control"
                              onChange={onChangeContent}
                              placeholder="description"
                              required
                            ></textarea>
                         </div>
                    
                    </>
                   )
                 }

                <div className=" align-items-center justify-content-center pl-1 pt-5 ">
                    <span className="cursor-pointer text-center d-lg-block bg-success p-2 rounded-3 text-white font-xsss fw-700 ls-3" onClick={ share }>
                      Share the project
                    </span>
                 </div>
           </div>
        </>;
};