import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../Hooks/userForms";
import { CreateUser } from "../actions/auth";
import validator from "validator";
import { removeError, setError } from "../actions/ui";
import SliderInfo from "components/sliderInformation/SliderInfo";
import { titleCase } from "helpers/titleCase";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const Register = ({ docInfo, docInfoLoading }) => {
  const dispatch = useDispatch();
  const [datos, setdatos] = useForm({
    userName: "",
    phoneNumber: "",
    email: "",
    password: "",
    password2: "",
  });

  const handledRegister = async (event) => {
    event.preventDefault();

    if (isFormValidate()) {
      delete datos["password2"];
      const userName = titleCase(datos?.userName);
      dispatch(CreateUser({ ...datos, userName }));
    }
  };
  const { msgError } = useSelector((state) => state.ui);
  const pattern = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g;

  const isFormValidate = () => {
    if (datos.userName.trim().length === 0 || !pattern.test(datos.userName)) {
      dispatch(setError("UserName no validate"));
      return false;
    } else if (!validator.isEmail(datos.email)) {
      dispatch(setError("Email is not validate"));
      return false;
    } else if (
      !validator.isNumeric(datos.phoneNumber) ||
      datos.phoneNumber.trim().length != 10
    ) {
      dispatch(setError("PhoneNumber no validate"));

      return false;
    } else if (datos.password.trim().length < 5) {
      dispatch(setError("Password must have more than 6 characters"));
      return false;
    } else if (datos.password.trim() !== datos.password2.trim()) {
      dispatch(setError("Passwords do not match"));
      return false;
    }
    dispatch(removeError());

    return true;
  };

  return (
    <Fragment>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100">
            {/* <a href="/"><i className="feather-zap text-success display1-size me-2 ms-0"></i><span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">Sociala. </span> </a> */}
            <button className="nav-menu me-0 ms-auto"></button>

            <Link to="/auth/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</Link>
                            <Link to="/auth/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</Link>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6">
            <SliderInfo docInfo={docInfo} docInfoLoading={docInfoLoading} />
          </div>
          <div className="col-xl-6 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-4">
                  Create <br />
                  your account
                </h2>

                <form onSubmit={handledRegister}>
                  {msgError && (
                    <div className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0 ">
                      {msgError}
                    </div>
                  )}

                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-user text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      name="userName"
                      onChange={setdatos}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      name="email"
                      onChange={setdatos}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Your Email Address"
                    />
                  </div>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-mobile text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      name="phoneNumber"
                      onChange={setdatos}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Your PhoneNumber"
                    />
                  </div>
                  <div className="form-group icon-input mb-3">
                    <input
                      type="Password"
                      name="password"
                      onChange={setdatos}
                      className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                      placeholder="Password"
                    />
                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                  </div>
                  <div className="form-group icon-input mb-1">
                    <input
                      type="Password"
                      name="password2"
                      className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                      onChange={setdatos}
                      placeholder="Confirm Password"
                    />
                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                  </div>
                  <div className="form-check text-left mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input mt-2"
                      id="exampleCheck2"
                    />
                    <label className="form-check-label font-xsss text-grey-500">
                      Accept Term and Conditions
                    </label>
                  </div>
                  <div className="col-sm-12 p-0 text-left">
                    <button
                      type="submit"
                      className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                    >
                      Register
                    </button>
                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                      Already have account{" "}
                      <Link to="/auth/login" className="fw-700 ms-1">
                        Login
                      </Link>
                    </h6>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Register;
