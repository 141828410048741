import { getOrganizations } from "../actions/organization";



export const types ={
    adminGetInformacionGeneral: "adminGetInformacionGeneral",
    isAdminActive: "isAdminActive",
    navigate: '[route] navigate',

    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiSetError: '[UI] ser Error',
    RemoveError: '[UI] Remove Error',
    UploadingFiles: 'UploadingFiles',

    getUpdateProfileImage: '[data] getUpdateProfileImage',
    getUpdateProfile: '[data] getUpdateProfile',
    getUpdateProfileImageReset: '[reset] getUpdateProfileImageReset',
    getUpdateProfileExperience: '[data] getUpdateProfileExperience',
    getCreateProfileExperience: '[data] getCreateProfileExperience',
    getOrganizationExperienceToRemoveProfile: '[data] getOrganizationExperienceToRemoveProfile',
    userLoading: '[data user loading]',
    userLoadingoff: '[data user loading off]',

    getMyProject: '[data] getMyProject',
    getMyProjectParticipate: '[data] getMyProjectParticipate',
    loadingproject: '[data] projectLoading',
    createProject: '[data] createProject',
    updateProject: '[data] updateProject',
    createUpdateEnd: '[data] createUpdateEnd',
    resetproject: '[data] project final reset',
    exitMemberProject: '[data] project exitMemberProjecto',
    removeProject: '[data] removeProject',
    isRemoveProjectReset: '[reset] isRemoveProjectReset',
    loadingMembersProject: 'loadingMembersProjecti',
    MembersListProject: 'ListProjectMembers',
    NotificationsMembers: 'NotificationsMembers{}',

    //change profile project
    isChangeMyProject: '[data] isChangeMyProject',

    tempProject: '[data] tempProject',
    loadingprojectview: '[data] project loading view',
    // removeloadingproyect: '[data] remove loading',

    getBasicInfo: '[data] getBasicInfo',
    getInformationIndicators: '[data] getInformationIndicators',
    getHelpsInfo: '[data] getHelpsInfo',
    
    getQuerySearch: '[data] getQuerySearch',
    users: '[data] users',
    getAllUserLoading: '[data] getAllUserLoading',
    allUserResponse: '[data] allUserResponse',
    usersExperience: '[data] users experience',

    loadingOrganization: '[loading] loadingOrganization',
    loadingOrganizationNavigation: '[loading] loadingOrganizationNavigation',
    loadingOrganizationNavigationRefresh: '[loading] loadingOrganizationNavigationRefresh',
    removeTempOrganization: '[loading] removeTempOrganization',
    tempImageOrganization: '[temp] tempImageOrganization',
    tempOrganization: '[data] tempOrganization',
    tempOrganizationLoad: '[data] tempOrganizationLoad',
    getMyOrganization: '[data] getMyOrganization',
    getAllOrganization: 'All Organizations',
    createOrganization: '[data] createOrganization',
    updateOrganization: '[data] updateOrganization',
    //change profile group
    isChangeMyOrganization: '[data] isChangeMyGroup',
    
    //postGroup
    createPostGroup: '[data] createPostGroup',
    updatePostGroup: '[data] updatePostGroup',
    removePostGroup: '[data] removePostGroup',
    getPostsGroup: '[data] getPostsGroup',
    getLikePostGroup: '[data] getLikePostGroup',
    getConformLikePostGroup: '[data] getConformLikePostGroup',
    isChangePostsGroup: '[data] isChangePostsGroup',
    createPost: '[data] createPost',
    isCreateReset: '[reset] isCreateReset',
    loadingPost: '[loading] loadingPost',
    loadingMyPost: 'loadingmtpostOn',
    loadingPostgroup: 'loadingPostgroup[::]',
    getGroupsIBelongTo: '[data] getGroupsIBelongTo',
    getPostsById: '[data] getPostsById',
    getPostsByIdRender: '[data] getPostsByIdRender',

    getPosts: '[data] getPosts',
    getLikePost: '[[data]]getLikePost',
    getConformLikePost: '[data] getConformLikePost',
    tempPost: '[data] tempPost',
    updatePost: '[data] updatePost',
    isUpdateReset: '[reset] isUpdateReset',
    removePost: '[data] removePost',
    isRemoveReset: '[reset] isRemoveReset',
    isChange: '[data] isChange',
    isChangeReset: '[reset] isChangeReset',
    
    getGroups: '[data] getGroups',
    getGroupsParticipate: '[data] getGroupsParticipate',
    removeTempGroup: '[data] removeTempGroup',
    loadingGroup: '[loading loadingGroup',
    userMemberGroup: '[temp] membergroup',
    allMembersGroup: '[temp] membergroupAll',
    allMembersDataGroup: '[temp] membergroupAllData',
    loadingMembersgroup: '[temp] membergroupAllDataloading',
    getGroupsSuggestionList: '[data] getGroupsSuggestionList',
    allGroupResponse: '[data] allGroups',
    createGroup: '[data] createGroup',
    tempGroupLoad: '[data] tempGroupLoad',
    tempGroup: '[data] tempGroup',
    updateGroup: '[data] updateGroup',
    //change profile group
    isChangeMyGroup: '[data] isChangeMyGroup',
    getAllGroupLoading: '[data] getAllGroupLoading',

    getPage403: '[data] getPage403',
    getPage403Reset: '[reset] getPage403Reset',

    //friends
    loadingFriends: '[loading] loadingFriends',
    getMyFriends: '[data] getMyFriends',
    getMyFriendsSize: '[data] getMyFriendsSize',
    loadingMyfriends: 'loadingMyfriends',
    loadingMyfriendsoff: 'loadingMyfriendsoff',
    usersFriends: 'userFriends',
    
    //change profile friend
    isChangeMyFriend: '[data] isChangeMyFriend',

    // Courses
    getCourses: '[mycourses]',
    getCoursesParticipate: '[data] getCoursesParticipate',
    loadingCourses: '[loadingCourses]',
    loadingCoursesoff: '[loadingCoursesoff]',
    createCourse: '[data] createCourse',
    updateCourse: '[data] updateCourse',
    tempCourse: '[tempCourseCreate]',
    tempCourseLoad: '[data] tempCourseLoad',
    finalCourse: '[tempCoursefinal]',
    tempFriendInvitation: '[data] tempFriendInvitation',
    exitsMemberCourse: 'exitsMemberCourse123',
    loadingMembersCourse: "loadinng course members",
    membersCourse: "membersCourseList",
    isChangeMyCourse: '[data] isChangeMyCourse',
    allCourses: '[data] allCourses',
    notificationsMemberscourse: 'notificationsMemberscourse[.|.]',
    loagingAllCourse: 'loagingAllCourse[+]',

    //Users global
    users_global: '[data] users_global',
    userSuggestions: '[data] userSuggestions',
    //Projects global
    projectsGlobal: '[data] projectsGlobal',
    projectSuggestions: '[data] projectSuggestions',
    loadingAllProject: 'loadingAllproject[...]',

    //Courses global
    coursesGlobal: '[data] coursesGlobal',
    courseSuggestions: '[data] courseSuggestions',
    resetCourse: '[data] Resetscourse',
    tempFriendInvitation: '[data] tempFriendInvitation',

    //ComentsPost
    getComments: '[data] getComments',
    createComents: 'CreateComents',
    updateComment: '[data] updateComment',
    isUpdateReset: '[reset] isUpdateReset',
    isRemoveReset: '[reset] isRemoveReset',
    loadingComents: 'LoadingComents',
    tempComment: 'temporalComments',
    resetComment: 'resetComjen tr',
    getRemoveComment: '[data] getRemoveComment',
    resetCommentsPostById: '[reset} resetCommentsPostById',
    viewNewPost:  '[reset] viewNewPost',
    
    getToken: '[data] getToken',
    allNotificationsResponse: '[data] allNotificationsResponse',
    newNotification: '[data] newNotification',
    newNotificationReset: '[reset] newNotificationReset',
    changeNotificationStateById: '[data] changeNotificationStateById',
    removeNotificationById: '[data] removeNotificationById',
    removeAllNotifications: '[data] removeAllNotifications',
    removeNotificationByIdReset: '[data] removeNotificationByIdReset',
    loadingAllNotification: '[data] loadingAllNotification',
    newNotificationActive: '[data] newNotificationActive',

    // Comments FORUM

    loadingCreateForum: 'loadingCreateForum[data]',
    CreateForum: 'CreateForum[data]',
    getCommentsForum: 'getcommentsForumjs',
    resetCreateComment: 'resetCreateComment',
    isChangeCommentsForum: 'isChangeCommentsForum',
    updateForum: 'updateforumjs',
    updateForumReplyComment: 'updateForumReplyComment',
    getFoumReplyComment : 'getFoumReplyComment',
    loadinRplyForum: 'loadinRplyForum',
    updateForumReset: 'updateForumResert',
    deleteForum: 'deleteforum',
    deleteForumReset: 'Deleteforun reset',

    chatCount: 'chatmesssage count'

}