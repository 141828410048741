
import { StreamChat } from 'stream-chat';
import { chatCountMessage } from '../actions/ui';
export const client = StreamChat.getInstance('p6gqdr46chg7');

export const Allusers = async (users) => {
    if(users.length > 0){
        const data = await client.queryUsers({  id: { $in: [...users] } } );
    }
};

export const ConnectUser = async (uid, name, url) => {

const token =  client.devToken(uid).toString();

  const user =  await client.connectUser(
      {
        id: uid,
        name: name,
        image: url,
      },
      token,
    );
    // saber cuantos mensajes no leidos al iniciar
    // chatCountMessage(user.me.total_unread_count)
    // console.log(`you have ${user.me.total_unread_count} unread messages on ${user.me.unread_channels} channels.`);
}