import React from "react";
import Load from "components/Load";
import PostViewShare from "../components/PostViewShare";
import PostView from "../PostView";

import { EmptyData } from "components/shared/EmptyData";
import { usePostViewGroup } from "Hooks/post/usePostViewGroup";
import { typeEventLike, typeEventPost } from "helpers/typeEvent";

export const PostViewListGroup = ({ idgroup, existMember }) => {
  const { loadingPostgroup, docs, loadingPagination, lastPostsElementRef } =
    usePostViewGroup(idgroup);

  if (loadingPostgroup && docs.length <= 0) {
    return (
      <div style={{ marginBottom: "100px" }}>
        <Load />;
      </div>
    );
  }

  if (!existMember) {
    return (
      <EmptyData
        pathImg="../../assets/images/privateGroup.svg"
        title="Private group"
      />
    );
  }
  if (docs.length <= 0) {
    return (
      <EmptyData
        pathImg="../../assets/images/empty.svg"
        title="no publications"
      />
    );
  }

  return (
    <div style={{ marginBottom: "100px" }}>
      {docs?.map((element, index) => {
        if (docs.length === index + 1) {
          if (element?.type == undefined) {
            return (
              <div key={element?.uid} ref={lastPostsElementRef}>
                <PostView
                  id={element?.uid}
                  groupId={idgroup}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.group}
                  typeLike={typeEventLike.group}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                />
              </div>
            );
          } else {
            return (
              <div key={element?.uid} ref={lastPostsElementRef}>
                <PostViewShare
                  id={element?.uid}
                  groupId={idgroup}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.group}
                  typeLike={typeEventLike.group}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                />
              </div>
            );
          }
        } else {
          if (element?.type == undefined) {
            return (
              <PostView
                key={element?.uid}
                id={element?.uid}
                groupId={idgroup}
                postVideo=""
                postImage={element?.attachments}
                avater={element?.publicUser?.photoUrl}
                userId={element?.publicUser?.userId}
                userName={element?.publicUser?.userName}
                time={element?.createDate}
                des={element?.content}
                like={element?.like}
                comment={element?.comment}
                type={typeEventPost.group}
                typeLike={typeEventLike.group}
                typePost={element?.type}
                Linkto={element?.linkTo}
              />
            );
          } else {
            return (
              <div key={element?.uid}>
                <PostViewShare
                  id={element?.uid}
                  groupId={idgroup}
                  postVideo=""
                  postImage={element?.attachments}
                  avater={element?.publicUser?.photoUrl}
                  userId={element?.publicUser?.userId}
                  userName={element?.publicUser?.userName}
                  time={element?.createDate}
                  des={element?.content}
                  like={element?.like}
                  comment={element?.comment}
                  type={typeEventPost.group}
                  typeLike={typeEventLike.group}
                  typePost={element?.type}
                  Linkto={element?.linkTo}
                />
              </div>
            );
          }
        }
      })}
      {loadingPagination && <Load />}
    </div>
  );
};
