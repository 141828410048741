import { GetGenerarReporte } from "actions/admins";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


export const useAdmin = () => {
   
    const {loading, data} = useSelector( state => state.admin );
   const dispatch = useDispatch();

   useEffect(() => {
     !data  && dispatch(GetGenerarReporte())
   }, [])

  return {
    loading,
    data
  }
}
