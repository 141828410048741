import React from 'react'
import PropTypes from 'prop-types';
import { ContainerRouteProfileOptions } from './ContainerRouteProfileOptions';
import { useHistory } from 'react-router-dom';

export const CardRouteProfileOptions = ({ component: Component , title  }) => {
    const history = useHistory();

   const back = () =>{
       history.goBack();
   }
    const cartRoute = () => {

        return (
            <div className="main-content theme-dark-bg right-chat-active mt-5">
                            
                 <div className="middle-sidebar-bottom">
                     <div className="middle-sidebar-left">
                         <div className="middle-wrap">
                             <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                 <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                     <li onClick={back}  className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></li>
                                     <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">{ title } </h4>
                                 </div>
                                 <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                
                                     <Component />
                                  
                                 </div>
                             </div>
                             
                         </div>
                     </div>
                     
                 </div>            
             </div>

        )
    }
    return (
        <ContainerRouteProfileOptions component={ cartRoute }/>
    )
}

CardRouteProfileOptions.propTypes = {
    title: PropTypes.string,
    component: PropTypes.func.isRequired,
}