import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUser } from "actions/profile";
import { removeError, setError } from "actions/ui";
import validator from "validator";

export const FormEditUser = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ user: state }) => state.user);
  const { msgError } = useSelector((state) => state.ui);

  const [formsValue, setfomrs] = useState({
    email: user?.email,
    city: user?.city ?? "",
    userName: user?.userName,
    address: user?.address ?? "",
    phoneNumber: user.phoneNumber.includes("+57") ? user.phoneNumber.replaceAll("+57", "") : user.phoneNumber,
    country: user?.country ?? "",
  });

  useEffect(() => {
    setfomrs({
      email: user?.email,
      city: user?.city ?? "",
      userName: user?.userName,
      address: user?.address ?? "",
      phoneNumber:user.phoneNumber.includes("+57") ? user.phoneNumber.replaceAll("+57", "") : user.phoneNumber,
      country: user?.country ?? "",
    });
  }, [user]);

  const handlerform = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfomrs({
      ...formsValue,
      [event.target.name]: value,
    });
  };

  //  Validations
  const isFormValidate = () => {
    const pattern = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g;

    if (
      formsValue?.userName.trim().length === 0 ||
      !pattern.test(formsValue.userName)
    ) {
      dispatch(setError("UserName no validate"));
      return false;
    } else if (
      formsValue.phoneNumber.trim().length !== 10 ||
      !validator.isNumeric(formsValue.phoneNumber)
    ) {
      dispatch(setError("PhoneNumber no validate"));

      return false;
    }

    dispatch(removeError());

    return true;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      uid: user.uid,
      email: formsValue.email,
      city: formsValue.city,
      userName: formsValue.userName,
      address: formsValue.address,
      phoneNumber: `+57${formsValue.phoneNumber}`,
      country: formsValue.country,
    };
    if (isFormValidate()) {
      dispatch(UpdateUser(data));
    }
  };

  return (
    <div className="card w-100 border-0 p-3 bg-white shadow-xss rounded-xxl">
      <div className="p-1">
        <span>Editar Informacion Personal</span>
      </div>

      <form onSubmit={handleLogin}>
        {msgError && (
          <div className="form-group mb-1 form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0 ">
            {msgError}
          </div>
        )}

        <div className="row">
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Name</label>
              <input
                type="text"
                value={formsValue.userName}
                name="userName"
                onChange={handlerform}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Email</label>
              <input
                type="text"
                value={formsValue.email}
                name="email"
                disabled
                onChange={handlerform}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Telefono</label>
              <input
                type="text"
                value={formsValue.phoneNumber}
                name="phoneNumber"
                onChange={handlerform}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Country</label>
              <input
                type="text"
                value={formsValue.country}
                name="country"
                onChange={handlerform}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">City</label>
              <input
                type="text"
                value={formsValue.city}
                name="city"
                onChange={handlerform}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Address</label>
              <input
                type="text"
                value={formsValue.address}
                name="address"
                onChange={handlerform}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12 mb-5 mt-2 ps-0">
          <button type="submit" className="btn btn-secondary">
            Actualizar Informacion
          </button>
        </div>
      </form>
    </div>
  );
};
