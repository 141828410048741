import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewNewPostById } from "actions/comments";

export const usePostView = (des) => {
  const dispatch = useDispatch();

  const initialState = {
    isOpen: false,
    isActive: false,
  };
  const { uid: userId } = useSelector((state) => state.auth);
  const [state, setState] = useState(initialState);
  const toggleOpen = () => setState({ isOpen: !state.isOpen });
  const toggleActive = () => setState({ isActive: !state.isActive });

  const [loadMoreComment, setLoadMoreComment] = useState("");
  const [isLoadMoreComment, setIsLoadMoreComment] = useState(
    des?.length > 200 ? false : true
  );

  const menuClass = `${state.isOpen ? " show" : ""}`;
  const emojiClass = `${state.isActive ? " active" : ""}`;

  const listMenuOption = [
    {
      title: "Editar",
      to: "/userpage/group/mys-groups",
    },
    {
      title: "Eliminar",
      to: "/CreateGroup",
    },
  ];

  const menuOption = {
    list: listMenuOption,
    title: "Menu",
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  ===> LOAD MORE
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (des?.length > 200) {
      setLoadMoreComment(`${des.substr(0, 199)}...`);
    } else {
      setLoadMoreComment(des);
    }
  }, [des]);

  const handleViewNewPostById = () => {
    dispatch(viewNewPostById());
  };

  const loadMore = () => {
    if (!isLoadMoreComment) {
      setLoadMoreComment(des);
      setIsLoadMoreComment(true);
    } else {
      setIsLoadMoreComment(false);
      if (des.length > 200) {
        setLoadMoreComment(des.substr(0, 199));
      } else {
        setLoadMoreComment(des);
      }
    }
  };

  return {
    userId,
    menuClass,
    emojiClass,
    menuOption,
    toggleOpen,
    toggleActive,
    handleViewNewPostById,
    loadMore,
    loadMoreComment,
    isLoadMoreComment,
  };
};
